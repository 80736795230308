
import { Lightning, Colors } from "@lightningjs/sdk"

export default class AmbientBackground extends Lightning.Component {
    static _template() {
        return {
            w: 1920,
            h: 1080,
            rect: true,
            color: Colors('backgroundColor').get(),
        }
    }
}
