import { Router, Lightning, Language, Profile, Colors } from '@lightningjs/sdk'
import { SettingsAction, SettingsView } from '../Components/index.js'
import { getUserInfo } from '../Services/PersonaService.js'
import configurations from '../Helper/Configurations.js'
import { StaticPageResponse } from '../Models/StaticPageRespModel.js'
import { getStaticPageDetails } from '../Services/AssetsService.js'

export default class Settings extends Lightning.Component {
    static _template() {
        return {
            w: 1920,
            h: 1080,
            rect: true,
            color: Colors('backgroundColor').get(),
            SettingsAction: { type: SettingsAction },
            SettingsView: { type: SettingsView }
        }
    }

    _focus() {
        this.patch({ alpha: 1 })
    }

    _firstActive() {
        this.buttonsView()
        this.defaultView()
        this._setState('SettingsAction')
    }

    buttonsView() {
        const totalButtons = []
        const SettingsButtons = [ ...this.staticPageButtons() ]
        const LoginButtons = [
          {
            label: Language.translate(configurations.loggedIn === 0 ? 'common_keys.sign_in.btn' : 'common_keys.sign_out.btn'),
            type: configurations.loggedIn === 0 ? 'SignIn' : 'SignOut'
          }
        ]
        const personalInfoBtn = { label: Language.translate('account.personal_info.text'), type: 'PersonalInfo' }

        const manageDevices = { label: Language.translate('common_keys.manage_all_devices.text'), type: 'ManageDevices' }

        const changeLanguage = { label: Language.translate('change_language.change_language.text'), type: 'AppLanguage' }

        if (configurations.locales.length > 1) SettingsButtons.unshift(changeLanguage)

        if (configurations.loggedIn && configurations.enableDeviceMangement) SettingsButtons.unshift(manageDevices)

        const ListButtons = configurations.loggedIn ? [personalInfoBtn, ...SettingsButtons] : SettingsButtons

        totalButtons.push(ListButtons)

        if (!configurations.authenticateWithEntitlement) {
          totalButtons.push(LoginButtons)
        }
        this.totalButtons = totalButtons
        this.tag('SettingsAction').data = { buttonData: totalButtons } // Settings Action
    }

    async defaultView() {
        let data
        if (configurations.loggedIn && !configurations.authenticateWithEntitlement) {
            const profileResponse = await Promise.all([getUserInfo()])
            data = profileResponse[0].data
            configurations.email = data.user_email
        } else if (configurations.authenticateWithEntitlement) {
            data = this.showHouseholdDetails()
        } else if (configurations.locales.length > 1) {
            data = configurations.locales
        } else {
            data = new StaticPageResponse(await getStaticPageDetails(this.totalButtons[0][0].type))
            return this.staticPageScrollView(data)
        }
        this.tag('SettingsView').data = { data: data }
    }


    showHouseholdDetails() {
        if (configurations.authenticateWithEntitlement) {
            Profile.household().then((res) => {
                return res || 'b2244e9d4c04826ccd5a7b2c2a50e7d4'
            })
        }
    }

    staticPageButtons() {
        for (const key in configurations.staticPages_data) {
            if (configurations.staticPages_data.hasOwnProperty(key) && key === configurations.region) {
                return configurations.staticPages_data[key]
            }
        }
    }

    focusSettingsView() {
      this._setState('SettingsView')
    }

    userDetailsView(data) {
      this.tag('SettingsView').userDetailsView(data)
    }

    languageOptionsView() {
      this.tag('SettingsView').languageOptionsView()
    }

    staticPageScrollView(data) {
      this.tag('SettingsView').staticPageScrollView(data)
    }

    focusSettingsAction() {
      this._setState('SettingsAction')
    }

    _getFocused() {
       return this.tag('SettingsAction')
    }

    _handleBack() {
      Router.focusWidget('Menu')
    }

    static _states() {
      return [
        class SettingsAction extends this {
          _getFocused() {
            return this.tag('SettingsAction')
          }
        },

        class SettingsView extends this {
          _getFocused() {
            return this.tag('SettingsView')
          }
        },
      ]
    }
}
