import { Lightning, Utils, Colors } from '@lightningjs/sdk'
import configurations from '../../Helper/Configurations'
import { returnImageUrl } from '../../Helper/Helpers'
import { imageSizes } from '../../Helper/GlobalConstants'

export default class Channel extends Lightning.Component {
  static _template() {
    return {
      Item: {
        w: Channel.width - 3, h: Channel.height - 3, rect: true, zIndex: 1,
        Img: { x: 12, y: 20, w: 135, h: 80, zIndex: 3, },
      },
    }
  }

  static get width() {
    return 160
  }

  static get height() {
    return 120
  }

  set item([item, selectedChannel, selectedChannelIndex]) {
    this._item = item
    this.selectedChannel = selectedChannel
    this.selectedChannelIndex = selectedChannelIndex
    this.patch({ Item: { Img: { src: Utils.proxyUrl(returnImageUrl(this._item.content_logo, imageSizes.icon)) } }, })
    this.selectedChannel
      ? this.patch({
        Item: {
          texture: Lightning.Tools.getRoundRect(Channel.width - 3, Channel.height - 3, 0, 3, Colors('cardColor').get(), true, Colors('cardColor').get(),),
        },
      })
      : this.patch({
        Item: {
          texture: Lightning.Tools.getRoundRect(Channel.width - 3, Channel.height - 3, 0, 3, Colors('backgroundColor').get(), true, Colors('backgroundColor').get()),
        },
      })
  }

  set index(_index) { this.tag('Item').alpha = 1 }

  _focus() {
    this.patch({
      Item: { texture: Lightning.Tools.getRoundRect(Channel.width - 3, Channel.height - 3, 0, 3, Colors('brandPrimaryColor').get(), true, Colors('backgroundColor').get()) },
    })
  }

  _unfocus() {
    this.selectedChannel
      ? this.patch({
        Item: {
          texture: Lightning.Tools.getRoundRect(Channel.width - 3, Channel.height - 3, 0, 3, Colors('cardColor').get(), true, Colors('cardColor').get(),),
        },
      })
      : this.patch({
        Item: {
          texture: Lightning.Tools.getRoundRect(Channel.width - 3, Channel.height - 3, 0, 3, Colors('backgroundColor').get(), true, Colors('backgroundColor').get()),
        },
      })
  }
}
