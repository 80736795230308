import { Lightning,Utils, Colors } from '@lightningjs/sdk'
import App from '../App.js'
import Meta from '../../metadata.json'
import { fontsizes } from '../Helper/GlobalConstants.js'

export default class Splash extends Lightning.Component {
  static _template() {
    return {
      TopToBottomGradient: {
        w: App.width,
        h: App.height,
        rect: true,
        color: Colors('splashScreenBgColor').get(),
      },
      Footer: {
        alpha: 1,
        w: App.width,
        h: App.height * 0.13,
        color: Colors('splashScreenBgColor').get(),
        rect: true,
        y: App.height - App.height * 0.13,
        Version: {
          countY: 0.5,
          x: App.width - 290,
          y: App.height * 0.13 * 0.5,
          mountY: 0.5,
          text: {
            text: `VERSION ${Meta.version}`,
            fontFace: 'Regular',
            fontSize: fontsizes.splashPageFooter,
            textColor: Colors('primaryFontColor1').get(),
          },
        },
      },
     Logo: {
        x: 962,
        y: 542,
        mount: 0.5,
        src: Utils.asset('static_assets/splashLogo.png')
      },
    }
  }

  _handleBack() { return }

  _handleKey() { return }

  _handleEnter() { return }

  _handleRight() { return }

  _handleLeft() { return }

  _handleUp() { return }

  _handleDown() { return }
}
