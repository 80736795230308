import configurations from "../Helper/Configurations"

export const trackLog = (message) => {
    const errorObject = {
      household: configurations.household,
      UserAgent: navigator.userAgent,
      Device: 'Metro',
      message: message,
    }
    const request = { method: 'POST' }
    const url = `${configurations.baseUrl}v2/log`
    request['body'] = JSON.stringify(errorObject)
    const res = fetch(url, request)
    console.log(res)
  }
  
  export const trackError = (message) => {
    const errorObject = {
      household: configurations.household,
      UserAgent: navigator.userAgent,
      Device: 'Metro',
      message: message,
    }
    const request = { method: 'POST' }
    const url = `${configurations.baseUrl}v2/err`
    request['body'] = JSON.stringify(errorObject)
    const res = fetch(url, request)
    console.log(res)
  }