import { Lightning, Colors } from '@lightningjs/sdk'

export default class DynamicButton extends Lightning.Component {
  static _template() {
    return {
      h: (h) => h,
      w: (w) => w,
      Background: {
        rect: true,
        texture: Lightning.Tools.getRoundRect(300, 80, 8, 2, Colors('strokeColor'), true, Colors('unfocusedBtnColor')),
      },
      Label: {
        mountY: 0.5,
        text: {
          fontFace: 'Bold',
          fontSize: 26,
          textAlign: 'center',
          textColor: Colors('primaryFontColor1').get(),
          wordWrap: true,
          wordWrapWidth: 550,
          maxLines: 1,
          maxLinesSuffix: '...'
        },
      },
      UnderLine: {
        x: 40,
        y: 47,
        alpha: 0,
      },
    }
  }

  _init() {
    if (this.w && !isNaN(this.w)) {
      return
    }
    this.tag('Label').on('txLoaded', () => {
      this.tag('UnderLine').patch({
        texture: Lightning.Tools.getRoundRect(this.tag('Label').finalW, 2, 0, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get())
      })
      this.patch({
        w: this.tag('Label').finalW + this._textPadding * 2,
      })
      this.setButtonStyles()
    })
  }

  set action(v) {
    this._action = v
  }

  get action() {
    return this._action
  }

  set textPadding(padding) {
    this._textPadding = padding
  }

  set withoutTexture(bool) {
    this._withoutTexture = bool
  }

  set label(label) {
    this._label = label

    if (!this.dynamicWidth && this.w && !isNaN(this.w)) {
      this._textPadding = 0
      this.patch({
        w: this.w,
        Label: {
          w: this.w,
          text: {
            w: this.w,
          },
        },
      })
      this.setButtonStyles()
    }
    this.tag('Label').text.text = label
  }
  set underline(undrline) {
    this._underline = undrline
    if (undrline) {
      this.tag('UnderLine').alpha = 1
    }
  }
  get underline() {
    return this._underline
  }

  set smartsigns(smartsigns) {
    this._smartsigns = smartsigns
    if (this._smartsigns) {
      this.patch({
        Label: {
          mountY: 0.469,
          text: {
            fontFace: 'Regular',
            fontSize: 20,
          },
        },
      })
    }
  }

  get smartsigns() {
    return this._smartsigns
  }

  get label() {
    return this._label
  }

  set borderRadius(radius) {
    this._borderRadius = radius
  }

  set color(color) {
    this._color = color
    this.tag('Background').patch({
      texture: Lightning.Tools.getRoundRect(this.w, this.h, this._borderRadius, 2, Colors('brandPrimaryColor').get(), true, Colors('brandPrimaryColor').get(),),
    })
  }

  get color() {
    return this._color
  }

  setButtonStyles() {
    if (this.hasFocus()) {
      this.onButtonFocus()
    } else {
      this.patch({
        Background: {
          texture: !this._withoutTexture
            ? Lightning.Tools.getRoundRect(this.w, this.h, this._borderRadius, 2, Colors('strokeColor').get(), false)
            : undefined
        },
      })
    }
    this.patch({
      Label: {
        x: this._textPadding,
        y: this.h / 2 + 2,
      },
    })
  }

  setSmartsignsStyles() {
    this.patch({
      Background: {
        texture: Lightning.Tools.getRoundRect(this.w, this.h, 15, 2, Colors('strokeColor').get(), false),
      },
    })
  }

  _focus() {
    this.toggleFocus(true)
  }

  _unfocus() {
    this.toggleFocus(false)
  }

  toggleFocus(focus) {
    if (focus) {
      this.onButtonFocus()
      if (this._underline) {
        this.tag('UnderLine').alpha = 0
      }
    } else {
      this.patch({
        Background: {
          texture: Lightning.Tools.getRoundRect(this.w, this.h, this._borderRadius, 2, Colors('strokeColor').get(), false),
        },
        Label: {
          text: { textColor: Colors('primaryFontColor1').get() }
        }
      })
      if (this._withoutTexture)
        this.tag('Background').texture = undefined

      if (this._underline) {
        this.tag('UnderLine').alpha = 1
      }
    }
  }

  onButtonFocus() {
    this.patch({
      Background: {
        texture: !this._withoutTexture
          ? Lightning.Tools.getRoundRect(
            this.w,
            this.h,
            this._borderRadius,
            2,
            Colors('focusedStrokeColor').get(),
            true,
            Colors('focusedBtnColor').get()
          )
          : Lightning.Tools.getRoundRect(
            this.w,
            this.h,
            this._borderRadius,
            2,
            Colors('brandPrimaryColor').get(),
            false,
          )
      },
      Label: {
        text: { textColor: Colors('primaryFontColor2').get() }
      }
    })
  }

  static get width() {
    return this.w
  }

  static get height() {
    return this.h
  }
}
