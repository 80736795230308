import { Colors, Lightning, Router, Storage, Language, VideoPlayer, Utils } from '@lightningjs/sdk'
import App from '../App'
import { UPDATE_CONTINUE_WATCHING_COLLECTION, youboraAdapterCode } from '../Helper/GlobalConstants.js'
import { closeWebSocketConnection, updatedSeasonsArray, navigateToPlayer, passFireboltAPIData, generateAdvertisingPlaybackURL, getColorCodeWithOpacity, isEmptyObject } from '../Helper/Helpers'
import { addToContinueWatch, addToHistory, sendWatchCount } from '../Services/PersonaService'
import { getCaption, getLanguageOptions, getSeasonEpisodes } from '../Services/AssetsService'
import configurations from '../Helper/Configurations'
import { vmapXml } from './vmap.js'
import { resetPlayerVariables, setMediaId, startMonitoring, startWatchedTime, stopWatchedTime, trackEvent, trackVideoProgress } from '../Services/MatomoService.js'
import { PlayerControls, ProgressBar, SkipButton } from '../Components/index.js'
import Dash from './Dash.js'
import npaw from 'npawlib'
import CaptionsHelper from '../Components/PlayerCaptions/CaptionsHelper.js'
import Loader from '../Components/Loader'
import { cliemtVmapXml, prepareAd } from './VmapClient.js'

let advertisementIndex = 0
let adPlaying = false
let contentPlaying = false
let backClick = false
export default class DashVodPlayer extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            color: Colors('backgroundColor').get(),
            w: App.width,
            h: App.height,
            PlayerControls: { type: PlayerControls, visible: false },
            ProgressBar: { type: ProgressBar, visible: false },
            SkipButton: { type: SkipButton },
            Loading: { type: Loader, zIndex: 10, visible: false },
            Error: {
                visible: false,
                ErrorImage: {
                    x: (App.width - 77) / 2, y: (App.height - 77) / 2,
                    w: 77, h: 77, src: Utils.asset('static_assets/FeedError.png'),
                },
                ErrorText: {
                    y: App.height / 2 + 60,
                    text: {
                        fontSize: 36, textColor: Colors('primaryFontColor1').get(), textAlign: 'center', fontFace: 'Regular',
                        wordWrapWidth: 900, maxLines: 3, maxLinesSuffix: '...', lineHeight: 55
                    },
                },
            },
            AdLabel: {
                zIndex: 5,
                alpha: 0,
                rect: true,
                shader: { type: Lightning.shaders.RoundedRectangle, radius: 8 },
                color: 0xffFFC50C,
                x: 64,
                y: 950,
                w: 103,
                h: 62,
                Text: { x: 37, y: 16, text: { text: 'Ad', textAlign: 'center', fontSize: 24, textColor: Colors('primaryFontColor1').get(), fontFace: 'Bold', } }
            },
            BottomGradient: {
                zIndex: 4,
                alpha: 0,
                rect: true,
                x: 0,
                y: 930,
                w: App.width,
                h: 150,
            },
        }
    }

    _init() {
        VideoPlayer.consumer(this)
        if (configurations.enabledYoubora)
            this.youboraPlugin = new npaw.Plugin({ accountCode: configurations.youboraCode, username: configurations.userId })

        this.tag('ErrorText').on('txLoaded', () => {
            const width = this.tag('ErrorText').renderWidth
            const dynamicXPos = (App.width - width) / 2
            this.tag('ErrorText').patch({ x: dynamicXPos })
        })
        this.videoPauseTime = 0
        this.midrolls = []
        const bottomGradientColor = getColorCodeWithOpacity(Colors('cardColor').get(), '05')
        this.tag('BottomGradient').patch({
          colorTop: Colors('shadowColor').get(),
          colorBottom: bottomGradientColor,
        })
    }

    _firstActive() {
        this.fireAncestors('$hideBackground')
    }

    clearSidePanel() {
        this.closeSidePanels()
        VideoPlayer.play()
        this.status = 'play'
        this.playing = true
        this.buttonData = this.buttonsdata()
        this.tag('PlayerControls').data = { data: this.buttonData, index: 0 }
        Router.focusPage()
        this.showPlayerControls()
        this.focusPlayerControls()
    }

    closeSidePanels() {
        this.widgets.backgroundsidepanel.data = { command: 'clear' }
        this.widgets.sidepanelnavigation.data = { component: 'clear' }
        this.widgets.episodelisting.clearEpisodes()
        this.widgets.audiosidepanel.alpha = 0
        this.widgets.subtitlessidepanel.alpha = 0
    }

    get loaderTag() {
        return this.tag('Loading')
    }

    displayLoader() {
        this.loaderTag.visible = true
    }

    hideLoader() {
        this.loaderTag.visible = false
    }

    playButtons() {
        let playButtons = []
        playButtons = [
            {
                action: 'play',
                y: 50,
                mountX: 0.5,
                mountY: 0.5,
                color: Colors('brandPrimaryColor').get(),
                src: this.playing ? 'static_assets/plain_pause.png' : 'static_assets/plain_play.png'
            },
            {
                action: 'centerButtons',
                alpha: this.loaderTag.visible ? 0 : 1,
                src: 'static_assets/rewind-center.png',
            },
            {
                action: 'centerButtons',
                alpha: this.loaderTag.visible ? 0 : 1,
                src: this.playing ? 'static_assets/pause-center.png' : 'static_assets/play-center.png',
            },
            {
                action: 'centerButtons',
                alpha: this.loaderTag.visible ? 0 : 1,
                src: 'static_assets/forward-center.png',
            },
        ]
        return playButtons
    }

    buttonsdata() {
        let buttons = this.playButtons()
        const startover = {
            action: 'startFromBeginning',
            y: 50,
            mountX: 0.5,
            mountY: 0.5,
            color: Colors('brandPrimaryColor').get(),
            src: 'static_assets/plain_reload.png'
        }
        const subtitleMultiAudio = {
            action: 'audioAndSubtitles',
            y: 50,
            mountX: 0.5,
            mountY: 0.5,
            color: Colors('brandPrimaryColor').get(),
            src: 'static_assets/plain_subtitle.png',
        }
        const episodeList = {
            action: 'episodelist',
            y: 50,
            mountX: 0.5,
            mountY: 0.5,
            color: Colors('brandPrimaryColor').get(),
            src: 'static_assets/plain_episodelist.png',
        }
        const nextEpisode = {
            action: 'nextEpisode',
            y: 50,
            mountX: 0.5,
            mountY: 0.5,
            color: Colors('brandPrimaryColor').get(),
            src: 'static_assets/plain_nextepisode.png',
        }

        if (configurations.enableStartOver && this.playbackType !== 'livechannel') buttons.push(startover)

        if (this.contentData?.seriesVideo) {
            buttons.push(nextEpisode)
            buttons.push(episodeList)
        }

        if(configurations.enableMetroMultiAudios || configurations.enableMetroSubtitles) {
            if ((this.audioTrackList?.length > 0 || this.subtitleTrackList?.length > 0) || this.webVttTextTracks?.length > 0)
                buttons.push(subtitleMultiAudio)
        }

        buttons = !this.showNextBtn ? buttons.filter((btnObj) => btnObj.action !== 'nextEpisode') : buttons

        if (this.contentData?.contentType === 'trailer') return this.playButtons() // for trailer only play/pause btn


        return buttons
    }


    set params(itemData) {
        Router.focusPage()
        this.displayLoader()
        this.closeSidePanels()
        this.tag('ProgressBar').ClearTimeInterval()
        passFireboltAPIData('start', '')
        this.getCaptionsData()
        this.intialTrackId = 0
        this.contentData = itemData.data || itemData
        this.contentData.contentId = this.contentData.videoId
        this.playbackType = this.contentData.contentType
        if (!this.contentData.playbackUrl) {
            this.hideLoader()
            this.focusPlayerControls()
            return this.patch({
                rect: true,
                Error: {
                    visible: true,
                    ErrorText: {
                        text: { text: Language.translate('player.video_can_not_play.error') }
                    }
                }
            })
        }
        this.playing = true
        this.skipLength = 10
        const contentTitle = this.contentData.contentTitle || this.contentData.contentTitleEn || this.contentData.title || ''
        if (this.contentData.action === 'continue') this.contentData.seekInfo = this.contentData.seek
        if (this.playbackType === 'trailer') {
            this.tag('PlayerControls').patchTitle = ''
        } else {
            this.contentData.seriesVideo ? this.seriesMetaData() : this.tag('PlayerControls').patchTitle = contentTitle
        }

        this.status = 'loaded'
        this._caption = []
        this.resetPlayerVariables()
        this.setMediaId(this.contentData)
        this.startMonitoring()
        this.trackVideoView(this.contentData)
        this.dash = Dash.MediaPlayer().create()

        this.videoEl = VideoPlayer._videoEl

        if (configurations.enableAdIntegration && configurations.enabeSSAI && this.contentData.adUrl) {
            if (this.contentData.adUrl) {
                this.addCheck()
            } else {
                let textTracks = []
                if (this.contentData?.text_tracks?.length > 0) {
                    for (const track of this.contentData.text_tracks) {
                        const tracksObj = {
                            webvtturl: track.url,
                            languageCode: track.language
                        }
                        textTracks.push(tracksObj)
                    }
                }
                this.webVttTextTracks = textTracks

                this.play()
            }
        } else if (configurations.enableAdIntegration && !configurations.enabeSSAI && this.contentData.adUrl) {
            this.clientVMAPFileParsing(this.contentData.adUrl)
        } else {
            this.play()
        }
        this.tag('PlayerControls').setIndexToDefault()
    }

    async addCheck() {
        this.processedAds = []
        let endAds = []

        // Generate advertising playback URL
        this.ad_Url = generateAdvertisingPlaybackURL(this.contentData.adUrl)

        // Fetch VMAP and process ads
        const vmapResp = await vmapXml(this.ad_Url)
        this.webVttTextTracks = vmapResp?.[0]['textTracks'] == undefined ? [] : vmapResp?.[0]['textTracks']
        this.vmapContentResp = vmapResp?.[0]['contentUri']

        if (vmapResp?.[1]?.length > 0) {
            this.processedAds.push(...vmapResp[1])

            // Filter post-roll ads to calculate durations
            endAds = this.processedAds.filter(ad => ad.timeOffset === 'end')

            // Calculate durations and timeOffsets in seconds
            let endAdsDuration = 0
            endAds.forEach(ad => {
                ad.duration = this.timeToSeconds(ad.duration['#text'])
                endAdsDuration += ad.duration
            })

            this.processedAds.forEach(ad => {
                ad.duration = ad.timeOffset === 'end' ? ad.duration : this.timeToSeconds(ad.duration['#text'])

                if (ad.timeOffset === 'start') {
                    ad.timeOffset = this.timeToSeconds('00:00:00')
                } else if (ad.timeOffset === 'end') {
                    ad.type = 'end'
                    ad.timeOffset = this.vmapContentResp.payloadlength - endAdsDuration
                    endAdsDuration -= ad.duration
                } else {
                    ad.timeOffset = this.timeToSeconds(ad.timeOffset)
                }
            })

            // Calculate startTime and endTime of Ad slots considering consecutive & non-consecutive Ads
            let cumulativeDuration = 0
            let previousTimeOffset = null
            let previousItemEndTime = 0

            for (const [_index, item] of this.processedAds.entries()) {
                if (item.timeOffset === previousTimeOffset) { // Consecutive Ads
                    item.startTime = previousItemEndTime
                    item.endTime = item.startTime + item.duration
                    previousItemEndTime = item.endTime
                } else { // Non-consecutive Ads
                    item.startTime = item.type === 'end' ? item.timeOffset : item.timeOffset + cumulativeDuration
                    item.endTime = item.startTime + item.duration
                    previousItemEndTime = item.endTime
                }

                cumulativeDuration += item.duration
                previousTimeOffset = item.timeOffset
            }
        }

        this.play(this.processedAds)
    }

    async clientVMAPFileParsing(vmapUrl) {
        this.processedAds = await cliemtVmapXml(vmapUrl) // here we get all Adds
        this.index = this.processedAds.findIndex(ad => ad.time == 'start')
        this.index >= 0 ? this.clientSideAdProcessing(this.processedAds[this.index].adUrl) : this.play()
    }


    async clientSideAdProcessing(clientAdUrl) {
        const res = await prepareAd(clientAdUrl) // here we prepared Ad.
        if (res?.ads?.length > 0) {
            this.advertisement = {}
            res.ads.some(ad => {
                return ad.creatives.some(creative => {
                    if (creative.mediaFiles && creative.mediaFiles.length) {
                        return creative.mediaFiles.some(mediaFile => {
                            if (mediaFile.width == 1280 && mediaFile.height == 720) {
                                this.advertisement.url = mediaFile.fileURL
                                if (creative.skipDelay) {
                                    this.advertisement.skip = creative.skipDelay
                                }
                                this.play(this.advertisement)
                                return true
                            } else {
                                return false
                            }
                        })
                    } else {
                        return false
                    }
                })
            })
        } else {
            this.play()
        }
    }


    timeToSeconds(timeString) {
        const [hours, minutes, seconds] = timeString.split(':').map(parseFloat)
        return hours * 3600 + minutes * 60 + seconds
    }

    play(ads) {
        ads && !configurations.enabeSSAI ? this.CSAIAdsIntegration() : this.playIntegration(ads)
        this.videoStartTime = new Date()
    }

    CSAIAdsIntegration() {
        this.isAdPlaying = true
        this.hideControls()
        clearTimeout(this.timer)
        this.videoEl_ad = VideoPlayer._videoEl
        this.videoEl_ad.src = this.advertisement.url
        this.videoEl_ad.play()
        this.videoEl_ad.style.display = 'block'
        VideoPlayer.show()

        // OntimeUpdate call every time while playing Video and also it is called first time when video is ready to play() i.e before metadata and onplay.
        this.videoEl_ad.ontimeupdate = () => {
            this.tag('AdLabel').alpha = 1
            this.tag('BottomGradient').alpha = 1
        }
        // Onplay() Activated
        this.videoEl_ad.onplay = () => {
            if (this.advertisement.skip) {
                Registry.setTimeout(() => {
                }, this.advertisement.skip * 1000)
            }
        }
        // onmetadata() Activated.
        this.videoEl_ad.onloadedmetadata = () => {
            this.patch({ rect: false })
            this.tag('AdLabel').alpha = 1
            this.tag('BottomGradient').alpha = 1
            this.hideLoader()
        }

        // execute when Ad Ended
        this.videoEl_ad.onended = () => {
            this._caption = []
            setTimeout(() => {
                if (this.midrolls.length > 0 && this.midrolls.length - 1 > this.index) {
                    this.index++
                    this.clientSideAdProcessing(this.midrolls[this.index].adUrl)
                } else {
                    this.midrolls = []
                    if (this.videoPauseTime != 'end') {
                        this.play()
                    } else {
                        if (this.contentData.seriesVideo) {
                            this.autoPlayEpisodes(this.contentData.seasonId, this.contentData.seriesId, 'navigate')
                        } else {
                            setTimeout(() => {
                                this.dash.reset()
                                VideoPlayer.close()
                                addToHistory(this.contentData.contentId)
                                if (configurations.concurrentStreamingEnabled) closeWebSocketConnection()
                                this.patch({ rect: true })
                                this.fireAncestors('$showBackground')
                                Router.back()
                            }, 1000)
                        }
                    }
                }
            }, 0)
        }
    }

    playIntegration(ads) {
        this.isAdPlaying = false
        this.trackEvent('play', this.contentData.title)
        this.seeked = false
        this.played = false
        this.dash.initialize(this.videoEl, null, false)
        this.dash.attachView(this.videoEl)
        this.dash.updateSettings({
            streaming: {
                buffer: {
                    enableSeekDecorrelationFix: false,
                    fastSwitchEnabled: false,
                    flushBufferAtTrackSwitch: true,
                    reuseExistingSourceBuffers: true,
                    bufferPruningInterval: 20,
                    bufferToKeep: 20,
                    bufferTimeAtTopQuality: 30,
                    bufferTimeAtTopQualityLongForm: 60,
                    initialBufferLevel: NaN,
                    stableBufferTime: 12,
                    longFormContentDurationThreshold: 600,
                    stallThreshold: 0.3,
                    useAppendWindow: true,
                    setStallState: true,
                },
                text: {
                    defaultEnabled: false,
                },
            },
        })
        this.dash.attachSource(this.vmapContentResp?.contenturi || this.contentData.playbackUrl)
        passFireboltAPIData('load', this.contentData.contentId)
        if (configurations.enabledYoubora) {
            const adapterCode = JSON.stringify(youboraAdapterCode)

            const youboraOptions = {
                'content.title': this.contentData.contentTitleEn || this.contentData.contentTitle || this.contentData.title,
                'content.id': this.contentData.contentId,
                'content.streamingProtocol': 'DASH',
                'content.isLive': false,
                'content.resource': this.vmapContentResp?.contenturi ? this.vmapContentResp?.contenturi : this.contentData.playbackUrl,
                'content.drm': this.contentData.isDrm,
                'device.id': configurations.deviceId,
                'device.type': 'metro',
                'content.customDimension.1': this.contentData.contentId, // GUID
                'content.customDimension.2': this.contentData.contentTitle, // non-english title
                'app.name': configurations.youboraSiteName,
            }
            this.youboraPlugin.registerAdapter(this.dash, adapterCode)
            this.youboraPlugin.setOptions(youboraOptions)
            this.youboraPlugin.fireInit()
        }

        this.videoEl.onplay = () => {
            this.playerError = false
            if (!this.played && !this.isAdPlaying && this.videoPauseTime && this.videoPauseTime > 0) {
                this.played = true
                VideoPlayer.seek(this.videoPauseTime)
                const playPromise = this.videoEl.play()
                if (playPromise) {
                    playPromise.then(_ => {
                        // this.videoEl.play()
                    })
                        .catch(error => {
                            //  this.videoEl.pause()
                            console.log("ERROR is", error)
                        })
                }
            }
        }

        this.videoEl.onloadedmetadata = async () => {
            let selectedLangIndex
            this.playerError = false
            if (this.contentData.seekInfo && !this.seeked) {
                VideoPlayer.duration < this.contentData.seek
                    ? VideoPlayer.seek(VideoPlayer.duration - 20)
                    : VideoPlayer.seek(this.contentData.seek)
                setTimeout(() => {
                    advertisementIndex = Storage.get('AdvertisementIndex')
                    this.seeked = true
                    this.patch({ rect: false })
                    VideoPlayer.show()
                    VideoPlayer.play()
                }, 2000)
            } else {
                VideoPlayer.show()
                VideoPlayer.play()
                advertisementIndex = 0
                setTimeout(() => {
                    this.patch({ rect: false })
                }, 1000)
            }
            if (!isEmptyObject(this.contentData.rent)) {
              await sendWatchCount(this.contentData.contentId, this.playbackType)
            }
            this.hideLoader()
            this.tag('ProgressBar').dimensions = [DashVodPlayer.width, DashVodPlayer.height]
            this.playbackType !== 'livechannel' && this.tag('ProgressBar').updateData([VideoPlayer.duration, VideoPlayer.currentTime], this.contentData.action)
            this.tag('ProgressBar').showOrHideProgressBarDot(false)
            this.audioTrackList = this.dash.getTracksFor('audio')
            this.subtitleTrackList = this.dash.getTracksFor('text')

            this.videoTracks = this.dash.getTracksFor('video')
            if (configurations.enabledYoubora) {
                const youboraParameter = {
                    'content.subtitles': Storage.get('subtitle-lang-selected') || 'None',
                }
                this.youboraPlugin.setOptions(youboraParameter)
            }
            //displaying subtitles from continue watching
            setTimeout(() => {
                if (this.webVttTextTracks?.length > 0 && configurations.enableWebVTT) {
                    if (Storage.get('subtitle-lang-selected') == null || Storage.get('subtitle-lang-selected') == '')
                        this.extractCaptions()
                    else {
                        selectedLangIndex = this.webVttTextTracks.findIndex((subObj) => subObj.languageCode === Storage.get('subtitle-lang-selected'))
                        this.extractCaptions(selectedLangIndex != -1 ? this.webVttTextTracks[selectedLangIndex]['webvtturl'] : '')
                    }
                } else {
                    if (this.dash.getTracksFor('text').length > 0) {
                        this.changeTrack(this.subtitleTrackList[0].lang)
                    }
                }
            }, 1000)

            if (this.audioTrackList.length > 0 && Storage.get('audio-lang-selected') && Storage.get('audio-lang-selected') !== '') {
                const selectedLangObj = this.audioTrackList.find(track => track.lang === configurations.default_audio_language) || this.audioTrackList[0]
                const selectedLang = selectedLangObj.lang
                this.widgets.audiosidepanel.audioItems = this.audioTrackList.map(audio => ({ label: selectedLang, langoption: this.languageJSON }))
                Storage.set('audio-lang-selected', selectedLang)
                this.changeAudioTrack(selectedLang)
            } else {
                const selectedLangObj = this.audioTrackList.find(track => track.lang === configurations.default_audio_language) || this.audioTrackList[0]
                const selectedLang = selectedLangObj.lang
                Storage.set('audio-lang-selected', selectedLang)
                this.changeAudioTrack(selectedLang)
            }


            //this.widgets.videoquality.data = [this.videoTracks, 'dash', this.dash]
            this.buttonData = this.buttonsdata()
            this.tag('PlayerControls').data = { data: this.buttonData, index: 0 }
            this.showPlayerControls()
            this.focusPlayerControls()
            //resetting the Ad related flags
            contentPlaying = false
            adPlaying = false
            backClick = false
            this.fwdCount = 0
            this.rewCount = 0
        }

        this.videoEl.ontimeupdate = () => {
            this.tag('AdLabel').alpha = 0
            this.tag('BottomGradient').alpha = 0
            if (configurations.enableAdIntegration && configurations.enabeSSAI) {
                while (advertisementIndex < ads?.length && this.barTime >= ads?.[advertisementIndex]?.endTime) {
                    advertisementIndex++
                    this.lastVisitedAdIndex = advertisementIndex
                }
                if (advertisementIndex < ads?.length && this.barTime >= ads[advertisementIndex]?.startTime && this.barTime <= ads[advertisementIndex]?.endTime) {
                    if (!adPlaying) {
                        Storage.set('AdvertisementIndex', advertisementIndex)
                        this.hideControls()
                        this.tag('AdLabel').alpha = 1
                        this.tag('BottomGradient').alpha = 1
                        this.closeSidePanels()
                        // this.widgets.adcontrols.alpha = 1
                        contentPlaying = false
                        adPlaying = true // Set adPlaying flag
                        this.isAdPlaying = true
                        //Router.focusWidget('AdControls')
                    }
                } else {
                    if (!contentPlaying && !backClick) {
                        contentPlaying = true
                        this.showPlayerControls()
                        this.tag('AdLabel').alpha = 0
                        this.tag('BottomGradient').alpha = 0
                        //this.widgets.adcontrols.alpha = 0
                        // Reset flags
                        adPlaying = false
                        this.isAdPlaying = false
                    }
                    this.tag('ProgressBar').updateData([VideoPlayer.duration, VideoPlayer.currentTime])
                    this.tag('ProgressBar').showOrHideProgressBarDot(true)
                }
            } else {
                this.tag('AdLabel').alpha = 0
                this.tag('BottomGradient').alpha = 0
                this.barTime = Math.floor(VideoPlayer.currentTime)
                this.isAdPlaying = false
                this.playbackType === 'livechannel'
                    ? this.tag('ProgressBar').updateData([10, 10])
                    : this.tag('ProgressBar').updateData([VideoPlayer.duration, VideoPlayer.currentTime])
                this.tag('ProgressBar').showOrHideProgressBarDot(true)
                if (this.processedAds && this.processedAds.length > 0 && !this.isAdPlaying && (this.midrolls.length == 0)) {
                    this.midrolls = this.processedAds.filter(
                        ad =>
                            ad.time == Math.floor(this.videoEl.currentTime) &&
                            ad.time > this.videoPauseTime,
                    )
                    if (this.midrolls.length > 0) {
                        this.videoPauseTime = this.midrolls[0].time
                        this.index = 0
                        this.clientSideAdProcessing(this.midrolls[0].adUrl)
                        this.dash.reset()
                    }
                }
            }

            // if (this._caption) {
            //     const captionText = CaptionsHelper.getCaptionByTimeIndex(this._caption, VideoPlayer.currentTime)
            //     this.tag('CaptionText').patch({
            //         text: { text: captionText !== undefined ? captionText : '' },
            //     })
            // }
        }

        this.videoEl.onended = () => {
            this.trackEvent('finish', this.contentData.title)
            this.trackVideoProgress(this.contentData, 'finish')
            this.tag('ProgressBar').showOrHideProgressBarDot(false)
            if (configurations.enabledYoubora) this.youboraPlugin.fireStop()

            const handleEnd = () => {
                this.hideControls()
                passFireboltAPIData('end', this.contentData.contentId)
                VideoPlayer.close()
                this.patch({ rect: true })
                this.fireAncestors('$showBackground')
                Router.back()
            }

          if (this.processedAds && !this.isAdPlaying) {
              const postrollAd = this.processedAds.find(ad => ad.time == 'end')
              if (postrollAd) {
                  this.videoPauseTime = postrollAd.time
                  return this.clientSideAdProcessing(postrollAd.adUrl)
              }
              handleEnd()
          } else {
              this.contentData.seriesVideo ? this.autoPlayEpisodes(this.contentData.seasonId, this.contentData.seriesId, 'navigate') : handleEnd()
          }

            this._caption = []
            this.dash.destroy()
            addToHistory(this.contentData.contentId)
            if (configurations.concurrentStreamingEnabled) closeWebSocketConnection()
        }

        this.dash.on(Dash.MediaPlayer.events.ERROR, (event) => {
            this.hideLoader()
            this.tag('AdLabel').alpha = 0
            this.tag('BottomGradient').alpha = 0
            let errorMessage
            switch (event.error.code) {
                case Dash.MediaPlayer.errors.MEDIA_ERR_ABORTED:
                    errorMessage = 'Media fetching aborted by the user.'
                    this.playerError = true
                    break
                case Dash.MediaPlayer.errors.MEDIA_ERR_NETWORK:
                    errorMessage = 'A network error occurred while fetching the media resource.'
                    this.playerError = true
                    break
                case Dash.MediaPlayer.errors.MEDIA_ERR_DECODE:
                    errorMessage = 'An error occurred while decoding the media resource.'
                    this.playerError = true
                    break
                case Dash.MediaPlayer.errors.MEDIA_ERR_SRC_NOT_SUPPORTED:
                    errorMessage = 'The media resource specified by the URL is not supported.'
                    this.playerError = true
                    break
                case Dash.MediaPlayer.errors.MEDIA_ERR_ENCRYPTED:
                    errorMessage = 'The media resource is encrypted and cannot be decoded.'
                    this.playerError = true
                    break
                default:
                    break
            }
            this.patch({
                rect: (errorMessage),
                Error: {
                    visible: (errorMessage),
                    ErrorText: { text: { text: errorMessage } }
                }
            })
        })
    }

    hideControls() {
        this.tag('PlayerControls').visible = false
        this.tag('ProgressBar').visible = false
    }

    stopPlaybackOnConcurrentStream() {
      VideoPlayer.close()
      this.dash?.destroy()
      this.fireAncestors('$showBackground')
    }

    $playPause(value) {
        if (this.isAdPlaying || this.playerError) return
        switch (value) {
            case 'play': {
                this._handlePlayPause()
                break
            }
            case 'forward': {
                this._handleForward()
                break
            }
            case 'rewind': {
                this._handleRewind()
                break
            }
            case 'startFromBeginning': {
                this._handlestartFromBeginning()
                break
            }
            case 'audioAndSubtitles': {
                this._handlePause()
                this.getAudioAndSubtitlesButtons()
                break
            }
            case 'episodelist': {
                this._handlePause()
                this.getEpisodeSidepanel()
                break
            }
            case 'nextEpisode': {
                this._handlePause()
                this.autoPlayEpisodes(this.contentData.seasonId, this.contentData.seriesId, 'navigate')
                break
            }
            case 'videoQuality': {
                this._handlePause()
                this.setVideoQuality()
                break
            }
            default: {
                break
            }
        }
    }

    setVideoQuality() {
        this.hideControls()
        this.widgets.sidepanelnavigation.data = { component: 'videoQuality' }
        this.status = 'pause'
        this.playing = false
        VideoPlayer.pause()
        this.openQuality()
    }

    openQuality() {
        this.widgets.videoquality.data = [this._hls.levels, 'hls', this._hls.currentLevel]
        this.widgets.videoquality.alpha = 1
        Router.focusWidget('VideoQuality')
    }

    _handlePlayPause() {
        if (this.isAdPlaying || this.playerError) return

        this.status = this.status == 'pause' ? 'play' : 'pause'
        if (this.status == 'pause') this.stopWatchedTime()
        if (this.status == 'play') this.startWatchedTime()
        this.trackEvent(this.status, this.contentData.title)
        this.showPlayerControls()
        this.status == 'pause' ? VideoPlayer.pause() : VideoPlayer.play()
        this.playing = this.status == 'pause' ? false : true
        this.buttonData = this.buttonsdata()
        this.tag('PlayerControls').data = { data: this.buttonData, index: 0 }
        this.hidePlayerControls()
    }

    videoFwdandRwd(dir, sec) {
        if (this.isAdPlaying || this.playerError) return

        if (this.playbackType === 'livechannel') return

        if (dir === 'right') {
            VideoPlayer.seek(sec)
            this.fwdCount = 0
        } else {
            VideoPlayer.seek(sec)
            this.rewCount = 0
        }
        this.trackEvent('seek', this.contentData.title)
    }

    handleForwardRewindData() {
        this.playing = false
        this.status = 'pause'
        this.buttonData = this.buttonsdata()
        this.tag('PlayerControls').data = { data: this.buttonData, index: 0 }
        this.hidePlayerControls()
    }

    _handleForward() {
        if (this.isAdPlaying || this.playerError) return

        if (this.playbackType === 'livechannel') return

        this.trackEvent('seek', this.contentData.title)
        this.showPlayerControls()
        VideoPlayer.pause()
        this.handleForwardRewindData()
        this.fwdCount += this.skipLength
        passFireboltAPIData('seeked', this.contentData.contentId, this.barTime)
        if (this.barTime >= VideoPlayer.duration) {
            this.tag('ProgressBar').updateData([VideoPlayer.duration, VideoPlayer.duration])
            this.tag('ProgressBar').updateData([VideoPlayer.duration, this.barTime])
            return Math.ceil(VideoPlayer.duration - 5)
        } else {
            this.barTime = VideoPlayer.currentTime + this.fwdCount
            this.tag('ProgressBar').updateData([VideoPlayer.duration, this.barTime])
            return Math.ceil(this.barTime)
        }
    }

    _handleRewind() {
        if (this.isAdPlaying || this.playerError) return

        if (this.playbackType === 'livechannel') return

        this.trackEvent('seek', this.contentData.title)
        this.showPlayerControls()
        this.barTime = VideoPlayer.currentTime - this.skipLength
        passFireboltAPIData('seeked', this.contentData.contentId, this.barTime)
        VideoPlayer.pause()
        this.handleForwardRewindData()
        if (VideoPlayer.currentTime <= 0 || this.barTime < 0) {
            this.tag('ProgressBar').updateData([VideoPlayer.duration, 0])
            return Math.ceil(0)
        } else {
            this.rewCount += this.skipLength
            this.barTime = VideoPlayer.currentTime - this.rewCount
            this.tag('ProgressBar').updateData([VideoPlayer.duration, this.barTime])
            return Math.ceil(this.barTime)
        }
    }

    _handlestartFromBeginning() {
        if (this.isAdPlaying || this.playerError) return

        this.trackEvent('play', this.contentData.title)
        this.showPlayerControls()
        VideoPlayer.seek(0)
        this.tag('ProgressBar').updateData([VideoPlayer.duration, 0])
        VideoPlayer.play()
        this.playing = true
        this.status = 'play'
        this.buttonData = this.buttonsdata()
        this.tag('PlayerControls').data = { data: this.buttonData, index: 1 }
        this.hidePlayerControls()
    }

    _handlePlay() {
      if (this.isAdPlaying || this.playerError) return

      this.showPlayerControls()
      this.trackEvent('play', this.contentData.title)
      this.tag('PlayerControls')._play()
      VideoPlayer.play()
      this.status = 'play'
      this.playing = true
      this.buttonData = this.buttonsdata()
      this.tag('PlayerControls').data = { data: this.buttonData, index: 0 }
      this.hidePlayerControls()
    }

    _handlePause() {
        if (this.isAdPlaying || this.playerError) return

        this.showPlayerControls()
        this.trackEvent('pause', this.contentData.title)
        this.tag('PlayerControls')._pause()
        VideoPlayer.pause()
        this.status = 'pause'
        this.playing = false
        this.buttonData = this.buttonsdata()
        this.tag('PlayerControls').data = { data: this.buttonData, index: 0 }
        this.hidePlayerControls()
    }

    async _handleBack() {
        if (this.loaderTag.visible) return
        if (!this.contentData.playbackUrl) {
            this.fireAncestors('$showBackground')
            return Router.back()
        }
        const videoContainer = document.getElementById('ad-container')
        videoContainer?.remove() // remove container after completing all ads
        if (this.adsManager) this.adsManager.destroy()

        this.patch({ rect: true })
        VideoPlayer.pause()
        const playerProgress = VideoPlayer.currentTime
        this.trackVideoProgress(this.contentData, playerProgress)
        this.displayLoader()
        this.endTime = new Date()
        this.buttonData = this.buttonsdata()
        this.tag('PlayerControls').data = { data: this.buttonData, index: 0 }
        this.playTime = Math.ceil((this.endTime.getTime() - this.videoStartTime.getTime()) / 1000)
        if ((VideoPlayer.currentTime > 59 && isEmptyObject(this.contentData.rent)) || this.playbackType === 'livechannel') {
          await sendWatchCount(this.contentData.contentId, this.playbackType)
        }
        if (VideoPlayer.currentTime >= 10 && this.playbackType === 'video' && configurations.loggedIn)
            await this.sendContinueWatchingData()
        this.hideControls()
        if (configurations.enabledYoubora) this.youboraPlugin.fireStop()
        if (configurations.concurrentStreamingEnabled) closeWebSocketConnection()
        VideoPlayer.close()
        passFireboltAPIData('end', this.contentData.contentId)
        this.dash?.destroy()
        this.stopWatchedTime()
        this.fireAncestors('$showBackground')
        this.hideLoader()
        UPDATE_CONTINUE_WATCHING_COLLECTION(true)
        Router.back()
    }

    async getAudioAndSubtitlesButtons() {
        this.widgets.backgroundsidepanel.data = {
            command: 'audioSubtitles',
            title: '',
        }
        this.widgets.sidepanelnavigation.data = {
            component: 'audioAndSubtitlesInPlayer',
            xValue: 1335,
        }
        if (this.dash.getTracksFor('audio').length > 0) {
            this.widgets.audiosidepanel.audioItems = await this.dash
                .getTracksFor('audio')
                .map((audio) => ({ label: audio.lang, langoption: this.languageJSON }))
            Storage.set('audioTracksAvailable', true)
            this.widgets.audiosidepanel.alpha = 1
        } else {
            Storage.set('audioTracksAvailable', false)
        }
        if (this.webVttTextTracks?.length > 0 || this.dash.getTracksFor('text').length > 0) {
            const tracks = configurations.enableWebVTT ? this.webVttTextTracks : this.dash.getTracksFor('text')
            this.widgets.subtitlessidepanel.subtitleItems = tracks.map((subtitle) => ({
                label: subtitle.lang,
                langoption: this.languageJSON,
            }))
            this.widgets.subtitlessidepanel.placeInTop = this.dash.getTracksFor('audio').length <= 0 ? true : false
            this.widgets.subtitlessidepanel.alpha = 1
        } else {
            this.widgets.subtitlessidepanel.placeInTop = this.dash.getTracksFor('audio').length <= 0 ? true : false
            this.widgets.subtitlessidepanel.alpha = 0
        }
        // isPlayerPage flag to call specific func in _handleEnter in those widgets
        this.widgets.audiosidepanel.isPlayerPage = true
        this.widgets.subtitlessidepanel.isPlayerPage = true
        Router.focusWidget('SidePanelNavigation')
        // setting preferred audio and subtitle from persona-api to storage
        // when we dont have subtitles and audio values from storage
        if (!Storage.get('subtitle-lang-selected')) {
            Storage.set('subtitle-lang-selected', this.contentData.preferred_subtitle)
        }
        if (!Storage.get('audio-lang-selected')) {
            Storage.set('audio-lang-selected', this.contentData.preferred_language)
        }

        Router.focusWidget('SidePanelNavigation')
    }

    getEpisodeSidepanel() {
        this.prepareSeasonEpisodes()
        this.widgets.backgroundsidepanel.data = { command: 'episodes' }
        this.widgets.sidepanelnavigation.data = { component: 'episodes' }
        this.widgets.sidepanelnavigation.updatePosition()
    }

    prepareSeasonEpisodes() {
        const seasonsLength = this.seasonsArray.length

        const selectedSeasonIDExists = Boolean(this.selectedSeasonID)

        const seasonIdToFetch = selectedSeasonIDExists ? this.selectedSeasonID : this.seasonsArray[0]?.seasonId
        if (seasonsLength > 0) {
            const seasonObj = this.seasonsArray.find(obj => obj.seasonId == seasonIdToFetch)
            this.getSeasonEpisodes(seasonObj.seasonId)
        } else {
            this.getSeriesEpisodes()
        }
        this.widgets.episodelisting.showEpisodes()
        this.widgets.episodelisting.retrieveSeasons({ data: this.seasonsArray, id: this.selectedSeasonID })
        Router.focusWidget('EpisodeListing')
    }

    getSeasonEpisodes(id) {
        if (!id) return
        const seasonObj = this.seasonsArray.find((obj) => id === obj.seasonId)
        const episodeIndex = seasonObj.contents.findIndex((obj) => obj.contentId === this.contentData.contentId)
        const formattedIndex = episodeIndex === -1 ? 0 : episodeIndex
        this.widgets.episodelisting.retrieveEpisodes({
            orientation: 2,
            videos: seasonObj.contents,
            x: 390,
            y: 35,
            w: 1540,
            h: 1000,
            season: seasonObj,
            episodeIndex: formattedIndex,
            continueWatching: this.seriesPersonaInfo?.continueWatchingContent,
            seriesMetaData: this.seriesMetaInfo,
            seriesPersonaInfo: this.seriesPersonaInfo
        })
    }

    async getSeriesEpisodes() {
        const seriesEpisodes = await this.seriesMetaInfo.data.series.seriesContents
        const episodeIndex = seriesEpisodes.findIndex((obj) => obj.contentId === this.contentData.contentId)
        const formattedIndex = episodeIndex === -1 ? 0 : episodeIndex
        this.widgets.episodelisting.retrieveEpisodes({
            orientation: 2,
            videos: seriesEpisodes,
            x: 390,
            y: 35,
            w: 1540,
            h: 1000,
            season: this.seriesMetaInfo.data.series,
            episodeIndex: formattedIndex,
            continueWatching: this.seriesPersonaInfo?.continueWatchingContent,
            onlyEpisodes: true,
            seriesMetaData: this.seriesMetaInfo,
            seriesPersonaInfo: this.seriesPersonaInfo
        })
    }

    autoPlayEpisodes(sid, seriesId, type) {
        this.tag('ProgressBar').showOrHideProgressBarDot(false)
        this.hideControls()
        const currentPlayingContentId = this.contentData.contentId

        const [sEpisodeContentList, newSid] = sid !== '' ?
            [this.seasonsArray.find((season) => season.seasonId === sid).contents, sid] :
            [this.seriesMetaInfo?.data.series.seriesContents, null]

        this.sEpisodeContentList = sEpisodeContentList
        sid = newSid

        this.validateNextEpisodeAndSeasons(this.sEpisodeContentList, currentPlayingContentId, seriesId, sid, type)
    }

    async validateNextEpisodeAndSeasons(episodelist, currentPlayingContentId, seriesId, seasonId, type) {
        this.seriesId = seriesId
        this.seasons = this.seriesMetaInfo?.data.seasons
        this.episodeContentList = episodelist
        this.upcomingEpisodeList = []
        this.nextEpisode = []
        this.seasonCount = 0
        this.showNextBtn = false
        if (seasonId) {
            for (let k = 0; k < this.seasons.length; k++) {
                this.seasonCount++
                if (this.seasons[k]['seasonId'] == seasonId) {
                    let cFlag = false
                    for (const element of this.episodeContentList) {
                        if (!cFlag) {
                            if (element['contentId'] == currentPlayingContentId) {
                                cFlag = true
                            }
                        } else {
                            this.upcomingEpisodeList.push(element)
                            cFlag = false
                        }
                    }
                    if (this.upcomingEpisodeList[0] == undefined) {
                        if (this.seasonCount != this.seasons.length) {
                            const seasonEpisodes = await getSeasonEpisodes(this.seasons[k + 1]['seasonId'])
                            this.sEpisodeContentList = seasonEpisodes.data.content
                            this.upcomingEpisodeList.push(this.sEpisodeContentList[0])
                            this.nextEpisode = this.upcomingEpisodeList[0]
                            this.showNextBtn = true
                        } else {
                            // last season - last episode
                            this.showNextBtn = false
                            this.nextEpisode = [{ content_id: undefined }]
                        }
                    } else {
                        this.showNextBtn = true
                        this.nextEpisode = this.upcomingEpisodeList[0]
                        // same season with next epsiode
                    }
                }
            }
        } else {
            let cFlag = false
            for (const element of this.episodeContentList) {
                if (!cFlag) {
                    if (element['contentId'] == currentPlayingContentId) {
                        cFlag = true
                    }
                } else {
                    this.upcomingEpisodeList.push(element)
                    cFlag = false
                }
            }
            if (this.upcomingEpisodeList.length == 1) {
                this.nextEpisode = this.upcomingEpisodeList[0]
                this.showNextBtn = true
            } else {
                this.nextEpisode = [{ contentId: undefined }]
                this.showNextBtn = false
                this.nextEpisode = [{ contentId: undefined }]
            }
        }

        if (this.playbackType != 'trailer') {
            this.additionPlayerBtnFlag = true
        }

        if (type == 'navigate') {
            const enteredEpisodeInfo = {
                videoId: this.nextEpisode.contentId,
                seasonsSlug: this.nextEpisode.seasonsSlug
            }
            Storage.set('enteredEpisodeInfo', JSON.stringify(enteredEpisodeInfo))
            this.playerNavigation(this.nextEpisode['contentId'])
        } else if (type == 'validation' && this.showNextBtn) {
            this.tag('Poster').src = configurations.imageBasePath + this.nextEpisode['banner_medium']
            this.tag('EpisodeDisplay').patch({
                text: {
                    text: 'E:' + this.nextEpisode['position'] + ' ' + this.nextEpisode['title'],
                },
            })
        }
    }

    async playerNavigation(episodeId) {
        const shouldSendContinueWatchingData = episodeId && VideoPlayer.currentTime >= 10 && VideoPlayer.currentTime !== VideoPlayer.duration && configurations.loggedIn
        const shouldSendWatchCount = episodeId && VideoPlayer.currentTime > 59 && VideoPlayer.currentTime !== VideoPlayer.duration && configurations.loggedIn

        if (shouldSendContinueWatchingData) {
            await this.sendContinueWatchingData()
        }

        if (shouldSendWatchCount) {
            await sendWatchCount(this.contentData.contentId, this.playbackType)
        }

        if (episodeId) {
            if (configurations.concurrentStreamingEnabled) closeWebSocketConnection()
            navigateToPlayer(episodeId, this.seriesMetaInfo, this.seriesPersonaInfo, 0, false)
            this.stopWatchedTime()
        } else {
            this.fireAncestors('$showBackground')
            Router.back()
        }
        this.dash.destroy()
    }

    async getCaptionsData() {
        this.languageJSON = await getLanguageOptions()
    }

    getLanguageJSON() {
        return this.languageJSON
    }

    seriesMetaData() {
        // helps in getting metaInfo-series-details from seriesDetailsApiCall
        this.seriesMetaInfo = this.contentData.seriesMetadata
        this.seriesPersonaInfo = this.contentData.seriesPersona
        this.seasonsArray = this.seriesMetaInfo.data.seasons?.length > 0 ? updatedSeasonsArray(this.seriesMetaInfo.data.seasons) : []
        const enteredEpisode = JSON.parse(Storage.get('enteredEpisodeInfo'))
        const enteredSeasonSlug = enteredEpisode?.seasonsSlug
        const clickedEpisodeSeason = enteredEpisode && enteredSeasonSlug ? this.seasonsArray.find(obj => obj.seasonsSlug === enteredSeasonSlug) : null
        this.selectedSeasonID = clickedEpisodeSeason?.seasonId || this.seriesPersonaInfo?.seasonId

        let episodeTitle
        if (this.seasonsArray?.length > 0) {
            const currentPlayingSeason = this.seasonsArray.find((season) => season.seasonsSlug === this.contentData.seasonsSlug)
            const currentPlayingEpisode = currentPlayingSeason?.contents.findIndex((episode) => episode.contentId === this.contentData.contentId) + 1
            episodeTitle = `${this.contentData.contentTitle} - ${Language.translate('common_keys.seasons_notation.text')}${currentPlayingSeason?.seasonPosition} ${Language.translate('common_keys.episodes_notation.text')}${currentPlayingEpisode}`
        } else {
            const currentPlayingEpisode = this.seriesMetaInfo?.data.series.seriesContents.findIndex((episode) => episode.contentId === this.contentData.contentId) + 1
            episodeTitle = `${this.contentData.contentTitle} - ${Language.translate('common_keys.episodes_notation.text')}${currentPlayingEpisode}`
        }
        this.tag('PlayerControls').patchTitle = this.contentData.contentTitle ? episodeTitle : ''
        this.autoPlayEpisodes(this.contentData.seasonId, this.contentData.seriesId, 'check')
    }

    onSeasonClicked(season) {
        if (season.seasonId == 'seriesEpisodes') return
        this.selectedSeasonID = season.seasonId
        this.prepareSeasonEpisodes()
    }

    async integrateIma() {
        const adUrl = this.contentData.adUrl
        let nextAd = null

        // create ad Display container
        const adContainerTag = document.createElement('div')
        adContainerTag.setAttribute('id', 'ad-container')
        document.body.appendChild(adContainerTag)

        this.adDisplayContainer = new google.ima.AdDisplayContainer(document.getElementById('ad-container'), this.videoEl)

        // Create ads loader.
        this.adsLoader = await new google.ima.AdsLoader(this.adDisplayContainer)

        // Listen and respond to ads loaded and error events.
        this.adsLoader.addEventListener(
            google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
            async (adsManagerLoadedEvent) => {
                // Get the ads manager.

                // videoContent should be set to the content video element.
                this.adsManager = await adsManagerLoadedEvent.getAdsManager(this.videoEl)
                this.loadAds()

                this.adsManager.addEventListener(google.ima.AdEvent.Type.SKIPPED, async () => {
                    this.isAdPlaying = false
                    this._hls.attachMedia(this.videoEl)
                    VideoPlayer.show()
                    VideoPlayer.play()
                    this.widgetControlAfterAds()
                })

                // Listen to any additional events, if necessary.
                this.adsManager.addEventListener(google.ima.AdEvent.Type.LOADED, (advEvent) => {
                    nextAd = advEvent.ad // when ad loaded, tells whether next ad available or not
                    if (nextAd) this.nextAd = true
                    if (!nextAd.data.linear) {
                        VideoPlayer.play()
                    }
                })

                this.adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, (advEvent) => {
                    VideoPlayer.pause()
                    VideoPlayer.hide()
                    document.getElementById('ad-container').style.display = 'block'
                    const ad = advEvent.ad
                    this._hls.detachMedia()
                    this.hideControls()

                    this.isSkippableAd = ad.data.skippable
                    this._setState('SkipButton')
                    this.isAdPlaying = true
                })

                this.adsManager.addEventListener(google.ima.AdEvent.Type.COMPLETE, () => {
                    // we detach on adStart and re-attach oad end,solved video visibility on stb using this
                    document.getElementById('ad-container').style.display = 'none'
                    this._hls.attachMedia(this.videoEl)
                    VideoPlayer.show()
                    VideoPlayer.play()
                    this.widgetControlAfterAds()
                    nextAd = null
                    this.nextAd = false
                    this.isAdPlaying = false
                })
                this.adsManager.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, (adEvent) => {
                    const adData = adEvent.ad
                    const videoContainer = document.getElementById('ad-container')
                    videoContainer && videoContainer.remove() // remove container after completing all ads

                    const adTimeOffset = adData.data.adPodInfo.timeOffset
                    if (adTimeOffset == -1) {
                        // cond for postroll
                        this.adsManager.destroy()
                        Router.getActivePage().widgets.progressbar.showOrHideProgressBarDot(false)
                        if (configurations.enabledYoubora) this.youboraPlugin.fireStop()
                        VideoPlayer.close()
                        Router.back()
                    }
                })
            },
            false
        )

        this.adsLoader.addEventListener(
            google.ima.AdErrorEvent.Type.AD_ERROR,
            () => {
                // Handle the error logging.
                if (this.adsManager) this.adsManager.destroy()
            },
            false
        )

        // Request video ads.
        const adsRequest = new google.ima.AdsRequest()
        adsRequest.adTagUrl = adUrl

        // Specify the linear and nonlinear slot sizes. This helps the SDK to
        // select the correct creative if multiple are returned.
        adsRequest.linearAdSlotWidth = VideoPlayer.width
        adsRequest.linearAdSlotHeight = VideoPlayer.height

        adsRequest.nonLinearAdSlotWidth = VideoPlayer.width
        adsRequest.nonLinearAdSlotHeight = VideoPlayer.height

        await this.adsLoader.requestAds(adsRequest)
    }

    loadAds() {
        if (this.isAdPlaying) return
        // I keep e.preventDefault() because it will prevent video to play before ads in case of preroll.
        this.adDisplayContainer.initialize()
        try {
            // Initialize the ads manager. Ad rules playlist will start at this time.
            this.adsManager.init(VideoPlayer.width, VideoPlayer.height, google.ima.ViewMode.NORMAL)
            this.adsManager.start()
        } catch (adError) {
            console.log('Ads manager could not start', adError)
            VideoPlayer.play()
        }
    }

    widgetControlAfterAds() {
        this.tag('PlayerControls').visible = true
        this.tag('ProgressBar').visible = true
        this.tag('SkipButton').visible = false
        this.focusPlayerControls()
        this.hidePlayerControls()
    }

    async sendContinueWatchingData() {
        await addToContinueWatch(this.contentData.contentId, VideoPlayer.currentTime)
    }

    changeAudioTrack(label, type = '') {
        clearTimeout(this.timer)
        this.displayLoader()
        const audioTracksArray = [...this.dash.getTracksFor('audio')]
        const labelIndex = audioTracksArray.findIndex((subObj) => subObj.lang === label)
        const index = labelIndex !== -1 ? labelIndex : 0

        if (labelIndex < 0) {
            Router.getActivePage().widgets.audiosidepanel.updateAudioTag(index)
        }
        this.dash.setCurrentTrack(this.dash.getTracksFor('audio')[index])
        this.dash.trackSwitchMode = { audio: 'alwaysReplace' }

        if (type == 'change') {
            Storage.set('audio-lang-selected', label)
            Storage.set('audio-lang-selected-index', index)
            this.timer = setTimeout(() => {
                this.hideLoader()
            }, 500)
        } else {
            this.hideLoader()
        }
    }

    async changeTrack(label) {
        if (configurations.enabledYoubora) {
            const youboraParameter = { 'content.subtitles': label }
            this.youboraPlugin.setOptions(youboraParameter)
        }
        let labelIndex = -1

        if (label && label !== Language.translate('player.none.text')) {
            const subtitleLangSelected = configurations.enableWebVTT ? label : label.toLowerCase()
            Storage.set('subtitle-lang-selected', subtitleLangSelected)

            if (configurations.enableWebVTT) {
                labelIndex = this.webVttTextTracks.findIndex((subObj) => subObj.languageCode === label)
                this._caption = []
                await this.extractCaptions(this.webVttTextTracks[labelIndex]?.webvtturl)
            } else {
                const textTracksArray = [...this.dash.getTracksFor('text')]
                labelIndex = textTracksArray.findIndex((subObj) => subObj.lang === subtitleLangSelected)
                this.dash.setTextTrack(labelIndex)
            }
        } else {
            Storage.set('subtitle-lang-selected', null)
            this.extractCaptions()
            this.dash.setTextTrack(-1)
            this.label = ''
        }
    }


    async extractCaptions(url) {
        if (!url) {
            this._caption = []
            return
        }
        CaptionsHelper.clearCurrentCaption()
        await getCaption(url).then((captionText) => {
            this._caption = CaptionsHelper.parseVTT(captionText)
        })
    }

    setSubtitleLanguage(subtitleLabel) {
        Storage.set('subtitle-lang-selected', subtitleLabel || null)
    }

    setAudioLanguage(audioLabel) {
        Storage.set('audio-lang-selected', audioLabel)
    }

    trackEvent(action, mediaTitle) {
        if (configurations.enableTv2zAnalytics) {
            trackEvent(action, mediaTitle)
        }
    }

    resetPlayerVariables() {
        if (configurations.enableTv2zAnalytics) {
            resetPlayerVariables()
        }
    }

    startMonitoring() {
        if (configurations.enableTv2zAnalytics) {
            startMonitoring()
        }
    }

    trackVideoProgress(item, progressTime) {
        if (configurations.enableTv2zAnalytics) {
            trackVideoProgress(item, progressTime)
        }
    }

    stopWatchedTime() {
        if (configurations.enableTv2zAnalytics) {
            stopWatchedTime()
        }
    }

    startWatchedTime() {
        if (configurations.enableTv2zAnalytics) {
            startWatchedTime()
        }
    }

    setMediaId(value) {
        if (configurations.enableTv2zAnalytics) {
            setMediaId(value)
        }
    }

    trackVideoView(item) {
        this.trackVideoProgress(item, 0)
    }

    destroyPlayer() {
        VideoPlayer.close()
        this.dash.destroy()
        this.fireAncestors('$showBackground')
        Router.back()
    }

    getIsLive() {
      return this.playbackType === 'livechannel' ? true : false
    }

    static get width() {
        return App.width - 200
    }

    static get height() {
        return 7
    }

    focusProgressBar() {
        this._setState('ProgressBar')
    }

    focusPlayerControls() {
        this._setState('PlayerControls')
    }

    showPlayerControls() {
        if (this.isAdPlaying || this.playerError) return

        this.tag('PlayerControls').visible = true
        this.tag('ProgressBar').visible = true
        this.hidePlayerControls()
    }

    hidePlayerControls() {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.tag('PlayerControls').visible = false
            this.tag('ProgressBar').visible = false
        }, 5000)
    }

    returnControlsVisibility() {
        return this.tag('PlayerControls').visible
    }

    returnProgressBarVisibility() {
        return this.tag('ProgressBar').visible
    }

    $returnContent() {
        return this.contentData
    }

    static _states() {
        return [
            class PlayerControls extends this {
                _getFocused() {
                    return this.tag('PlayerControls')
                }

                $enter() {
                    return this.tag('PlayerControls')
                }
            },
            class ProgressBar extends this {
                _getFocused() {
                    return this.tag('ProgressBar')
                }
            },

            class SkipButton extends this {
                _getFocused() {
                    return this.tag('SkipButton')
                }
            },


        ]
    }
}
