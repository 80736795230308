import { Lightning, Utils, Router, Colors } from '@lightningjs/sdk'
import App from '../App.js'
import { fontsizes } from '../Helper/GlobalConstants.js'


export default class EmptyPage extends Lightning.Component {
  static _template() {
    return {
      zIndex: 3,
      alpha: 1,
      Background: {
        w: App.width,
        h: App.height,
        Gradient: {
          rect: true,
          color: Colors('backgroundColor').get()
        },
        WishlistIcon: {
          w: 100,
          h: 100,
          x: 1920 / 2,
          y: 1080 / 2,
          mountX: 0.5,
          mountY: 0.5
        },
        Label: {
          w: App.width,
          h: App.height,
          x: 965,
          y: 1080 / 2 + 70,
          mountX: 0.5,
          alpha: 1,
          text: {
            text: '',
            fontFace: 'Bold',
            fontSize: fontsizes.emptyPageText,
            fontWeight: 400,
            textColor: Colors('primaryFontColor1').get(),
            textAlign: 'center'
          }
        }
      }
    }
  }

  _focus() {
    this.patch({ alpha: 1 })
  }

  _handleEnter() {
    this._hello = 0
  }

  _handleLeft() {
    return Router.focusWidget('Menu')
  }

  set data(data) {
    this.tag('Background').patch({
      WishlistIcon: {
        src: Utils.asset('static_assets/' + data.Image)
      },
      Label: {
        text: {
          text: data.Message
        }
      }
    })
  }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }
}
