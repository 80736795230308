import { Lightning, Router, Language, Storage, Colors } from '@lightningjs/sdk'
import { Grid } from '@lightningjs/ui'
import App from '../../App.js'
import VideoItem from './VideoItem'

export default class SearchResults extends Lightning.Component {
  static _template() {
    return {
      w: App.width,
      h: 400,
      x: 550,
      y: 160,
      Header: {
        x: 355,
        y: 40,
        text: {
          fontFace: 'Regular',
          fontSize: 26,
          textColor: Colors('primaryFontColor1').get(),
        },
      },
      visible: true,
      Grid: {
        alpha: 1,
        x: 340,
        y: 90,
        w: 1120,
        h: App.height - 210,
        clipping: true,
        direction: 'column',
        columns: 3,
        crossSpacing: 15,
        mainSpacing: 25,
        itemType: VideoItem,
        type: Grid,
        signals: { onIndexChanged: true },
      },
    }
  }

  _active() {
    this.tag('Grid').setSmooth('alpha', 1)
    this.index = 0
  }

  _init() {
    this.tag('Grid').clear()
    this.index = 0
    this.totalCount = null
  }

  set data(v) {
    Router.getActivePage().displayLoader()
    this.currentSearchTerm = v.key || ""
    this.searchContentslength = v.res?.totalCount
    this.totalvideos = v.res.contents[0].list
    let initialArrayList = this.totalvideos.slice(0,12)
    const children = initialArrayList.map((item) => {
      item.x = 1
      item.y = 1
      item.w = 320
      item.h = 180
      item.orientation = 1
      return { type: VideoItem, item }
    })
    this.tag('Grid').clear()
    this.tag('Grid').add(children)
    this.tag('Header').patch({
      text: {
        text:
          Language.translate('search.found.text') +
          " " +
          this.searchContentslength +
          " " +
          Language.translate('search.results_for.text') +
          " " +
          " " +
          `"` +
          v.key +
          `"`,
      },
    })
    this.tag('Grid').scroll = { after: 4 }
    Router.getActivePage().hideLoader()
  }

  onIndexChanged(indexData) {
    const { mainIndex,previousMainIndex,index:currentIndex } = indexData
    if (mainIndex > 1 && previousMainIndex !== mainIndex) {
      this.requestNextItems()
    }
  }

  requestNextItems(){
    let currentItemsLength = this.tag('Grid').items.length
    let nextArray = this.totalvideos.slice(currentItemsLength,currentItemsLength+3)
    if(nextArray.length === 0){
      return
    }
    const children = nextArray.map((item) => {
        item.x = 1
        item.y = 1
        item.w = 320
        item.h = 180
        item.orientation = 1
        return { type: VideoItem, item }
    })
    this.tag('Grid').addAt(children, currentItemsLength)
    this._refocus()
  }

  _getFocused() {
    return this.tag('Grid')
  }

  _handleEnter() {
    const selectedResult = this.totalvideos[this.tag('Grid').index]
    if (selectedResult.isSeries) {
      !selectedResult.videoId
        ? Router.navigate(`detail/series/${selectedResult.contentId}`, { overlayStatus: 'hideOverlay', keepAlive: true })
        : (
          Storage.set('enteredEpisodeInfo', JSON.stringify({ videoId: selectedResult.videoId, seasonsSlug: selectedResult.seasonsSlug })),
          Router.navigate(`detail/series/${selectedResult.contentId}`, { overlayStatus: 'showOverlay', keepAlive: true }))
    } else
      Router.navigate(`detail/vod/${selectedResult.contentId}`, { keepAlive: true })
  }

  _handleLeft() {
    Storage.remove('searchStateInfo')
    Router.getActivePage().focusKeyboard()
  }

  _handleRight() {
    this.tag('Grid').next()
  }

  _handleKey() { return }

  _handleUp() { return }

  _handleDown() {
    if (this.tag('Grid')._lines?.[this.tag('Grid')._lines.length - 1].includes(this.tag('Grid')._index)){
      return
    }//when it is last row, handledown shouldn't go to next or last item, so we return

    this.tag('Grid').last()
  }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }
}
