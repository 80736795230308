import { Lightning, Colors } from '@lightningjs/sdk';
import { List } from '@lightningjs/ui';
import { transition } from '../../lib/helpers';

export default class CarouselList extends Lightning.Component {
    static _template() {
        return {
            alpha: 0.001, transitions: { alpha: { duration: 0.25 } },
            Label: {
              w: 1600,
              x: 90,
              y: 10,
              pivotX: 0,
              pivotY: 1,
              scale: 0.8,
              text: {
                fontFace: 'Bold',
                fontSize: 40,
                wordWrapWidth: 1600,
                maxLines: 1,
                maxLinesSuffix: '...',
                textColor: Colors('primaryFontColor1').get()
              }
            },
            List: {
                w: 1600, y: 80, x: 90, type: List, spacing: 30
            }
        }
    }

    set index(num) {
        this._index = num;
    }

    get index() {
        return this.tag('List').index;
    }

    _init() {
        this._transitionAlpha = this.transition('alpha');
        this._transitionLabel = this.tag('Label').transition('y');

        this._labelAnimation = this.tag('Label').animation({
            duration: 0.2, actions: [
                { p: 'y', v: { 0: 10, 1: -10 } },
                { p: 'scale', v: { 0: 0.8, 1: 1 } },
            ]
        })
    }

    _firstActive() {
        const parentIndex = this.collectionWrapper.index;
        const index = this.cparent.componentIndex;
        this.patch({
            alpha: index >= parentIndex,
            Label: { text: { text: this.title } },
            List: {
                itemType: this.itemType,
                items: this.items,
                index: this._index
            }
        });
    }

    _unfocus() {
        if (!this.cparent) {
            return;
        }

        const parentIndex = this.collectionWrapper.index;
        const index = this.cparent.componentIndex;
        if (index !== parentIndex) {
            this.tag('List').setIndex(0);
        }
        if (index < parentIndex) {
            transition(this._transitionAlpha, 0.001);
        }
        this._labelAnimation.stop();
    }

    _focus() {
        transition(this._transitionAlpha, 1);
        this._labelAnimation.start();
    }

    _getFocused() {
        return this.tag('List');
    }

    static get marginBottom() {
        return 30;
    }
}
