import configurations from "../Helper/Configurations";
import { API_VERSION } from "../Helper/GlobalConstants";
import middleware from "./Middleware";
import { Storage } from "@lightningjs/sdk";
import { CategoryResponse } from "../Models/CategoryModel";

/**
 * Fetches home page data.
 * @returns {Promise} A promise that resolves with the home page response or rejects with an error.
 */
export const getHome = async () => {
  const url = `${configurations.baseUrl}${API_VERSION}/assets/home`;
  const params = {
    version: 'v1'
  }
  try {
    return await middleware('GET', url, params, true, configurations.headers);
  } catch (error) {
    console.error(error);
    return error;
  }
};

/**
 * Fetches video details for VOD-Detail page.
 * @param {string} id - The ID of the video.
 * @param {string} videoType - The type of the video.
 * @returns {Promise} A promise that resolves with the video details response or rejects with an error.
 */
export const getVideoDetails = async (id, videoType) => {
  const url = `${configurations.baseUrl}v2/assets/content/${videoType}/${id}`;
  const params = {
    version: 'v1'
  }

  try {
    return await middleware('GET', url, params, true, configurations.headers);
  } catch (error) {
    console.error(error);
    return error;
  }
};

/**
 * Fetches categories data.
 * @returns {Promise} A promise that resolves with the categories response or rejects with an error.
 */
export const getCategories = async () => {
  const url = `${configurations.baseUrl}${API_VERSION}/assets/categories`;
  const params = {
    version: 'v1'
  }
  let modelResp
  try {
    const categoryResp = await middleware('GET', url, params, true, configurations.headers);

    if (categoryResp?.status_code === 200) {
      modelResp = new CategoryResponse(categoryResp);
      configurations.categories = modelResp
    }
    return modelResp;
  } catch (error) {
    return error;
  }
};


/**
 * Fetches series content data.
 * @returns {Promise} A promise that resolves with the categories response or rejects with an error.
 */
export const getSeriesDetails = async (id) => {
  const url = `${configurations.baseUrl}v2/assets/series/${id}`
  const params = {
    version: 'v1'
  }
  try {
    return await middleware('GET', url, params, true, configurations.headers)
  } catch (error) {
    console.log(error)
    return error
  }
}

/**
 * Fetches information about episodes belonging to a particular season.
 * @param {string} id - The ID of the season for which episodes information is to be fetched.
 * @returns {Promise} - A Promise that resolves with the fetched data if successful, or an error object if an error occurs.
 */
export const getSeasonEpisodes = async (id) => {
  const url = `${configurations.baseUrl}v2/assets/content/season/${id}`
  const params = {
    version: 'v1'
  }
  try {
    return await middleware('GET', url, params, true, configurations.headers)
  } catch (error) {
    console.log(error)
    return error
  }
};

/**
 * Fetch selected category videos
 * @param {Category id} id
 */
export const getCategory = async (id, egpChannel) => {
  const version = !egpChannel ? "v3" : "v2"
  const url = egpChannel
    ? `${configurations.baseUrl}${version}/assets/livechannels`
    : `${configurations.baseUrl}${version}/assets/category/${id}/contents`
  const params = {
    version: 'v1'
  }

  try {
    return await middleware('GET', url, params, true, configurations.headers)
  } catch (error) {
    console.log(error)
    return error
  }
}


/** Fetches viewall videos based on the provided key, category, and optional parameters.
* @param {string} key - The key specifying the type of videos to fetch (e.g., 'continue', 'wishlist').
* @param {string} category - The category of videos (e.g., 'movies', 'tvshows', 'home').
* @param {number} skip - The number of items to skip in the result (default is 0).
* @param {number} take - The number of items to retrieve in the result (default is 14).
* @param {string} componentType - The type of component (optional).
* @returns {Promise} A promise that resolves to the API response or an error.
*/

export const getViewAllVideos = async (componentType, key, category, skip = 0, take = 14) => {
  const isPersonaVideos =
    key === 'continue' ||
    key === 'wishlist' ||
    key === 'purchases' ||
    key.includes('because_you_watched') ||
    key == 'top_picks_for_you'

  let url = isPersonaVideos
    ? `${configurations.baseUrl}v2/persona/content/persona`
    : `${configurations.baseUrl}${API_VERSION}/assets/seeall/${key}`

  const params = isPersonaVideos ? { skip, take } : { skip, take, component: componentType }
  Object.assign(params, { version: 'v1' })
  if (configurations.enableProfiles) {
    params.profile_id = Storage.get('profileId') || configurations.selectedProfileId
  }

  if (!isPersonaVideos && category !== 'home') {
    url = `${url}/${category}`
  }

  try {
    return await middleware('GET', url, params, true, configurations.headers)
  } catch (error) {
    console.error(error)
    return error
  }
};


/**
 * Search video
 * @param {searchKey} keyword
 * @return {object} the response object containing an array of the searched video results
 */
export const searchVideo = async (keyword) => {
  const url = `${configurations.baseUrl}v2/assets/search`

  const params = {
    keyword: keyword,
    facelift: 1,
    version: "v1"
  }

  try {
    return await middleware('GET', url, params, true, configurations.headers)
  } catch (error) {
    console.log(error)
    return error
  }
}

/**
 * Fetch static page details based on the specified type.
 * @param {string} type - The type of static page to retrieve.
 * @returns {Promise} - A promise that resolves to the response or rejects with an error.
 */
export const getStaticPageDetails = async (type) => {
  const url = `${configurations.baseUrl}v2/assets/pages/${type}`;
  try {
    const aboutUsRes = await middleware('GET', url, null, true, configurations.headers);
    return aboutUsRes;
  } catch (error) {
    console.error('Error fetching static page details:', error);
    return error;
  }
};

export const getEpgScheduleList = async (feedIds, newDate) => {
  const scheduleArray = [];
  const noFeedPresent = {
    flag: 'NoFeed',
  };
  if (!feedIds || feedIds.length === 0) {
    scheduleArray.push(noFeedPresent);
    return scheduleArray;
  }

  const feedIndex = JSON.parse(Storage.get('EpgChannelSelected'))?.index || 0;
  let updateData
  try {
    updateData = Storage.get('updateData') || feedIds[feedIndex];
    const response = await fetch(`${configurations.s3EpgPath}${updateData}_${newDate}.json`);
    const epgData = await response.json();
    scheduleArray.push(epgData);
  } catch (error) {
    console.error('Error fetching EPG data:', error);
    noFeedPresent.feed_id = updateData
    scheduleArray.push(noFeedPresent);
  }

  return scheduleArray;
};

export const getLanguageOptions = async () => {
  try {
    const res = await fetch(configurations.playerLanguageOptions, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!res.ok) {
      throw new Error(`Failed to fetch data. Status: ${res.status}`)
    }
    return await res.json()
  } catch (error) {
    return error
  }
}

export const getCaption = async link => {
  return fetch(link)
    .then(data => data.text())
    .then(captionData => {
      return captionData
    })
}



