import { Lightning, Colors } from '@lightningjs/sdk'

export default class TagsButton extends Lightning.Component {
  static _template() {
    return {
      h: (h) => h,
      w: (w) => w,
      Background: {
        zIndex: 5,
        rect: true,
        texture: Lightning.Tools.getRoundRect(300, 29, 0, 1, Colors('unfocusedBtnColor').get(), true, Colors('unfocusedBtnColor').get()),
      },
      Label: {
        zIndex: 10,
        mountY: 0.5,
        text: {
          fontFace: 'Bold',
          fontSize: 20,
          textAlign: 'center',
          wordWrap: true,
          wordWrapWidth: 350,
          maxLines: 1,
          maxLinesSuffix: '...',
          textColor: Colors('primaryFontColor1').get(),
        },
      },
    }
  }

  _init() {
    if (this.w && !isNaN(this.w)) {
      return
    }
    this.tag('Label').on('txLoaded', () => {
      this.patch({
        w: this.tag('Label').finalW + this._textPadding * 2,
      })
      this.setButtonStyles()
    })
  }

  set action(v) {
    this._action = v
  }

  get action() {
    return this._action
  }

  set textPadding(padding) {
    this._textPadding = padding
  }

  set withoutTexture(bool) {
    this._withoutTexture = bool
  }

  set label(label) {
    this._label = label

    if (!this.dynamicWidth && this.w && !isNaN(this.w)) {
      this._textPadding = 0
      this.patch({
        w: this.w,
        Label: {
          w: this.w,
          text: {
            w: this.w,
          },
        },
      })
      this.setButtonStyles()
    }
    this.tag('Label').text.text = label
  }

  get label() {
    return this._label
  }

  set borderRadius(radius) {
    this._borderRadius = radius
  }

  setButtonStyles() {
      this.patch({
        Background: {
          texture: !this._withoutTexture
            ? Lightning.Tools.getRoundRect(this.w, this.h, this._borderRadius, 1, Colors('unfocusedBtnColor').get(), true, Colors('unfocusedBtnColor').get())
            : undefined
        },
      })

    this.patch({
      Label: {
        x: this._textPadding,
        y: this.h / 2 + 2,
      },
    })
  }


  static get width() {
    return this.w
  }

  static get height() {
    return this.h
  }
}
