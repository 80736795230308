import { Lightning, Router, Storage, Profile, Language, Colors } from '@lightningjs/sdk'
import { removeLastElement, showDialog } from '../../Helper/Helpers'
import { login } from '../../Services/AuthService'
import { DEVICE_TYPE } from '../../Helper/GlobalConstants'
import configurations from '../../Helper/Configurations'
import Button from '../Button'
import Input from './Input'
import Meta from '../../../metadata.json'
import PasswordCircle from './PasswordCircle'
import Loader from '../Loader'

export default class LoginForm extends Lightning.Component {
  static _template() {
    return {
      InputFields: { x: 514, zIndex: 2 },
      ButtonList: { zIndex: 2 },
      PasswordCircleList: { x: 445, y: 38 },
      Loading: {
        visible: false,
        type: Loader,
        zIndex: 10,
      }
    }
  }

  _active() {
    this.buttonIndex = 0
    this.emailValue = ''
    this.passwordValue = ''
    this.passwordConversion()
    this.handleInputType()
  }

  get loaderTag() {
    return this.tag('Loading')
  }

  displayLoader() {
    this.loaderTag.visible = true
  }

  hideLoader() {
    this.loaderTag.visible = false
  }

  set data(formData) {
    this.returnOperator()
    this.returnHouseHoldID()
    this.highlightonUnfocus = null
    this.emailValue = ''
    this.passwordValue = ''
    this.focusType = 'input'
    this.buttonIndex = 0
    this.inputFields = formData[0]
    this.bData = formData[1]

    this.tag('ButtonList').children = this.bData.map((button) => {
      return { type: Button, button }
    })
    this.prepareInputFields(this.inputFields)
    this.highlightSelectedInput()
  }

  highlightSelectedInput() {
    this.tag('InputFields').children[this.buttonIndex].patch({
      texture: Lightning.Tools.getRoundRect(740, 63, 4, 3, Colors('brandPrimaryColor').get(), true, Colors('primaryFontColor1').get()),
    })
  }

  returnOperator() {
    Profile.operator().then(res => {
      this.Operator = res
    })
  }

  async loginResponse() {
    // const device = Device.version().then((version) => {
    //   const osName = version.os.readable.split(' ')[0]
    //   const osVersion = version.os.readable.split(' ')[2]
    //   return [osName, osVersion]
    // })

    // const screenRes = Device.screenResolution().then(resolution => {
    //   return resolution
    // })

    // const modelName = Device.model().then((model => {
    //   return model
    // }))

    // const model_name = await modelName
    // const screenResolution = await screenRes
    // const osInfo = await device
    const params = {
      d_type: DEVICE_TYPE,
      email: this.emailValue,
      locale: configurations.locale,
      login_by: 'manual',
      password: this.passwordValue,
      region: configurations.region,
      unique_device_id: this.houseHoldID,
      user_device_info: {
        'manufacturer': this.Operator,
        'model': 'comcast',
        'version': Meta.version,
        'osName': 'Linux',
        'osVersion': '0.01',
        'displayWidth': 1920,
        'displayHeight': 1080
      }
    }
    if (!this.apiCalled) {
      this.apiCalled = true
      this.displayLoader()
      const loginResp = await login(params)
      this.apiCalled = false
      this.hideLoader()
      if (loginResp?.status_code === 200 && !loginResp.data.verifyEmail) {
        configurations.responsemsg = loginResp.data.message
        Storage.set('email', this.emailValue)
        if (loginResp.data.device_limit_exceed && configurations.enableDeviceMangement)
          return Router.navigate('managedevices/during-sign-in')

        if (configurations.enableProfiles)
          return Router.navigate('profile/add')

        return Router.navigate('home')
      }

      if (loginResp?.status_code === 200 && loginResp.data.verifyEmail) {
        configurations.userId = loginResp.data.user_id
        return Router.navigate('verificationmail')
      }

      if (loginResp.error) {
        this.showDialog('common_keys.alert.text', loginResp.error.message)
      }
    }
  }

  returnHouseHoldID() {
    Profile.household().then((res) => {
      this.houseHoldID = res
    })
  }

  _getFocused() {
    return this.focusType == 'input'
      ? this.tag('InputFields').children[this.buttonIndex]
      : this.tag('ButtonList').children[this.buttonIndex]
  }

  $populateInput(value, inputType) {
    const showPasswordStatus = Router.getActivePage().returnShowPasswordStatus()
    switch (inputType) {
      case 'email':
        if (value == 'backspace') {
          this.emailValue = removeLastElement(this.emailValue)
          this.inputFields[this.buttonIndex]['placeholder'] = removeLastElement(this.inputFields[this.buttonIndex]['placeholder'])
          this.prepareInputFields(this.inputFields)
          break
        }
        this.emailValue = value != '' ? (this.emailValue += value) : value
        this.inputFields[this.buttonIndex].alpha = 1
        this.inputFields[this.buttonIndex]['placeholder'] =
          this.inputFields[this.buttonIndex]['placeholder'] && value != ''
            ? (this.inputFields[this.buttonIndex]['placeholder'] += value)
            : value
        this.prepareInputFields(this.inputFields)
        break
      case 'password':
        if (value == 'backspace') {
          this.passwordValue = removeLastElement(this.passwordValue)
          this.renderPassword(showPasswordStatus, value)
          break
        }
        this.passwordValue = value != '' ? (this.passwordValue += value) : value
        this.renderPassword(showPasswordStatus, value)
        break
      default:
        break
    }
    this.highlightSelectedInput()
  }

  showEnteredPassword(status) {
    if (status) {
      const mainArray = []
      this.tag('PasswordCircleList').children = mainArray.map((maskedPassword) => {
        return { type: PasswordCircle, maskedPassword }
      })
    }
    this.inputFields[1].alpha = status ? 1 : 0
    this.inputFields[1]['placeholder'] = status ? this.passwordValue : ''
    if (!status) this.passwordConversion()
    this.prepareInputFields(this.inputFields)
  }

  renderPassword(showPasswordStatus, value) {
    if (value == 'backspace') {
      this.inputFields[this.buttonIndex]['placeholder'] = showPasswordStatus ? removeLastElement(this.inputFields[1]['placeholder']) : ''
    } else {
      showPasswordStatus
        ? (this.inputFields[this.buttonIndex]['placeholder'] =
          this.inputFields[this.buttonIndex]['placeholder'] && value != ''
            ? (this.inputFields[this.buttonIndex]['placeholder'] += value)
            : value)
        : (this.inputFields[this.buttonIndex]['placeholder'] = '')
    }
    this.inputFields[this.buttonIndex].alpha = showPasswordStatus ? 1 : 0
    this.prepareInputFields(this.inputFields)
    if (!showPasswordStatus) this.passwordConversion()
  }

  prepareInputFields(inputFields) {
    this.tag('InputFields').children = inputFields.map((input) => {
      return { type: Input, input }
    })
  }

  _handleEnter() {
    if (this.focusType == 'input') {
      this.highlightonUnfocus = true
      this.highlightSelectedInput()
      this.onInputHandleEnter()
    } else {
      this.onButtonsHandleEnter()
    }
  }

  onInputHandleEnter() {
    this.handleInputType()
    Router.getActivePage().focusKeyboard()
  }

  handleInputType() {
    // handling input type(E-mail and Password) based on the index value
    const mapInputType = { 0: 'email', 1: 'password' }
    const keyboardType = mapInputType[this.buttonIndex]
    Router.getActivePage().handleInputType(keyboardType)
  }

  onButtonsHandleEnter() {
    switch (this.buttonIndex) {
      case 0:
        this.onLoginClick()
        break
      case 1:
        Router.navigate('forgotpassword')
        break
      case 2:
        const route = configurations.registrationViaThirdParty ? 'svodpopup/signup' : 'signup'
        Router.navigate(route)
        break
      default: break
    }
  }

  showDialog(header, message) {
    const dialog = this.fireAncestors('$returnDialog')
    const actionArray = [
      {
        label: Language.translate('common_keys.ok.text'),
        action: () => { dialog.close() }
      },
    ]
    showDialog(Language.translate(header), Language.translate(message), actionArray)
  }

  onLoginClick() {
    const isEmailValid = this.validateEmail(this.emailValue)

    if (this.emailValue == '' || this.passwordValue == '') {
      this.showDialog('common_keys.alert.text', 'Email & Password are required')
      this.buttonIndex = 0
    } else if (!isEmailValid) {
      this.showDialog('common_keys.alert.text', 'common_keys.invalid_email.text')
      this.buttonIndex = 0
    } else {
      this.loginResponse()
    }
  }

  validateEmail(emailValue) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(emailValue)
  }

  passwordConversion() {
    if (this.passwordValue) {
      const inputArray = this.passwordValue.split('')
      const mainArray = []
      this.xValue = 555
      this.yValue = 525
      for (let i = 0; i < inputArray.length; i++) {
        let tempObj = {}
        tempObj = {
          x: this.xValue,
          y: this.yValue,
        }
        mainArray.push(tempObj)
        this.xValue = this.xValue + 20
      }
      this.tag('PasswordCircleList').children = mainArray.map((maskedPassword) => {
        return { type: PasswordCircle, maskedPassword }
      })
    } else {
      const mainArray = []
      this.tag('PasswordCircleList').children = mainArray.map((maskedPassword) => {
        return { type: PasswordCircle, maskedPassword }
      })
      if (this.inputFields?.length > 0) {
        this.inputFields[1].alpha = 1
        this.prepareInputFields(this.inputFields)
      }
    }
  }

  focusFromCheckBox(focusData) {
    this.focusType = focusData.focusType
    this.buttonIndex = focusData.index
  }

  _handleDown() {
    const showPasswordStatus = Router.getActivePage().returnShowPasswordStatus()
    if (this.focusType == 'input') {
      this.highlightonUnfocus = false
      if (this.buttonIndex == 0)
        this.buttonIndex++
      else if (this.buttonIndex == 1)
        Router.getActivePage().focusCheckBox()
      if (!showPasswordStatus) this.passwordConversion()
    } else {
      this.buttonIndex < this.bData.length - 1 ? this.buttonIndex++ : null
    }
  }

  _handleKey() { return }

  _handleLeft() {
    if (this.focusType == 'input') {
      this.highlightonUnfocus = true
      this.highlightSelectedInput()
      this.onInputHandleEnter()
    } else {
      this.buttonIndex = 0
      this.handleInputType()
      this.focusType = 'input'
      return Router.getActivePage().focusKeyboard()
    }
  }

  _handleRight() { return }

  _handleUp() {
    const showPasswordStatus = Router.getActivePage().returnShowPasswordStatus()
    if (this.focusType == 'input') {
      this.highlightonUnfocus = false
      this.buttonIndex == 1 ? this.buttonIndex-- : Router.getActivePage().focusBack()
    } else {
      this.buttonIndex > 0 ? this.buttonIndex-- : Router.getActivePage().focusCheckBox()
    }
    if (!showPasswordStatus) this.passwordConversion()
  }

  returnSelectedField() {
    return this.highlightonUnfocus
  }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }
}

