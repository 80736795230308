import App from '../../App.js'
import { Lightning, Utils, Router, Storage, Language, Colors } from '@lightningjs/sdk'
import { ProgressBar, InlineContent } from '@lightningjs/ui-components'
import configurations from '../../Helper/Configurations.js'
import { convertTimeString, isEmptyObject, returnImageUrl } from '../../Helper/Helpers.js'
import { gradientColor, imageSizes } from '../../Helper/GlobalConstants.js'

export default class MetaInfo extends Lightning.Component {
    static _template() {
        return {
            zIndex: 1,
            w: App.width,
            h: App.height,
            TopGradient: {
                w: 620,
                h: App.height,
                rect: true,
                color: Colors('backgroundColor').get(),
            },
            LeftToRightGradient: {
                x: 620,
                w: 800,
                h: App.height,
                rect: true,
                colorRight: gradientColor.bannerImgGradient,
                colorLeft: Colors('backgroundColor').get(),
            },
            ChannelLogo: {
                w: 200,
                h: 130,
                x: 1620,
                y: 103,
            },
            ContentContainer: {
                x: 123, y: 0,
                zIndex: 3,
                h: App.height - 300,
                flex: { direction: 'column', justifyContent: 'center' },
                OnAir: {
                    flexItem: { marginBottom: 20 },
                    zIndex: 3,
                    type: InlineContent,
                    justify: 'flex-start',
                    style: { iconWidth: 32, iconHeight: 32 },
                },
                LogoImage: {
                    flexItem: { marginBottom: 20 },
                    h: 250
                },
                Title: {
                    flexItem: { marginBottom: 20 },
                    text: {
                        fontSize: 64,
                        textColor: Colors('primaryFontColor1').get(),
                        wordWrapWidth: 800,
                        maxLines: 1,
                        maxLinesSuffix: '...',
                        lineHeight: 40,
                        fontFace: 'Bold',
                    },
                },
                MetaContainer: {
                    flexItem: { marginBottom: 20 },
                    type: InlineContent,
                    content: [],
                    justify: 'flex-start',
                    customStyleMappings: {
                        textStyle: {
                            fontSize: 28,
                            textColor: 0xfffafafb,
                            fontFace: 'Regular',
                        }
                    }
                },
                Description: {
                    flexItem: { marginBottom: 20 },
                    text: {
                        fontSize: 22,
                        textColor: Colors('primaryFontColor1').get(),
                        wordWrapWidth: 650,
                        lineHeight: 30,
                        fontFace: 'Regular',
                        maxLines: 3,
                        maxLinesSuffix: '...',
                    },
                },
                Starring: {
                    text: {
                        fontSize: 24,
                        textColor: Colors('primaryFontColor1').get(),
                        wordWrapWidth: 650,
                        maxLines: 1,
                        maxLinesSuffix: '...',
                        lineHeight: 40,
                        fontFace: 'Regular',
                    },
                },
                Director: {
                    text: {
                        fontSize: 24,
                        textColor: Colors('primaryFontColor1').get(),
                        wordWrapWidth: 800,
                        maxLines: 1,
                        maxLinesSuffix: '...',
                        lineHeight: 40,
                        fontFace: 'Regular',
                    },
                },
                ChannelExpiry: {
                    flexItem: { marginBottom: 20 },
                    type: InlineContent,
                    content: [],
                    style: { iconWidth: 32, iconHeight: 32 },
                    justify: 'flex-start',
                    customStyleMappings: {
                        textStyle: {
                            fontSize: 26,
                            textColor: Colors('primaryFontColor1').get(),
                            lineHeight: 30,
                            fontFace: 'Regular',
                        }
                    }
                },
                StartTime: {
                    text: {
                        fontSize: 18,
                        textColor: Colors('primaryFontColor1').get(),
                        wordWrapWidth: 800,
                        maxLines: 1,
                        maxLinesSuffix: '...',
                        lineHeight: 29,
                        fontFace: 'Bold',
                    },
                },
                ProgressBar: {
                    alpha: 0,
                    type: ProgressBar,
                    w: 464,
                    h: 8,
                    style: {
                        barColor: Colors('secondaryFontColor').get(),
                        progressColor: Colors('brandPrimaryColor').get(),
                    }
                },
            },
            ComingNext: {
                x: 1550,
                y: 825,
                text: {
                    fontSize: 28,
                    textColor: Colors('primaryFontColor1').get(),
                    wordWrapWidth: 350,
                    maxLines: 1,
                    maxLinesSuffix: '...',
                    lineHeight: 29,
                    fontFace: 'Bold',
                },
            },
            PlayerButtons: {
                y: 800,
            },
            Play: {
                x: 960,
                y: 540,
            },
        }
    }

    _init() {
        this.btnActionArray = null
    }

    setIndexToDefault() {
        this._index = 0
    }

    updateProgressBar(value) {
        this.tag('ProgressBar').progress = value
    }

    updateLogo(v) {
        this.patch({
            ChannelLogo: {
                alpha: v?.channel_type === 'live' ? 0 : 1,
                src: v?.channel_type === 'live' ? null : returnImageUrl(v.content_logo, imageSizes.icon)
            }
        })
    }

    makeUIInvisible(bool) {
        const alpha = bool ? 1 : 0
        this.patch({
            TopGradient: { alpha },
            LeftToRightGradient: { alpha },
            ContentContainer: { visible: bool, alpha: alpha },
            ComingNext: { alpha },
            PlayerButtons: { alpha },
            Play: { alpha }
        })
    }

    receiveChannelData(channelData) {
        this.channelData = channelData
    }

    updateMetaInfo(v) {
        if (!Storage.get('NofeedAvailable') && v) {
            const time = this.getStartTime(v)
            const metaValues = v?.metaContainer || ''
            const cleanedCasting = metaValues?.casting ? metaValues?.casting.split(/\s*,\s*/).join(', ') : ''
            const cleanedDirector = metaValues?.direct ? metaValues?.direct.split(/\s*,\s*/).join(', ') : ''
            this.patch({
                ComingNext: {
                    text: { text: v?.comingNextTitle ? Language.translate('player.coming_next.text') + ':' + ' ' + v.comingNextTitle : '' }
                },
                ContentContainer: {
                    OnAir: {
                        // visible: true,
                        content: [
                            {
                                icon: Utils.asset('static_assets/onair.png'),
                                w: 32,
                                h: 32
                            },
                            {
                                text: Language.translate('live.on_air.text'),
                                fontSize: 40,
                                textColor: Colors('errorColor').get(),
                                wordWrapWidth: 1060,
                                lineHeight: 30,
                                maxLines: 1,
                                fontFace: 'Bold',
                            }
                        ],
                    },
                    LogoImage: {
                        h: 250,
                        w: 500,
                        src: metaValues?.imageIcon ? configurations.imageBasePath + metaValues?.imageIcon : null,
                    },
                    Title: {
                        text: { text: v?.content_title || '' }
                    },
                    MetaContainer: {
                        content: [
                            { text: metaValues?.year || '', style: 'textStyle' },
                            { text: metaValues?.genre ? `| ${Language.translate(metaValues.genre)}` : '', style: 'textStyle' },
                        ],
                    },
                    Description: {
                        text: { text: v?.content_description }
                    },
                    Starring: {
                        text: { text: cleanedCasting ? Language.translate('content_detail.starring.text') + ':' + ' ' + cleanedCasting : '' }
                    },
                    Director: {
                        text: { text: cleanedDirector ? Language.translate('content_detail.director.text') + ':' + ' ' + cleanedDirector : '' }
                    },
                    ChannelExpiry: {
                        visible: (this.channelData?.availability === 3 && !isEmptyObject(this.channelData?.rent)),
                        content: [
                            { icon: Utils.asset('static_assets/expiry_icon.png'), w: 32, h: 32 },
                            { text: this.channelData?.rent?.expires_in || '' }
                        ],
                    },
                    StartTime: {
                        text: { text: time ? Language.translate('live_channel.started_at.text') + ' ' + time : '' }
                    },
                    ProgressBar: { alpha: 1 }
                }
            })
            this.tag('StartTime').on('txLoaded', () => {
                if (v?.content_title && !metaValues?.imageIcon) {
                    this.tag('LogoImage').visible = false
                    this.tag('Title').visible = true
                } else {
                    this.tag('Title').visible = false
                    this.tag('LogoImage').visible = true
                }
            })
        } else {
            this.patch({
                TopGradient: { alpha: 0 },
                LeftToRightGradient: { alpha: 0 },
                ContentContainer: { visible: false, alpha: 0 },
                ComingNext: { alpha: 0, text: { text: '' } }
            })
        }
    }

    getStartTime(v) {
        const time24HourFormat = v.startTime
        const timeString = time24HourFormat.toLowerCase()
        let convertedString = timeString
        if (timeString.includes("am") || timeString.includes("pm")) {
            const timeOnly = timeString.split(' ')[0]
            convertedString = timeOnly.toString()
        }
        const time12HourFormat = convertTimeString(convertedString)
        const timeParam = time12HourFormat
            ? time12HourFormat
            : new Date(v.startMillis).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        return timeParam
    }

    updateControls(v) {
        this.tag('PlayerButtons').alpha = 1
        this.tag('Play').alpha = 1
        this._index = this._index > 0 ? this._index : 0
        this.isPlaying = true
        this.buttonsData = v.data.filter((obj) => obj.action !== 'play-center')
        this.btnActionArray = this.buttonsData.map((obj) => obj.action)
        let x = 41
        this.tag('PlayerButtons').children = this.buttonsData.map((button, index) => {
            if (index != 0) x = x + 100
            if (button.action == v.action) this._index = index
            button.x = x
            return { type: PlayerButton, button }
        })
        this.tag('PlayerButtons').patch({
            w: this.buttonsData.length * 100,
            alpha: 1,
        })
    }

    _handleStop() {
        Router.getActivePage().tag('MetaInfo').visible ? this._handleBack() : Router.getActivePage().showOverlay()
    }

    _handleEnter() {
        Router.getActivePage().tag('MetaInfo').visible ? this.$handleFunctionality('enter') : Router.getActivePage().showOverlay()
    }

    $handleFunctionality(checkFunction) {
        switch (checkFunction) {
            case 'enter': {
                Router.getActivePage().$playPause(this.buttonsData[this._index].action)
                break
            }
            case 'left': {
                if (this._index > 0)
                    this._index--
                break
            }
            case 'right': {
                if (this._index < this.buttonsData.length - 1)
                    this._index++
                break
            }
            default:
                break
        }
    }

    _handleLeft() {
        Router.getActivePage().tag('MetaInfo').visible ? this.$handleFunctionality('left') : Router.getActivePage().showOverlay()
    }

    _handleRight() {
        Router.getActivePage().tag('MetaInfo').visible ? this.$handleFunctionality('right') : Router.getActivePage().showOverlay()
    }

    _handlePlayPause() {
        Router.getActivePage().$playPause('play')
    }

    _handlePlay() {
        Router.getActivePage()._handlePlay()
    }

    _handlePause() {
        Router.getActivePage()._handlePause()
    }

    _handleBack() {
        Router.getActivePage()._handleBack()
    }

    _getFocused() {
        if (this.buttonsData?.length != 0) return this.tag('PlayerButtons').children[this._index]
    }

    _handleKey() {
        if (Router.getActivePage().tag('MetaInfo').visible) return
        Router.getActivePage().showOverlay()
    }

    _handleUp() {
        Router.getActivePage().tag('MetaInfo').visible ? Router.getActivePage().focusComponent('back') : Router.getActivePage().showOverlay()
    }

    _handleDown() {
        Router.getActivePage().tag('MetaInfo').visible ? Router.getActivePage().focusComponent('channels') : Router.getActivePage().showOverlay()
    }

    set data(v) {
        this.updateControls(v)
        const playButton = v.data.filter((obj) => obj.action === 'play-center')
        this.tag('Play').children = playButton.map((button) => {
            return { type: PlayButton, button }
        })
    }

}

class PlayerButton extends Lightning.Component {
    static _template() {
        return {
            Image: {
                mount: 0.5,
            },
        }
    }
    set button(v) {
        this.buttonData = v
        this.patch({
            Image: {
                x: this.buttonData.x,
                y: this.buttonData.y,
                mountX: this.buttonData.mountX,
                mountY: this.buttonData.mountY,
                src: Utils.asset(this.buttonData.src),
            },
        })
    }

    _focus() {
        this.patch({ Image: { color: Colors('brandPrimaryColor').get(), } })
    }

    _unfocus() {
        this.patch({ Image: { color: Colors('primaryFontColor1').get() } })
    }
}

class PlayButton extends Lightning.Component {
    static _template() {
        return {
            Icon: {
                mount: 0.5,
            },
        }
    }
    set button(v) {
        this.patch({
            Icon: {
                alpha: v.alpha,
                src: Utils.asset(v.src),
            },
        })
    }
}
