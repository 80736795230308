import { Lightning, Router, Storage, Utils, Colors } from '@lightningjs/sdk'
import { VerticalButtons } from '../../Components'
import configurations from '../../Helper/Configurations'

export default class SidePanelNavigation extends Lightning.Component {
  static _template() {
    return {
      alpha: 0,
      VerticalButtons: { type: VerticalButtons }
    }
  }

  set data(valObj) {
    this.patch({
      Background: {
        zIndex: 6,
        x: 1297,
        w: 65,
        h: 1080,
        rect: true,
        alpha: 0.9,
        texture: Lightning.Tools.getRoundRect(65, 1080, 0, 2, Colors('overlayColor').get(), true, Colors('overlayColor').get()),
        Icon: {
          zIndex: 35,
          x: 23.6,
          y: 522.5,
          w: 17.8,
          h: 35.6,
          src: Utils.asset('static_assets/arrow.png'),
        }
      }
    })
    // activeComponent - helps in determining which sidepanel focus to be enabled
    this.activeComponent = valObj.component
    this.activeComponent === 'clear' ? this.patch({ alpha: 0 }) : this.patch({ alpha: 1 })
  }

  updatePosition() {
    this.tag('Background').setSmooth('x', 0, { duration: 0.5, timingFunction: 'ease' })
  }


  _focus() {
    this.tag('Background').patch({
      texture: Lightning.Tools.getRoundRect(65, 1080, 0, 3, Colors('brandPrimaryColor').get(), true, Colors('overlayColor').get()),
    })
  }

  _unfocus() {
    this.tag('Background').patch({
      texture: Lightning.Tools.getRoundRect(65, 1080, 0, 2, Colors('overlayColor').get(), true, Colors('overlayColor').get()),
    })
  }

  _handleRight() {
    switch (this.activeComponent) {
      case 'showMoreInfo':
        const isSidePanelScrollable = Router.getActivePage().widgets.moreinfosidepanel.isScrollable()
        isSidePanelScrollable && Router.focusWidget('MoreInfoSidePanel')
        break
      case 'audioAndSubtitlesInDetailPage':
        const isAudioSubtitleScrollable = Router.getActivePage().widgets.detailpageaudiosubtitles.isScrollable()
        isAudioSubtitleScrollable && Router.focusWidget('DetailPageAudioSubtitles')
        break
      case 'audioandsubtitles':
      case 'audioAndSubtitlesInPlayer': {
        Storage.get('audioTracksAvailable')
          ? Router.focusWidget('AudioSidePanel')
          : Router.focusWidget('SubtitlesSidePanel')
        break
      }
      case 'episodesInPlayer':
      case 'episodes': {
        Router.focusWidget('EpisodeListing')
        break
      }
      case 'change-locale': {
        Router.focusPage()
        Router.getActivePage().focusLocalesList()
        break
      }
      case 'videoQuality':
        Router.focusWidget('VideoQuality')
        break
      default:
        break
    }
  }

  _handleLeft() {
    this.handleFunctionality()
  }

  _handleUp() { return }

  _handleDown() { return }

  _handleEnter() {
    this.handleFunctionality()
  }

  _handleBack() {
    this.handleFunctionality()
  }

  handleFunctionality() {
    this.closeSidePanel()
    if (Router.getActiveHash().includes('series')) {
      Storage.remove('enteredEpisodeInfo')
      Storage.remove('fromEpisodePanel')
      Router.getActivePage().removeEnteredEpisodeRemembrance()
    }
  }

  _handleKey() { return }

  closeSidePanel() {
    const currentRoute = Storage.get("currentRoute")
    switch (this.activeComponent) {
      case 'episodes': {
        Router.getActivePage().clearSidePanel()
        if (Router.getActiveHash().includes('player')) return Router.getActivePage().focusPlayerControls()
        Router.getActivePage().focusVerticalBtns()
        Router.getActivePage().setVerticalBtnIndex(Storage.get('episodesButton'))
        break
      }
      case 'change-locale': {
        Router.focusPage()
        Router.getActivePage().focusLanguageSelection()
        Router.getActivePage().clearSidePanel()
        break
      }
      case 'episodesInPlayer': // for both the cases same code block should be executed
      case 'audioAndSubtitlesInPlayer': {
        const loggedInAndCorrectComponent = configurations.loggedIn && this.activeComponent === 'audioAndSubtitlesInPlayer';
        const notLivePlayer = !Router.getActiveHash().includes('liveplayer');
        if (loggedInAndCorrectComponent && notLivePlayer) {
          Router.getActivePage().focusPlayerControls();
        }
        Router.getActivePage().clearSidePanel();
        Router.getActiveHash().includes('liveplayer') ? (Router.focusPage(), Router.getActivePage().focusComponent('metaInfo')) : ''
        break;
      }
      case 'videoQuality': {
        Router.getActivePage().focusPlayerControls()
        Router.getActivePage().clearSidePanel()
        break
      }
      default: {
        Router.getActivePage().clearSidePanel()
        !currentRoute.includes("player") && Router.getActivePage().focusVerticalBtns()
        break
      }
    }
  }
}
