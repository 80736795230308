import { DevicesAPIResponse } from '../Models/DeviceManagementModel'
import { getUserDevicesInfo } from '../Services/PersonaService'
import { ManageDevices } from '../Views'

/**
 * Defines the configuration for the 'Manage Devices' page.
 */
export default {
    // Path for accessing the 'Manage Devices' page, with a dynamic parameter 'checkScenario'.
    path: 'managedevices/:checkScenario',
    component: ManageDevices,

    /**
     * Defines the asynchronous setup for the 'Manage Devices' page.
     * @param {Object} page - The page instance.
     * @param {string} checkScenario - The scenario to be checked.
     * @returns {Promise} - A promise that resolves with data for the page.
     */
    on: async (page, { checkScenario }) => {
        const apiResponse = await getUserDevicesInfo()
        const userDevicesData = new DevicesAPIResponse(apiResponse)
        const pageData = { userDevicesInfo: userDevicesData, checkFlow: checkScenario }
        page.initiatePage(pageData)
    },
}
