import { Lightning, Colors } from '@lightningjs/sdk'
import { fontsizes } from '../../Helper/GlobalConstants'

export default class SidePanelButton extends Lightning.Component {
  static _template() {
    return {
      h: (h) => h,
      w: (w) => w,
      x: 50,
      ButtonRect: {
        w: (w) => w,
        h: 46,
        padding: 20,
      },

      Label: {
        x: 20,
        y: 40,
        mountY: 0.7,
        text: {
          fontFace: 'Regular',
          fontSize: fontsizes.sidePanelBtnLabel,
          textAlign: 'center',
          wordWrap: false,
        },
      },
    }
  }

  _init() {
    this.tag('Label').on('txLoaded', () => {
      this.calculateWidth = this.tag('Label').finalW + 40
      this.tag('ButtonRect').patch({
        w: this.calculateWidth,
        h: this.tag('Label').finalY + 30,
      })
    })
  }

  set label(val) {
    this.tag('Label').patch({
      text: { text: val },
    })
  }

  _focus() {
    this.tag('ButtonRect').patch({
      // here set a rectangle
      texture: Lightning.Tools.getRoundRect(this.calculateWidth, 46, 2, 2, Colors('brandPrimaryColor').get(), true, Colors('focusedBtnColor').get()),
    })
  }

  _unfocus() {
    this.tag('ButtonRect').texture = undefined
  }

  _handlekey() {
    /* TODO document why this method '_handlekey' is empty */
  }
}
