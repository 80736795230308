import { Lightning } from '@lightningjs/sdk'
import { Episodes, Seasons } from '../Components'

export default class EpisodeListing extends Lightning.Component {
  static _template() {
    return {
      Seasons: { type: Seasons },
      Episodes: { type: Episodes }
    }
  }

  retrieveSeasons(buttonsData) {
    this.tag('Seasons').data = buttonsData
  }

  retrieveEpisodes(episodesData) {
    this.tag('Episodes').items = episodesData
    this.focusEpisodes()
  }

  _getFocused() {
    this.focusEpisodes()
  }

  focusSeasons() {
    this._setState('Seasons')
  }

  focusEpisodes() {
    this._setState('Episodes')
  }

  getCurrentSeasonSlug(slug) {
    this.tag('Episodes').getCurrentSeasonSlug(slug)
  }

  sliderDownTransition() {
    this.tag('Episodes').sliderDownTransition()
  }

  clearEpisodes() {
    this.patch({
      Seasons: { visible: false, alpha: 0 },
      Episodes: { visible: false, alpha: 0 }
    })
  }

  showEpisodes() {
    this.patch({
      Seasons: { visible: true, alpha: 1 },
      Episodes: { visible: true, alpha: 1 }
    })
  }

  static _states() {
    return [
      class Seasons extends this {
        _getFocused() {
          return this.tag('Seasons')
        }
      },
      class Episodes extends this {
        _getFocused() {
          return this.tag('Episodes')
        }
      }
    ]
  }
}
