import { Lightning, Router, Language, Colors } from '@lightningjs/sdk'
import { getRegister } from '../../Services/AuthService'
import { removeLastElement, showDialog } from '../../Helper/Helpers'
import { DEVICE_TYPE } from '../../Helper/GlobalConstants'
import configurations from '../../Helper/Configurations'
import PasswordCircle from './PasswordCircle'
import Loader from '../Loader'
import Button from '../Button'
import Input from './Input'


export default class SignUpForm extends Lightning.Component {
  static _template() {
    return {
      InputFields: { x: 514, zIndex: 2 },
      ButtonList: { zIndex: 2 },
      PasswordCircleList: { x: 452, y: -5 },
      Loading: {
        visible: false,
        type: Loader,
        zIndex: 10,
      }
    }
  }

  _active() {
    this.buttonIndex = 0
    this.fullNameValue = ''
    this.emailValue = ''
    this.passwordValue = ''
    this.formatePasswordCircles()
    this.handleInputType()
  }

  get loaderTag() {
    return this.tag('Loading')
  }

  displayLoader() {
    this.loaderTag.visible = true
  }

  hideLoader() {
    this.loaderTag.visible = false
  }

  set data(formData) {
    this.focusType = 'input'
    this.highlightonUnfocus = null
    this.buttonIndex = 0
    this.inputFields = formData[0]
    this.bData = formData[1]
    this.tag('ButtonList').children = this.bData.map((button) => {
      return { type: Button, button }
    })
    this.prepareInputFields(this.inputFields)
    this.highlightSelectedInput()
  }

  async registration() {
    const params = {
      name: this.fullNameValue,
      email: this.emailValue,
      password: this.passwordValue,
      c_password: this.passwordValue,
      login_by: 'manual',
      d_type: DEVICE_TYPE,
      region: configurations.region,
    }
    if (!this.apiCalled) {
      this.apiCalled = true
      this.displayLoader()
      const registrationResponse = await getRegister(params)
      this.apiCalled = false
      this.hideLoader()
      if (registrationResponse?.status_code === 200) {
        configurations.responsemsg = registrationResponse.data.message
        configurations.userId = registrationResponse.data.user_id
        configurations.email = registrationResponse.data.user_email

        if (registrationResponse.data.verifyEmail)
          return Router.navigate('verificationmail')

        if (configurations.enableProfiles)
          return Router.navigate('profile/add')

        return Router.navigate('home')
      }

      if (registrationResponse.error) {
        this.showDialog('common_keys.alert.text', registrationResponse.error.message)
      }
    }
  }

  _getFocused() {
    return this.focusType == 'input'
      ? this.tag('InputFields').children[this.buttonIndex]
      : this.tag('ButtonList').children[this.buttonIndex]
  }

  $populateInput(value, inputType) {
    const showPasswordStatus = Router.getActivePage().returnShowPasswordStatus()
    switch (inputType) {
      case 'fullName':
        if (value == 'backspace') {
          this.fullNameValue = removeLastElement(this.fullNameValue)
          this.inputFields[this.buttonIndex]['placeholder'] = removeLastElement(this.inputFields[this.buttonIndex]['placeholder'])
          this.prepareInputFields(this.inputFields)
          break
        }
        this.fullNameValue = value != '' ? (this.fullNameValue += value) : value
        this.appendInputValue(value)
        break
      case 'email':
        if (value == 'backspace') {
          this.emailValue = removeLastElement(this.emailValue)
          this.inputFields[this.buttonIndex]['placeholder'] = removeLastElement(this.inputFields[this.buttonIndex]['placeholder'])
          this.prepareInputFields(this.inputFields)
          break
        }
        this.emailValue = value != '' ? (this.emailValue += value) : value
        this.appendInputValue(value)
        break
      case 'password':
        if (value == 'backspace') {
          this.passwordValue = removeLastElement(this.passwordValue)
          this.passwordRender(showPasswordStatus, value)
          break
        }
        this.passwordValue = value != '' ? (this.passwordValue += value) : value
        this.passwordRender(showPasswordStatus, value)
        break
      default: break
    }
    this.highlightSelectedInput()
  }

  focusFromCheckBox(focusData) {
    this.focusType = focusData.focusType
    this.buttonIndex = focusData.index
  }

  showEnteredPassword(bool) {
    if (bool) {
      const mainArray = []
      this.tag('PasswordCircleList').children = mainArray.map((maskedPassword) => {
        return { type: PasswordCircle, maskedPassword }
      })
    }
    this.inputFields[2].alpha = bool ? 1 : 0
    this.inputFields[2]['placeholder'] = bool ? this.passwordValue : ''
    if (!bool) this.formatePasswordCircles()
    this.prepareInputFields(this.inputFields)
  }

  passwordRender(bool, value) {
    if (value == 'backspace') {
      this.inputFields[this.buttonIndex]['placeholder'] = bool ? removeLastElement(this.inputFields[this.buttonIndex]['placeholder']) : ''
    } else {
      bool
        ? (this.inputFields[this.buttonIndex]['placeholder'] =
          this.inputFields[this.buttonIndex]['placeholder'] && value != ''
            ? (this.inputFields[this.buttonIndex]['placeholder'] += value)
            : value)
        : (this.inputFields[this.buttonIndex]['placeholder'] = '')
    }
    this.inputFields[this.buttonIndex].alpha = bool ? 1 : 0
    this.prepareInputFields(this.inputFields)
    if (!bool) this.formatePasswordCircles()
  }

  appendInputValue(value) {
    this.inputFields[this.buttonIndex].alpha = 1
    this.inputFields[this.buttonIndex]['placeholder'] =
      this.inputFields[this.buttonIndex]['placeholder'] && value != ''
        ? (this.inputFields[this.buttonIndex]['placeholder'] += value)
        : value
    this.prepareInputFields(this.inputFields)
  }

  prepareInputFields(inputFields) {
    this.tag('InputFields').children = inputFields.map((input) => {
      return { type: Input, input }
    })
  }

  _handleEnter() {
    if (this.focusType == 'input') {
      this.highlightonUnfocus = true
      this.highlightSelectedInput()
      this.onInputHandleEnter()
    } else {
      this.onButtonsHandleEnter()
    }
  }

  onButtonsHandleEnter() {
    this.buttonIndex == 0 ? this.onSignUpClick() : Router.navigate('login')
  }

  showDialog(header, message) {
    const dialog = this.fireAncestors('$returnDialog')
    const actionArray = [
      {
        label: Language.translate('common_keys.ok.text'),
        action: () => { dialog.close() }
      },
    ]
    showDialog(Language.translate(header), Language.translate(message), actionArray)
  }

  onSignUpClick() {
    const isEmailValid = this.validateEmail(this.emailValue)
    const isNameValid = this.validateName(this.fullNameValue)

    if (this.fullNameValue == '' || this.passwordValue == '' || this.emailValue == '') {
      this.showDialog('common_keys.alert.text', 'Full name, email & password fields should not be empty.')
      this.buttonIndex = 0
    } else if (!isEmailValid || !isNameValid) {
      const translationKey = !isEmailValid ? 'common_keys.invalid_email.text' : 'common_keys.desc_ur_name.text'
      this.showDialog('common_keys.alert.text', translationKey)
      this.buttonIndex = 0
    } else {
      this.registration()
    }
  }

  validateEmail(emailValue) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(emailValue)
  }

  validateName(nameValue) {
    const nameRegex = /^[A-Za-z]{3,}$/
    return nameRegex.test(nameValue)
  }

  onInputHandleEnter() {
    this.handleInputType()
    Router.getActivePage().focusKeyboard()
  }

  returnSelectedField() {
    return this.highlightonUnfocus
  }

  handleInputType() {
    // handling input type(Name, E-mail and Password) based on the index value
    const mapInputType = { 0: 'fullName', 1: 'email', 2: 'password' }
    const keyboardType = mapInputType[this.buttonIndex]
    Router.getActivePage().handleInputType(keyboardType)
  }

  formatePasswordCircles() {
    if (this.passwordValue) {
      const inputArray = this.passwordValue.split('')
      const mainArray = []
      this.xValue = 550
      this.yValue = 667
      for (let i = 0; i < inputArray.length; i++) {
        let tempObj = {}
        tempObj = { x: this.xValue, y: this.yValue }
        mainArray.push(tempObj)
        this.xValue = this.xValue + 20
      }
      this.tag('PasswordCircleList').children = mainArray.map((maskedPassword) => {
        return { type: PasswordCircle, maskedPassword }
      })
    } else {
      const mainArray = []
      this.tag('PasswordCircleList').children = mainArray.map((maskedPassword) => {
        return { type: PasswordCircle, maskedPassword }
      })
      if (this.inputFields?.length > 0) {
        this.inputFields[2].alpha = 1
        this.prepareInputFields(this.inputFields)
      }
    }
  }

  highlightSelectedInput() {
    this.tag('InputFields').children[this.buttonIndex].patch({
      texture: Lightning.Tools.getRoundRect(740, 63, 4, 3, Colors('brandPrimaryColor').get(), true, Colors('primaryFontColor1').get()),
    })
  }

  _handleKey() {
    return
  }

  _handleRight() {
    return
  }

  _handleLeft() {
    if (this.focusType == 'input') {
      this.highlightonUnfocus = true
      this.highlightSelectedInput()
      this.onInputHandleEnter()
    } else {
      this.buttonIndex = 0
      this.handleInputType()
      this.focusType = 'input'
      return Router.getActivePage().focusKeyboard()
    }
  }

  _handleDown() {
    const showPasswordStatus = Router.getActivePage().returnShowPasswordStatus()
    if (this.focusType == 'input') {
      this.highlightonUnfocus = false
      if (this.buttonIndex == 0 || this.buttonIndex == 1)
        this.buttonIndex++
      else if (this.buttonIndex == 2)
        Router.getActivePage().focusCheckBox()
      if (!showPasswordStatus) this.formatePasswordCircles()
    } else {
      this.buttonIndex < this.bData.length - 1 ? this.buttonIndex++ : null
    }
  }

  _handleUp() {
    const showPasswordStatus = Router.getActivePage().returnShowPasswordStatus()
    if (this.focusType == 'input') {
      this.highlightonUnfocus = false
      this.buttonIndex > 0 ? this.buttonIndex-- : Router.getActivePage().focusBack()
    } else {
      this.buttonIndex > 0 ? this.buttonIndex-- : Router.getActivePage().focusCheckBox()
    }
    if (!showPasswordStatus) this.formatePasswordCircles()
  }

  _handleBack() {
    Router.back()
  }
}
