import { Lightning, Router, Storage, Colors} from '@lightningjs/sdk'
import KeyboardTemplate from './KeyboardTemplate.js'
import KeyboardButton from './KeyboardButton.js'

export default class Keyboard extends Lightning.Component {
  static _template() {
    return {
      zIndex: 4,
      texture: Lightning.Tools.getRoundRect(640, 838, 0, 3, Colors('cardColor').get(), true, Colors('cardColor').get(),),
    }
  }

  get keyboardTemplate() {
    return KeyboardTemplate
  }

  get keyboardButton() {
    return KeyboardButton
  }

  get maxCharacters() {
    return this._layout == 'search' ? 15 : 40
  }

  set value(v) {
    if (v.length <= this.maxCharacters) {
      this._value = v
      this.signal('valueChanged', { value: v })
    }
  }

  get value() {
    return this._value
  }

  get rows() {
    return this.children
  }

  get rowLength() {
    return this.rows[this.rowIndex].children.length
  }

  get currentKey() {
    // if respective element is undefined then decrementing the value
    return this.children[this.rowIndex]?.children[this.colIndex] || this.children[this.rowIndex]?.children[--this.colIndex]
  }

  set layout(layout) {
    this._layout = layout
    this._update()
    this.fireAncestors('$keyboardLayoutUpdate')
  }

  set type(v) {
    this.inputType = v
  }

  get layout() {
    return this._layout
  }

  _getFocused() {
    return this.currentKey
  }

  _navigate(dir, value) {
    dir = dir === 'up' || dir === 'down' ? 'vertical' : 'horizontal'
    if (
      dir === 'horizontal' &&
      this.colIndex + value < this.rowLength &&
      this.colIndex + value > -1
    ) {
      this.previous = null
      return (this.colIndex += value)
    } else if (
      dir === 'vertical' &&
      this.rowIndex + value < this.rows.length &&
      this.rowIndex + value > -1
    ) {
      const currentColIndex = this.colIndex
      const targetRow = this.rowIndex + value
      if (this.previous && this.previous.row === targetRow) {
        const tmp = this.previous.col
        this.previous.col = this.colIndex
        this.colIndex = tmp
      } else {
        const targetRow = this.children[this.rowIndex + value]
        const targetItems = targetRow.children
        const ck = this.currentKey
        let target = 0
        for (let i = 0; i < targetItems.length; i++) {
          const ckx = this.children[this.rowIndex].x + ck.x
          const tix = targetRow.x + targetItems[i].x
          if ((ckx >= tix && ckx <= tix + targetItems[i].w) || (tix >= ckx && tix <= ckx + ck.w)) {
            target = i
            break
          }
        }
        this.colIndex = target
      }
      this.previous = { col: currentColIndex, row: this.rowIndex }
      return (this.rowIndex += value)
    }
    return false
  }

  _update() {
    if (this._layout && this.keyboardTemplate.layouts[this._layout] === undefined) {
      console.error(
        `Configured layout "${this.layout}" does not exist. Reverting to "${Object.keys(this.keyboardTemplate.layouts)[0]}"`
      )
      this._layout = null
    }
    if (!this._layout) {
      this._layout = Object.keys(this.keyboardTemplate.layouts)[0]
    }
    const { keyWidth, keyHeight, horizontalSpacing = 0, verticalSpacing = 0, layouts } = this.keyboardTemplate

    this.children = layouts[this._layout].rows.map((row, rowIndex) => {
      let keyOffset = 0
      const { x = 0, rowVerticalSpacing = verticalSpacing, rowHorizontalSpacing = horizontalSpacing, keys = [] } = row
      return {
        y: keyHeight * rowIndex + rowIndex * rowVerticalSpacing + 40,
        x,
        children: keys.map((key) => {
          key = Object.assign({ action: 'input' }, key)
          const prevOffset = keyOffset
          const { w = keyWidth, h = keyHeight, action, toLayout } = key
          keyOffset += w + rowHorizontalSpacing
          return { key, action, toLayout, x: prevOffset + 40, w, h, type: this.keyboardButton }
        }),
      }
    })
  }

  reset() {
    this.colIndex = 0
    this.rowIndex = 0
    this._value = ''
    this.enteredValue = ''
    this.previous = null
  }

  _init() {
    this.reset()
  }

  _active() {
    this._update()
    const currentRoute = Storage.get("currentRoute")
    const keyboardWidth = currentRoute.includes("search") ? 560 : 640
    this.patch({
      texture: Lightning.Tools.getRoundRect(keyboardWidth, 838, 0, 3, Colors('cardColor').get(), true, Colors('cardColor').get(),),
      KeyboardShadow: { w: keyboardWidth + 25, h: 838 + 32 }
    })
  }

  _handleRight() {
    if (this.colIndex == 6 || (this.rowIndex == 7 && this.colIndex == 2)) {
      Router.getActivePage()._handleRight()
    }
    return this._navigate('right', 1)
  }

  _handleLeft() {
    if (this.colIndex == 0 && Storage.get("currentRoute").includes("search")) {
      Router.getActivePage().onkeyboardExitLeft()
    } else {
      return this._navigate('left', -1)
    }
  }

  _handleUp() {
    if (this.rowIndex == 0) return
    return this._navigate('up', -1)
  }

  _handleDown() {
    return this.rowIndex >= 7 ? this.signal('onKeyboardExitDown') : this._navigate('down', 1)
  }

  _handleEnter() {
    const key = this.currentKey
    if (Storage.get('currentRoute').includes('search')) {
      const isBackSpaceOrDeletePressed = (key.action === 'backspace' || key.action === 'delete')
      const isLoaderVisible = Router.getActivePage().getLoaderVisibility()
      if (isLoaderVisible && isBackSpaceOrDeletePressed) return
    }
    switch (key.action) {
      case 'input':
          this.value = key.c
          this.enteredValue += key.c
        break
      case '.com':
      case '@gmail.com':
      case '@hotmail.com':
      case '@yahoo.com':
          this.value = key.c
          this.enteredValue += key.c
        break
      case 'backspace':
        if (this.value.length > 0) {
          this.value = 'backspace'
        }
        break
      case 'space':
        if (this.value.length > 0 && this.enteredValue.length < this.maxCharacters) {
          this.value = ' '
          this.enteredValue = ' '
        }
        break
      case 'delete':
        if (this.value.length > 0) {
          this.value = ''
          this.enteredValue = ''
        }
        break
      case 'toggleToLayout':
        this.layout = key.toLayout
        break
      default:
        this.signal(key.action)
        break
    }
    if (key.action != 'toggleToLayout' && this.enteredValue.length < this.maxCharacters)
      Router.getActivePage().onKeyboardInputUpdate(this.value, this.inputType, key.action)

  }

  _disable() {
    this.reset()
  }

  set clearCode(_data) {
    this.value = ''
    Router.getActivePage().onCodeUpdate(this.value)
  }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }

  _handleKey(key) {
    if (/^\d$/.test(key.key)) {
      this.value = key.key
      Router.getActivePage().onKeyboardInputUpdate(this.value, 'keypadNum')
    }
  }
}
