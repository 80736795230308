import { Utils, Router } from '@lightningjs/sdk';
import { Menu, InputField, Dialog, MoreInfoSidePanel, BackgroundSidepanel, AudioSidePanel, SubtitlesSidePanel, EpisodeListing, DetailPageAudioSubtitles, AudioPlayer} from './Widgets';
import routerConfig from './lib/routerConfig.js';
import { AmbientBackground } from './Components';
import SidePanelNavigation from './Widgets/SidePanelNavigation';
import configurations from './Helper/Configurations.js'
import Loader from './Components/Loader.js'
import { passFireboltAPIData, showExitPopUp } from './Helper/Helpers';
import { initAppInstance } from './Helper/GlobalConstants.js';
export default class App extends Router.App {
  static getFonts() {
    return [
      { family: 'Bold', url: Utils.asset('fonts/TV2ZFont-Bold.ttf') },
      {
        family: 'Regular', url: Utils.asset('fonts/TV2ZFont-Regular.ttf'),
      },
    ]
  }


  static language() {
    return {
      file: Utils.asset('translations.json'),//configurations.languageTranslationFile,
      language: 'en-US'//configurations.defaultLocale,
    }
  }

  _construct() {
    if (configurations.enableFirebolt) {
      passFireboltAPIData('lifecycleready')
    }
  }

  _detach() {
    if (configurations.enableFirebolt) {
      passFireboltAPIData('lifecycleclose')
    }
  }


  // Static method to define the initial template structure of the application
  static _template() {
    return {
      // Main content structure
      Content: {
        zIndex: 1, w: 1920, h: 1080,
        AmbientBackground: { type: AmbientBackground },

        Pages: {
          forceZIndexContext: true
        },
        Loading: { visible: true, type: Loader },
      },
      // Background components

      // Widget components
      Widgets: {
        Menu: { type: Menu, visible: true },
        InputField: { type: InputField, visible: true },
        Dialog: { visible: false, type: Dialog },
        MoreInfoSidePanel: { type: MoreInfoSidePanel },
        SidePanelNavigation: { type: SidePanelNavigation },
        BackgroundSidepanel: { type: BackgroundSidepanel },
        AudioSidePanel: { type: AudioSidePanel },
        SubtitlesSidePanel: { type: SubtitlesSidePanel },
        EpisodeListing: { type: EpisodeListing },
        DetailPageAudioSubtitles:{ type: DetailPageAudioSubtitles },
        AudioPlayer: { type : AudioPlayer },

        ClosedCaptions: {
          flex: {
            direction: 'column',
          },
          zIndex: -1,
          w: 1920,
          h: 1080,
          y: 800,
        },
      }
    };
  }

  ClosedCaptions() {
    return this.tag("ClosedCaptions")
  }

  // Method to handle the closing of the application
  _handleAppClose() {
    showExitPopUp()
  }

  // Method to get the texture of the application content
  $getAppContentTexture() {
    return this.tag('Content').getTexture();
  }

  $returnDialog() {
    return this.tag('Dialog')
  }

  // Method to update the ambient background based on an event
  $updateAmbientBackground(e) {
    this.tag('AmbientBackground').update(e.color);
  }

  // Method called during the setup of the application
  _setup() {
    initAppInstance(this)
    Router.startRouter(routerConfig, this);
  }

  $hideBackground() {
    this.tag('Content').patch({
      AmbientBackground: { smooth: { alpha: [0, { duration: 0.2, delay: 0.0 }] } },
    });
  }

  $showBackground() {
    this.tag('Content').patch({
      AmbientBackground: { smooth: { alpha: [1, { duration: 0.2, delay: 0.2 }] } },
    });
  }


  // Static method to indicate that the application supports
  static colors() {
    return true;
  }

  static get width() {
    return 1920;
  }

  static get height() {
    return 1080;
  }

  static get sideMargin() {
    return 60;
  }

  static get topMargin() {
    return 50;
  }

  static get btnHeight() {
    return 32;
  }
}
