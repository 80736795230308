import configurations from '../Helper/Configurations.js'
import { getRefreshToken } from './AuthService.js'
import { completeFlushOut } from '../Helper/Helpers.js'
import { Storage } from '@lightningjs/sdk'
import { COUNTRY_CODE, DEVICE_TYPE, REGION } from '../Helper/GlobalConstants.js'

/**
 * Convert params to query params - API URL
 * @param {QueryParams} params
 */
const qsify = (params) => {
  return Object.keys(params)
    .map((key) => {
      return `${key}=${encodeURIComponent(params[key])}`
    })
    .join('&')
}

/**
 * HTTP middleware
 * @param {HttpMethod-GET,POST} method
 * @param {HttpURL} url
 * @param {GET-Params,POST-Body} value
 * @param {Header-TokenValidation} auth
 * @param {Header-TokenValidation} headers
 */
const middleware = async (method, url, value, auth, headers) => {
  const request = { method }

  value = value || {}
  value['d_type'] = DEVICE_TYPE

  if (configurations.countryCode) {
    value['country_code'] = configurations.countryCode || COUNTRY_CODE
  }

  if (configurations.region) {
    value['region'] = configurations.region || REGION
  }

  if (configurations.locale || Storage.get('locale')) {
    value['locale'] = configurations.locale || Storage.get('locale')
  }

  const accessToken = Storage.get('loggedIn') || configurations.loggedIn
    ? configurations.userAccessToken || Storage.get('userAccessToken')
    : configurations.guestAccessToken || Storage.get('guestAccessToken')

  const excludedPaths = [
    'auth/platform_settings',
    'baseURL',
    'auth/token',
    'auth/refresh/token'
  ]

  const shouldAddAuthHeader = !excludedPaths.some(path => url.includes(path))

  if (shouldAddAuthHeader) {
    headers['Authorization'] = accessToken
  }
  method === 'GET' ? (url = `${url}?${qsify(value)}`) : (request['body'] = JSON.stringify(value))

  if (auth) request['headers'] = headers

  let counter = 0
  let responseJSON
  try {
    while (counter < 2) {
        counter++

        const fetchResult = await fetch(url, request)
        responseJSON = await fetchResult.json()

        if (responseJSON?.status_code == 200 || responseJSON?.status == 200)
            return responseJSON

        if (responseJSON?.status_code == 422 || responseJSON?.status == 422) {
            if (responseJSON.error.code == 1001) {
                await getRefreshToken()
                window.location.reload(true)
            }
        } else {
            switch (responseJSON.error.code) {
                case 1001:
                case 4001:
                case 4005:
                case 1003:
                case 4003:
                case 4004:
                case 1011:
                case 4002:
                    completeFlushOut(responseJSON.error.code)
                    break
                case 1002:
                    await getRefreshToken()
                    window.location.reload(true)
                    break
                case 1018:
                case 1019:
                    // if API got failed with these codes, then clear storage and reload the app
                    Storage.clear()
                    window.location.reload(true)
                    break
                default: break
            }
        }
    }
    return responseJSON
  } catch (error) {
    return error
  }
}


export const matomoMiddleware = async (method, value) => {
  let url = configurations.analyticsUrl
  const request = { method }

  if (!value) {
    value = {}
  }

  const defaultdata = {
    idsite: configurations.idsite,
    rec: 1,
    url: '',
    rand: new Date().getTime(),
    apiv: 1,
  }
  const finaldata = Object.assign({}, defaultdata, value)

  method === 'GET' ? (url = `${url}?${qsify(finaldata)}`) : (request['body'] = JSON.stringify(finaldata))

  try {
    await fetch(url, request)
  } catch (error) {
    console.log(error)
  }
}



export default middleware
