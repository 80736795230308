import { Lightning, Router, Utils, Colors } from '@lightningjs/sdk'
import { Checkbox } from '@lightningjs/ui-components'

export default class CheckBox extends Lightning.Component {
  static _template() {
    return {
      x: 900,
      y: 170,
      zIndex: 2,
      CheckBox: {
        x: 88,
        y: 465,
        type: Checkbox,
        style: {
          strokeColor: Colors('primaryFontColor1').get(),
          backgroundColor: Colors('cardColor').get(),
          backgroundColorChecked: Colors('cardColor').get(),
          checkColor: Colors('primaryFontColor1').get(),
          radius: 0,
          checkW: 30,
          checkH: 30,
          checkSrc: Utils.asset('static_assets/check.png'),
        },
      },
      CheckIcon: {
        x: 91,
        y: 466,
        alpha: 0,
        src: Utils.asset('static_assets/check.png'),
      },
      ShowPassword: {
        x: 140,
        y: 467,
        text: {
          text: 'Show Password',
          textColor: Colors('primaryFontColor1').get(),
          fontFace: 'Regular',
          fontSize: 24,
          maxLines: 1,
          wordWrapWidth: 800,
        },
      },
    }
  }

  set position(pos) {
    this.patch({
      x: pos[0],
      y: pos[1],
    })
  }

  _active() {
    this.tag('CheckIcon').patch({ alpha: 0 })
    this.tag('CheckBox').checked = false
  }

  _handleEnter() {
    this.tag('CheckBox').checked = (!this.tag('CheckBox').checked)
    const checkedStatus = this.tag('CheckBox').checked
    checkedStatus ? this.tag('CheckIcon').patch({ alpha: 1 }) : this.tag('CheckIcon').patch({ alpha: 0 })
    Router.getActivePage().showEnteredPassword(checkedStatus)
  }

  returnShowPasswordStatus() {
    return this.tag('CheckBox').checked
  }

  _getFocused() {
    this._focus()
  }

  _focus() {
    this.patch({
      CheckBox: {
        style: {
          strokeColor: Colors('brandPrimaryColor').get(),
          radius: 0,
          backgroundColor: Colors('cardColor').get(),
          backgroundColorChecked: Colors('cardColor').get(),
          checkColor: Colors('primaryFontColor1').get(),
        },
      },
    })
  }

  _unfocus() {
    this.patch({
      CheckBox: {
        style: {
          strokeColor: Colors('primaryFontColor1').get(),
          radius: 0,
          backgroundColor: Colors('cardColor').get(),
          backgroundColorChecked: Colors('cardColor').get(),
          checkColor: Colors('primaryFontColor1').get(),
        },
      },
    })
  }

  _handleDown() {
    Router.getActivePage().defineFocus({ focusType: 'button', index: 0 })
    Router.getActivePage().focusForms()
  }

  _handleUp() {
    const indexToFocus = Router.getActiveHash().includes('login') ? 1 : 2
    Router.getActivePage().defineFocus({ focusType: 'input', index: indexToFocus })
    Router.getActivePage().focusForms()
  }

  _handleLeft() {
    Router.getActivePage().handleInputType('password')
    Router.getActivePage().focusKeyboard()
  }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }

  _handleRight() { return }

  _handleKey() { return }
}
