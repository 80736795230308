export default class CaptionAsset {
  constructor(obj) {
    this._start = obj.start
    this._end = obj.end
    this._payload = obj.payload //? obj.payload.replace(/<(.*?)>/g, '') : '' // Remove <v- >, etc tags
  }

  get start() {
    return this._start
  }

  get end() {
    return this._end
  }

  get payload() {
    return this._payload
  }
}
