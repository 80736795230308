import { LoginOption } from '../Views'
/**
 * Defines the configuration for the 'home' page.
 */
export default {
    path: 'loginoption',
    component: LoginOption,
    /**
     * Defines the asynchronous setup for the 'home' page.
     * @param {Object} page - The page instance.
     */
    on: async (page) => {
       return page.preparePage()
    },
    /**
     * List of widgets to be included on the 'home' page.
     */
}
