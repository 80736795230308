import { Language, Lightning, Router, Storage, Utils, Colors } from '@lightningjs/sdk'
import { processTranslationFile, showDialog } from '../../Helper/Helpers'
import IconDynamicButton from '../ButtonsList/IconDynamicButton'
import SidePanelButton from '../SidePanelButtons/SidePanelButton'
import SidePanelButtons from '../SidePanelButtons/index'
import App from '../../App'
import configurations from '../../Helper/Configurations'
import { fontsizes } from '../../Helper/GlobalConstants'

export default class LocalesList extends Lightning.Component {
  static _template() {
    return {
      alpha: 0,
      x: 800,
      zIndex: 6,
      TopToBottomGradient: {
        x: 560,
        w: 565,
        h: App.height,
        rect: true,
        color: Colors('overlayColor').get(),
        alpha: 0.85
      },
      TextSelect: {
        x: 700,
        y: 100,
        mountY: 0.5,
        text: {
          text: Language.translate('change_language.language_selection.text'),
          fontStyle: 'Bold',
          fontSize: fontsizes.languageSelectionText,
          fontFace: 'Regular',
          textAlign: 'right',
        },
      },
      ScrollWrapper: {
        y: 150,
        x: 850,
        h: App.height,
        w: 250,
        clipping: true,
        LanguageVerticalButtons: {
          y: 1,
          flex: { direction: 'column' },
        },
      }
    }
  }

  _getFocused() {
    return this.tag('LanguageVerticalButtons').children[this.itemIndex]
  }

  _disable() {
    this.tag('LanguageVerticalButtons').y = 1
  }

  openLocalesList() {
    this.setSmooth('alpha', 1)
    const localeIndexSelected = Storage.get('localeList-index-selected')
    this.itemIndex = localeIndexSelected || 0
    this.patch({
      TextSelect: {
        text: {
          text: Language.translate('change_language.language_selection.text'),
        },
      },
    })
    this.tag('LanguageVerticalButtons').children = configurations.locales.map((localeObj, ind) => {
      return {
        type: ind === this.itemIndex ? IconDynamicButton : SidePanelButton,
        h: 50,
        textPadding: 10,
        label: localeObj.lang_name,
        iconImage: ind === this.itemIndex ? Utils.asset(`static_assets/tick.png`) : undefined, // set this to satisfy same way src delivery to iconDynamic-button
        iconWithText: ind === this.itemIndex ? true : false,
        flexItem: { marginTop: 30, alignSelf: 'stretch', maxHeight: 100 },
        isVerticalBtn: true,
      }
    })
    // setting itemIndex
    configurations.locales.map((item, index) => {
      // setting which lang is selected
      if (item.lang_code == Storage.get('locale') || item.lang_code == configurations.locale) {
        this.itemIndex = index
      }
    })
  }

  _handleUp() {
    if (this.itemIndex > 0) {
      this.itemIndex--

      if (configurations.locales.length > 7) {
        this.scrollFunc('up')
      }
    }
  }

  _handlekey() { return }

  _handleDown() {
    if (this.itemIndex < configurations.locales.length - 1) {
      this.itemIndex++
      if (this.itemIndex >= 5 && configurations.locales.length > 7) {
        this.scrollFunc('down')
      }
    }
  }

  _handleRight() { return }

  async _handleEnter() {
    await processTranslationFile(configurations.locales, 'change', configurations.locales[this.itemIndex].lang_code)
    Storage.set('localeList-index-selected', this.itemIndex)
    Router.getActivePage().clearSidePanel()
    Router.getActivePage().updateLanguageCode()
    Router.getActivePage().updateTranslations()
    const dialog = this.fireAncestors('$returnDialog')
    const actionArray = [
      {
        label: Language.translate('common_keys.ok.text'),
        action: () => {
          dialog.close()
          Router.getActivePage().focusLanguageSelection()
        }
      }
    ]
    showDialog(
      Language.translate('common_keys.alert.text'),
      Language.translate('account.profile_successfully_updated.text'),
      actionArray
    )
    this.tag('LanguageVerticalButtons').children = null
    this.tag('LanguageVerticalButtons').children = configurations.locales.map((localeObj, ind) => {
      return {
        type: ind === this.itemIndex ? IconDynamicButton : SidePanelButtons,
        h: 50,
        textPadding: 50,
        label: localeObj.lang_name,
        iconImage: ind === this.itemIndex ? Utils.asset(`static_assets/tick.png`) : undefined,
        iconWithText: ind === this.itemIndex ? true : false,
        flexItem: { marginTop: 30, alignSelf: 'stretch', maxHeight: 100 },
        isVerticalBtn: true,
      }
    })
  }


  _handleBack() {
    Router.focusWidget('SidePanelNavigation')
  }

  _handleLeft() {
    Router.focusWidget('SidePanelNavigation')
  }

  _handleKey() { return }

  scrollFunc(direction) {
    // all these numbers are after self-testing
    switch (direction) {
      case 'down': {
        this.tag('LanguageVerticalButtons').patch({
          smooth: {
            y: -(this.itemIndex * 22),
          },
        })
        break
      }
      case 'up': {
        this.tag('LanguageVerticalButtons').patch({
          smooth: {
            y: -((this.itemIndex - 1) * 22),
          },
        })
        break
      }
      default: break
    }
  }

}
