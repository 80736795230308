import { Lightning, Router, Storage, Language, Colors } from '@lightningjs/sdk'
import { forgotPassword } from '../../Services/AuthService'
import { removeLastElement, showDialog } from '../../Helper/Helpers'
import { DEVICE_TYPE } from '../../Helper/GlobalConstants.js'
import Button from '../Button'
import Input from './Input'
import configurations from '../../Helper/Configurations'
import Loader from '../Loader'

export default class ForgotForm extends Lightning.Component {
  static _template() {
    return {
      InputFields: { x: 511, zIndex: 2 },
      ButtonList: { zIndex: 2 },
      Loading: {
        visible: false,
        type: Loader,
        zIndex: 10,
      }
    }
  }

  _active() {
    this.buttonIndex = 0
    this.emailValue = ''
    Router.getActivePage().handleInputType('email')
  }

  get loaderTag() {
    return this.tag('Loading')
  }

  displayLoader() {
    this.loaderTag.visible = true
  }

  hideLoader() {
    this.loaderTag.visible = false
  }

  set data(formData) {
    this.focusType = 'input'
    this.highlightonUnfocus = null
    this.buttonIndex = 0
    this.inputFields = formData[0]
    this.bData = formData[1]
    this.tag('ButtonList').children = this.bData.map((button) => {
      return { type: Button, button }
    })
    this.prepareInputFields(this.inputFields)
  }

  async callforgotPassword(emailvalue) {
    const paramsObj = {}
    paramsObj['region'] = configurations.region
    paramsObj['locale'] = configurations.locale
    paramsObj['d_type'] = DEVICE_TYPE
    paramsObj['email'] = emailvalue
    this.displayLoader()
    const forgotpasswordresp = await forgotPassword(paramsObj)
    this.hideLoader()
    if (forgotpasswordresp?.status_code === 200 || forgotpasswordresp?.status_code === 401) {
      const actionArray = [
        {
          label: Language.translate('Back to login'),
          action: () => { Router.navigate('login') }
        },
      ]
      showDialog(
        Language.translate('common_keys.alert.text'),
        Language.translate('registration.check_recovery_mail.text'),
        actionArray
      )
    }
  }

  $populateInput(value, inputType) {
    if (inputType == 'email' || inputType == 'keypadNum') {
      if (value == 'backspace') {
        this.emailValue = removeLastElement(this.emailValue)
        this.inputFields[this.buttonIndex]['placeholder'] = removeLastElement(this.inputFields[this.buttonIndex]['placeholder'])
        this.prepareInputFields(this.inputFields)
        this.highlightSelectedInput()
        return
      }
      this.emailValue = value != '' ? (this.emailValue += value) : value
      Storage.set('email', this.emailValue)
      this.inputFields[this.buttonIndex].alpha = 1
      this.inputFields[this.buttonIndex]['placeholder'] =
        this.inputFields[this.buttonIndex]['placeholder'] && value != ''
          ? (this.inputFields[this.buttonIndex]['placeholder'] += value)
          : value
      this.prepareInputFields(this.inputFields)
      this.highlightSelectedInput()
    }
  }

  prepareInputFields(inputFields) {
    this.tag('InputFields').children = inputFields.map((input) => {
      return { type: Input, input }
    })
  }

  _getFocused() {
    return this.focusType == 'input'
      ? this.tag('InputFields').children[this.buttonIndex]
      : this.tag('ButtonList').children[this.buttonIndex]
  }

  _handleEnter() {
    if (this.focusType == 'input' && this.buttonIndex == 0) {
      this.highlightInputField()
    } else {
      if (this.buttonIndex == 0) {
        const isEmailValid = this.validateEmail(this.emailValue)
        !isEmailValid ? this.showPopUp(this.emailValue) : this.callforgotPassword(this.emailValue)
      }
    }
  }

  validateEmail(emailValue) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(emailValue)
  }

  highlightSelectedInput() {
    this.tag('InputFields').children[this.buttonIndex].patch({
      texture: Lightning.Tools.getRoundRect(740, 63, 4, 3, Colors('brandPrimaryColor').get(), true, Colors('primaryFontColor1').get()),
    })
  }

  highlightInputField() {
    Router.getActivePage().handleInputType('email')
    this.highlightonUnfocus = true
    this.highlightSelectedInput()
    Router.getActivePage().focusKeyboard()
  }

  _handleKey() { return }

  _handleLeft() {
    this.focusType == 'input'
      ? this.highlightInputField()
      : (this.buttonIndex = 0,
        this.focusType = 'input', Router.getActivePage().focusKeyboard())
  }

  showPopUp(val) {
    const dialog = this.fireAncestors('$returnDialog')
    const actionArray = [
      {
        label: Language.translate('common_keys.ok.text'),
        action: () => { dialog.close() }
      },
    ]
    showDialog(
      Language.translate('common_keys.alert.text'),
      Language.translate(val ? 'common_keys.invalid_email.text' : 'common_keys.valid_email_address.text'),
      actionArray
    )
  }

  _handleDown() {
    if (this.focusType == 'input') {
      this.highlightonUnfocus = false
      if (this.buttonIndex == 0) {
        this.buttonIndex = 0
        this.focusType = 'button'
      }
    }
  }

  _handleUp() {
    if (this.buttonIndex > 0) {
      this.buttonIndex--
    } else if (this.buttonIndex == 0 && this.focusType == 'button') {
      this.highlightonUnfocus = false
      this.focusType = 'input'
      this.buttonIndex = this.inputFields.length - 1
    } else if (this.buttonIndex == 0 && this.focusType == 'input') {
      this.highlightonUnfocus = false
      Router.getActivePage().focusBack()
    }
  }

  returnSelectedField() {
    return this.highlightonUnfocus
  }

  _handleRight() { return }

  _handleBack() {
    Router.back()
  }
}
