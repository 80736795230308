import { Lightning, Router, Storage, Colors } from '@lightningjs/sdk'
import { FONT_REGULAR, fontsizes } from '../../Helper/GlobalConstants'

export default class LanguageSelection extends Lightning.Component {
  static _template() {
    return {
      LanguageBox: {
        alpha: 0,
        x: 1795,
        y: 45,
        zIndex: 4,
        text: {
          fontSize: fontsizes.langShortcut,
          fontFace: FONT_REGULAR,
        },
        UnderLine: {
          y: 55,
          texture: Lightning.Tools.getRoundRect(60, 2, 0, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get())
        }
      },
      LangBackground: {
        alpha: 0,
        x: 1780,
        y: 47,
        texture: Lightning.Tools.getRoundRect(85, 60, 2, 2, Colors('brandPrimaryColor').get(), true, Colors('focusedBtnColor').get()),
      },
    }
  }

  set data(data) {
    this._index = 0
    this.realIndexFocused = true
    this.langList = data
    this.patch({
      LanguageBox: { alpha: 1 },
      LangBackground : { alpha: 0 }
    })
    this.updateLanguageCode()
  }

  updateLanguageCode() {
    const locale = Storage.get('locale')
    for (const language of this.langList) {
      if (locale == language.lang_code) {
        const string = language.lang_code.slice(0, 2)
        this.patch({
          LanguageBox: {
            text: { text: string.toUpperCase() },
          },
        })
        break
      }
    }
  }

  _focus() {
    this.patch({
      LangBackground : { alpha: 1 },
      LanguageBox: { alpha: 1 }
    })
  }

  _getFocused() {
    this._focus()
  }

  _unfocus() {
    this.tag('LangBackground').alpha = 0
  }

  _handleLeft() {
    Router.getActivePage().focusBack()
  }

  _handleDown() {
    if (Router.getActiveHash().includes('authenticate')) return
    Router.getActivePage().focusBack()
  }

  _handleEnter() {
    Router.getActivePage().openLocalesList()
    Router.getActivePage().widgets.sidepanelnavigation.data = {
      component: 'change-locale',
      xvalue: 1335,
    }
  }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }

  _handleUp() { return }

  _handleRight() { return }

  _handleKey() { return  }
}

