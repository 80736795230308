import { Lightning, Router, Registry, Storage, Utils, Colors, Language } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import ProgramItem from './ProgramItem'
import configurations from '../../Helper/Configurations'
import { getPersonaDetails } from '../../Services/PersonaService'

export default class ProgramsList extends Lightning.Component {
    static _template() {
        return {
            Background: {
                zIndex: 2,
                alpha: 0,
                rect: true,
                color: Colors('backgroundColor').get(),
            },
            NoFeedError: {
                alpha: 0,
                zIndex: 3,
                ErrorImage: {
                    y: (870 - 170) / 2, //870 container height
                    w: 77, h: 77, src: Utils.asset('static_assets/FeedError.png'),
                },
                ErrorText: {
                    y: (870 + 100) / 2,
                    text: {
                        text: Language.translate('live_channelstreaming_temp_unavailabletext'),
                        fontSize: 36, textColor: Colors('primaryFontColor1').get(), lineHeight: 59, wrap: true, textAlign: 'center', wordWrapWidth: 900, fontFace: 'Regular', maxLines: 2
                    },
                },
            },
            List: {
                clipping: true,
                alpha: 1,
                type: List,
                direction: 'column',
                signals: { onRequestItems: true, onIndexChanged: true }
            },
        }
    }

    onIndexChanged(indexData) {
        const { index: currentIndex } = indexData
        if (currentIndex + 4 === this.tag('List').length - 1) {
            this.onRequestItems()
        }
    }

    async onRequestItems() {
        let nextSetOfCarousel = this.currentShowingArray.slice(this.tag('List').length, this.tag('List').length + 4)

        const children = nextSetOfCarousel.map((show, i) => {
            const startTime = new Date(show.start_date).toLocaleTimeString()
            const endTime = new Date(show.end_date).toLocaleTimeString()
            if (show?.items?.[0]?.geo_countries) {
                const availableCountries = show?.items[0]?.geo_countries[0]?.available_countries.split(',')
                availableCountries.find((country) => {
                    if (country === configurations.countryCode.toUpperCase()) {
                        if (show.items[0].content_id && show.items[0].content_id !== '') {
                            show.location_available = 1
                            show.title = show.items[0].content_title
                            show.description = show.items[0].content_description
                        }
                    }
                })
            }
            return {
                w: 1700,
                h: 100,
                type: ProgramItem,
                index: i,
                show: show,
                startTime: startTime,
                endTime: endTime,
                endMillis: show.end_date,
                startMillis: show.start_date,
                programId: show?.items?.[0] ? show?.items?.[0].content_id : undefined,
                contentType: show?.content_type || undefined
            }
        })
        this.feedInfo = children
        let onlyItem = this.feedInfo.filter((listItem) => {
            return listItem.content_type !== 'filler'
        })
        this.tag('List').addAt(onlyItem, this.tag('List').length)
    }

    _init() {
        this.calculateTextWidth()
    }

    calculateTextWidth() {
        const itemWidth = configurations.smartChannelsEnabled ? 1920 : 1600
        this.tag('ErrorText').on('txLoaded', () => {
            const width = this.tag('ErrorText').renderWidth
            const dynamicXPos = (itemWidth - width) / 2
            this.tag('ErrorText').patch({ x: dynamicXPos })
        })
        this.tag('ErrorImage').patch({ x: (itemWidth - 100) / 2 })
    }

    set data(v) {
        this.itemPreparation(...v.programInfo, v.channelInfo)
    }

    set flexItem(v) {
        this.w = v.w
        this.h = v.h
        this.patch({
            y: v.y,
            x: v.x,
            w: v.w,
            h: v.h,
            Background: {
                w: v.w,
                h: v.h
            },
            List: {
                w: v.w,
                h: v.h
            }
        })
    }

    static width() {
        return this.w
    }

    static height() {
        return this.h
    }


    _getFocused() {
        this.tag('List').alpha = 1
        this._index = this.tag('List')._index
        return this.tag('List')
    }

    itemPreparation(item, channelInfo) {
        if (item.flag && item.flag === 'NoFeed') {
            this.errorMessagePrompt()
        }
        else {
            Registry.clearIntervals()
            this.tag('NoFeedError').alpha = 0
            this.tag('Background').alpha = 1
            Storage.remove('NofeedAvailable')
            Storage.set('updateData', item.feed_id)
            !Router.getActiveHash().includes('tvguide') && Router.getActivePage().tag('MetaInfo').receiveChannelData(channelInfo)
            let index = 0
            const todayDate = new Date()
            const currentShowIndex = item.programs.findIndex((program) => {
                const milsec = program.end_date
                const programDate = new Date(milsec)
                const g1 = programDate.getTime()
                const g2 = todayDate.getTime()
                const x = new Date(todayDate).toLocaleDateString()
                const y = programDate.toLocaleDateString()
                return y >= x && g1 >= g2
            })
            const showIndex = currentShowIndex === -1 ? 0 : currentShowIndex
            this.newShowArray = item.programs.slice(showIndex, item.programs.length - 1)
            this.currentShowingArray = this.newShowArray.filter((listItem) => {
                return listItem.content_type !== 'filler'
            })
            if (this.currentShowingArray.length < 0) {
                this.errorMessagePrompt()
            } else {
                let initialShows = this.currentShowingArray.slice(0, 12)
                this.newShowArray[0].currentShow = true
                const children = initialShows.map((show, i) => {
                    const startTime = new Date(show.start_date).toLocaleTimeString()
                    const endTime = new Date(show.end_date).toLocaleTimeString()
                    if (show?.items?.[0]?.geo_countries) {
                        const availableCountries = show?.items[0]?.geo_countries[0]?.available_countries.split(',')
                        availableCountries.find((country) => {
                            if (country === configurations.countryCode.toUpperCase()) {
                                if (show.items[0].content_id) {
                                    show.location_available = 1
                                    show.title = show.items[0].content_title
                                    show.description = show.items[0].content_description
                                }
                            }
                        })
                    }
                    return {
                        w: this.w,
                        h: 100,
                        type: ProgramItem,
                        index: i,
                        show: show,
                        startTime: startTime,
                        endTime: endTime,
                        endMillis: show.end_date,
                        startMillis: show.start_date,
                        programId: show?.items?.[0] ? show?.items?.[0].content_id : undefined,
                        content_type: show?.content_type || undefined
                    }
                })
                this.feedInfo = children
                this.tag('List').clear()
                this.tag('List').add(children)
                this.tag('List').alpha = 1
                this.tag('List').setIndex(index)
                !Router.getActiveHash().includes('tvguide') && this.getProgramInfo(channelInfo, this.feedInfo)
            }
        }
        !Router.getActiveHash().includes('tvguide') && Router.getActivePage().tag('MetaInfo').updateLogo(channelInfo)
    }

    errorMessagePrompt() {
        this.tag('NoFeedError').patch({
            alpha: 1,
            ErrorText: {
                text: { text: Language.translate('live_channelstreaming_temp_unavailabletext') }
            }
        })
        Storage.set('NofeedAvailable', true)
        !Router.getActiveHash().includes('tvguide') && Router.getActivePage().tag('MetaInfo').updateMetaInfo(null)
        this.tag('NoFeedError').alpha = 1
        this.tag('Background').alpha = 1
        Router.getActivePage().hideLoader()
        this.tag('List').clear()
        this.tag('List').alpha = 0
    }

    async getProgramInfo(data, feedInfo) {
        this.reload()
        const firstItem = feedInfo[0]
        const type = firstItem?.show?.content_type || firstItem?.content_type
        if (type === 'video' && data.channel_type != 'live') {
            await this.getVideoInfo(firstItem, data)
        } else if (type === 'filler' && data.channel_type != 'live') {
            const item = this.getFillerItem(firstItem)
            Router.getActivePage().updateLiveStreamPlayback(item, data.channel_type, data)
            Router.getActivePage().tag('MetaInfo').updateMetaInfo(undefined)
        } else {
            const item = this.getDefaultItem(firstItem)
            Router.getActivePage().tag('MetaInfo').updateMetaInfo(item)
        }
    }

    getFillerItem(firstItem) {
        const utcMilliseconds = firstItem.start_date // UTC milliseconds (example value)
        const country = configurations.countryCode
        const startTime = new Date(utcMilliseconds).toLocaleTimeString('en-' + country, { hour: 'numeric', minute: 'numeric', second: '2-digit', hour12: false })
        const localDate = new Date(utcMilliseconds)
        const offsetMilliseconds = localDate.getTimezoneOffset() * 60000 // Convert minutes to milliseconds
        const localMilliseconds = utcMilliseconds - offsetMilliseconds

        let item = {
            content_type: firstItem.content_type,
            content_description: firstItem.description,
            startTime: startTime,
            playback_url: firstItem.dash_playback_url,
            startMillis: localMilliseconds,
            content_title: firstItem.title,
            comingNextTitle: ''
        }
        const { image, cast, director, publish_year, genres } = firstItem?.show?.items?.[0] || ''
        const imageIcon = image
        const casting = cast
        const direct = director
        const year = publish_year
        const genre = genres?.[0] || ''
        item.metaContainer = { imageIcon, casting, direct, year, genre }

        return item
    }

    async getVideoInfo(firstItem, data) {
        const programId = firstItem?.programId || null
        const metaOfItem = firstItem?.show?.items?.[0] || ''
        const videoInfo = await getPersonaDetails(programId, 'video')
        videoInfo.data.content_type = firstItem.content_type,
        videoInfo.data.startTime = firstItem.startTime
        videoInfo.data.startMillis = firstItem.startMillis
        const { image, cast, director, publish_year, genres } = metaOfItem
        const imageIcon = image
        const casting = cast
        const direct = director
        const year = publish_year
        const genre = genres?.[0] || ''
        videoInfo.data.metaContainer = { imageIcon, casting, direct, year, genre }
        videoInfo.data.comingNextTitle = this.tag('List')?.items?.[1]?.showDetails?.title || ''
        Router.getActivePage().updateLiveStreamPlayback(videoInfo.data, data.channel_type, data)
        Router.getActivePage().tag('MetaInfo').updateMetaInfo(videoInfo.data)
    }

    getDefaultItem(firstItem) {
        let item = {
            content_description: firstItem?.show?.description,
            startTime: firstItem.startTime,
            content_title: firstItem?.show?.title,
            comingNextTitle: this.tag('List').items[1]?.showDetails?.title
        }
        const { image, cast, director, publish_year, genres } = firstItem?.show?.items?.[0] || ''
        const imageIcon = image
        const casting = cast
        const direct = director
        const year = publish_year
        const genre = genres?.[0] || ''
        item.metaContainer = { imageIcon, casting, direct, year, genre }

        return item
    }

    sendProgressValueToLive() {
        if (!Storage.get('NofeedAvailable')) {
            const startMillis = this.feedInfo[0].startMillis
            const currentTimeInMs = Date.now()
            const endMillis = this.feedInfo[0].endMillis
            const durationMinutes = endMillis / (1000 * 60) - startMillis / (1000 * 60)
            const currentMinutes = ((currentTimeInMs - startMillis) / (1000 * 60))
            const updateProgress = currentMinutes / durationMinutes
            Router.getActivePage().tag('MetaInfo').updateProgressBar(updateProgress)
            return startMillis
        }
    }

    reload() {
        const firstItem = this.feedInfo[0]
        this.reloadTimer && Registry.clearInterval(this.reloadTimer)
        this.reloadTimer = undefined
        const reloadCallback = () => {
            const currentTime = new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
            const programEndtime = new Date(firstItem.endMillis || firstItem.end_date).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
            if (programEndtime === currentTime) {
                Router.reload()
                !Router.getActiveHash().includes('tvguide') && Router.getActivePage().destroyLivePlayer()
            } else {
                return
            }
        }
        this.reloadTimer = Registry.setInterval(reloadCallback, 1000)
    }

    _inactive() {
        if (Storage.get('activeCategory') != 3 || Storage.get('selectedCategory') != "Kanalen") {
            this.reloadTimer && Registry.clearInterval(this.reloadTimer)
            Storage.remove('updateData')
            this.reloadTimer = undefined
        }
    }

    _handleBack() {
        this.reloadTimer && Registry.clearInterval(this.reloadTimer)
        this.reloadTimer = undefined
        Router.getActivePage()._handleBack()
    }

    _handleUp() {
        Router.getActivePage().focusComponent('channels')
    }

    _handleDown() {
        // Need to implement
    }

    _handleRight() {
        // Need to implement
    }

    _handleLeft() {
        // Need to implement
    }

    _handleEnter() {
        this._index = this.tag('List')._index
        if (this.tag('List')?._items[this._index]?.show?.items?.[0]?.content_id) {
            const selectedItem = this.tag('List')._items[this._index].show.items[0]
            selectedItem['isSelected'] = true
            selectedItem.is_series
                ? Router.navigate(`detail/series/${selectedItem.content_id}`, { overlayStatus: 'hideOverlay', keepAlive: true })
                : Router.navigate(`detail/vod/${selectedItem.content_id}`, { keepAlive: true })
            !Router.getActiveHash().includes('tvguide') && Router.getActivePage().destroyLivePlayer()
            Router.getActivePage().displayLoader()
        }
    }

    _handleKey() { return }

    updateZappingPosition(action) {
        if (action === 'Down') {
            this.patch({
                y: 211,
                alpha: 1,
                visible: true,
                List: { alpha: 1 },
            })
        }
        else {
            this.patch({
                y: 1080,
                alpha: 1,
                visible: false,
                List: { alpha: 1 }
            })
        }
    }
}
