import { Lightning, Router, Language, Colors } from '@lightningjs/sdk'
import { FONT_BOLD, FONT_REGULAR } from '../Helper/GlobalConstants.js'
import { Button, LandscapeKeyboard } from '../Components/index.js'
import { removeLastElement } from '../Helper/Helpers.js'
import App from '../App.js'
import Input from '../Components/Forms/Input.js';
import configurations from '../Helper/Configurations.js'
import { generateProfileDeleteOtp, verifyGeneratedOtp } from '../Services/PersonaService.js'
import { InlineContent } from '@lightningjs/ui-components'
import PasswordCircle from '../Components/Forms/PasswordCircle.js'
import Loader from '../Components/Loader.js'

export default class DeleteProfile extends Lightning.Component {
    static _template() {
        return {
            DeleteProfileHeading: {
                y: 140,
                text: {
                    text: Language.translate('profile.delete_modal_title.text'),
                    fontFace: FONT_BOLD,
                    fontSize: 40,
                    lineHeight: 53,
                    textColor: Colors('primaryFontColor1').get(),
                },
            },
            DeleteProfileSubHeading: {
                y: 239,
                text: {
                    fontFace: FONT_REGULAR,
                    fontSize: 24,
                    lineHeight: 40,
                    textColor: Colors('secondaryFontColor').get(),
                },
            },
            ErrorMessage: {
                y: 328,
                alpha: 0,
                text: {
                  fontFace: 'Regular',
                  fontSize: 26,
                  textColor: Colors('errorColor').get(),
                },
            },
            PinContainer: {
                alpha: 0,
                y: 0,
                PinBox: {
                    x: 740,
                    y: 318,
                    zIndex: 1,
                    Box1: {
                        x: 0,
                        texture: Lightning.Tools.getRoundRect(80, 80, 4, 2, Colors('primaryFontColor1').get(), false),
                        Label1: {
                            x: 30,
                            y: 15,
                            text: {
                                textColor: Colors('primaryFontColor1').get(),
                                fontFace: FONT_BOLD,
                                fontSize: 42,
                                lineHeight: 32,
                            },
                        },
                    },
                    Box2: {
                        x: 120,
                        texture: Lightning.Tools.getRoundRect(80, 80, 4, 2, Colors('primaryFontColor1').get(), false),
                        Label2: {
                            x: 30,
                            y: 15,
                            text: {
                                textColor: Colors('primaryFontColor1').get(),
                                fontFace: FONT_BOLD,
                                fontSize: 42,
                                lineHeight: 39,
                            },
                        },
                    },
                    Box3: {
                        x: 240,
                        texture: Lightning.Tools.getRoundRect(80, 80, 4, 2, Colors('primaryFontColor1').get(), false),
                        Label3: {
                            x: 30,
                            y: 15,
                            text: {
                                textColor: Colors('primaryFontColor1').get(),
                                fontFace: FONT_BOLD,
                                fontSize: 42,
                                lineHeight: 39,
                            },
                        },
                    },
                    Box4: {
                        x: 360,
                        texture: Lightning.Tools.getRoundRect(80, 80, 4, 2, Colors('primaryFontColor1').get(), false),
                        Label4: {
                            x: 30,
                            y: 15,
                            text: {
                                textColor: Colors('primaryFontColor1').get(),
                                fontFace: FONT_BOLD,
                                fontSize: 42,
                                lineHeight: 39,
                            }
                        }
                    }
                },
                ResendText: {
                    w: App.width,
                    flex: { direction: 'row', justifyContent: 'center' },
                    y: 450,
                    type: InlineContent,
                    content: [],
                },
            },
            PasswordContainer: {
                y: 300,
                x: 500,
                InputFields: { zIndex: 2 },
            },
            PasswordCircleList: { zIndex: 2 },
            ButtonsContainer: {
                x: 560,
                y: 470,
                ButtonFields: { zIndex: 2, visible: false },
            },
            LandscapeKeyboard: { type: LandscapeKeyboard, visible: true },
            Loading: { visible: false, type: Loader, zIndex: 10 },
        }
    }

    _init() {
        this.passwordValue = ''
        this.index = 0
        this._setState('LandscapeKeyboard')
        this.dynamicTextPositioning()
    }

    dynamicTextPositioning() {
        this.tag('DeleteProfileHeading').on('txLoaded', () => {
            const width = this.tag('DeleteProfileHeading').renderWidth
            const dynamicXPos = (App.width - width) / 2
            this.tag('DeleteProfileHeading').patch({ x: dynamicXPos })
        })

        this.tag('DeleteProfileSubHeading').on('txLoaded', () => {
            const width = this.tag('DeleteProfileSubHeading').renderWidth
            const dynamicXPos = (App.width - width) / 2
            this.tag('DeleteProfileSubHeading').patch({ x: dynamicXPos })
        })

        this.tag('ErrorMessage').on('txLoaded', () => {
            const width = this.tag('ErrorMessage').renderWidth
            const dynamicXPos = (App.width - width) / 2
            this.tag('ErrorMessage').patch({ x: dynamicXPos })
      })
    }

    get loaderTag() {
        return this.tag('Loading')
    }

    displayLoader() {
        this.loaderTag.visible = true
    }

    hideLoader() {
        this.loaderTag.visible = false
    }

    async generateOTP() {
        const generateOTPObjet = { 'profile_id': this.profileInfo.profile_id }
        await generateProfileDeleteOtp(generateOTPObjet)
    }

    validateOtp(val) {
        const otpRegex = /^\d{4}$/
        return otpRegex.test(val)
    }

    async verifyOTP() {
        const isValidOtp = (this.emailFlow) ? true : this.validateOtp(this.passwordValue)
        if (!isValidOtp) return this.handleErrorCase(Language.translate('registration.invalid_pin.error'))

        const params = { profile_id: this.profileInfo.profile_id }
        params[this.emailFlow ? 'password' : 'otp'] = this.passwordValue
        this.displayLoader()
        const otpValRes = await verifyGeneratedOtp(params)
        this.hideLoader()
        if (otpValRes?.status_code === 200) {
            configurations.profilesList = otpValRes.data
            let updateDefaultImages = configurations.defaultProfileImages.filter(item => item.length !== 1)
            configurations.defaultProfileImages = updateDefaultImages
            return Router.navigate('profile/add')
        }
        if (otpValRes.error) this.handleErrorCase(otpValRes.error.message.trim())
    }

    handleErrorCase(message) {
        this.deleteMessageError = true
        this.tag('LandscapeKeyboard').clearLetterByLetter(this.passwordValue)
        this.tag('ErrorMessage').patch({
            alpha: 1,
            text: { text: message }
        })

        if (!this.emailFlow) {
            this.patch({
                PinContainer: { PinBox: { smooth: { y: 397 } }, ResendText: { smooth: { y: 517 } } },
                ButtonsContainer: { smooth: { y: 567 } }
            })
            const labels = ['Label1', 'Label2', 'Label3', 'Label4']
            const boxes = ['Box1', 'Box2', 'Box3', 'Box4']
            labels.forEach(label => { this.tag(label).patch({ text: { textColor: Colors('primaryFontColor1').get() } }) })
            boxes.forEach(box => {
                this.tag(box).patch({ texture: Lightning.Tools.getRoundRect(80, 80, 4, 2, Colors('errorColor').get(), false) })
            })
            this.tag('LandscapeKeyboard').visible = true
            return this._setState('LandscapeKeyboard')
        } else {
            this.patch({
                PasswordContainer: { smooth: { y: 400 } },
                ButtonsContainer: { smooth: { y: 531 } }
            })
            this.passwordConversion(455)
        }
    }

    showTimerToResendMail() {
        let timer = 30
        this.buttonsInfo = [
            {
                x: 1,
                y: 10,
                w: 800,
                h: 63,
                fontSize: 24,
                title: Language.translate('common_keys.code_not_received.text'),
                layout: 'border',
                radius: 10,
            },
        ]
        this.tag('ResendText').patch({
            alpha: 1,
            content: [
                {
                    text: Language.translate('common_keys.resend_code.text'),
                    style: {
                        textColor: Colors('primaryFontColor1').get(),
                        fontFace: 'Regular',
                        fontSize: 25,
                    },
                },
                {
                    text: `30 sec`,
                    style: {
                        textColor: Colors('primaryFontColor1').get(),
                        fontFace: 'Bold',
                        fontSize: 25,
                    },
                },
            ],
        })
        this.IntervalId = setInterval(() => {
            if (timer != 1) {
                timer = timer - 1
                this.tag('ResendText').patch({
                    alpha: 1,
                    content: [
                        {
                            text: Language.translate('common_keys.resend_code.text'),
                            style: {
                                textColor: Colors('primaryFontColor1').get(),
                                fontFace: 'Regular',
                                fontSize: 25,
                            },
                        },
                        {
                            text: `${timer} sec`,
                            style: {
                                textColor: Colors('primaryFontColor1').get(),
                                fontFace: 'Bold',
                                fontSize: 25,
                            },
                        },
                    ],
                })
            } else {
                this.tag('ResendText').alpha = 0
                this.tag('ButtonFields').children.push(resendBtn)
                this.tag('ButtonFields').children[0].alpha = 1
                clearInterval(this.IntervalId)
            }
        }, 1000)
        this.tag('ButtonFields').children = this.buttonsInfo.map((button) => {
            return { type: Button, button }
        })
        this.tag('ButtonFields').visible = true
        this.tag('ButtonFields').children[0].alpha = 0
        const resendBtn = this.tag('ButtonFields').children[0]
        const labels = ['Label1', 'Label2', 'Label3', 'Label4']
        labels.forEach(label => { this.tag(label).patch({ text: { text: '' } }) })
        return this.tag('ButtonFields').children[0]
    }

    set params(data) {
        let editButtonsArray = []
        this.resendCounter = 0
        this.profileInfo = data
        this.deleteMessageError = false
        if (configurations.userEmailVerified || configurations.userMobileVerified) {
            this.tag('PinContainer').alpha = 1
            this.emailFlow = 0
            this.generateOTP()
            this.passwordValue = ''
            this.tag('DeleteProfileSubHeading').patch({ text: { text: Language.translate('profile.mobile_code_verification.text') } })
            this.showTimerToResendMail()
        } else {
            this.tag('PinContainer').alpha = 0
            this.emailFlow = 1
            this.passwordValue = ''
            this.tag('DeleteProfileHeading').patch({ text: { text: Language.translate('profile.delete_modal_title.text') } })
            this.tag('DeleteProfileSubHeading').patch({ text: { text: Language.translate('profile.enter_account_password.text') } })
            this.tag('ButtonsContainer').patch({ y: 431 })
            this.inputFields = [
                {
                    x: 60, y: 38, textX: 10, alpha: 1,
                    inputFillColor: Colors('cardColor').get(),
                    placeholder: Language.translate('profile.enter_password.text'),
                }
            ]
            this.prepareInputFields(this.inputFields)

            const deleteBtnObject = {
                x: 1,
                y: 10,
                w: 800,
                h: 64,
                fontSize: 24,
                title: Language.translate('profile.delete_profile.text'),
                layout: 'border',
                radius: 10,
            }
            editButtonsArray.push(deleteBtnObject)
            this.tag('ButtonFields').children = editButtonsArray.map((button) => {
                return { type: Button, button }
            })
        }

    }

    prepareInputFields(inputFields) {
        this.tag('InputFields').children = inputFields.map((input) => {
            input.w = 800
            return { type: Input, input }
        })
    }

    _getFocused() {
        if (this.index == 0) return this.tag('InputFields').children[0]
        if (this.index == 1) return this.tag('ButtonFields').children[0]
        if (this.index == 2) return this.tag('ButtonFields').children[1]
    }

    onkeyboardExitUp() {
        this.index = 1
        Router.focusPage()
    }

    _handleDown() {
        const maxIndex = this.emailFlow ? 1 : 2
        if (this.index < maxIndex) {
            this.index++
        }
    }

    _handleUp() {
        if (this.emailFlow) {
            if (this.index > 0) return this.index--
            return this.tag('InputFields').children[0]
        }
        if (this.index > 1) {
            return this.index--
        } else {
            this.tag('LandscapeKeyboard').clearLetterByLetter(this.passwordValue)
            this.tag('LandscapeKeyboard').visible = true
            return this._setState('LandscapeKeyboard')
        }
    }

    actionControls() {
        this.tag('LandscapeKeyboard')._handleRight()
    }

    _handleRight() { return }

    _handleEnter() {
        if (this.emailFlow) {
            if (this.index === 1) {
                return this.verifyOTP()
            }

            if (this.index === 0) {
                this.tag('LandscapeKeyboard').visible = true
                return this._setState('LandscapeKeyboard')
            }
        }

        if (!this.emailFlow && this.index === 1 && this.resendCounter < 2) {
            this.resendCounter++
            this.resendCode()
            this.tag('LandscapeKeyboard').visible = true
            return this._setState('LandscapeKeyboard')
        }

        if (!this.emailFlow && this.index === 2) {
            this.verifyOTP()
        }
    }


    _handleKey() { return }

    _handleLeft() { return }

    _handleBack() {
        Router.back()
    }

    retainState() {
        if (this.emailFlow) {
            this.tag('LandscapeKeyboard').visible = false
            this.index = 0
        } else {
            if ((this.passwordValue.length <= 4 && this.tag('LandscapeKeyboard').visible) && !this.deleteMessageError) {
                return Router.back()
            } else {
                this.tag('LandscapeKeyboard').visible = false
                this.index = 2
            }

        }
        this._setState('')
        Router.focusPage()
    }

    hideErrorMessage() {
        this.tag('ErrorMessage').patch({ alpha: 0 })
        this.patch({
            PinContainer: { PinBox: { smooth: { y: 318 } } },
            PasswordContainer: { smooth: { y: 300 } },
            ButtonsContainer: { smooth: { y: 470 } }
        })
    }

    onKeyboardInputUpdate(value, keyAction) {
        if (!this.emailFlow) {
            const labels = [this.tag('Label1'), this.tag('Label2'), this.tag('Label3'), this.tag('Label4')]
            const boxes = [this.tag('Box1'), this.tag('Box2'), this.tag('Box3'), this.tag('Box4')]
            const maxLength = 4

            const updateLabelsAndBoxes = (start, end, boxColor) => {
                for (let i = start; i < end; i++) {
                    labels[i].patch({ text: { text: '', textColor: 0xff000000 } })
                    boxes[i].patch({ texture: Lightning.Tools.getRoundRect(80, 80, 4, 2, boxColor, false) })
                }
            }

            if (keyAction === 'backspace') {
                this.passwordValue = this.passwordValue.slice(0, -1)
                const endIndex = this.passwordValue.length + 1 || 0
                updateLabelsAndBoxes(endIndex - 1, maxLength, Colors('primaryFontColor1').get(),)
            } else if (keyAction === 'delete') {
                this.passwordValue = ''
                updateLabelsAndBoxes(0, maxLength, Colors('primaryFontColor1').get())
            } else if (keyAction !== 'backspace' && keyAction !== 'delete' && keyAction !== 'space' && value.length <= maxLength) {
                this.passwordValue = value
                for (const label of labels) {
                    if (!label.text?.text) {
                        label.patch({ text: { text: value.slice(-1), textColor: Colors('primaryFontColor1').get() } })
                        break
                    }
                }
            }

            if (this.passwordValue.length === 4 && keyAction == 'done') {
                this.index = 2
                this.tag('LandscapeKeyboard').visible = false
                this.tag('ResendText').alpha = 0
                clearInterval(this.IntervalId)
                const deleteBtnObject = {
                    x: 1,
                    y: 115,
                    w: 800,
                    h: 64,
                    fontSize: 24,
                    title: Language.translate('profile.delete_profile.text'),
                    layout: 'border',
                    radius: 10,
                }
                this.buttonsInfo.push(deleteBtnObject)
                this.tag('ButtonFields').children = this.buttonsInfo.map((button) => {
                    return { type: Button, button }
                })
                this._setState('')
                Router.focusPage()
            }
        } else {
            switch (keyAction) {
                case 'backspace':
                    this.passwordValue = removeLastElement(this.passwordValue)
                    this.inputFields[0]['placeholder'] = this.passwordValue == '' ? Language.translate('profile.enter_password.text') : removeLastElement(this.inputFields[0]['placeholder'])
                    this.passwordConversion(this.deleteMessageError ? 455 : 355)
                    break
                case 'delete':
                    this.passwordValue = ''
                    this.inputFields[0]['placeholder'] = Language.translate('profile.enter_password.text')
                    this.passwordConversion(this.deleteMessageError ? 455 : 355)
                    break
                case 'toggleToLayout':
                    this.passwordValue = ''
                    this.inputFields[0]['placeholder'] = Language.translate('profile.enter_password.text')
                    this.passwordConversion(this.deleteMessageError ? 455 : 355)
                    break
                case 'done':
                    this.passwordValue = value
                    this.tag('ButtonFields').visible = true
                    this.tag('LandscapeKeyboard').visible = false
                    this.index = 1
                    this._setState('')
                    Router.focusPage()
                    break
                default:
                    this.passwordValue = value
                    this.inputFields[0].alpha = 0
                    this.inputFields[0]['placeholder'] = value
                    this.prepareInputFields(this.inputFields)
                    this.passwordConversion(this.deleteMessageError ? 455 : 355)
                    break
            }
        }
    }

    passwordConversion(yPos = '') {
        if (this.passwordValue) {
            const inputArray = this.passwordValue.split('')
            const mainArray = []
            this.xValue = 575
            this.yValue = yPos || 355
            for (let i = 0; i < inputArray.length; i++) {
                const tempObj = {
                    x: this.xValue,
                    y: this.yValue,
                }
                mainArray.push(tempObj)
                this.xValue = this.xValue + 20
            }
            this.tag('PasswordCircleList').children = mainArray.map((maskedPassword) => {
                maskedPassword.color = Colors('primaryFontColor1').get()
                return { type: PasswordCircle, maskedPassword }
            })
        } else {
            const mainArray = []
            this.tag('PasswordCircleList').children = mainArray.map((maskedPassword) => {
                return { type: PasswordCircle, maskedPassword }
            })
            if (this.inputFields?.length > 0) {
                this.inputFields[0].alpha = 1
                this.prepareInputFields(this.inputFields)
            }
        }
    }

    resendCode() {
        this.generateOTP()
        this.passwordValue = ''
        this.showTimerToResendMail()
    }


    returnSelectedField() {
        return false
    }

    patchDataToTemplate() {
        this.index = 0
        this._setState('LandscapeKeyboard')

    }

    static _states() {
        return [
            class LandscapeKeyboard extends this {
                _getFocused() {
                    return this.tag('LandscapeKeyboard')
                }
            },
        ]
    }
}



