import { Language, Router, Storage, Colors } from '@lightningjs/sdk';
import { KeyboardInputDisplay, Page, SearchResults } from '../Components';
import { Keyboard } from '../Components';
import { Shadow } from '@lightningjs/ui-components';
import { searchVideo } from '../Services/AssetsService';
import { SearchResponse } from '../Models/SearchModel';
import Loader from '../Components/Loader';
import { fontsizes } from '../Helper/GlobalConstants';

export default class Search extends Page {
    static _template () {
        return {
          SearchBar: {
            type: KeyboardInputDisplay,
            x: 100,
            y: 100,
          },
          Message: {
            x: 910,
            y: 200,
            alpha: 0,
            Toast: {
              w: 915,
              x: 450,
              mountX: 0.5,
              text: {
                fontFace: 'Regular',
                fontSize: fontsizes.searchPageToastMessage,
                textColor: Colors('brandPrimaryColor').get(),
                wordWrapWidth: 900,
                textAlign: 'left',
              },
            },
            AdditionalToast: {
              y: 100,
              w: 915,
              x: 450,
              mountX: 0.5,
              text: {
                text: Language.translate('search.may_be_something_else.text'),
                fontFace: 'Regular',
                fontSize: fontsizes.searchPageToastMessage,
                textColor: Colors('primaryFontColor1').get(),
                wordWrapWidth: 900,
                textAlign: 'left',
              },
            },
          },
          SearchResults: { type: SearchResults },
          Keyboard: { type: Keyboard },
          Loading: {
            visible: false,
            type: Loader,
            zIndex: 10,
          },
          KeyboardShadow: {
            x: 235,
            y: 80,
            type: Shadow,
            w: 580,
            h: 870,
            style: {
              alpha: 1,
              color: Colors('shadowColor').get(),
            },
          },
        }
    }

    _getFocused() {
      return this.keyboardTag
    }

    get keyboardTag() {
      return this.tag('Keyboard')
    }

    get searchBarTag() {
      return this.tag('SearchBar')
    }

    get searchResultsTag() {
      return this.tag('SearchResults')
    }

    get loaderTag() {
      return this.tag('Loading')
    }

    getLoaderVisibility() {
      return this.loaderTag.visible
    }

    _enable() {
      this.keyboardTag.value = ''
      this.keyboardTag.x = 240
      this.keyboardTag.y = 109
      this.keyboardTag.layout = 'search'
      this.searchBarTag.displayType = 'search'
      this.searchBarTag.input = this.currentSearchTerm || Language.translate('search.placeholder_text.text')
      this.currentSearchTerm = this.currentSearchTerm || '' //initial search term empty
      this.hideNoResults() // hides message tag initially
    }


    onKeyboardInputUpdate(value, _inputType, keyAction) {  // from Keyboard tag, values are updated here
      const isAddAction = keyAction === 'space' || keyAction === 'input'

      if (this.currentSearchTerm.length > 23 && isAddAction) return  // only search with string length less than 23

      if (this.timer) clearTimeout(this.timer)

      if (!this.currentSearchTerm.length && keyAction !== 'input') return  // if empty-string only input action is enabled

      switch (keyAction) {
        case 'delete': {
          this.searchBarTag.input = Language.translate('search.placeholder_text.text')
          this.currentSearchTerm = ''
          this.hideNoResults()//hides message when search term empty
          this.hideSearchResults() //hides search results when empty string
          break
        }

        case 'backspace': {
          this.currentSearchTerm = this.currentSearchTerm.slice(0, -1)
          this.searchBarTag.input = this.currentSearchTerm || Language.translate('search.placeholder_text.text')
          this.hideNoResults() // hides message when search term empty
          !this.currentSearchTerm.length && this.hideSearchResults() // hides search results when empty string
          break
        }

        case 'space': {
          this.currentSearchTerm = this.currentSearchTerm + ' '
          this.searchBarTag.input = this.currentSearchTerm
          break
        }

        case 'input': {
          this.currentSearchTerm = this.currentSearchTerm + value
          this.searchBarTag.input = this.currentSearchTerm
          break
        }

        default: break
      }

      //search api called
      if (this.currentSearchTerm.length > 2) {
        this.timer = setTimeout(() => {
          this.handleSearch(this.currentSearchTerm)
        }, 2000)
      }
    }

    // search api called here and response returned to component
    async handleSearch(searchTerm) {
      if (!searchTerm.length) return

      this.focusKeyboard()
      this.displayLoader() // show loader
      const response = await searchVideo(searchTerm)
      const searchResponse = new SearchResponse(response)
      searchResponse.data.total ? this.showSearchResults(searchResponse, searchTerm) : this.showNoResults(searchTerm)
      this.hideLoader() // hide loader
      return
    }

    showSearchResults(searchData, searchTerm) {  //passing data to searchResults
      this.searchResultsTag.data = {
        res: searchData.data,
        key: searchTerm,
      }
      this.searchResultsTag.visible = true
      this.tag('Message').setSmooth('alpha', 0)
    }

    showNoResults(searchTerm) {
      this.searchResultsTag.visible = false
      this.tag('Message').setSmooth('alpha', 1)
      this.patch({
        Message: {
          Toast: {
            text: Language.translate('search.no_results.text') + ' ' + `'` + searchTerm + `'`
          }
        },
      })
    }

    hideNoResults() {
      this.tag('Message').setSmooth('alpha', 0)
    }

    hideSearchResults() {
      this.searchResultsTag.visible = false
    }

    displayLoader() {
      this.loaderTag.visible = true
    }

    hideLoader() {
      this.loaderTag.visible = false
    }

    focusKeyboard() {
      this._setState('Keyboard')
    }

    focusSearchResults() {
      this._setState('SearchResults')
    }

    onkeyboardExitLeft() {
      Router.focusWidget('Menu')
    }

    _handleBack(){
      if (!this.loaderTag.visible) {
        Storage.remove('searchStateInfo') // well check after playing whether state is maintained, if so remove this line all over app
        Router.focusWidget('Menu')
      }
    }

    pageTransition(pageIn, pageOut) {
      const menu = pageIn.widgets.menu
      if (menu.alpha !== 1) {
        menu.visible = false
        menu.alpha = 0.001
        menu.setSmooth('alpha', 1, { delay: 0.2, duration: 0.2 })
      }
      return super.pageTransition(pageIn, pageOut)
    }

    static _states() {
      return [
        class SearchResults {
          _getFocused() {
            return this.searchResultsTag
          }

          _handleLeft() {
            this.focusKeyboard()
          }
        },
        class Keyboard {
          _getFocused() {
            return this.keyboardTag
          }

          _handleRight() {
            if (this.searchResultsTag.visible) {
              this.focusSearchResults()
            }
          }
        }
      ]
    }
  }
