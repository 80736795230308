import {Lightning} from '@lightningjs/sdk';

export default class PlayerProgress extends Lightning.Component{
	static _template(){
		return {
			CurrentTime: {
				w: 200, x: 545, text: { text: '', fontFace: 'RobotoCondensed', maxLines: 1, lineHeight: 30, textAlign: 'right', fontSize: 26, color: 0xFFF1F1F1 }
			},
			Progress: {
				alpha: 0, forceZIndexContext: true, x: 210, y: 13,
				Total: { w: 1000, h: 7, rect: true, color: 0xFF202020 },
				Active: { w: 0, h: 7, rect: true, color: 0xFFF1F1F1 }
			},
			Duration: {
				alpha: 0, w: 200, x: 1220, text: { text: '', fontFace: 'RobotoCondensed', maxLines: 1, lineHeight: 30, fontSize: 26, color: 0xFFF1F1F1 }
			}
		};
	}

	hideBar(){
		this.patch({
			CurrentTime: { x: 545 },
			Progress: { smooth: { alpha: 0 } },
			Duration: { smooth: { alpha: 0 } }
		});
	}

	set _progress(v){
		const now = Date.now();
		let estimation = 0;
		if(!this._last || (this._last < now - 1000)){
			estimation = 1000;
		}else{
			estimation = now - this._last;
		}
		this._last = now;
		const x = v * 1000;
		estimation *= 0.001;
		this.tag('Active').setSmooth('texture.w', Math.max(x, 0.0001), {
			timingFunction: 'linear',
			duration: estimation
		});
	}

	setProgress(currentTime, duration){

		if (currentTime < 0 || !isFinite(currentTime) || currentTime === '00:00' || currentTime === 'Infinity:NaN:NaN') {
			this.hideBar();
			this.tag('CurrentTime').patch({ text: { text: '' } });
			return;
		}

		if(!Number.isFinite(duration) || currentTime === 0 && duration === 1){
			this.hideBar();
			this.tag('CurrentTime').patch({ text: { text: PlayerProgress.formatTime(currentTime) } });
			return;
		}

		this._progress = currentTime / Math.max(duration, 1);
		this.patch({
			Progress: { smooth: { alpha: 1 } },
			CurrentTime: { smooth: { x: 0 }, text: { text: PlayerProgress.formatTime(currentTime) } },
			Duration: { smooth: { alpha: 1 }, text: { text: PlayerProgress.formatTime(duration) } }
		});
	}

	reset(){
		this.hideBar();
		this.patch({
			Duration: { text: { text: PlayerProgress.formatTime(0) } },
			CurrentTime: { text: { text: '' } }
		});
		this._progress = 0 / Math.max(0, 1);
	}

	static formatTime(seconds){
		const hours = Math.floor(seconds / 3600);
		seconds -= hours * 3600;
		const minutes = Math.floor(seconds / 60);
		seconds -= minutes * 60;
		seconds = Math.floor(seconds);
		const parts = [];
		if(hours) parts.push(hours);
		parts.push(minutes);
		parts.push(seconds);
		return parts.map(number=>(number < 10 ? '0' + number : '' + number)).join(':');
	}

	_init(){
		this.tag('Active').texture = {
			type: Lightning.textures.SourceTexture,
			textureSource: this.tag('Total').texture.source
		};
	}

	_setup(){
	}
}
