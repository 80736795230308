import { Lightning, Router, Utils, Language, Colors } from '@lightningjs/sdk'
import IconDynamicButton from '../ButtonsList/IconDynamicButton'
import { List } from '@lightningjs/ui'

export default class VerticalButtons extends Lightning.Component {
  static _template() {
    return {
      // Used List UI component for scrollable buttons
      VerticalButtonList: {
        h: 320,
        w: 1000,
        clipping: true,
        type: List,
        direction: 'column',
        spacing: 20,
      }
    }
  }

  set data({ buttonData, isGeoBlocking, personaInfo }) {
    this.isGeoBlocking = isGeoBlocking
    this.personaInfo = personaInfo
    this.buttonsData = buttonData

    //Each buttonItem preparation
    const children = this.buttonsData.map((button) => {
      return {
        type: IconDynamicButton,
        h: 46,
        textPadding: 30,
        label: button.label,
        iconImage: Utils.asset(`static_assets/${button.iconImage}`), // set this to satisfy same way src delivery to iconDynamic-button
        iconWithText: true,
        flexItem: { marginTop: 24, alignSelf: 'stretch', maxHeight: 100 },
        isVerticalBtn: true,
        isMoreAboutBtn: button.isMoreAboutBtn ? true : false,
        episodesListing: button.episodesListing ? true : false
      }
    })
    this.tag('VerticalButtonList').clear()
    this.tag('VerticalButtonList').add(children)
    this._refocus()
    this.tag('VerticalButtonList').scroll = {
      after: 5,
      forward: 0.9,
      backward: 0.1,
    }
  }


  setSpecificIndex(index) {
    this.tag('VerticalButtonList').setIndex(index)
  }

  _getFocused() {
    return this.tag('VerticalButtonList')
  }

  _handleRight() { return }

  _handleLeft() { return }

  _handleEnter() {
    if (Router.getActivePage().tag('Loading').visible) return
    this.buttonsFunctionality()
  }

  _handleUp() {
    Router.getActivePage().focusHorizontalBtns()
  }

  async buttonsFunctionality() {
    const btnIndex = this.tag('VerticalButtonList').index
    const selectedButton = this.tag('VerticalButtonList').items[btnIndex]._label
    const page = Router.getActivePage()

    switch (selectedButton) {
      case Language.translate('my_list.remove.text'):
      case Language.translate('my_list.pop_menu_list.text'):
        page.handleWishlist()
        break
      case Language.translate('player.audio_subtitles.text'):
        page.getAudioAndSubtitlesButtons()
        break
      case Language.translate('content_detail.remove_from_cont_watch.btn'):
        await page.removeContinueWatching()
        break
      case Language.translate('content_detail.see_all_details.text'):
        page.showMoreInfo()
        break
      case Language.translate('common_keys.back.text'):
        page._handleBack()
        break
      case Language.translate('common_keys.episodes.text'):
        page.getEpisodeSidepanel()
        break
      default:
        break
    }
  }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }

  _handleKey() { return }
}
