export class LiveChannelResponse {
    constructor(response) {
        this.statusCode = response.status_code;
        this.statusMessage = response.message;
        this.liveChannel = new LiveChannel(response.data.livechannel);
    }
}

class LiveChannel {
    constructor(data) {
        this.channel_id = data.channel_id || "";
        this.title = data.title || "";
        this.description = data.description || "";
        this.tags = data.tags || [];
        this.content_logo = data.content_logo || "";
        this.screenshot_landscape = data.screenshot_landscape || "";
        this.background_portrait = data.background_portrait || "";
        this.background_landscape_center = data.background_landscape_center || "";
        this.background_landscape = data.background_landscape || "";
        this.poster_square = data.poster_square || "";
        this.poster_landscape = data.poster_landscape || "";
        this.poster_portrait = data.poster_portrait || "";
        this.title_en = data.title_en || "";
        this.playback_type = data.playback_type || 0;
        this.playback_url = data.playback_url || "";
        this.is_tvod = data.is_tvod || 0;
        this.is_local_logo = data.is_local_logo || 0
        this.age = data.age || 0;
        this.channel_type = data.channel_type || "";
        this.contentId = data.contentId || "";
        this.slug = data.slug || "";
        this.feed_id = data.feed_id || "";
        this.content_logo_resolutions = data.content_logo_resolutions || {};
        this.background_portrait_resolutions = data.background_portrait_resolutions || {};
        this.background_landscape_center_resolutions = data.background_landscape_center_resolutions || {};
        this.background_landscape_resolutions = data.background_landscape_resolutions || {};
        this.poster_square_resolutions = data.poster_square_resolutions || {};
        this.poster_landscape_resolutions = data.poster_landscape_resolutions || {};
        this.poster_portrait_resolutions = data.poster_portrait_resolutions || {};
        this.geo_available = data.geo_available || 0;
        this.preferred_language = data.preferred_language || "";
        this.preferred_subtitle = data.preferred_subtitle || "";
        this.access_age_limit = data.access_age_limit || "";
        this.ask_access_pin = data.ask_access_pin || 0;
        this.user_type = data.user_type || 0;
        this.availability = data.availability || 0;
        this.hls_playback_url = data.hls_playback_url || "";
        this.dash_playback_url = data.dash_playback_url || "";
        this.ad_url = data.ad_url || "";
        this.smart_signs = data.smart_signs ? data.smart_signs.map(sign => new SmartSign(sign)) : [];
        this.rent = data.rent || {};
        this.buy = data.buy || {};
    }
}

class SmartSign {
    constructor(data) {
        this.title = data.title || "";
        this.translation_key = data.translation_key || "";
        this.icon_image = data.icon_image || "";
        this.icon_svg = data.icon_svg || "";
    }
}
