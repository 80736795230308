import { Language, Lightning, Router, Storage, Utils, Colors } from '@lightningjs/sdk'
import configurations from '../../Helper/Configurations'
import IconDynamicButton from '../../Components/ButtonsList/IconDynamicButton'
import { List } from '@lightningjs/ui'
import { fontsizes } from '../../Helper/GlobalConstants'

export default class SubtitlesSidePanel extends Lightning.Component {
  static _template() {
    return {
      x: 1400,
      zIndex: 6,
      SubtitleHeader: {
        x: 100,
        y: 570,
        alpha: 0,
        text: {
          textAlign: 'right',
          fontSize: fontsizes.subtitlesSidePanelSubtitleHeader,
          textColor: Colors('primaryFontColor1').get(),
          lineHeight: 46,
          fontFace: 'Bold',
        },
      },
      SubtitlesList: {
        y: 625,
        x: 100,
        h: 380,
        w: 250,
        clipping: true,
        type: List,
        direction: 'column',
        spacing: 20,
      }
    }
  }

  set subtitleItems(val) {
    this.tag('SubtitleHeader').y = 570
    this.tag('SubtitlesList').y = 625

    //const isMultiAudio = Router.getActivePage().isMultiAudiosPresent()
    if (!configurations.enableMetroMultiAudios) {
      this.tag('SubtitleHeader').y = 60
      this.tag('SubtitlesList').patch({ y: 124, h: 380 })
    }
    this.tag('SubtitleHeader').alpha = 0
    this.subtitleItemsArray = val

    this.tag('SubtitleHeader').patch({
      text: Language.translate('common_keys.subtitles.text')
      //text: appInstance.getCuesLength() > 0 ? Language.translate('Captions') : Language.translate('common_keys.subtitles.text')
    })

    // subtitleLabelArray used for setting subtitle to storage
    this.subtitleLabelArray = val.map((item) => item.label)

    const subtitleLabelFromStorage = Storage.get('subtitle-lang-selected') //appInstance.getCuesLength() > 0 ? Storage.get('cc-lang-selected') : Storage.get('subtitle-lang-selected')
    // setting index if found in array or select 'none'
    const indexOfLabel = this.subtitleLabelArray.findIndex((item) => item === subtitleLabelFromStorage)
    const subtitleIndexFromStorage = indexOfLabel !== -1 ? indexOfLabel : this.subtitleItemsArray.length



    if (val.length > 0) {
      this.subtitleLabelArray.push(Language.translate('player.none.text'))
      this.subtitleItemsArray.push({ label: Language.translate('player.none.text') })
      this.tag('SubtitleHeader').alpha = 1
    }

    this.tag('SubtitlesList').clear()
    //Each buttonItem preparation
    const children = this.subtitleItemsArray.map((subtitleItem, arrayIndex) => {
      const languageJSON = subtitleItem.langoption
      let isCaptionData = !!languageJSON
      let labelData
      if (isCaptionData) {
        labelData = subtitleItem.label != Language.translate('player.none.text') && languageJSON[subtitleItem.label]
          ? Language.translate(languageJSON[subtitleItem.label]['iso_language_name'].toLowerCase())
          : Language.translate(subtitleItem.label)
      } else {
        labelData = subtitleItem.label
      }
      return {
        type: IconDynamicButton,
        h: 46,
        textPadding: 20,
        label: labelData,
        iconImage: subtitleIndexFromStorage === arrayIndex ? Utils.asset(`static_assets/tick.png`) : '',
        iconWithText: subtitleIndexFromStorage === arrayIndex ? true : false,
        flexItem: { marginTop: 24, alignSelf: 'stretch', maxHeight: 100 },
        isVerticalBtn: true,
      }
    })
    this.tag('SubtitlesList').add(children)
    this._refocus()
    this.tag('SubtitlesList').scroll = {
      after: 4,//(!configurations.enableMetroMultiAudios) ? 14 : 6,
      forward: 0.9,
      backward: 0.1,
    }
    this.tag('SubtitlesList').setIndex(0)
  }

  returnSubtitlesArray() {
    return this.subtitleItemsArray
  }

  set isPlayerPage(v) {
    this.isPlayerPageActive = v ? true : false
  }

  set placeInTop(val) {
    // if audio tracks not available or audio flag set to false
    this.tag('SubtitleHeader').y = val ? 60 : 570
    this.tag('SubtitlesList').y = val ? 124 : 625
  }

  _init() {
    this.index = 0
  }

  _disable() {
    this.index = 0
    // this.tag('Subtitles').y = 1
    this.isPlayerPageActive = false
  }

  _getFocused() {
    return this.tag('SubtitlesList')
  }

  _handleUp() {
    if (Storage.get('audioTracksAvailable') !== false) {
      Router.focusWidget('AudioSidePanel')
    }
  }

  _handleLeft() {
    Router.focusWidget('SidePanelNavigation')
  }

  _handleRight() { return }

  _handleKey() { return }

  _handleBack() {
    Router.focusWidget('SidePanelNavigation')
  }

  _handleEnter() {
    this.index = this.tag('SubtitlesList').index
    if (this.isPlayerPageActive) {//subtitles
      // player page
      const selectedSubtitleLang = this.subtitleLabelArray[this.index]
      Router.getActivePage().setSubtitleLanguage(selectedSubtitleLang || null)
      Router.getActivePage().changeTrack(selectedSubtitleLang)
      Router.focusWidget('SubtitlesSidePanel')
    } else {
      Router.getActivePage().setSubtitleLanguage(this.subtitleLabelArray[this.index] || null)
    }
    // updates the widget ui
    this.updateSubtitlesTag(this.index)
  }
  updateSubtitlesTag(subtitleIndex) {
    this.tag('SubtitlesList').clear()
    const children = this.subtitleItemsArray.map((subtitleItem, index) => {
      const languageJSON = subtitleItem.langoption
      let isCaptionData = !!languageJSON
      let labelData
      if (isCaptionData) {
        labelData = subtitleItem.label != Language.translate('player.none.text') && languageJSON[subtitleItem.label]
          ? Language.translate(languageJSON[subtitleItem.label]['iso_language_name'].toLowerCase())
          : Language.translate(subtitleItem.label)
      } else {
        labelData = subtitleItem.label
      }
      return {
        type: IconDynamicButton,
        h: 46,
        textPadding: 30,
        label: labelData,
        iconImage: subtitleIndex === index ? Utils.asset(`static_assets/tick.png`) : '', // set this to satisfy same way src delivery to iconDynamic-button
        iconWithText: true,
        flexItem: { marginTop: 24, alignSelf: 'stretch', maxHeight: 100 },
        isVerticalBtn: true,
        //isMoreAboutBtn: button.isMoreAboutBtn ? true : false,
        //episodesListing: button.episodesListing ? true : false
      }
    })
    this.tag('SubtitlesList').add(children)
    this._refocus()

    this.tag('SubtitlesList').setIndex(subtitleIndex)
  }
}
