import { Lightning, Router, Storage } from '@lightningjs/sdk'
import KeyboardTemplate from './LandscapeKeyboardTemplate.js'
import KeyboardButton from './KeyboardButton.js'


export default class Keyboard extends Lightning.Component {
  static _template() {
    return {
      zIndex: 99,
    }
  }

  get keyboardTemplate() {
    return KeyboardTemplate
  }

  get keyboardButton() {
    return KeyboardButton
  }

  get maxCharacters() {
    return (Router.getActiveHash().includes('manageprofiles')) ? 11 : 16
  }

  set value(v) {
    if (v.length < this.maxCharacters) {
      this._value = v
      this.signal('valueChanged', { value: v })
    }
  }

  get value() {
    return this._value
  }

  get rows() {
    return this.children
  }

  get rowLength() {
    return this.rows[this.rowIndex].children.length
  }

  get currentKey() {
    if (Storage.get('search_statemgt')) {
      this.rowIndex = Storage.get('search_rowIndex')
      this.colIndex = Storage.get('search_colIndex')
      Storage.set('search_statemgt', false)
    } else {
      Storage.set('search_rowIndex', this.rowIndex)
      Storage.set('search_colIndex', this.colIndex)
    }
    return this.children[this.rowIndex].children[this.colIndex]
  }

  set layout(layout) {
    this._layout = layout
    this._update()
    this.fireAncestors('$keyboardLayoutUpdate')
  }

  get layout() {
    return this._layout
  }

  _getFocused() {
    return this.currentKey
  }

  _navigate(dir, value) {
    dir = dir === 'up' || dir === 'down' ? 'vertical' : 'horizontal'
    if (
      dir === 'horizontal' &&
      this.colIndex + value < this.rowLength &&
      this.colIndex + value > -1
    ) {
      this.previous = null
      return (this.colIndex += value)
    } else if (
      dir === 'vertical' &&
      this.rowIndex + value < this.rows.length &&
      this.rowIndex + value > -1
    ) {
      const currentColIndex = this.colIndex
      const targetRow = this.rowIndex + value
      if (this.previous && this.previous.row === targetRow) {
        const tmp = this.previous.col
        this.previous.col = this.colIndex
        this.colIndex = tmp
      } else {
        const targetRow = this.children[this.rowIndex + value]
        const targetItems = targetRow.children
        const ck = this.currentKey
        let target = 0
        for (let i = 0; i < targetItems.length; i++) {
          const ckx = this.children[this.rowIndex].x + ck.x
          const tix = targetRow.x + targetItems[i].x
          if ((ckx >= tix && ckx <= tix + targetItems[i].w) || (tix >= ckx && tix <= ckx + ck.w)) {
            target = i
            break
          }
        }
        this.colIndex = target
      }
      this.previous = { col: currentColIndex, row: this.rowIndex }
      return (this.rowIndex += value)
    }
    return false
  }

  _update() {
    if (this._layout && this.keyboardTemplate.layouts[this._layout] === undefined) {
      console.error(
        `Configured layout "${this.layout}" does not exist. Reverting to "${Object.keys(this.keyboardTemplate.layouts)[0]
        }"`
      )
      this._layout = null
    }
    if (!this._layout) {
      this._layout = Object.keys(this.keyboardTemplate.layouts)[0]
    }
    const {
      keyWidth,
      keyHeight,
      horizontalSpacing = 0,
      verticalSpacing = 0,
      layouts,
    } = this.keyboardTemplate

    this.children = layouts[this._layout].rows.map((row, rowIndex) => {
      let keyOffset = 0
      const {
        x = 0,
        rowVerticalSpacing = verticalSpacing,
        rowHorizontalSpacing = horizontalSpacing,
        keys = [],
      } = row
      return {
        y: keyHeight * rowIndex + rowIndex * rowVerticalSpacing,
        x,
        children: keys.map(key => {
          key = Object.assign({ action: 'input' }, key)
          const prevOffset = keyOffset
          const { w = keyWidth, h = keyHeight, action, toLayout } = key
          keyOffset += w + rowHorizontalSpacing
          return { key, action, toLayout, x: prevOffset, w, h, type: this.keyboardButton }
        }),
      }
    })
  }

  reset() {
    this.colIndex = 0
    this.rowIndex = 0
    this._value = ''
    this.previous = null
  }

  _init() {
    Storage.set('search_statemgt', false)
    this.reset()
    this._update()
  }

  _handleRight() {
    if (this.colIndex == 9  && this.rowIndex == 3)
      Router.getActivePage().actionControls()
    return this._navigate('right', 1)
  }

  _handleLeft() {
    this.colIndex == 0 ? Router.getActivePage().onkeyboardExitLeft() : this._navigate('left', -1)
  }

  _handleUp() {
    this.rowIndex == 0 ? Router.getActivePage().onkeyboardExitUp() : this._navigate('up', -1)
  }

  _handleDown() {
    if ((this.layout === 'search' || this.layout === 'authenticate') && this.rowIndex >= 6) {
      this.signal('onKeyboardExitDown')
    } else if (this.layout === 'numerical' && this.rowIndex >= 3) {
      this.signal('onKeyboardExitDown')
    } else if (
      this.layout !== 'search' &&
      this.layout !== 'numerical' &&
      this.layout !== 'authenticate' &&
      this.rowIndex >= 4
    ) {
      this.signal('onKeyboardExitDown')
    } else {
      return this._navigate('down', 1)
    }
  }

  _handleEnter(specialbtn = '',keytype = '') {
    const key = this.currentKey
    let userAction,typeAction
    if (specialbtn == 'toggleToLayout') {
      userAction = specialbtn
      typeAction = keytype
    } else if (keytype == 'backspace' || keytype == 'delete' || keytype == 'done') {
      userAction = keytype
    } else {
      userAction = key.action
    }
    switch (userAction) {
      case 'input':
        this.value += key.c
        break
      case '.com':
      case '@gmail.com':
      case '@hotmail.com':
      case '@yahoo.com':
        this.value += key.c
        break
      case 'backspace':
        if (this.value.length > 0) {
          this.value = this.value.slice(0, -1)
        } else {
          return
        }
        break
      case 'space':
        if (this.value.length > 0) {
          this.value += ' '
        } else {
          return
        }
        break
      case 'delete':
        if (this.value.length > 0) {
          this.value = ''
        } else {
          return
        }
        break
      case 'toggleToLayout':
        this.layout = typeAction
        break
      case 'Done':
        this.value = this.value
    }
    Router.getActivePage().onKeyboardInputUpdate(this.value,userAction)
  }

  _disable() {
    this.reset()
  }

  _handleBack() {
    Router.getActivePage().retainState()
  }

  _handleKey() { return }
}
