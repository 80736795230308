import { Lightning, Router, Language, Utils, Colors } from '@lightningjs/sdk'
import { Shadow } from '@lightningjs/ui-components'
import { Back, ForgotForm, LanguageSelection, LocalesList } from '../Components'
import App from '../App'
import configurations from '../Helper/Configurations'
import Keyboard from '../Components/Keyboard'

export default class ForgotPassword extends Lightning.Component {
  static _template() {
    return {
      Background: {
        w: App.width,
        h: App.height,
        rect: true,
        color: Colors('backgroundColor').get(),
      },
      Logo: {
        h: 160,
        w: 1920,
        zIndex: 4,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        LogoImg: {
          src: Utils.asset('static_assets/loginLogo.png'),
        }
      },
      KeyboardShadow: {
        x: 140,
        y: 140,
        type: Shadow,
        w: 665,
        h: 870,
        style: {
          alpha: 1,
          color: Colors('shadowColor').get(),
        },
      },
      ForgotPasswordTemplate: {
        x: 950,
        y: 170,
        zIndex: 1,
        texture: Lightning.Tools.getRoundRect(906, 839, 0, 0, Colors('cardColor').get(), true, Colors('cardColor').get(),),
        Heading: {
          y: 220,
          text: {
            textColor: Colors('primaryFontColor1').get(),
            fontSize: 48,
            fontFace: 'Bold',
            wordWrapWidth: 650,
            maxLines: 1,
            maxLinesSuffix: '...'
          },
        },
        SubHeading: {
          y: 300,
          text: {
            fontSize: 32,
            fontFace: 'Regular',
            wordWrapWidth: 700,
            maxLines: 1,
            maxLinesSuffix: '...',
            textColor: Colors('primaryFontColor1').get(),
          },
        },
        EnterEmail: {
          x: 80,
          y: 390,
          text: {
            fontFace: 'Regular',
            fontSize: 24,
            lineHeight: 29,
            textColor: Colors('primaryFontColor1').get(),
          },
        },
      },
      ForgotForm: { type: ForgotForm },
      Keyboard: { type: Keyboard },
      LanguageSelection: { type: LanguageSelection },
      LocalesList: { type: LocalesList },
      Back: { type: Back }
    }
  }

  preparePageData() {
    this.patch({
      ForgotPasswordTemplate: {
        Heading: {
          text: { text: Language.translate('registration.reset_password.text') }
        },
        SubHeading: {
          text: { text: Language.translate('password.reset_password.text') }
        },
        EnterEmail: {
          text: { text: Language.translate('common_keys.email.text') }
        }
      }
    })
    this.dynamicPosForText()
    if (configurations.locales.length > 1)
      this.tag('LanguageSelection').data = configurations.locales
    const buttons = [
      {
        x: 1030,
        y: 720,
        w: 740,
        h: 63,
        title: Language.translate('registration.reset_password.text'),
        fontSize: 34,
        layout: 'border',
        radius: 10,
      },
    ]
    const inputFields = [
      {
        x: 514,
        y: 600,
        textX: 10,
      },
    ]
    this.tag('ForgotForm').data = [inputFields, buttons]
    this.tag('Keyboard').layout = 'abc'
    this.tag('Keyboard').patch({ x: 150, y: 170 })
    this.tag('Back').updatePosition([990, 213])
    this._setState('Keyboard')
  }

  dynamicPosForText() {
    this.tag('Heading').on('txLoaded', () => {
      const headingwidth = this.tag('Heading').renderWidth
      const headingPos = (908 - headingwidth) / 2
      this.tag('Heading').patch({ x: headingPos + 10 })
    })

    this.tag('SubHeading').on('txLoaded', () => {
      const subHeadingwidth = this.tag('SubHeading').renderWidth
      const subHeadingPos = (908 - subHeadingwidth) / 2
      this.tag('SubHeading').patch({ x: subHeadingPos + 10 })
    })
  }

  filterActiveWidget() {
    const totalWidgets = this.widgets
    return Object.values(totalWidgets).filter((obj) => obj.__active === true)
  }

  onKeyboardInputUpdate(value, inputType) {
    this.tag('ForgotForm').$populateInput(value, inputType)
  }

  updateTranslations() {
    this.preparePageData()
  }

  _handleBack() {
    Router.back()
  }

  _getFocused() {
    this._setState('Keyboard')
  }

  focusForms() {
    this._setState('ForgotForm')
  }

  focusKeyboard() {
    this._setState('Keyboard')
  }

  returnSelectedField() {
    return this.tag('ForgotForm').returnSelectedField()
  }

  handleInputType(inputType) {
    this.tag('Keyboard').type = inputType
  }

  _handleRight() {
    this.focusForms()
  }

  clearSidePanel() {
    this.widgets.sidepanelnavigation.alpha = 0
    this.tag('LocalesList').alpha = 0
  }

  updateLanguageCode() {
    this.tag('LanguageSelection').updateLanguageCode()
  }

  focusLanguageSelection() {
    this._setState('LanguageSelection')
  }

  openLocalesList() {
    this.tag('LocalesList').openLocalesList()
    this._setState('LocalesList')
  }

  focusBack() {
    if (configurations.locales.length > 1)
      this._setState('Back')
  }

  focusLocalesList() {
    this._setState('LocalesList')
  }

  static _states() {
    return [
      class ForgotForm extends this {
        _getFocused() {
          return this.tag('ForgotForm')
        }
      },

      class Keyboard extends this {
        _getFocused() {
          return this.tag('Keyboard')
        }
      },

      class LanguageSelection extends this {
        _getFocused() {
          return this.tag('LanguageSelection')
        }
      },

      class Back extends this {
        _getFocused() {
          return this.tag('Back')
        }
      },

      class LocalesList extends this {
        _getFocused() {
          return this.tag('LocalesList')
        }
      }
    ]
  }
}
