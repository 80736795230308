import { Login } from '../Views'
/**
 * Defines the configuration for the 'login' page.
 */
export default {
    path: 'login',
    component: Login,
    on: async (page) => {
      page.preparePageData()
    },
    widgets: ['Back', 'LanguageSelection', 'LocalesList', 'SidePanelNavigation']
}
