import { matomoMiddleware } from './Middleware.js'
import App from '../App.js'
import configurations from '../Helper/Configurations.js'
import { playerVariables } from '../Helper/GlobalConstants.js'


export const trackPageView = async pageData => {
  try {
    await matomoMiddleware('GET', pageData)
  } catch (error) {
    console.log(error)
  }
}

function getCurrentTime() {
  return new Date().getTime()
}

export function startWatchedTime() {
  playerVariables.lastTimeCheck = getCurrentTime()
}

export function setMediaId(item) {
  playerVariables.videoId = item['contentId'] + generateUniqueId(10)
}

export function resetPlayerVariables() {
  playerVariables.watchedTime = 0
  playerVariables.lastTimeCheck = 0
  playerVariables.lastBufferCheck = 0
  playerVariables.bufferTime = 0
  playerVariables.lastBufferTimeCheck = 0
  playerVariables.initialTime = 0
  playerVariables.waitTime = 0
  playerVariables.start = true
  playerVariables.videoId = ''
}

export function stopWatchedTime() {
  if (playerVariables.lastTimeCheck != 0) {
    const elapsed = getCurrentTime() - playerVariables.lastTimeCheck
    playerVariables.watchedTime += elapsed
    playerVariables.lastTimeCheck = 0
  }
}

export function startBufferTime() {
  if (playerVariables.lastBufferTimeCheck == 0) {
    playerVariables.lastBufferTimeCheck = getCurrentTime()
  }
}

export function stopBufferTime() {
  if (playerVariables.lastBufferTimeCheck != 0) {
    playerVariables.bufferTime += getCurrentTime() - playerVariables.lastBufferTimeCheck
    playerVariables.lastBufferTimeCheck = 0
  }
}

export function generateUniqueId(length) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function updateInitialTime(inTime) {
  playerVariables.initialTime = inTime
}

export function updateWaitTime(inTime) {
  playerVariables.waitTime = inTime - playerVariables.initialTime
}

export const trackEvent = async (action, mediaTitle) => {
  const eventData = {
    e_c: 'MediaVideo',
    e_a: action,
    e_n: mediaTitle,
    e_v: '',
    ca: 1
  }
  await matomoMiddleware('GET', eventData)
}

export const trackState = async (action, mediaTitle) => {
  if (playerVariables.start) {
    playerVariables.start = false
  }
  await trackEvent(action, mediaTitle)
}

export function updateTimerMethod() {
  const currTime = getCurrentTime()
  if (playerVariables.lastTimeCheck != 0) {
    const elapsed = currTime - playerVariables.lastTimeCheck
    playerVariables.watchedTime += elapsed
    playerVariables.lastTimeCheck = currTime
  }
}

export const trackNow = async itemData => {
  updateTimerMethod()
  const thisItemData = itemData
  thisItemData['ma_st'] = Math.round(playerVariables.watchedTime / 1000)
  thisItemData['ma_id'] = playerVariables.videoId
  trackPageView(thisItemData)
}

export const trackPageNow = async itemData => {
  trackPageView(itemData)
}

export function startMonitoring() {
  const initialTime = getCurrentTime()
  updateInitialTime(initialTime)
  startWatchedTime()
}

export function stopMonitoring() {
  stopWatchedTime()
}

export function trackVideoProgress(item, progressTime) {
  const timeFinished = progressTime == 'finish' ? item['durationSec'] : progressTime

  const pageData = {
    ma_ti: item['contentId'],
    ma_id: playerVariables.videoId,
    title: item['title'],
    ma_re: item['playbackUrl'],
    ma_mt: 'Video',
    ma_ttp: 0,
    ma_ps: Math.round(timeFinished),
    ma_pn: 'Lightning Player',
    ma_le: item['durationSec'],
    ma_w: App.width,
    ma_h: App.height,
    ma_fs: 1,
    _cvar: JSON.stringify({"1": ['deviceId',configurations.deviceId]})
  }

  pageData.uid = configurations.enableProfiles ? configurations.selectedProfileId : configurations.userId

  trackNow(pageData)
}

export function trackPageInformation(pageData) {
  trackPageNow(pageData)
}
