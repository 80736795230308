import { Lightning } from '@lightningjs/sdk'

export default class ListComponent extends Lightning.components.ListComponent {
  _init() {
    this._roll = true
    this._rollMax = 1
  }
  set activeItem(v) {
    this._activeItem = v
    this._restorePosition = v.x
    this._setState('Expanded')
  }

  forceState(state) {
    this._setState(state)
  }

  static _states() {
    return [
      class Expanded extends this {
        $enter() { /* TODO document why this method '$enter' is empty */  }
        $exit() { /* TODO document why this method '$exit' is empty */  }
        _handleBack() { /* TODO document why this method '_handleBack' is empty */  }
      },
    ]
  }
}
