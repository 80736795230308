import { Lightning, Router, Colors } from '@lightningjs/sdk'
import { getColorCodeWithOpacity } from '../../Helper/Helpers.js'
import App from '../../App'

export default class ProgressBar extends Lightning.Component {
  static _template() {
    return {
      zIndex: 2,
      w: App.width,
      h: App.height,
      TopGradient: {
        rect: true,
        alpha: 1,
        y: 0,
        x: 0,
        w: App.width,
        h: 150,
      },
      BottomGradient: {
        alpha: 1,
        rect: true,
        x: 0,
        y: 930,
        w: App.width,
        h: 150,
      },
      RoundRectangleFirst: {
        x: 80.6,
        y: 969.8,
      },
      RoundRectangleSecond: {
        x: 80.6,
        y: 969.8,
        w: 0,
      },
      PlayerSeekHelper: {
        zIndex: 4,
        alpha: 0,
        mountY: 0.5,
        y: 973,
        texture: Lightning.Tools.getRoundRect(15, 15, 7.5),
        color: Colors('primaryFontColor1').get(),
      },
      PlayerSeekHelperSmall: {
        alpha: 0,
        zIndex: 3,
        mountY: 0.5,
        y: 973,
        x: 63,
        texture: Lightning.Tools.getRoundRect(15, 15, 7.5),
        color: Colors('primaryFontColor1').get(),
      },
      RunningDuration: {
        x: 1560,
        y: 1007.2,
        text: {
          fontSize: 28,
          textColor: Colors('primaryFontColor1').get(),
          wordWrapWidth: 1060,
          lineHeight: 30,
          fontFace: 'Bold',
        },
      },
      TotalDuration: {
        x: 1675,
        y: 1007.2,
        text: {
          fontSize: 28,
          textColor: Colors('primaryFontColor1').get(),
          wordWrapWidth: 1060,
          lineHeight: 30,
          fontFace: 'Bold',
        },
      },
    }
  }

  _active() {
    const bottomGradientColor = getColorCodeWithOpacity(Colors('cardColor').get(), '30')
    this.tag('TopGradient').patch({
      colorTop: bottomGradientColor,
      colorBottom: Colors('shadowColor').get(),
    })
    this.tag('BottomGradient').patch({
      colorTop: Colors('shadowColor').get(),
      colorBottom: bottomGradientColor,
    })
  }

  placeDotToStart() {
    this.patch({
      PlayerSeekHelperSmall: { x: 63 },
    })
  }

  _focus() {
    this.tag('PlayerSeekHelper').patch({
      color: Colors('brandPrimaryColor').get(),
      alpha: 1,
      texture: Lightning.Tools.getRoundRect(20, 20, 10),
    })
    this.tag('PlayerSeekHelperSmall').alpha = 0
  }

  _unfocus() {
    this.tag('PlayerSeekHelper').patch({
      smooth: {
        texture: Lightning.Tools.getRoundRect(14, 14, 7),
        alpha: 0,
        color: Colors('primaryFontColor1').get(),
      },
    })
    this.tag('PlayerSeekHelperSmall').alpha = 1
  }

  _handlePlayPause() {
    Router.getActivePage().$playPause('play')
  }

  _handleForward() {
    this.seekTime = Router.getActivePage()._handleForward()
  }

  _handleRewind() {
    this.seekTime = Router.getActivePage()._handleRewind()
  }

  _handlePlay() {
    Router.getActivePage()._handlePlay()
  }

  _handlePause() {
    Router.getActivePage()._handlePause()
  }

  _handleLeft() {
    this.left = Router.getActivePage()._handleRewind()
  }

  _handleRight() {
   this.right = Router.getActivePage()._handleForward()
  }

  _captureKeyRelease(event) {
    if (this.seekTime === 0 || event.keyCode === 38) return
    let keyType
    if (event.keyCode === 54 || event.keyCode === 228) {
      keyType = 'right'
    } else if (event.keyCode === 53 || event.keyCode === 227) {
      keyType = 'left'
    } else if (event.keyCode === 39) {
      Router.getActivePage().videoFwdandRwd('right', this.right)
    } else if (event.keyCode === 37) {
      Router.getActivePage().videoFwdandRwd('left', this.left)
    }

    if (event.keyCode === 54 || event.keyCode == 53 || event.keyCode === 228 || event.keyCode === 227) {
      clearTimeout(this.seekTimer)
      this.seekTimer = setTimeout(() => {
        Router.getActivePage().videoFwdandRwd(keyType, this.seekTime)
      }, 500)
    }
  }

  _handleStop() {
    this._handleBack()
  }

  _handleUp() {
    Router.getActivePage().showPlayerControls()
  }

  _handleDown() {
    const controlsAplhaValue = Router.getActivePage().returnControlsVisibility()
    controlsAplhaValue ? Router.getActivePage().focusPlayerControls() : Router.getActivePage().showPlayerControls()
  }

  _handleEnter() {
    const controlsAplhaValue = Router.getActivePage().returnControlsVisibility()
    controlsAplhaValue ? Router.getActivePage()._handlePlayPause() : Router.getActivePage().showPlayerControls()
  }

  _handleKey() { return }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }

  set updatePosition(position) {
    this.patch({
      RoundRectangleFirst: {
        y: position,
      },
      RoundRectangleSecond: {
        y: position,
      },
      RunningDuration: {
        y: position + 50,
      },
      TotalDuration: {
        y: position + 50,
      },
    })
  }

  set dimensions(v) {
    this.playerWidth = v[0]
    this.playerHeight = v[1]
    this.tag('RoundRectangleFirst').patch({
      w: v[0],
      h: v[1],
      texture: Lightning.Tools.getRoundRect(v[0], v[1], 0, 0, true),
    })
    this.tag('RoundRectangleSecond').patch({
      h: v[1],
      texture: Lightning.Tools.getRoundRect(0, v[1], 0, 0, Colors('brandPrimaryColor').get(), true, Colors('brandPrimaryColor').get(),),
    })
  }

  updateData(v, action) {
    let playedPercentage
    if (action === 'start') {
      playedPercentage = ''
    }
    const currentTimeFormated = this.getTimeFormat(Math.floor(v[1]))
    const totalDurationFormated = this.getTimeFormat(Math.floor(v[0]))
    this.tag('RoundRectangleFirst').setSmooth('alpha', 0)
    this.tag('RoundRectangleSecond').setSmooth('alpha', 0)
    if (Math.floor(v[0]) != 'Infinity') {
      this.tag('RoundRectangleFirst').setSmooth('alpha', 1)
      this.tag('RoundRectangleSecond').setSmooth('alpha', 1)

      this.tag('TotalDuration').patch({
        text: { text: ' / ' + totalDurationFormated },
      })
      this.tag('RunningDuration').patch({
        text: { text: currentTimeFormated },
      })
      const percentage = (Math.floor(v[1]) / Math.floor(v[0])) * 100
      playedPercentage = this.roundNumber((percentage / 100) * this.playerWidth, 2)
      this.tag('RoundRectangleSecond').patch({
        texture: Lightning.Tools.getRoundRect(
          playedPercentage - 3,
          this.playerHeight,
          0,
          0,
          Colors('brandPrimaryColor').get(),
          true,
          Colors('brandPrimaryColor').get(),
        ),
      })
      this.tag('PlayerSeekHelper').x = playedPercentage + 65
      this.tag('PlayerSeekHelperSmall').x = playedPercentage + 65
    }
    if (v[0] === 10 && v[1] === 10) {
      // liveChannel - here 10 is just a no given from player page
      this.tag('RunningDuration').alpha = 0
      this.tag('TotalDuration').alpha = 0
    } else {
      // vod content
      this.tag('RunningDuration').alpha = 1
      this.tag('TotalDuration').alpha = 1
    }
  }

  getTimeFormat(timeInSeconds) {
    const pad = function (num, size) {
      return ('000' + num).slice(size * -1)
    }
    const time = parseFloat(timeInSeconds).toFixed(3)
    const hours = Math.floor(time / 60 / 60)
    const minutes = Math.floor(time / 60) % 60
    const seconds = Math.floor(time - minutes * 60)
    return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2)
  }

  roundNumber(num, scale) {
    if (!('' + num).includes('e')) {
      return +(Math.round(num + 'e+' + scale) + 'e-' + scale)
    } else {
      const arr = ('' + num).split('e')
      let sig = ''
      if (+arr[1] + scale > 0) {
        sig = '+'
      }
      const i = +arr[0] + 'e' + sig + (+arr[1] + scale)
      const j = Math.round(i)
      return +(j + 'e-' + scale)
    }
  }

  ClearTimeInterval() {
    this.tag('RunningDuration').patch({ text: { text: ' ' } })
    this.tag('TotalDuration').patch({ text: { text: ' ' } })
  }

  showOrHideProgressBarDot(value) {
    const dotValue = value ? 1 : 0
    this.tag('PlayerSeekHelperSmall').alpha = dotValue
    this.tag('PlayerSeekHelper').alpha = dotValue
  }
}
