/**
 * Represents the response for viewing all content.
 */
export class ViewAllContentResponse {
    /**
     * Creates an instance of ViewAllContentResponse.
     * @param {number} statusCode - The status code of the response.
     * @param {string} statusMessage - The status message of the response.
     * @param {ViewAllDataItem} viewAllDataItem - The data item containing view all content details.
     */
    constructor(response) {
        this.statusCode = response.status_code;
        this.statusMessage = response.message;
        this.data = response.data?.contents ? new ViewAllDataItem(response.data) : new PersonaViewAllDataItem(response)
    }
}

/**
 * Represents details for viewing all content data item.
 */
class ViewAllDataItem {
    /**
     * Creates an instance of ViewAllDataItem.
     * @param {Object} data - The input object representing a view all data item.
     */
    constructor(data) {
        this.orientation = data.orientation || 0;
        this.totalCount = data.total_count || 0;
        this.title = data.title || "";
        this.collectionSlug = data.collection_slug || "";
        this.key = data.key || "";
        this.collectionLogo = data.collection_logo || "";
        this.collectionLogoResolutions = data.collection_logo_resolutions || "";
        this.viewAllList = data.contents.map(item => new ViewAllItem(item))
    }
}

/**
 * Represents details for viewing all content data item for persona carousels.
 */
 class PersonaViewAllDataItem {
  /**
   * Creates an instance of ViewAllDataItem.
   * @param {Object} data - The input object representing a view all data item.
   */
  constructor (obj) {
      this.orientation = obj.orientation || 0;
      this.count = obj.count || 0;
      this.totalCount = obj.count || 0;
      this.title = obj.title || "";
      this.key = obj.key || "";
      this.viewAllList = obj.list.map(item => new ViewAllItem(item))
  }
}

/**
 * Represents an item in the view all content list.
 */
class ViewAllItem {
    /**
     * Creates an instance of ViewAllItem.
     * @param {Object} item - The input object representing a view all item.
     */
    constructor(item) {
        this.publishTime = item.publish_time || "";
        this.title = item.title || "";
        this.description = item.description || "";
        this.tags = item.tags || "";
        this.director = item.director || "";
        this.writer = item.writer || "";
        this.producer = item.producer || "";
        this.cast = item.cast || "";
        this.contentLogo = item.content_logo || "";
        this.collectionLogo = item.collection_logo || ""
        this.screenshotLandscape = item.screenshot_landscape || "";
        this.backgroundPortrait = item.background_portrait || "";
        this.backgroundLandscapeCenter = item.background_landscape_center || "";
        this.backgroundLandscape = item.background_landscape || "";
        this.posterSquare = item.poster_square || "";
        this.posterLandscape = item.poster_landscape || "";
        this.posterPortrait = item.poster_portrait || "";
        this.titleEn = item.title_en || "";
        this.isLocalLogo = item.is_local_logo || 0
        this.isSeries = item.is_series || 0
        this.isTvod = item.is_tvod || 0;
        this.duration = item.duration || "";
        this.key = item.key || ''
        this.durationMin = item.duration_min || 0;
        this.age = item.age || 0;
        this.releaseDate = item.release_date || "";
        this.position = item.position || 0;
        this.trailerId = item.trailer_id || "";
        this.contentSlug = item.content_slug || "";
        this.contentId = item.content_id || "";
        this.collectionSlug = item.collection_slug || "";
        this.isLiveChannel = item.is_livechannel || 0;
        this.seriesSlug = item.series_slug || "";
        this.seasonsSlug = item.seasons_slug || "";
        this.slug = item.slug || ""
        this.seasonPosition = item.season_position || 0;
        this.videoId = item.video_id || "";
        this.durationSec = item.duration_sec || 0;
        this.contentLogoResolutions = item.content_logo_resolutions || "";
        this.backgroundPortraitResolutions = item.background_portrait_resolutions || "";
        this.backgroundLandscapeCenterResolutions = item.background_landscape_center_resolutions || "";
        this.backgroundLandscapeResolutions = item.background_landscape_resolutions || "";
        this.posterSquareResolutions = item.poster_square_resolutions || "";
        this.posterLandscapeResolutions = item.poster_landscape_resolutions || {};
        this.posterPortraitResolutions = item.poster_portrait_resolutions || {};
        this.genre = item.genre || "";
        this.curation = item.curation || "";
        this.availability = item.availability || 0;
        this.seek = item.seek || 0;
        this.rent = item.rent || {}
        this.buy = item.buy || {}
        this.percentage = item.percentage || 0;
        this.wishlistStatus = item.wishlist_status || false;
    }
}
