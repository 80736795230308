import { Lightning, Router, Language, Utils, Colors } from '@lightningjs/sdk'
import { Shadow } from '@lightningjs/ui-components'
import { FONT_BOLD, FONT_REGULAR } from '../Helper/GlobalConstants'
import { Back, Keyboard, LanguageSelection, LocalesList, SignUpForm } from '../Components'
import App from '../App'
import configurations from '../Helper/Configurations'
import CheckBox from '../Components/Forms/Checkbox'

export default class SignUp extends Lightning.Component {
  static _template() {
    return {
      Background: {
        w: App.width,
        h: App.height,
        rect: true,
        color: Colors('backgroundColor').get(),
      },
      Logo: {
        h: 160,
        w: 1920,
        zIndex: 4,
        flex: {
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        },
        LogoImg: {
          src: Utils.asset('static_assets/loginLogo.png'),
        }
      },
      KeyboardShadow: {
        x: 140,
        y: 140,
        type: Shadow,
        w: 665,
        h: 870,
        style: {
          alpha: 1,
          color: Colors('shadowColor').get(),
        },
      },
      SignUpTemplate: {
        x: 900,
        y: 170,
        zIndex: 1,
        texture: Lightning.Tools.getRoundRect(906, 839, 0, 0, Colors('cardColor').get(), true, Colors('cardColor').get(),),
        Heading: {
          y: 32,
          text: {
            fontFace: FONT_BOLD,
            fontSize: 48,
            lineHeight: 58,
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 650,
            maxLines: 1,
            maxLinesSuffix: '...'
          },
        },
        SubHeading: {
          y: 120,
          text: {
            fontFace: FONT_REGULAR,
            fontSize: 32,
            lineHeight: 38.4,
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 550,
            maxLines: 1,
            maxLinesSuffix: '...'
          },
        },
        EnterName: {
          x: 88,
          y: 206,
          text: {
            fontFace: FONT_REGULAR,
            fontSize: 24,
            lineHeight: 29,
            textColor: Colors('primaryFontColor1').get(),
          },
        },
        EnterEmail: {
          x: 88,
          y: 325,
          text: {
            fontFace: FONT_REGULAR,
            fontSize: 24,
            lineHeight: 29,
            textColor: Colors('primaryFontColor1').get(),
          },
        },
        EnterPassword: {
          x: 88,
          y: 439,
          text: {
            fontFace: FONT_REGULAR,
            fontSize: 24,
            lineHeight: 29,
            textColor: Colors('primaryFontColor1').get(),
          },
        },
      },
      SignUpForm: { type: SignUpForm },
      CheckBox: { type: CheckBox },
      Keyboard: { type: Keyboard },
      LanguageSelection: { type: LanguageSelection },
      LocalesList: { type: LocalesList },
      Back: { type: Back }
    }
  }

  dynamicPosForText() {
    this.tag('Heading').on('txLoaded', () => {
      const headingwidth = this.tag('Heading').renderWidth
      const headingPos = (908 - headingwidth) / 2
      this.tag('Heading').patch({ x: headingPos + 10 })
    })

    this.tag('SubHeading').on('txLoaded', () => {
      const subHeadingwidth = this.tag('SubHeading').renderWidth
      const subHeadingPos = (908 - subHeadingwidth) / 2
      this.tag('SubHeading').patch({ x: subHeadingPos + 10 })
    })
  }

  preparePageData() {
    this.patch({
      SignUpTemplate: {
        Heading: {
          text: { text: Language.translate('Sign Up to start watching') }
        },
        SubHeading: {
          text: { text: Language.translate('login.dont_have_account.text') }
        },
        EnterName: {
          text: { text: Language.translate('my_acc_edit_name_label')}
        },
        EnterEmail: {
          text: { text: Language.translate('common_keys.email.text') }
        },
        EnterPassword: {
          text: { text: Language.translate('common_keys.password.text') }
        }
      }
    })
    this.dynamicPosForText()
    if (configurations.locales.length > 1)
      this.tag('LanguageSelection').data = configurations.locales
    const buttons = [
      {
        x: 990,
        y: 820,
        w: 740,
        h: 63,
        title: Language.translate('common_keys.sign_up.text'),
        fontSize: 34,
        layout: 'rect',
        radius: 10,
      },
      {
        x: 990,
        y: 920,
        w: 740,
        h: 63,
        fontSize: 24,
        title1: Language.translate('common_keys.already_have_an_account.text'),
        title2: Language.translate('common_keys.sign_in.btn'),
        layout: 'border',
        radius: 10,
      },
    ]
    const inputFields = [
      {
        x: 474,
        y: 407,
        textX: 10,
      },
      {
        x: 474,
        y: 530,
        textX: 10,
      },
      {
        x: 474,
        y: 645,
        textX: 10,
      },
    ]
    this.tag('SignUpForm').data = [inputFields, buttons]
    this.tag('Keyboard').layout = 'abc'
    this.tag('Keyboard').patch({ x: 150, y: 170 })
    this.tag('CheckBox').position = [900, 280]
    this.tag('Back').updatePosition([948, 213])
    this._setState('Keyboard')
  }

  _getFocused() {
    this._setState('Keyboard')
  }

  _handleBack() {
    Router.back()
  }

  _handleRight() {
    this.focusForms()
  }

  filterActiveWidget() {
    const totalWidgets = this.widgets
    return Object.values(totalWidgets).filter((obj) => obj.__active === true)
  }

  onKeyboardInputUpdate(value, inputType) {
    this.tag('SignUpForm').$populateInput(value, inputType)
  }

  clearSidePanel() {
    this.widgets.sidepanelnavigation.alpha = 0
    this.tag('LocalesList').alpha = 0
  }

  updateTranslations() {
    this.preparePageData()
  }

  handleInputType(inputType) {
    this.tag('Keyboard').type = inputType
  }

  focusForms() {
    this._setState('SignUpForm')
  }

  returnSelectedField() {
    return this.tag('SignUpForm').returnSelectedField()
  }

  focusKeyboard() {
    this._setState('Keyboard')
  }

  focusCheckBox() {
    this._setState('CheckBox')
  }

  defineFocus(focusData) {
    this.tag('SignUpForm').focusFromCheckBox(focusData)
  }

  showEnteredPassword(passwordStatus) {
    this.tag('SignUpForm').showEnteredPassword(passwordStatus)
  }

  returnShowPasswordStatus() {
    return this.tag('CheckBox').returnShowPasswordStatus()
  }

  updateLanguageCode() {
    this.tag('LanguageSelection').updateLanguageCode()
  }

  focusLanguageSelection() {
    this._setState('LanguageSelection')
  }

  openLocalesList() {
    this.tag('LocalesList').openLocalesList()
    this._setState('LocalesList')
  }

  focusBack() {
    if (configurations.locales.length > 1)
      this._setState('Back')
  }

  focusLocalesList() {
    this._setState('LocalesList')
  }

  static _states() {
    return [
      class SignUpForm extends this {
        _getFocused() {
          return this.tag('SignUpForm')
        }
      },

      class CheckBox extends this {
        _getFocused() {
          return this.tag('CheckBox')
        }
      },

      class Keyboard extends this {
        _getFocused() {
          return this.tag('Keyboard')
        }
      },

      class LanguageSelection extends this {
        _getFocused() {
          return this.tag('LanguageSelection')
        }
      },

      class Back extends this {
        _getFocused() {
          return this.tag('Back')
        }
      },

      class LocalesList extends this {
        _getFocused() {
          return this.tag('LocalesList')
        }
      }
    ]
  }
}
