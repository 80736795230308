export class CategoryPageData {
  constructor(response) {
    this.statusCode = response.status_code
    this.statusMessage = response.message
    this.categoryPageData = response.data ? response.data.map(item => new CategoryDataItem(item)) : []
  }
}

export class CategoryDataItem {
  constructor(data) {
    this.items = data?.list?.map(item => new CategoryListItem(item)) || []
    this.title = data.title || ""
    this.key = data.key || ""
    this.orientation = data.orientation || 2
    this.takeValue = data.take_value || 0
    this.count = data.count || 0
    this.totalCount = data.total_count || 0
    this.slug = data.slug || ""
    this.categorySlug = data.category_slug || ""
    this.component = data.component || ""
    this.contentTitle = data.content_title || ''
  }
}

export class CategoryListItem {
  constructor(data) {
    this.publishTime = data.publish_time || ""
    this.title = data.title || ""
    this.description = data.description || ""
    this.tags = data.tags || ""
    this.director = data.director || ""
    this.writer = data.writer || ""
    this.producer = data.producer || ""
    this.cast = data.cast || ""
    this.contentLogo = data.content_logo || ""
    this.collectionLogo = data.collection_logo || ""
    this.screenshotLandscape = data.screenshot_landscape || ""
    this.backgroundPortrait = data.background_portrait || ""
    this.backgroundLandscapeCenter = data.background_landscape_center || ""
    this.backgroundLandscape = data.background_landscape || ""
    this.posterLandscape = data.poster_landscape || ""
    this.posterPortrait = data.poster_portrait || ""
    this.titleEn = data.title_en || ""
    this.isTvod = data.is_tvod || 0
    this.duration = data.duration || ""
    this.durationMin = data.duration_min || 0
    this.age = data.age || 0
    this.releaseDate = data.release_date || ""
    this.position = data.position || 0
    this.hasTrailer = data.has_trailer || 0
    this.trailerId = data.trailer_id || ""
    this.contentSlug = data.content_slug || ""
    this.slug = data.slug || ""
    this.contentId = data.content_id || ""
    this.contentIdAlternate = data.contentId || "" // Handle the duplicate contentId key
    this.collectionSlug = data.collection_slug || ""
    this.collectionTitle = data.collection_title || ""
    this.isLiveChannel = data.is_livechannel || 0
    this.seriesSlug = data.series_slug || ""
    this.isLocalLogo = data.is_local_logo || 0
    this.key = data.key || ''
    this.seasonsSlug = data.seasons_slug || ""
    this.seasonsTitle = data.seasons_title || ""
    this.seasonPosition = data.season_position || 0
    this.isSeries = data.is_series || 0
    this.videoId = data.video_id || 0
    this.feedId = data.feed_id || ""
    this.channelType = data.channel_type || ""
    this.rent = data.rent || {}
    this.buy = data.buy || {}
    this.durationSec = data.duration_sec || 0
    this.slug = data.slug || ""
    this.availability = data.availability || 0
    this.seek = data.seek || 0
    this.percentage = data.percentage || 0
    this.userType = data.user_type || 0
    this.wishlistStatus = data.wishlist_status || false
    this.totalSeasons = data.total_seasons || 0
    this.seriesEpisodesCount = data.series_episodes_count || 0
    this.contentLogoResolutions = data.content_logo_resolutions || {}
    this.backgroundPortraitResolutions = data.background_portrait_resolutions || ""
    this.backgroundLandscapeCenterResolutions = data.background_landscape_center_resolutions || ""
    this.backgroundLandscapeResolutions = data.background_landscape_resolutions || {}
    this.posterSquareResolutions = data.poster_square_resolutions || ""
    this.posterLandscapeResolutions = data.poster_landscape_resolutions || {}
    this.posterPortraitResolutions = data.poster_portrait_resolutions || {}
    this.genre = data.genre || ""
  }

}
