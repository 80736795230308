import { Router, Language, Colors } from '@lightningjs/sdk'
import { flagBasedNavigation } from '../Helper/Helpers.js'
import App from '../App.js'
import DynamicButton from '../Components/DynamicButton.js'
import Page from '../Components/Page.js'

export default class SVODScreen extends Page {
    static _template() {
        return {
            InfoContainer: {
                x: 150, w: 831, h: App.height,
                flex: { direction: 'column', justifyContent: 'center' },
                Heading: {
                  text: {
                    textColor: Colors('primaryFontColor1').get(),
                    fontSize: 80,
                    fontFace: 'Bold',
                    lineHeight: 93.75,
                    wordWrapWidth: 950 ,
                    maxLines: 3,
                    maxLinesSuffix: '...'
                  },
                },
                Description1: {
                  text: {
                    textColor: Colors('secondaryFontColor').get(),
                    fontSize: 28,
                    fontFace: 'Regular',
                    lineHeight: 40,
                    wordWrapWidth: 831,
                  },
                },
                Description2: {
                  text: {
                    textColor: Colors('secondaryFontColor').get(),
                    fontSize: 28,
                    fontFace: 'Regular',
                    lineHeight: 40,
                    wordWrapWidth: 831,
                  },
                }
            },
            Buttons: {
                x: 1400, h: App.height, w: 174,
                flex: { direction: 'column', justifyContent: 'center' }
            }
          }
    }

    _init() {
        this.buttonIndex = 0
    }

    set data(pageType) {
        this.buttonIndex = 0
        const { heading, description } = this.getTextData(pageType)
        this.tag('InfoContainer').patch({
          Heading: {
              text: { text: Language.translate(heading) }
          },
          Description1: {
              text: { text: Language.translate(description) }
          },
          Description2: {
              visible: (pageType === 'signup'),
              text: { text: Language.translate('registration.signup_redirection_desc.text') }
          }
        })
        this.buttonsData = this.getButtons(pageType)
        this.tag('Buttons').children = this.buttonsData.map((button) => {
          return {
                type: DynamicButton,
                h: 80,
                textPadding: 30,
                label: button.label,
                borderRadius: 2,
                textPadding: 30,
                withoutTexture: false,
                flexItem: { marginTop: 36, alignSelf: 'center', maxHeight: 100 },
                isVerticalBtn: true,
          }
        })
    }

    getTextData(pageType) {
      let heading, description = ''
      switch (pageType) {
          case 'login':
            heading = 'plans.subscription_info_title.text'
            description = 'plans.subscription_info_desc.text'
            break
          case 'signup':
            heading = 'registration.signup_title.text'
            description = 'registration.signup_info_desc.text'
            break
          default:
            heading = 'plans.subscription_info_title_v2.text'
            description = 'plans.subscription_info_desc_v2.text'
            break
      }
      return { heading, description }
    }

    getButtons(pageType) {
        const buttons = [
            { label: Language.translate('common_keys.sign_in.btn') },
            { label: Language.translate('common_keys.close.btn') }
        ]
        pageType === 'login' ? buttons : buttons.shift()
        return buttons
    }

    _handleBack() {
        Router.back()
    }

    _getFocused() {
        return this.tag('Buttons').children[this.buttonIndex]
    }

    _handleUp() {
        if (this.buttonIndex > 0) this.buttonIndex--
    }

    _handleLeft() { return }

    _handleRight() { return }

    _handleDown() {
        if (this.buttonIndex < this.buttonsData.length - 1) {
            this.buttonIndex++
        }
    }

    _handleEnter() {
        this.tag('Buttons').children[this.buttonIndex]._label === Language.translate('common_keys.close.btn') ? Router.back() : flagBasedNavigation()
    }
}
