/**
 * Class representing the response for search data.
 */
export class SearchResponse {
  /**
   * Creates an instance of SearchResponse.
   * @param {Object} res - The input object representing the search response.
   */
  constructor(res) {
    this.statusCode = res.status_code;
    this.statusMessage = res.message;
    this.data = res.data ? new SearchDataItem(res.data) : [];
  }
}

/**
 * Class representing details for search data item.
 */
class SearchDataItem {
  /**
   * Creates an instance of SearchDataItem.
   * @param {Object} data - The input object representing a search data item.
   */
  constructor(data) {
    this.contents = data?.contents?.length > 0 ? data.contents.map((item) => new SearchContentItem(item)) : [];
    this.total = data.total;
    this.totalCount = data.total_count;
  }
}

class SearchContentItem {
  /**
   * Creates an instance of SearchContentItem.
   * @param {Object} data - The input object representing a search content item.
   */
  constructor(data) {
    this.list = data?.list?.length > 0 ? data.list.map((item) => new SearchListItem(item)) : [];
    this.count = data.count;
    this.key = data.key;
    this.orientation = data.orientation;
    this.title = data.title;
  }
}

class SearchListItem {
  /**
   * Creates an instance of SearchListItem.
   * @param {Object} data - The input object representing a search list item.
   */
  constructor(data) {
    this.publishTime = data.publish_time || null;
    this.title = data.title || null;
    this.description = data.description || null;
    this.tags = data.tags || null;
    this.director = data.director || null;
    this.writer = data.writer || null;
    this.producer = data.producer || null;
    this.cast = data.cast || null;
    this.contentLogo = data.content_logo || null;
    this.screenshotLandscape = data.screenshot_landscape || null;
    this.backgroundPortrait = data.background_portrait || null;
    this.backgroundLandscapeCenter = data.background_landscape_center || null;
    this.backgroundLandscape = data.background_landscape || null;
    this.posterSquare = data.poster_square || null;
    this.posterLandscape = data.poster_landscape || null;
    this.posterPortrait = data.poster_portrait || null;
    this.contentId = data.content_id || null;
    this.contentId2 = data.contentId || null;
    this.titleEn = data.title_en || null;
    this.contentSlug = data.content_slug || null;
    this.seriesSlug = data.series_slug || null;
    this.seasonsSlug = data.seasons_slug || null;
    this.collectionSlug = data.collection_slug || null;
    this.isTvod = data.is_tvod || null;
    this.duration = data.duration || null;
    this.durationMin = data.duration_min || null;
    this.age = data.age || null;
    this.isSeries = data.is_series || null;
    this.isLivechannel = data.is_livechannel || null;
    this.releaseDate = data.release_date || null;
    this.trailerId = data.trailer_id || null;
    this.durationSec = data.duration_sec || null;
    this.contentLogoResolutions = data.content_logo_resolutions || null;
    this.backgroundPortraitResolutions = data.background_portrait_resolutions || null;
    this.backgroundLandscapeCenterResolutions = data.background_landscape_center_resolutions || null;
    this.backgroundLandscapeResolutions = data.background_landscape_resolutions || null;
    this.posterSquareResolutions = data.poster_square_resolutions || null;
    this.posterLandscapeResolutions = data.poster_landscape_resolutions || {};
    this.posterPortraitResolutions = data.poster_portrait_resolutions || {};
  }
}
