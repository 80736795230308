import HlsLivePlayer from "../Views/HlsLivePlayer"
import configurations from "../Helper/Configurations"
import { Storage } from "@lightningjs/sdk"

export default {
  path: 'hlsliveplayer/:categoryId/:contentId',
  after: async (page, { categoryId, contentId }) => {
    let categoryIndex
    configurations.categories.categoryData.forEach((Obj, index) => {
      if (Obj.categoryId === categoryId) {
        categoryIndex = index
      }
    })
    Storage.set('activeCategory', categoryIndex + 2)
    page.data(categoryId, contentId)
    return true
  },
  component: HlsLivePlayer,
  widgets: [
    'SidePanelNavigation',
    'BackgroundSidepanel',
    'AudioSidePanel',
    'SubtitlesSidePanel',
    'EpisodeListing',
  ]
}
