import { Lightning, Storage, Router, Language, Utils, Colors } from '@lightningjs/sdk'
import { InlineContent, ProgressBar } from '@lightningjs/ui-components'
import { List } from '@lightningjs/ui'
import { navigateToPlayer } from '../Helper/Helpers'
import configurations from '../Helper/Configurations'
import { returnImageUrl } from '../Helper/Helpers'
import { fontsizes, imageSizes } from '../Helper/GlobalConstants'

export default class Episodes extends Lightning.Component {
  static _template() {
    return {
      zIndex: 6,
      Container: {
        flex: { direction: 'column' },
        SeasonTitle: {
          x: 10,
          flexItem: { marginBottom: 10 },
          alpha: 0,
          text: {
            fontSize: 28,
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 250,
            fontFace: 'Bold',
            maxLines: 1,
            maxLinesSuffix: '...',
          },
        },
        SeasonSynopsis: {
          x: 10,
          flexItem: { marginBottom: 10 },
          alpha: 0,
          text: {
            fontSize: 22,
            textColor: Colors('primaryFontColor1').get(),
            lineHeight: 36,
            wordWrapWidth: 1500,
            fontFace: 'Regular',
            maxLines: 4,
            maxLinesSuffix: '...',
          },
        },
        EpisodesTitle: {
          x: 10,
          alpha: 0,
          flexItem: { marginBottom: 10 },
          text: {
            fontSize: 28,
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 250,
            fontFace: 'Bold',
            maxLines: 1,
            maxLinesSuffix: '...',
          },
        },
        Slider: {
          type: List,
          flexItem: { marginBottom: 10 },
          w: 300,
          h: 685,
          clipping: true,
          direction: 'column',
          spacing: 30,
        },
      }
    }
  }


  set items(episodesData) {
    this.patch({ alpha: 1 })
    this.realIndexFocused = true
    this.episodes = episodesData.videos
    this.seasonData = episodesData.season
    this.seriesMetaData = episodesData.seriesMetaData
    this.seriesPersona = episodesData.seriesPersonaInfo
    this.patch({
      Container: {
        x: episodesData.x,
        y: episodesData.y,
        SeasonTitle: {
          alpha: 1,
          text: { text: episodesData.onlyEpisodes ? Language.translate('common_keys.seasons.text') + ' ' + 1 : episodesData.season.title }
        },
        SeasonSynopsis: {
          alpha: 1,
          text: { text: episodesData.season.description }
        },
        EpisodesTitle: {
          alpha: 1,
          text: Language.translate('common_keys.episodes.text'),
        },
        Slider: {
          w: episodesData.w,
          h: episodesData.h,
        }
      }
    })

    const children = this.episodes.map((item, index) => {
      const continueWatchingObj = episodesData.continueWatching.find((obj) => obj.contentId === item.contentId)
      item.percentage = continueWatchingObj?.percentage || 0
      item.episodePosition = index + 1
      return { type: EpisodeItem, item }
    })
    this.tag('Slider').clear()
    this.tag('Slider').add(children)
    this.slider.scroll = { after: 3 }
    this.slider.setIndex(episodesData.episodeIndex)
  }

  get slider() {
    return this.tag('Slider')
  }

  _handleLeft() {
    Router.getActivePage().widgets.episodelisting.focusSeasons()
  }

  _handleRight() { return }

  getCurrentSeasonSlug(slug) {
    this.currentSeasonSlug = slug
  }

  _handleEnter() {
    if (Router.getActivePage().tag('Loading').visible) return
    const item = this.episodes[this.slider.index]
    item.index = this.slider.index
    const hash = Router.getActiveHash()
    const page = Router.getActivePage()
    const enteredEpisodeInfo = {
      videoId: item.contentId,
      seasonsSlug: this.currentSeasonSlug
    }
    Storage.set('enteredEpisodeInfo', JSON.stringify(enteredEpisodeInfo))

    if (
      (configurations.enableRegistration && !configurations.loggedIn) ||
      (!configurations.enableRegistration && !configurations.loggedIn && this.seriesPersona?.availability === 1)
    ) {
      return Router.navigate('svodpopup/login')
    }

    if (hash.includes('player')) {
      const currentPlayingContent = page.$returnContent()
      if (item.contentId === currentPlayingContent.contentId) return
      page.playerNavigation(item.contentId)
    } else {
      Storage.set('fromEpisodePanel', 'Yes')
      navigateToPlayer(item.contentId, this.seriesMetaData, this.seriesPersona, 0, true)
    }
  }

  _getFocused() {
    this.sliderUpTransition()
    return this.slider
  }

  _active() {
    this.tag('SeasonSynopsis').on('txLoaded', () => {
      this.seasonSynopsisH = this.tag('SeasonSynopsis').renderHeight || 0
    })
  }

  sliderUpTransition() {
    this.patch({
      Container: {
        SeasonTitle: { alpha: 0 },
        SeasonSynopsis: { alpha: 0 },
      }
    })

    // based on season desc height, calculating the scroll, if scroll is 0 then default value giving as -70
    const scrollValue = this.seasonSynopsisH == 0 ? -70 : (!this.seasonSynopsisH ? 0 : -this.seasonSynopsisH - 20)

    this.tag('Container').setSmooth('y', scrollValue, { duration: 0.3, timingFunction: 'ease' })
  }

  sliderDownTransition() {
    this.patch({
      Container: {
        SeasonTitle: { alpha: 1 },
        SeasonSynopsis: { alpha: 1 },
      }
    })
    this.tag('Container').setSmooth('y', 35, { duration: 0.3, timingFunction: 'ease' }) // 35 is the container starting position
  }

  _handleBack() {
    Router.getActivePage().widgets.episodelisting.focusSeasons()
  }

  _handleKey() {
    /* TODO document why this method '_handleKey' is empty */
  }

}

class EpisodeItem extends Lightning.Component {
  static _template() {
    return {
      alpha: 1,
      FocusTexture: {
        alpha: 0,
        zIndex: 5,
        texture: Lightning.Tools.getRoundRect(
          EpisodeItem.width + 10,
          EpisodeItem.height + 10,
          8,
          3,
          Colors('brandPrimaryColor').get(),
          false
        ),
      },
      LazyBackground: {
        zIndex: 3,
        w: EpisodeItem.width - 14,
        x: 8,
        y: 8,
        h: EpisodeItem.width - 14,
        rect: true,
        color: Colors('brandPrimaryColor').get(),
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 8 },
      },
      Poster: {
        zIndex: 4,
        w: EpisodeItem.width - 14,
        x: 8,
        y: 8,
        h: EpisodeItem.width - 14,
        rect: true,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 8 },
      },
      PlayIcon: {
        zIndex: 6,
        alpha: 0,
        w: 100,
        h: 100,
        x: (480 - 100) / 2,
        y: (270 - 100) / 2,
        src: Utils.asset('static_assets/play_icon.png'),
      },
      Overlay: {
        alpha: 0,
        zIndex: 5,
        x: 8,
        y: 8,
        w: EpisodeItem.width,
        h: EpisodeItem.height,
        rect: true,
        color: Colors('overlayColor').get()
      },
      ProgressBar: {
        alpha: 0,
        zIndex: 6,
        x: 30,
        y: 260,
        w: EpisodeItem.width - 45,
        h: 8,
        type: ProgressBar,
        style: {
          barColor: Colors('shadowColor').get(),
          progressColor: Colors('brandPrimaryColor').get(),
        }
      },
      DurationContainer: {
        x: 1170,
        w: 330,
        flex: { direction: 'row', justifyContent: 'flex-end' },
        DurationInfo: {
          justify: 'flex-end',
          type: InlineContent,
          style: { iconW: 24, iconH: 24 },
          content: []
        }
      },
      EpisodeTitle: {
        x: 520,
        y: 10,
        text: {
          fontFace: 'Bold',
          fontSize: fontsizes.verticalColumnListEpisodeTitle,
          textColor: Colors('primaryFontColor1').get(),
          wordWrapWidth: 700,
          maxLines: 1,
          maxLinesSuffix: '...'
        },
      },
      EpisodeDescription: {
        x: 520,
        y: 60,
        text: {
          fontFace: 'Regular',
          lineHeight: 36,
          fontSize: fontsizes.verticalColumnListEpisodeDescription,
          textColor: Colors('primaryFontColor1').get(),
          wordWrapWidth: 1000,
          maxLines: 6,
          maxLinesSuffix: '...'
        },
      },
    }
  }

  set item(episode) {
    this.episodeData = episode
    let src = null
    src = returnImageUrl(episode.posterLandscape, imageSizes.card)
    this.width = 480
    this.height = 270
    this.patch({
      alpha: 1,
      w: this.width,
      h: this.height,
      LazyBackground: {
        w: EpisodeItem.width,
        h: EpisodeItem.height,
        rect: true,
        color: Colors('cardColor').get(),
      },
      Poster: {
        w: EpisodeItem.width,
        h: EpisodeItem.height,
        src: src,
      },
      DurationContainer: {
        DurationInfo: {
          content: [
            {
              text: episode.durationMin + Language.translate('common_keys.min.text'),
              style: {
                fontSize: fontsizes.verticalColumnListdurationInfo,
                fontFace: 'Bold',
                wordWrapWidth: 100,
                maxLines: 1,
                maxLinesSuffix: '...'
              }
            },
            {
              icon: Utils.asset('static_assets/timer.png'),
              w: 24,
              h: 24
            }
          ]
        }
      },
      EpisodeTitle: {
        text: {
          text: episode.title + ' - ' + Language.translate('content_detail.episodes.text') + episode.episodePosition
        },
      },
      EpisodeDescription: {
        text: { text: episode.description },
      },
    })
    if (episode.percentage) {
      this.tag('ProgressBar').patch({
        alpha: 1,
        progress: episode.percentage / 100
      })
    }
  }

  _focus() {
    this.patch({
      FocusTexture: { alpha: 1 },
      PlayIcon: { alpha: 1 },
      Overlay: { alpha: 1 }
    })
  }

  _unfocus() {
    this.patch({
      FocusTexture: { alpha: 0 },
      PlayIcon: { alpha: 0 },
      Overlay: { alpha: 0 }
    })
  }

  static get width() {
    return 480
  }

  static get height() {
    return 270
  }
}
