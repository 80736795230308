import configurations from '../Helper/Configurations'
import middleware from "./Middleware"
import { Storage } from '@lightningjs/sdk'
import { API_VERSION, BASEURL, BEARER } from "../Helper/GlobalConstants"
import { getDeviceUniqueId } from '../Helper/Helpers'

export const baseURL = async () => {
  const params = {
    app_name: configurations.appName,
    version: configurations.appVersion,
  }
  const MAXTRIES = 2;
  let count = 0;
  while (count < MAXTRIES) {
    try {
      const res = await middleware('GET', BASEURL, params, false, configurations.headers)
      if (res?.status_code === 200) {
        return configurations.baseUrl = res.data?.base_url
      }
      count++;
    } catch (error) {
      console.log(error);
      count++;
    }
  }
  // If all attempts fail, update the base URL to the fallback and return an error message
  return 'Max attempts reached. Unable to fetch API URL.';
}

export const platformSettingsAPI = async () => {
  const url = `${configurations.baseUrl}${API_VERSION}/auth/platform_settings`
  const params = { version: 'v1' }
  try {
    return await middleware('GET', url, params, true, configurations.headers)
  } catch (error) {
    console.log(error)
    return error
  }
}

export const userSettingsAPI = async () => {
  const params = {}
  let url = `${configurations.baseUrl}${API_VERSION}/auth/user_settings`

  if (!Storage.get('loggedIn') && Storage.get('guestId')) {
    configurations.deviceId = Storage.get('deviceId')
    configurations.guestId = Storage.get('guestId')
    url = `${url}/${configurations.deviceId}/${configurations.guestId}`
  } else if (Storage.get('userId') && Storage.get('loggedIn')) {
    // It is not vallid for auth with entitlement
    configurations.deviceId = Storage.get('deviceId')
    configurations.userId = Storage.get('userId')
    url = `${url}/${configurations.deviceId}/${configurations.userId}`
  }

  const loggedIn = Storage.get('loggedIn') || configurations.loggedIn
  params['logged_in'] = loggedIn

  try {
    const res = await middleware('POST', url, params, true, configurations.headers)
    if (res?.status_code === 200) {
      configurations.region = res.data.configuration.region.region_code
      configurations.countryCode = res.data.configuration.country_code

      if ('user' in res.data) {
        if (Storage.get('guestId') || res.data.user.g_id) {
          configurations.guestId = res.data.user.g_id || Storage.get('guestId')
          Storage.set('guestId', configurations.guestId)
          configurations.deviceId = res.data.user.d_id
        }
        configurations.profilesList = res.data.user.profiles
        configurations.userEmailVerified = res.data.user.user_email_verified
        configurations.userMobileVerified = res.data.user.user_mobile_verified
        configurations.loggedIn = res.data.user.logged_in
        configurations.userType = res.data.user.user_type
        Storage.set('deviceId', configurations.deviceId)
        Storage.set('loggedIn', configurations.loggedIn)
      }
    }
    return res
  } catch (error) {
    console.log(error)
    return error
  }
};

export const fetchToken = async () => {
  const url = `${configurations.baseUrl}v2/auth/token`
  const params = {
    d_id: configurations.deviceId || Storage.get('deviceId'),
  }
  if (configurations.userId || Storage.get('userId')) params.u_id = configurations.userId || Storage.get('userId')
  else params.g_id = configurations.guestId || Storage.get('guestId')

  try {
    const res = await middleware('GET', url, params, true, configurations.headers)
    if (res?.status_code === 200) {
      if (configurations.loggedIn) {
        configurations.userAccessToken = res.data.access_token
        configurations.userRefreshToken = res.data.refresh_token
        Storage.set('userAccessToken', configurations.userAccessToken)
        Storage.set('userRefreshToken', configurations.userRefreshToken)
      } else {
        configurations.guestAccessToken = res.data.access_token
        configurations.guestRefreshToken = res.data.refresh_token
        Storage.set('guestAccessToken', configurations.guestAccessToken)
        Storage.set('guestRefreshToken', configurations.guestRefreshToken)
      }
    }
  } catch (error) {
    return error
  }
}

export const getRefreshToken = async () => {
  const url = `${configurations.baseUrl}v2/auth/refresh/token`
  const refreshToken = configurations.loggedIn
    ? configurations.userRefreshToken || Storage.get('userRefreshToken')
    : configurations.guestRefreshToken || Storage.get('guestRefreshToken')
  const headers = {
    ['Content-Type']: 'application/json',
    ['Authorization']: BEARER + refreshToken
  }
  try {
    const res = await middleware('GET', url, null, true, headers)
    if (configurations.loggedIn) {
      configurations.userAccessToken = res.data.access_token
      configurations.userRefreshToken = res.data.refresh_token
      Storage.set('userAccessToken', configurations.userAccessToken)
      Storage.set('userRefreshToken', configurations.userRefreshToken)
    } else {
      configurations.guestAccessToken = res.data.access_token
      configurations.guestRefreshToken = res.data.refresh_token
      Storage.set('guestAccessToken', configurations.guestAccessToken)
      Storage.set('guestRefreshToken', configurations.guestRefreshToken)
    }
  } catch (error) {
    return error
  }
}

 export const login = async (params) => {
  const url = `${configurations.baseUrl}v2/auth/login`
  try {
    const res = await middleware('POST', url, params, true, configurations.headers)
    if (res?.status_code === 200) {
      configurations.userAccessToken = res.data.access_token
      configurations.userRefreshToken = res.data.refresh_token
      Storage.set('userAccessToken', configurations.userAccessToken)
      Storage.set('userRefreshToken', configurations.userRefreshToken)
      configurations.loggedIn = res.data.logged_in
      configurations.profilesList = res.data.profiles
      Storage.set('loggedIn', configurations.loggedIn)
      configurations.userId = res.data.user_id
      configurations.userType = res.data.user_type
      Storage.set('userId', configurations.userId)
      return res
    } else {
      return res
    }
  } catch (error) {
    return error
  }
}

/**
 * Fetch Register
 * @return {object} the response object for Register
 */
 export const getRegister = async (paramsObj) => {
  const url = `${configurations.baseUrl}v2/auth/register`
  try {
    const res = await middleware('POST', url, paramsObj, true, configurations.headers)
    if (res?.status_code === 200 && !res.data.verifyEmail) {
      configurations.userAccessToken = res.data.access_token
      configurations.userRefreshToken = res.data.refresh_token
      configurations.profilesList = res.data.profiles
      Storage.set('userAccessToken', configurations.userAccessToken)
      Storage.set('userRefreshToken', configurations.userRefreshToken)
      configurations.loggedIn = res.data.logged_in
      Storage.set('loggedIn', configurations.loggedIn)
      configurations.userId = res.data.user_id
      Storage.set('userId', configurations.userId)
      return res
    } else {
      return res
    }
  } catch (error) {
    return error
  }
}

/**
 * Function to request password reset for a user.
 * @param {Object} input - The input object containing necessary data for password reset.
 * @returns {Promise} - A promise resolving to the result of the password reset request.
 */
 export const forgotPassword = async (input) => {
  const url = `${configurations.baseUrl}v2/auth/forgot/password`

  try {
    return await middleware('PUT', url, input, true, configurations.headers)
  } catch (error) {
    return error
  }
}

/**
 * Otp Validation.
 * @return {object} the response object for Register
 */

 export const optValidate = async (paramsObj) => {
  const url = `${configurations.baseUrl}v2/auth/account/verify`
  try {
    return await middleware('POST', url, paramsObj, true, configurations.headers)
  } catch (error) {
    return error
  }
}

 export const metroLogin = async () => {
  const url = `${configurations.baseUrl}v2/auth/device/login/metro`

  try {
    await getHousehold()

    const params = {
      household: configurations.household,
      ...(Storage.get('deviceId') || configurations.deviceId
        ? { d_id: Storage.get('deviceId') || configurations.deviceId }
        : {})
    }
    const res = await middleware('POST', url, params, true, configurations.headers)
    if (res?.status_code === 200) {
      configurations.userAccessToken = res.data.access_token
      configurations.userRefreshToken = res.data.refresh_token
      Storage.set('userAccessToken', configurations.userAccessToken)
      Storage.set('userRefreshToken', configurations.userRefreshToken)
      Storage.set('guestAccessToken', configurations.userAccessToken)
      Storage.set('guestRefreshToken', configurations.userRefreshToken)
      configurations.deviceId = res.data.d_id
      configurations.userId = res.data.user_id
      configurations.loggedIn = res.data.logged_in
      Storage.set('loggedIn', res.data.logged_in)
      Storage.set('userId', configurations.userId)
      Storage.set('deviceId', configurations.deviceId)
    }
    return res
  } catch (error) {
    console.log(error)
    return error
  }
}

/**
 * Fetch the profile's household from the STB
 *
 * @return {string} the profile's household string.
 */
 export const getHousehold = async () => {
  try {
    const profileHousehold = await getDeviceUniqueId()
    return configurations.household = profileHousehold || 'b2244e9d4c04826ccd5a7b2c2a50e7d4'
  } catch (error) {
    console.log(error)
    return error
  }
}
