/*
 * Application related constants
 */

import globalValues from '../../src/GlobalConfigurations.json'
const configurations = {
    headers: {
      ['Content-Type']: 'application/json',
    },
    defaultLocale: globalValues.defaultLocale,
    loggedIn: 0,
    userType: 0,
    appVersion: globalValues.appVersion,
    appName: globalValues.appName,
    enableStartOver: globalValues.enableStartOver,
    enableAgeRating: globalValues.enableAgeRating,
    enableHlsStream: globalValues.enableHlsStream,
    enableFirebolt: globalValues.enableFirebolt,
    enableWebVTT: globalValues.brightcoveExternalVTT,
    enableCMP: globalValues.enableCMP,
    enabeSSAI: globalValues.brightcoveSSAI,
    inHouseAnalyticsEndpoint: globalValues.inHouseAnalyticsEndpoint,
    googleAnalyticsId: globalValues.googleAnalyticsId,
    registrationViaThirdParty: globalValues.registrationViaThirdParty,
    enableScheduleEPG: globalValues.enableScheduleEPG
  }
  export default configurations
