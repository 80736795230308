import { Lightning, Router, Utils, Colors } from '@lightningjs/sdk'
import App from '../../App'
import Keyboard from './KeyboardBox'

export default class LandscapeKeyboard extends Lightning.Component {
    static _template() {
        return {
            KeyboardBorder: {
                y: App.height - 472,
                x: 0,
                texture: Lightning.Tools.getRoundRect(App.width, 472, 0, 0, Colors('cardColor').get(), true, Colors('cardColor').get(),),
            },
            SearchButtons: {},
            KeyBoard: {
                type: Keyboard,
                x: 40,
                y: 648,
            }
        }
    }

    _enable() {
        this.tag('KeyboardBorder').patch({ alpha: 1, zIndex: 99 })
        this._index = 0
        this.focusItem = 'KeyBoard'
        this.tag('KeyBoard').layout = 'landscape'
        this.buttons = [
            {
                y: 648,
                unfocusImage: 'static_assets/search_shift.png',
                text: 'ABC',
                btntype: 'ABC'
            },
            {
                y: 732,
                text: '#$!',
                btntype: 'SPCHAR'
            },
            {
                y: 816,
                unfocusImage: 'static_assets/backspaceLandscape.png',
                text: 'Cancel',
                btntype: 'backspace'
            },
            {
                y: 900,
                unfocusImage: 'static_assets/landscapedelete.png',
                text: 'Delete',
                btntype: 'delete'
            },
            {
                y: 984,
                unfocusImage: 'static_assets/search_tick_white.png',
                text: 'Done',
                btntype: 'done'
            },

        ]
        this.tag('SearchButtons').children = this.buttons.map(item => {
            return { type: KeyboardButton, item }
        })
    }

    _getFocused() {
        return this._index == 0 ? this.tag('KeyBoard') : this.tag('SearchButtons').children[this._index - 1]
    }

    _handleRight() {
        if (this._index == 0) {
            this._index++
        }
    }

    _handleDown() {
        if (this._index < this.buttons.length) {
            this._index++
        }
    }

    _handleUp() {
        if (this._index > 1) {
            this._index--
        }
    }

    _handleLeft() {
        this._index = 0
    }

    _handleEnter() {
      if (this._index == 1) {
        let capObject
        if (this.buttons[this._index - 1].text == 'ABC') {
          capObject = {
            y: 648,
            text: 'abc',
            btntype: 'ABC'
          }
        } else if (this.buttons[this._index - 1].text == 'abc') {
          capObject = {
            y: 648,
            unfocusImage: 'static_assets/search_shift.png',
            text: 'ABC',
            btntype: 'abc'
          }
        }
        this.buttons[0] = capObject
        this.tag('SearchButtons').children = this.buttons.map(item => {
          return { type: KeyboardButton, item }
        })
        this.tag('KeyBoard')._handleEnter('toggleToLayout', this.buttons[this._index - 1].btntype)
        return
      }

      if (this._index == 2) {
        this.tag('KeyBoard')._handleEnter('toggleToLayout', this.buttons[this._index - 1].btntype)
      }

      if (this._index == 3 || this._index == 4 || this._index == 5) {
        this.tag('KeyBoard')._handleEnter('', this.buttons[this._index - 1].btntype)
      }
    }

    _handleKey() { return }

    resetKeyboard() {
        this.tag('KeyBoard')._disable()
    }

    clearLetterByLetter(searchkey) {
        this.tag('KeyBoard').value = searchkey
    }

    getFocusedKey() {
        this.tag('KeyBoard').key = 'backspace'
    }

    _handleBack() {
        Router.getActivePage().retainState()
    }
}

class KeyboardButton extends Lightning.Component {
    static _template() {
        return {
            zIndex: 99,
            x: 1544,
            texture: Lightning.Tools.getRoundRect(336, 72, 4, 1, Colors('primaryFontColor1').get(), false),
            ButtonText: {
                y: 22,
                w: 336,
                flex: { direction: 'row', justifyContent: 'center' },
                Icon: {
                    flexItem: { marginRight: 20 },
                    alpha: 1,
                    src: Utils.asset('static_assets/clear_white.png')
                },
                Label: {
                    y: -10,
                    alpha: 1,
                    color: Colors('primaryFontColor1').get(),
                    text: {
                        text: 'Done',
                        fontFace: 'Regular',
                        fontSize: 38,
                        textColor: Colors('primaryFontColor1').get(),
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        wordWrapWidth: 120,
                        maxLines: 1,
                        maxLinesSuffix: '...'
                    }
                },
            }

        }
    }

    set item(v) {
        this.buttonsInfo = v
        if (v.text == 'Done') {
            this.patch({
                texture: Lightning.Tools.getRoundRect(336, 72, 4, 1, Colors('brandPrimaryColor').get(), true, Colors('brandPrimaryColor').get(),),
                y: v.y,
                ButtonText: {
                    Icon: {
                        src: Utils.asset(v.unfocusImage)
                    },
                    Label: {
                        text: {
                            text: v.text
                        }
                    }
                }
            })
        } else {
            this.patch({
                y: v.y,
                ButtonText: {
                    Icon: {
                        src: Utils.asset(v.unfocusImage)
                    },
                    Label: {
                        text: {
                            text: v.text
                        }
                    }
                }
            })
        }

    }

    _focus() {
        this.patch({
            texture: Lightning.Tools.getRoundRect(336, 77, 4, 3, Colors('brandPrimaryColor').get(), true, Colors('focusedBtnColor').get()),
        })
    }

    _unfocus() {
      this.buttonsInfo.text === 'Done'
        ? this.patch({
          texture: Lightning.Tools.getRoundRect(336, 72, 4, 1, Colors('brandPrimaryColor').get(), true, Colors('brandPrimaryColor').get(),),
        })
        : this.patch({
          texture: Lightning.Tools.getRoundRect(336, 77, 4, 1, Colors('primaryFontColor1').get(), false, Colors('primaryFontColor1').get(),),
        })
    }
}
