import Authenticate from '../Views/Authenticate'
/**
 * Defines the configuration for the 'home' page.
 */
export default {
    path: 'authenticate',
    component: Authenticate,
    /**
     * Defines the asynchronous setup for the 'home' page.
     * @param {Object} page - The page instance.
     */
    on: async (page) => {
        page.patchDataToTemplate()
    },
    widgets: ['Back', 'LanguageSelection', 'LocalesList', 'SidePanelNavigation'],
}
