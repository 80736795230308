import { Lightning, Utils, Router, Colors } from '@lightningjs/sdk'
import configurations from '../../Helper/Configurations'
import { displayTimebasedOnFormat } from '../../Helper/Helpers'
export default class ProgramItem extends Lightning.Component {
  static _template() {
    return {
      Item: {
        zIndex: 4,
        alpha: 1, w: ProgramItem.width, h: ProgramItem.height, rect: true, BottomLine: { x: 0, y: 95, alpha: 1, h: 5, w: ProgramItem.width, ect: true, color: Colors('backgroundColor').get() },
        Container: {
          w: ProgramItem.containerWidth, h: 112, x: 91, y: 29,
          StartTime: {
            alpha: 1, h: 46,
            text: { fontFace: 'Bold', fontSize: 28, lineHeight: 45, textColor: Colors('primaryFontColor1').get(), textAlign: 'center', maxLines: 1, textOverflow: '...', },
          },
        },
        InfoIcon: { alpha: 1, x: 1637, y: 32, w: 32, h: 32 },
        ImageContainer: { alpha: 1, x: 245, y: 12, w: 110, h: 72 },
        MetaContainer: {
          x: 405, y: 13, w: 1199, h: 69,
          Title: { alpha: 1, w: 1199, text: { fontFace: 'Bold', fontSize: 30, lineHeight: 45, textColor: Colors('primaryFontColor1').get(), maxLines: 1, maxLinesSuffix: '...', } },
          Description: {
            w: 1199, h: 23, y: 46,
            text: { fontFace: 'Regular', fontSize: 21, lineHeight: 22, textColor: Colors('primaryFontColor1').get(), maxLines: 1, maxLinesSuffix: '...', }
          }
        }
      }
    }
  }

  set show(show) {
    this.showDetails = show
    const ms = this.showDetails.start_date
    const result = displayTimebasedOnFormat(ms)
    const timeTextWidth = this.displayTextWidth(result)
    const imageURL =
      this.showDetails?.items?.[0]
        ? this.showDetails?.items[0]?.image != ''
          ? configurations.imageBasePath + this.showDetails?.items[0]?.image
          : configurations.epgVideoPlaceholder
        : configurations.epgVideoPlaceholder
    this.patch({
      Item: {
        texture: Lightning.Tools.getRoundRect(ProgramItem.width - 3, ProgramItem.height - 3, 0, 3, Colors('backgroundColor').get(), true, Colors('backgroundColor').get()),
        Container: { StartTime: { x: ProgramItem.containerWidth / 2 - timeTextWidth - 30, text: { text: result } } },
        ImageContainer: { src: Utils.proxyUrl(imageURL) },
        MetaContainer: {
          Title: { text: { text: this.showDetails?.title ? this.showDetails.title : '' } },
          Description: {
            text: { text: this.showDetails?.items?.[0] ? this.showDetails?.items[0]?.content_description : this.showDetails?.description, },
          }
        }
      },
    });
    (this.showDetails?.items?.[0] === undefined) || (this.showDetails?.items?.[0]?.image == '')
      ? this.tag('ImageContainer').patch({ h: 90, y: 2, })
      : this.tag('ImageContainer').patch({ h: 72, y: 12, })
    if (this.showDetails.currentShow) { this.BackgroundColorUpdate() }

    const infoIconVisibility = this.showInfoIcon(show)
    if (infoIconVisibility) {
      this.patch({
        Item: {
          InfoIcon: { src: Utils.asset("static_assets/Info.png") },
        }
      })
    }

  }

  showInfoIcon(val) {
    return this.showDetails?.items && this.showDetails.items[0] && this.showDetails.items[0].content_id && val?.location_available ? true : false
  }

  BackgroundColorUpdate() {
    this.patch({
      Item: {
        texture: Lightning.Tools.getRoundRect(ProgramItem.width - 3, ProgramItem.height - 3, 0, 0, Colors('cardColor').get(), true, Colors('cardColor').get(),),
        BottomLine: { alpha: 0 },
      }
    })
  }

  displayTextWidth(text, font) {
    const canvas = this.displayTextWidth.canvas || (this.displayTextWidth.canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  }

  _focus() {
    this.patch({
      Item: {
        texture: Lightning.Tools.getRoundRect(ProgramItem.width - 3, ProgramItem.height - 3, 0, 3, Colors('brandPrimaryColor').get(), true, Colors('backgroundColor').get()),
        BottomLine: { alpha: 0 },
      },
    })
  }

  _unfocus() {
    !this.showDetails.currentShow
      ? this.patch({
        Item: {
          texture: Lightning.Tools.getRoundRect(ProgramItem.width - 3, ProgramItem.height - 3, 0, 3, Colors('backgroundColor').get(), true, Colors('backgroundColor').get()),
          BottomLine: { alpha: 1 },
        },
      })
      : this.BackgroundColorUpdate()
  }

  static get containerWidth() {
    return 160
  }
  static get width() {
    return Router.getActiveHash().includes('player') ? 1920 : 1700
  }
  static get height() {
    return 100
  }
}
