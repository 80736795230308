import { Lightning, Utils, Colors } from '@lightningjs/sdk'


export default class KeyboardButton extends Lightning.Component {
  static _template() {
    return {
      BtnBackground: { },
      Content: { },
    }
  }

  set action(v) {
    this._action = v
  }

  get action() {
    return this._action
  }

  get c() {
    return this.key.c
  }

  set key(v) {
    this._key = v
    if (this.active) {
      this._update()
    }
  }

  _update() {
    const key = this._key
    const content = this._key.source
      ? {
        w: key.source == 'static_assets/landscapespacebar.png' ? 300 : 1308,
        h: key.source == 'static_assets/landscapespacebar.png'
            ? 10
            : key.source == 'static_assets/backspaceLandscape.png'
              ? 30
              : 40,
        y: key.source == 'static_assets/landscapespacebar.png' ? 35 : 30,
        src: key.source ? Utils.asset(key.source) : '',
      }
      : {
        text: {
          text: key.c || '',
          textAlign: 'center',
          textColor: Colors('primaryFontColor1').get(),
        },
      }
    this.patch({
      texture: Lightning.Tools.getRoundRect(this.w - 3, this.h - 3, 0, 0, Colors('cardColor').get(), true, Colors('cardColor').get(),),
      Content: {
        w: this.w,
        h: this.h,
        mountX: 0.5,
        mountY: 0.35,
        x: this.w / 2,
        y: this.h / 2,

        ...content,
      },
    })
  }

  get key() {
    return this._key
  }

  _focus() {
    this.patch({
      BtnBackground: {
        texture: Lightning.Tools.getRoundRect(this.w - 3, this.h - 3, 0, 3, Colors('brandPrimaryColor').get(), true, Colors('focusedBtnColor').get())
      }
    })

    const actionMappings = {
      'space': { Content: { color: Colors('primaryFontColor1').get() } },
      'backspace': { Content: { color: Colors('primaryFontColor1').get() } },
      'toggleToLayout': { Content: { w: 162, h: 76, color: 0xff000000 } },
      'delete': { Content: { w: 48, h: 40, color: 0xff000000 } },
      'input': { Content: { color: Colors('primaryFontColor1').get() } },
      'default': { Content: { text: { textColor: 0xff000000 }, fontFace: 'Regular' } }
  }

    const patchObject = actionMappings[this._key.action] || actionMappings['default']

    this.patch(patchObject)

  }

  _unfocus() {
    const actionMappings = {
        'space': { Content: { color: Colors('primaryFontColor1').get() } },
        'backspace': { Content: { color: Colors('primaryFontColor1').get() } },
        'toggleToLayout': { Content: { w: 162, h: 76, color: Colors('primaryFontColor1').get() } },
        'delete': { Content: { w: 48, h: 40, color: Colors('primaryFontColor1').get() } },
        'input': { Content: { color: Colors('primaryFontColor1').get() } },
        'default': { Content: { text: { textColor: Colors('primaryFontColor1').get() }, fontFace: 'Regular' } }
    }

    const patchObject = actionMappings[this._key.action] || actionMappings['default']

    this.patch(patchObject)

    this.patch({
        BtnBackground: {
            texture: Lightning.Tools.getRoundRect(this.w - 3, this.h - 3, 0, 3, Colors('cardColor').get(), true, Colors('cardColor').get(),),
        },
    })
}


  _firstActive() {
    this._update()
  }
}
