import DashLivePlayer from "../Views/DashLivePlayer"
import configurations from "../Helper/Configurations"
import { getCategories } from "../Services/AssetsService"

export default {
    path: 'dashliveplayer/:categoryId/:contentId',
    on: async (page, { categoryId, contentId }) => {
        configurations.epgChannel = 1 // For calling v2 API for livechannels
        const apiCallsArray = [getCategories()]
        await Promise.all(apiCallsArray)
            .then((response) => {
                const [cateoriesAPIResponse] = response
                page.widgets.menu.data = cateoriesAPIResponse.categoryData
                return true
            })

        page.data(categoryId, contentId)
        return true
    },
    component: DashLivePlayer,
    widgets: [
        'SidePanelNavigation',
        'BackgroundSidepanel',
        'AudioSidePanel',
        'SubtitlesSidePanel',
        'EpisodeListing'
    ]
}
