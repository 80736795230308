import { Main } from '../Views'
import { getHome, getCategories } from '../Services/AssetsService'
import { HomeResponse } from '../Models/HomeModel'
import { BANNER_WIDGET } from '../Helper/GlobalConstants'
import { getContinueWatchingVideos } from '../Services/PersonaService'
import { appendPersonaVideos, createPageComponents } from '../Helper/Helpers'
import configurations from '../Helper/Configurations'
import { Storage } from '@lightningjs/sdk'
/**
 * Defines the configuration for the 'home' page.
 */
export default {
  path: 'home',
  component: Main,
  /**
   * Defines the asynchronous setup for the 'home' page.
   * @param {Object} page - The page instance.
   */
  on: async (page) => {
    const apiCallsArray = [getCategories(), getHome()]

    if (configurations.loggedIn)
      apiCallsArray.push(getContinueWatchingVideos(''))

    await Promise.all(apiCallsArray)
      .then((response) => {
        const [cateoriesAPIResponse, homeAPIResponse, personaAPIResponse] = response

        const parsedHomeResponse = new HomeResponse(homeAPIResponse)

        const parsedPersonaResponse = personaAPIResponse && new HomeResponse(personaAPIResponse)

        const homeVideos = configurations.loggedIn
          ? appendPersonaVideos(parsedHomeResponse.homeData, parsedPersonaResponse.homeData)
          : parsedHomeResponse.homeData.filter(item => item.items?.length > 0)

        const filteredHomeData = homeVideos.filter((obj) => obj.component !== BANNER_WIDGET)

        const initialCarouselsArray = homeVideos.slice(0, 4)

        page.setListData(filteredHomeData, parsedHomeResponse, initialCarouselsArray)

        page.addCarousels(createPageComponents(initialCarouselsArray))

        Storage.set('activeCategory', 0)

        page.widgets.menu.data = cateoriesAPIResponse.categoryData

        return true
      })
  },
  /**
   * List of widgets to be included on the 'home' page.
   */
  widgets: ['menu']
}
