import { Lightning, Utils, Colors } from '@lightningjs/sdk'
import { InlineContent } from '@lightningjs/ui-components';
import { FONT_BOLD, FONT_REGULAR, fontsizes } from '../Helper/GlobalConstants';
export default class Button extends Lightning.Component {
  static _template() {
    return {
      texture: Lightning.Tools.getRoundRect(400, 60, 30, 1, 0xfff3f3f3, false, 0xfff3f3f3),
      Label: {
        y: 5,
        flex: { direction: 'row', justifyContent: 'center' },
        type: InlineContent,
        content: [],
        alpha: 0,
      },
      TextLabel: { alpha: 0 },
      Icon: { alpha: 0 }
    }
  }

  textAlignment() {
    this.tag('TextLabel').on('txLoaded', () => {
      const width = this.tag('TextLabel').renderWidth
      const height = this.tag('TextLabel').renderHeight
      const xPos = (this.w - width) / 2
      const yPos = (this.h - height) / 2
      this.tag('TextLabel').patch({ x: xPos, y: yPos + 1.5 })
    })
  }

  set button(v) {
    this.buttonData = v
    const buttonColor = this.buttonData.textureColor || Colors('strokeColor').get()
    this.y = this.buttonData.y
    this.h = this.buttonData.h
    this.w = this.buttonData.w
    this.x = this.buttonData.x
    this.radius = this.buttonData.radius || 8
    this.textAlignment()
    this.patch({
      texture:
        this.buttonData.layout == 'border'
          ? Lightning.Tools.getRoundRect(this.w, this.h, 4, 2, buttonColor, false)
          : Lightning.Tools.getRoundRect(this.w, this.h, 4, 2, Colors('strokeColor').get(), false),
      Label: {
        w: this.buttonData.w,
        h: this.buttonData.h,
        alpha: !this.buttonData.title ? 1 : 0,
        content: [
          {
            text: this.buttonData.title1,
            style: {
              fontSize: this.buttonData.highlightOnlyBorder ? this.buttonData.fontSize : fontsizes.popupButtonsText,
              fontFace: FONT_REGULAR,
              textColor: Colors('primaryFontColor1').get(),
            }
          },
          {
            text: this.buttonData.title2,
            style: {
              fontSize: this.buttonData.highlightOnlyBorder ? this.buttonData.fontSize : fontsizes.popupButtonsText,
              fontFace: FONT_BOLD,
              textColor: Colors('primaryFontColor1').get(),
            }
          }
        ]
      },
      TextLabel: {
        alpha: this.buttonData.title ? 1 : 0,
        text: {
          text: this.buttonData.title,
          fontSize: this.buttonData.highlightOnlyBorder || this.buttonData.textureColor ? this.buttonData.fontSize : fontsizes.popupButtonsText,
          fontFace: this.buttonData.buttonWithIcon ? FONT_REGULAR : FONT_BOLD,
          textColor: Colors('primaryFontColor1').get(),
        }
      },
      Icon: {
        x: this.buttonData.iconX,
        y: this.buttonData.iconY,
        w: this.buttonData.iconW,
        h: this.buttonData.iconH,
        alpha: this.buttonData.buttonWithIcon ? 1 : 0,
        src: Utils.asset('static_assets/Angle_Right.png')
      }
    })
  }

  _focus() {
    this.patch({
      texture: this.buttonData.highlightOnlyBorder
        ? Lightning.Tools.getRoundRect(this.w, this.h, 4, 2, Colors('focusedStrokeColor').get(), false)
        : Lightning.Tools.getRoundRect(this.w, this.h, 4, 2, Colors('focusedStrokeColor').get(), true, Colors('focusedBtnColor').get()),
      TextLabel: {
        text: { textColor: Colors('primaryFontColor2').get()}
      }
    })
  }

  _unfocus() {
    const buttonColor = this.buttonData.textureColor || Colors('strokeColor').get()
    this.patch({
      texture: this.buttonData.layout == 'border'
          ? Lightning.Tools.getRoundRect(this.w, this.h, 4, 2, buttonColor, false)
          : Lightning.Tools.getRoundRect(this.w, this.h, 4, 2, Colors('strokeColor').get(), false),
      TextLabel: {
        text: { textColor: Colors('primaryFontColor1').get() }
      }
    })
  }
}
