import { Lightning, Router, Utils, Colors } from '@lightningjs/sdk'
import App from '../App.js'
import Button from '../Components/Button.js'

export default class Dialog extends Lightning.Component {
    static _template() {
        return {
            zIndex: 11, w: App.width, h: App.height, rect: true, color: Colors('overlayColor').get(),
            Background: {
              x: (App.width - 550) / 2, y: (App.height - 660) / 2, texture: Lightning.Tools.getRoundRect(550, 660, 1, 2, Colors('brandPrimaryColor').get(), true, Colors('backgroundColor').get()),
            },
            PopUpDetails: {
              x: (App.width - 550) / 2, y: ((App.height - 500) / 2) - 40, w: 550, h: 500, flex: { direction: 'column', justifyContent: 'flex-start', alignItems: 'center' },
              Header: { flexItem: { marginBottom: 20 }, text: { fontSize: 36, fontFace: 'Regular', wordWrapWidth: 400, maxLines: 1, maxLinesSuffix: '...' } },
              Message: { flexItem: { marginBottom: 40 }, color: Colors('secondaryFontColor').get(),
                text: { wordWrapWidth: 500, fontSize: 28, lineHeight: 40, textAlign: 'center', fontFace: 'Regular', maxLines: 3, maxLinesSuffix: '...' }
              },
              AlertIcon: { flexItem: { marginBottom: 40 }, w: 120, h: 120, src: Utils.asset('static_assets/alert_icon.png')},
            },
            Buttons: { }
        }
    }

    _init() {
      this.buttonIndex = 0
      this.transition('alpha').on('finish', () => {
        if (this.alpha === 0) {
          this.visible = false
        }
      })
    }

    _handleKey() {
        return true
    }

    _handleEnter() {
        const current = this.buttons[this.buttonIndex]
        !!(current?.action?.call && current.action.apply) ? current.action.call() : this.application.closeApp()
    }

    open({ header = '', message = '', actions = [], dimensions = {} }) {
      dimensions.width && dimensions.height
        ? this.patch({
          Background: {
            x: (App.width - dimensions.width) / 2,
            y: (App.height - dimensions.height) / 2,
            texture: Lightning.Tools.getRoundRect(dimensions.width, dimensions.height, 1, 2, Colors('brandPrimaryColor').get(), true, Colors('backgroundColor').get())
          },
          PopUpDetails: {
            y: (App.height - dimensions.height) / 2 + 30,
            Header: { text: { wordWrapWidth: 500 } },
            Message: { text: { maxLines: 5 } }
          }
        })
        : this.patch({
          Background: {
            x: (App.width - 550) / 2,
            y: (App.height - 660) / 2,
            texture: Lightning.Tools.getRoundRect(550, 660, 1, 2, Colors('brandPrimaryColor').get(), true, Colors('backgroundColor').get())
          },
          PopUpDetails: {
            y: (App.height - 500) / 2 - 40,
            Header: { text: { wordWrapWidth: 400 } },
            Message: { text: { maxLines: 3 } }
          }
        })
        if (actions.length === 0) {
          actions.push({
            label: 'Back',
            action: () => { this.close() }
          })
        }
        this.patch({
          PopUpDetails: {
            Header: { text: { text: header } },
            Message: { text: { text: message } }
          }
        })
        this.tag('Buttons').children = actions.map((button, index) => {
            const yPosition = dimensions.width && dimensions.height ? 700 : 660
            button.x = (App.width - 500) / 2
            button.y = index == 0 ? yPosition : yPosition + 100
            button.w = 500
            button.h = 80
            button.title = button.label
            button.radius = 10
            button.layout = 'border'
            return { type: Button, button }
        })
        this.visible = true
        this.setSmooth('alpha', 1)
        this.buttons = actions
        this.buttonIndex = 0
        Router.focusWidget('Dialog')
    }

    close() {
        this.setSmooth('alpha', 0)
        Router.focusPage()
    }

    _handleDown() {
      if (this.buttonIndex == 0 && this.buttons.length - 1 > this.buttonIndex)
        this.buttonIndex++
    }

    _handleUp() {
      if (this.buttonIndex == 1) this.buttonIndex--
    }

    _getFocused() {
        return this.tag('Buttons').children[this.buttonIndex]
    }
}
