import { Language, Lightning, Router, Colors } from '@lightningjs/sdk'
import { FONT_REGULAR } from '../Helper/GlobalConstants'
import { editDeviceName } from '../Services/PersonaService'
import { LandscapeKeyboard } from '../Components'
import App from '../App'

export default class EditNamePage extends Lightning.Component {
  static _template() {
    return {
      Background: {
        rect: true,
        color: Colors('backgroundColor').get(),
        w: App.width,
        h: App.height
      },
      Search: {
        x: 80,
        y: 100,
        texture: Lightning.Tools.getRoundRect(1760, 70, 0, 3, Colors('brandPrimaryColor').get(), false)
      },
      SearchText: {
        x: 100,
        y: 110,
        text: {
          textColor: Colors('secondaryFontColor').get(),
          fontSize: 38,
          fontFace: FONT_REGULAR,
        },
      },
      LandscapeKeyboard: { type: LandscapeKeyboard }
    }
  }

  _init() {
    this.tag('LandscapeKeyboard').alpha = 1
    this.tag('SearchText').patch({
      text: { text: Language.translate('device_management.enter_dev_name.label')}
    })
  }

  set params(data) {
    this.deviceInfo = data
    return this.tag('LandscapeKeyboard')
  }

  _getFocused() {
    return this.tag('LandscapeKeyboard')
  }

  actionControls() {
    this._handleRight()
  }

  onKeyboardInputUpdate(value, userAction) {
    this.tag('LandscapeKeyboard').alpha = 1
    this.updatedName = value
    const color = this.updatedName == '' ? Colors('secondaryFontColor').get() : Colors('primaryFontColor1').get()
    const text = this.updatedName || Language.translate('device_management.enter_dev_name.label')
    this.tag('SearchText').patch({
      text: { text: text, textColor: color, fontFace: FONT_REGULAR, fontSize: 38 },
    })

    if (userAction == 'done') {
      this._handleEnter()
    }
  }

  onkeyboardExitLeft() { return }

  onkeyboardExitUp() { return }

  _handleRight() {
    this.tag('LandscapeKeyboard')._handleRight()
  }

  async _handleEnter() {
    const deviceInfoObj = {
      updatedName: this.updatedName,
      deviceId: this.deviceInfo.deviceId,
      deviceType: this.deviceInfo.deviceType
    }
    await editDeviceName(deviceInfoObj)
    Router.navigate(`managedevices/${this.deviceInfo.checkScenario}`)
  }

  clearSearchKeyboard() {
    this.tag('SearchText').patch({
      text: { text: Language.translate('device_management.enter_dev_name.label'), textColor: Colors('secondaryFontColor').get(), fontFace: FONT_REGULAR, fontSize: 38 },
    })
    this.updatedName = ''
    this.tag('LandscapeKeyboard').resetKeyboard()
  }

  _handleBack() {
    this.clearSearchKeyboard()
    Router.navigate(`managedevices/${this.deviceInfo.checkScenario}`)
  }
}
