import { Router, Storage } from "@lightningjs/sdk";
import { userSettingsAPI, fetchToken, baseURL, platformSettingsAPI, metroLogin } from "../Services/AuthService";
import { getAdvertisingInformation, passFireboltAPIData, processTranslationFile } from "../Helper/Helpers";
import { PlatformSettingResponse } from "../Models/PlatformSettingModel";
import ContentDetailController from "../Controllers/ContentDetailController";
import HomeController from "../Controllers/HomeController";
import SearchController from "../Controllers/SearchController";
import SplashController from "../Controllers/SplashController";
import LoginController from '../Controllers/LoginController'
import AuthenticateController from "../Controllers/AuthenticateController";
import LoginOptionController from "../Controllers/LoginOptionController";
import configurations from "../Helper/Configurations";
import CategoryController from "../Controllers/CategoryController";
import ViewAllController from "../Controllers/ViewAllController";
import SignUpController from "../Controllers/SignUpController";
import ForgotController from "../Controllers/ForgotController";
import VerificationController from "../Controllers/VerificationController";
import SettingsController from "../Controllers/SettingsController";
import TVguideController from "../Controllers/TVguideController";
import ManageDevicesController from "../Controllers/ManageDevicesController";
import EditNameController from "../Controllers/EditNameController";
import HlsVodPlayerController from "../Controllers/HlsVodPlayerController";
import DashVodPlayerController from "../Controllers/DashVodPlayerController";
import HlsLiveController from "../Controllers/HlsLiveController";
import DashLiveController from "../Controllers/DashLiveController";
import WishlistController from "../Controllers/WishlistController";
import ProfileController from "../Controllers/ProfileController";
import ManageProfilesController from "../Controllers/ManageProfilesController";
import DeleteProfileController from "../Controllers/DeleteProfileController";
import SVODController from '../Controllers/SVODController'



/**
 * Configuration for the application's routes.
 */
const routes = [
  HomeController,
  AuthenticateController,
  LoginController,
  LoginOptionController,
  SearchController,
  ContentDetailController,
  SplashController,
  CategoryController,
  ViewAllController,
  SignUpController,
  ForgotController,
  VerificationController,
  SettingsController,
  TVguideController,
  ManageDevicesController,
  EditNameController,
  HlsVodPlayerController,
  DashVodPlayerController,
  HlsLiveController,
  DashLiveController,
  WishlistController,
  ProfileController,
  ManageProfilesController,
  DeleteProfileController,
  SVODController
];

/**
 * Application configuration object.
 */
export default {
  /**
   * Asynchronous bootstrapping function to perform initial setup.
   */
  boot: async () => {
    try {
      // Perform asynchronous tasks during application bootstrapping
      await baseURL()
      if (configurations.enableFirebolt)  passFireboltAPIData('lifecycleready')
      new PlatformSettingResponse(await platformSettingsAPI())
      await processTranslationFile(configurations.locales, 'initial')
      await userSettingsAPI()
      if (!Storage.get('userAccessToken') && !Storage.get('guestAccessToken')) {
        configurations.authenticateWithEntitlement ? await metroLogin() : await fetchToken()
      }
      Storage.remove('stateInfo')
      Storage.remove('fromEpisodePanel')
      Storage.remove('enteredEpisodeInfo')
      Storage.remove('enteredProfileIndex')
      if (configurations.enableCMP) await getAdvertisingInformation()
      Router.resume()
    } catch (error) {
      // Handle errors during bootstrapping, log, or perform fallback actions
      console.error('Error during application bootstrapping:', error)
    }
  },

  /**
   * Determining the root path of our application based on login value.
   */
  root: () => {
    return new Promise((resolve) => {
      if (!configurations.loginMode || Storage.get('loggedIn')) {
        configurations.enableProfiles && configurations.loggedIn ? resolve('profile/add') : resolve(routes[0].path)
      } else {
        switch (configurations.supportedLogins) {
          case 'qr':
            // AuthenticateController
            resolve(routes[1].path)
            break
          case 'credentials':
            // Login Controller
            resolve(routes[2].path)
            break
          case 'qr,credentials':
            // LoginOption Controller
            resolve(routes[3].path)
            break
          default:
            break
        }
      }
    })
  },

  /**
   * List of route controllers that define the application's navigation.
   */
  routes,
  beforeEachRoute: (prev, current) => {
    Storage.set('prevRoute', prev)
    Storage.set('currentRoute', current?._hash)
    return true
  }
};

