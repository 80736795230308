import { Lightning, Router, Utils } from '@lightningjs/sdk'
import App from '../App'

export default class Loader extends Lightning.Component {
  static _template() {
    return {
      zIndex: 11,
    }
  }

  _setup() {
    this._loader = this.animation({
      duration: 1,
      repeat: -1,
      stopMethod: 'immediate',
      actions: [{ p: 'rotation', v: { 0: { v: 0, sm: 0 }, 1: { v: 2 * Math.PI, sm: 0 } } }],
    })
  }

  _active() {
    const loaderDimensions = !Router.getActiveHash() || Router.getActiveHash().includes('profile/add') ? 60 : 140
    const yPos = !Router.getActiveHash() || Router.getActiveHash().includes('profile/add') ? 900 : (App.height - 140) / 2
    const xPos = (App.width - loaderDimensions) / 2
    const source = Utils.asset(!Router.getActiveHash() || Router.getActiveHash().includes('profile/add') ? 'static_assets/splash_loader.png' : 'static_assets/spinner.png')
    this.patch({
      x: xPos, y: yPos,
      src: source,
      w: loaderDimensions,
      h: loaderDimensions,
    })
    this._loader.start()
  }

  _inactive() {
    this._loader.stop()
  }

  _handleBack() {
    return false
  }

  _handleKey() {
    //block all the key events other than BACK
  }
}
