
import { Router, Storage, Language } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { Page, Banner, Detail } from '../Components'
import {
  CONTINUE, WISHLIST, BANNER_WIDGET,
  IS_CONTINUEWATCH_COLLECTION_UPDATED,
  IS_WISHLIST_COLLECTION_UPDATED,
  UPDATE_CONTINUE_WATCHING_COLLECTION,
  UPDATE_WISHLIST_COLLECTION
} from '../Helper/GlobalConstants'
import { createPageComponents } from '../Helper/Helpers'
import configurations from '../Helper/Configurations'
import Loader from '../Components/Loader'
import EmptyPage from '../Components/EmptyPage'

export default class Category extends Page {
    static _template() {
        return {
          VideoSlider: {
            x: 110,
            type: List,
            w: (w) => w,
            y: 660,
            h: 500,
            direction: 'column',
            scroll: 0,
            scrollTransition: { duration: 0.4 },
            signals: { onIndexChanged: true },
          },
          Banner: { type: Banner },
          Detail: { type: Detail, visible: false },
          Loading: { type: Loader, visible: false },
          EmptyPage: { type: EmptyPage, visible: false }
        }
    }

    setListData(categoryList, data, categoryVideos) {
      // category completedata list is stored
      this.categoryListData = categoryList
      this.categoryAPIData = data
      this.initialCarousels = categoryVideos
    }

    onIndexChanged(indexData) {
      const { dataLength, index: currentIndex } = indexData
      /* whenever the currentIndex is 2 less than
      VideoSlider Length, do pagination */
      if (currentIndex === dataLength - 2) {
        this.paginateList(false, indexData)
      }
    }

    //helps to paginate VideoSlider
    paginateList() {
      if (this.categoryListData) {
        const nextArray = this.categoryListData.slice(this.tag('VideoSlider').items.length, this.tag('VideoSlider').items.length + 4)

        //will return the carousel to be added
        const content = createPageComponents(nextArray)

        this.tag('VideoSlider').addAt(content, this.tag('VideoSlider').items.length)
        // this.updatePersonaCarousels() //commented becauses max call stack exceeded error is throwing
        this._refocus()
      }
    }

    pageTransition(pageIn, pageOut) {
        const menu = pageIn.widgets.menu
        if(menu.alpha !== 1) {
            menu.visible = false
            menu.alpha = 0.001
            menu.menuCollapse()
            menu.setSmooth('alpha', 1, {delay: 0.2, duration: 0.2})
        }
        return super.pageTransition(pageIn, pageOut)
    }

    _getFocused() {
      return this.bannerVideos?.items?.length > 0 && (!this.stateInfo || this.stateInfo.banner) ? this.focusBanner() : this.focusCarousel()
    }


    _handleUp() {
      if (this.slider.index == 0 && this.bannerVideos?.items.length > 0) {
        this.tag('Detail').visible = false
        this.focusBanner()
      }
    }

    addLists(array) {
      // this shifts focus from menu to page
      Router.focusPage()
      this.bannerVideos = array.find(obj => obj.component === BANNER_WIDGET)
      this.categoryDataWithoutBanner = array.filter(obj => obj.component !== BANNER_WIDGET)
      if (this.categoryDataWithoutBanner.length === 0) return this.showEmptyPage()
      if (this.bannerVideos?.items?.length > 0) {
        this.tag('Banner').data = this.bannerVideos
      }
      const content = this.tag('VideoSlider')
      content.items.length === 0 && content.add(this.categoryDataWithoutBanner)
      this.updatePersonaCarousels()
      this.stateInfo = JSON.parse(Storage.get('stateInfo'))
      this.bannerVideos?.items?.length > 0 && (!this.stateInfo || this.stateInfo.banner) ? this.focusBanner() : this.focusCarousel()
    }

    showEmptyPage() {
      this.slider.clear()
      this.tag('Banner').visible = false
      this.tag('Detail').visible = false
      this.tag('EmptyPage').visible = true
      this.tag('EmptyPage').data = {
        Message: Language.translate('common_keys.no_content_found.error'),
        Image: 'globe.png'
      }
      return this._setState('EmptyPage')
    }

    get slider() {
      return this.tag('VideoSlider')
    }

    _handleLeft() {
      Router.focusWidget('Menu')
    }

    _handleBack() {
      Router.focusWidget('Menu')
    }

    focusCarousel() {
      this.tag('Detail').visible = true
      this.tag('Banner').patch({ visible: false, alpha: 0 })
      this._setState('VideoSlider')
      return this.tag('VideoSlider')
    }

    focusBanner() {
      this.tag('Detail').visible = false
      this.tag('Banner').patch({ visible: true,  alpha: 1 })
      this._setState('Banner')
      return this.tag('Banner')
    }

    updateBackDropImg(image) {
      this.tag('Detail').updateBackDropImg(image)
    }

    updateDetailWidget(item) {
      this.tag('Detail').item = item
    }

    showDetailWidget() {
      this.tag('Detail').visible = true
    }

    updatePersonaCarousels() {
      // Re-updating the continue-watching & my-list carousels to get latest contents
      if (configurations.loggedIn) {

        const reorganizeCarousel = (collectionKey) => {
          const collectionExists = this.categoryAPIData.some(collection => collection.key == collectionKey.toLowerCase())

          if (collectionExists) {
            const videoSlider = this.tag('VideoSlider')
            const oldCarousel = videoSlider.items.find(collection => collection.items[0].item.collectionSlug === collectionKey)
            const updateExistingCarousel = this.categoryListData.some(obj => obj.title === oldCarousel?.title)
            const newCarousel = createPageComponents(this.categoryListData.filter(collection => collection.key === collectionKey.toLowerCase()))
            const carouselIndexToUpdate = updateExistingCarousel
              ? videoSlider.items.findIndex(collection => collection.items[0].item.collectionSlug === collectionKey)
              : this.categoryListData.findIndex(collection => collection.key === collectionKey.toLowerCase())

            if (carouselIndexToUpdate === -1) {
                if (!oldCarousel) return
                videoSlider.remove(oldCarousel)
                if (videoSlider.index === 0) {
                  videoSlider.clear()
                  return this.addLists(createPageComponents(this.initialCarousels))
                }
                return videoSlider.setIndex(videoSlider.index - 1)
            }

            if (updateExistingCarousel) {
                videoSlider.remove(oldCarousel)
                videoSlider.addAt(newCarousel, carouselIndexToUpdate)
                if (carouselIndexToUpdate <= 1 && videoSlider.index > 1) videoSlider.setIndex(videoSlider.index - 1)
            } else {
                videoSlider.addAt(newCarousel, carouselIndexToUpdate)
                videoSlider.setIndex(0)
            }
          }
        }

        if (IS_CONTINUEWATCH_COLLECTION_UPDATED) reorganizeCarousel(CONTINUE)
        if (IS_WISHLIST_COLLECTION_UPDATED) reorganizeCarousel(WISHLIST)

        UPDATE_CONTINUE_WATCHING_COLLECTION(false)
        UPDATE_WISHLIST_COLLECTION(false)
      }
    }

    static _states() {
      return [
        class VideoSlider extends this {
          _getFocused() {
            return this.tag('VideoSlider')
          }
        },
        class Banner extends this {
          _getFocused() {
            return this.tag('Banner')
          }
        },

        class EmptyPage extends this {
          _getFocused() {
            return this.tag('EmptyPage')
          }
        }
      ]
    }
}
