/**
 * Class representing the response for category data.
 */
export class CategoryResponse {
    /**
     * Creates an instance of CategoryResponse.
     * @param {Object} data - The input object representing the category response.
     */
    constructor(data) {
        this.statusCode = data.status_code;
        this.statusMessage = data.message;
        this.categoryData = data.data ? data.data.map(item => new CategoryDataItem(item)) : [];
    }
}

/**
 * Class representing details for a category data item.
 */
class CategoryDataItem {
    /**
     * Creates an instance of CategoryDataItem.
     * @param {Object} data - The input object representing a category data item.
     */
    constructor(data) {
        this.categoryId = data.category_id || "";
        this.title = data.title || "";
        this.slug = data.slug || "";
        this.iconUnfocused = data.icon_unfocused || "";
        this.iconFocused = data.icon_focused || "";
        this.backgroundLandscape = data.background_landscape || "";
        this.iconUnfocusedResolutions = data.icon_unfocused_resolutions || {};
        this.iconFocusedResolutions = data.icon_focused_resolutions || {};
        this.backgroundLandscapeResolutions = data.background_landscape_resolutions || {};
        this.titleEn = data.title_en || "";
    }
}
