import { Language, Lightning, Router, Colors } from '@lightningjs/sdk'
import App from '../App'
import { Button } from '.'
import configurations from '../Helper/Configurations'
import { createWebSocketConnection, replacePlaceholdersInTranslations, showConcurrentStreamingPopUp } from '../Helper/Helpers'
import { DASH_PLAYER, HLS_PLAYER, UPDATE_CONTINUE_WATCHING_COLLECTION } from '../Helper/GlobalConstants'


export default class TVODSidePanel extends Lightning.Component {
  static _template() {
    return {
      zIndex: 8,
      Gradient: {
        w: 1200,
        h: App.height,
        rect: true,
        color: Colors('overlayColor').get()
      },
      Background: {
        x: 1200,
        y: 0,
        w: 720,
        h: App.height,
        rect: true,
        color: Colors('backgroundColor').get(),
      },
      StartWatching: {
        x: 1250,
        y: 300,
        text: {
          fontFace: 'Bold', fontSize: 60,
          maxLines: 1, maxLinesSuffix: '...',
          wordWrapWidth: 600,
        },
      },
      ContentContainer: {
        x: 1270,
        y: 420,
        flex: { direction: 'column' },
        RentDesc: {
          flex: { direction: 'row' },
          Dot: {
            y: 10,
            texture: Lightning.Tools.getRoundRect(10, 10, 5, 0, Colors('primaryFontColor1').get(), Colors('primaryFontColor1').get()),
            flexItem: { marginRight: 20 }
          },
          Description: {
            text: {
              fontFace: 'Regular', fontSize: 24,
              maxLines: 2, maxLinesSuffix: '...',
              wordWrapWidth: 550, lineHeight: 36
            }
          },
          flexItem: { marginBottom: 10 }
        },
        WatchDesc: {
          flex: { direction: 'row' },
          Dot2: {
            y: 10,
            texture: Lightning.Tools.getRoundRect(10, 10, 5, 0, Colors('primaryFontColor1').get(), Colors('primaryFontColor1').get()),
            flexItem: { marginRight: 20 }
          },
          Description2: {
            text: {
              fontFace: 'Regular', fontSize: 24,
              maxLines: 2, maxLinesSuffix: '...',
              wordWrapWidth: 500, lineHeight: 36
            }
          }
        }
      },
      ButtonsList: { }
    }
  }

  _init() {
    this.buttonIndex = 0
    this.transition('alpha').on('finish', () => {
      if (this.alpha === 0) {
        this.visible = false
      }
    })
  }

  set data(data) {
    this.clearSidePanel()
    this.videoData = data[0]
    this.contentMetaData = data[1]
    this.enteredIndex = data[2]
    this.buttonIndex = 0
    const stringsToReplace = [this.videoData.rent.expiry + 'hrs']
    const rentDesc = replacePlaceholdersInTranslations(Language.translate('plans.rented_content_warning_1.text'), stringsToReplace)
    this.patch({
      StartWatching: {
        text: { text: Language.translate('welcome.start_watching.text') }
      },
      ContentContainer: {
        RentDesc: {
          Description: {
            text: { text: rentDesc }
          }
        },
        WatchDesc: {
          Description2: {
            text: { text: Language.translate('plans.rented_content_warning_2.text') }
          }
        }
      }
    })
    const buttons = [
      {
        x: 1250,
        y: 640,
        w: 500,
        h: 52,
        fontSize: 22,
        title: Language.translate('plans.agree_and_start.button'),
        layout: 'border',
        radius: 10,
        textureColor: Colors('brandPrimaryColor').get(),
      },
      {
        x: 1250,
        y: 720,
        w: 500,
        h: 52,
        fontSize: 22,
        title: Language.translate('plans.watch_later.button'),
        layout: 'border',
        radius: 10,
        textureColor: Colors('brandPrimaryColor').get(),
      },
    ]
    this.tag('ButtonsList').children = buttons.map(button => { return { type: Button, button } })
    this.visible = true
    this.setSmooth('alpha', 1)
  }

  _handleEnter() {
    this.buttonIndex === 0 ? this.navigateToPlayer() : this.closeOverlay()
  }

  clearSidePanel() {
    Router.getActivePage().widgets.backgroundsidepanel.data = { command: 'clear' }
    Router.getActivePage().widgets.sidepanelnavigation.data = { component: 'clear' }
    Router.getActivePage().widgets.episodelisting.clearEpisodes()
    Router.getActivePage().widgets.detailpageaudiosubtitles.alpha = 0
    Router.getActivePage().widgets.moreinfosidepanel.alpha = 0
  }

  navigateToPlayer() {
    const videoData = this.videoData
    if (videoData.askAccessPin || videoData.ask_access_pin) {
      return Router.getActivePage().showPinControlPopUp([this.contentMetaData, videoData, this.buttonIndex])
    }
    if (videoData.is_livechannel) {
      videoData.contentType = 'livechannel'
      videoData.action = 'live'
      videoData.playbackUrl = videoData.playback_url
      videoData.videoId = videoData.channel_id
    } else {
      videoData.contentType = 'video'
      videoData.action = videoData.seek > 0 && this.enteredIndex === 0 ? 'continue' : 'start'
      videoData.videoId = videoData.contentId
      UPDATE_CONTINUE_WATCHING_COLLECTION(true)
    }

    const routePrefix = configurations.enableHlsStream ? HLS_PLAYER : DASH_PLAYER
    const routeParams = [videoData.action, videoData.videoId].join('/')
    if (videoData.availability === 6) return showConcurrentStreamingPopUp()
    if (configurations.concurrentStreamingEnabled) createWebSocketConnection(videoData.videoId)
    Router.navigate(routePrefix + '/' + routeParams, videoData)
  }

  closeOverlay() {
    this.visible = false
    this.setSmooth('alpha', 0)
    Router.getActivePage().focusButtons()
  }

  _handleBack() {
    this.closeOverlay()
  }

  _handleDown() {
    if (this.buttonIndex === 0) {
      this.buttonIndex++
    }
  }

  _handleUp() {
    if (this.buttonIndex === 1) {
      this.buttonIndex--
    }
  }

  _getFocused() {
    return this.tag('ButtonsList').children[this.buttonIndex]
  }

  _handleKey() { return }

  _handleLeft() { return }

  _handleRight() { return }
}
