import { Language, Lightning, Router, Colors } from '@lightningjs/sdk'
import App from '../../App.js'
import configurations from '../../Helper/Configurations.js'
import { formatHoursInDays, generateQRCode, isEmptyObject, replacePlaceholdersInTranslations } from '../../Helper/Helpers'
import { fontsizes } from '../../Helper/GlobalConstants.js'
import { InlineContent } from '@lightningjs/ui-components'

export default class TVODOverlay extends Lightning.Component {
  static _template() {
    return {
      TVODOverlay: {
        visible: false, alpha: 0,
        w: App.width,
        h: App.height,
        Overlay: {
          zIndex: 7,
          w: (w) => w,
          h: (h) => h,
          rect: true,
          color: Colors('overlayColor').get()
        },
        ContentDescription: {
          x: 120, zIndex: 8,
          h: App.height,
          flex: { direction: 'column', justifyContent: 'center' },
          ContentTitle: {
            text: {
              textColor: Colors('primaryFontColor1').get(),
              fontFace: 'Bold', fontSize: 55,
              wordWrapWidth: 700, maxLines: 1, maxLinesSuffix: '...'
            },
            flexItem: { marginBottom: 30 },
          },
          SubscriberPrice: {
            PriceText: {
              x: 10,
              y: 8,
              text: {
                text: Language.translate('plans.subscriber_special_price.tag'),
                fontFace: 'Bold', fontSize: 20,
                textColor: 0xff000000,
                wordWrapWidth: 400,
                maxLines: 1, maxLinesSuffix: '...'
              }
            },
            flexItem: { marginBottom: 10 },
          },
          RentTitle: {
            flex: { direction: 'row' },
            DiscountedRent: {
              flexItem: { marginRight: 10 },
              text: {
                textColor: Colors('primaryFontColor1').get(), fontFace: 'Bold',
                fontSize: 45, wordWrapWidth: 700,
                maxLines: 1, maxLinesSuffix: '...'
              }
            },
            CrossedRentText: {
              text: {
                textColor: 0x80ffffff, fontFace: 'Bold',
                fontSize: 45, wordWrapWidth: 700,
                maxLines: 1, maxLinesSuffix: '...'
              },
              CrossedRent: { x: -5, y: 30 }
            },
            flexItem: { marginBottom: 10 }
          },
          RentDescription: {
            text: {
              textColor: Colors('primaryFontColor1').get(), fontFace: 'Regular',
              fontSize: 24, wordWrapWidth: 700, lineHeight: 36,
              maxLines: 3, maxLinesSuffix: '...'
            },
            flexItem: { marginBottom: 30 }
          },
          SubscriberPrice2: {
            PriceText2: {
              x: 10,
              y: 8,
              text: {
                text: Language.translate('plans.subscriber_special_price.tag'),
                fontFace: 'Bold', fontSize: 20,
                textColor: 0xff000000,
                wordWrapWidth: 400,
                maxLines: 1, maxLinesSuffix: '...'
              }
            },
            flexItem: { marginBottom: 10 },
          },
          BuyTitle: {
            flex: { direction: 'row' },
            DiscountedBuy: {
              flexItem: { marginRight: 10 },
              text: {
                textColor: Colors('primaryFontColor1').get(), fontFace: 'Bold',
                fontSize: 45, wordWrapWidth: 700,
                maxLines: 1, maxLinesSuffix: '...'
              }
            },
            CrossedBuyText: {
              text: {
                textColor: 0x80ffffff, fontFace: 'Bold',
                fontSize: 45, wordWrapWidth: 700,
                maxLines: 1, maxLinesSuffix: '...'
              },
              CrossedBuy: { x: -5, y: 30 }
            },
            flexItem: { marginBottom: 10 }
          },
          BuyDescription: {
            text: {
              textColor: Colors('primaryFontColor1').get(), fontFace: 'Regular',
              fontSize: 24, wordWrapWidth: 700, lineHeight: 36,
              maxLines: 3, maxLinesSuffix: '...'
            },
            flexItem: { marginBottom: 20 }
          },
          SubscribeNow: {
            text: {
              textColor: 0xffffda23, fontFace: 'Bold', fontSize: 24, wordWrapWidth: 800, maxLines: 1, maxLinesSuffix: '...'
            },
          }
        },
        Divider: {
          zIndex: 8,
          x: (App.width - 2) / 2,
          y: (App.height - 700) / 2,
          texture: Lightning.Tools.getRoundRect(2, 700, 1, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
        },
        QRcodeDescription: {
          x: 1150,
          zIndex: 8,
          h: App.height,
          flex: { direction: 'column', alignItems: 'center', justifyContent: 'center' },
          ScanQRCode: {
            flexItem: { marginBottom: 30 },
            text: {
              textColor: Colors('primaryFontColor1').get(),
              fontSize: fontsizes.authenticatePageQRText,
              fontFace: 'Regular',
              lineHeight: 48,
              maxLines: 2,
              wordWrapWidth: 650,
              textAlign: 'left'
            },
          },
          QRCodeImage: {
            h: 357,
            w: 357,
            flexItem: { marginBottom: 40 }
          },
          ORText: {
            flexItem: { marginBottom: 30 },
            text: {
              color: Colors('primaryFontColor1').get(),
              fontSize: fontsizes.authenticatePageQRText,
              fontFace: 'Regular',
              lineHeight: 48,
              maxLines: 2,
              wordWrapWidth: 650,
            },
          },
          URLDescription: {
            x: -45,
            flex: { direction: 'column' },
            Child1: {
              type: InlineContent,
              justify: 'flex-start',
              content: [],
              customStyleMappings: {
                textStyle: {
                  textColor: Colors('primaryFontColor1').get(), fontFace: 'Regular',
                  fontSize: 32, maxLines: 3, maxLinesSuffix: '...', textAlign: 'left'
                }
              },
              flexItem: { marginBottom: 10 }
            },
            Child2: {
              text: {
                textColor: Colors('primaryFontColor1').get(), fontFace: 'Regular',
                fontSize: 32, maxLines: 2, wordWrapWidth: 700, maxLinesSuffix: '...', textAlign: 'left'
              }
            }
          }
        },
      }
    }
  }

  _init() {
    this.tag('PriceText').on('txLoaded', () => {
      const SubscriberPriceText = this.tag('PriceText')
      const rectTotalWidth = SubscriberPriceText.renderWidth + 15
      const rectTotalHeight = SubscriberPriceText.renderHeight + 12

      this.tag('SubscriberPrice').patch({
        texture: Lightning.Tools.getRoundRect(rectTotalWidth, rectTotalHeight, 20, 2, 0xffffda23, true, 0xffB29401)
      })
    })

    this.tag('PriceText2').on('txLoaded', () => {
      const SubscriberPriceText = this.tag('PriceText2')
      const rectTotalWidth = SubscriberPriceText.renderWidth + 15
      const rectTotalHeight = SubscriberPriceText.renderHeight + 12

      this.tag('SubscriberPrice2').patch({
        texture: Lightning.Tools.getRoundRect(rectTotalWidth, rectTotalHeight, 20, 2, 0xffffda23, true, 0xffB29401)
      })
    })

    this.tag('CrossedRentText').on('txLoaded', () => {
      const crossedRentText = this.tag('CrossedRentText')
      const rectTotalWidth = crossedRentText.renderWidth + 15

      this.tag('CrossedRent').patch({
        texture: Lightning.Tools.getRoundRect(rectTotalWidth, 2, 1, 0, 0x80ffffff, true, 0x80ffffff)
      })
    })

    this.tag('CrossedBuyText').on('txLoaded', () => {
      const crossedBuyText = this.tag('CrossedBuyText')
      const rectTotalWidth = crossedBuyText.renderWidth + 15

      this.tag('CrossedBuy').patch({
        texture: Lightning.Tools.getRoundRect(rectTotalWidth, 2, 1, 0, 0x80ffffff, true, 0x80ffffff)
      })
    })

    this.transition('alpha').on('finish', () => {
      if (this.alpha === 0) {
        this.visible = false
      }
    })
  }

  openTVODOverlay(channelData) {
    const isRentObjectExists = !isEmptyObject(channelData.rent)
    const isBuyObjectExists = !isEmptyObject(channelData.buy)
    const currentRegion = configurations.regions.find(region => region.region_code === configurations.region)
    const rentAmount = configurations.userType ? channelData.rent.sub_amount : channelData.rent.amount
    const buyAmount = configurations.userType ? channelData.buy.sub_amount : channelData.buy.amount
    const rentAmountWithCurrency = currentRegion.prefix_currency ? currentRegion.prefix_currency + rentAmount : rentAmount + currentRegion.suffix_currency
    const buyAmountWithCurrency = currentRegion.prefix_currency ? currentRegion.prefix_currency + buyAmount : buyAmount + currentRegion.suffix_currency
    const crossedRentAmount = `${currentRegion.prefix_currency || ''}${channelData.rent.amount}${currentRegion.suffix_currency || ''}`
    const crossedBuyAmount = `${currentRegion.prefix_currency || ''}${channelData.buy.amount}${currentRegion.suffix_currency || ''}`
    const buySubAmount = `${currentRegion.prefix_currency || ''}${channelData.buy.sub_amount}${currentRegion.suffix_currency || ''}`
    const rentSubAmount = `${currentRegion.prefix_currency || ''}${channelData.rent.sub_amount}${currentRegion.suffix_currency || ''}`
    const rentAmountToShow = channelData.rent.sub_amount < channelData.rent.amount ? rentSubAmount : rentAmountWithCurrency
    const buyAmountToShow = channelData.buy.sub_amount < channelData.buy.amount ? buySubAmount : buyAmountWithCurrency
    const days = formatHoursInDays(channelData.rent.expiry)
    const qrCodeImageURL = configurations.angularSiteURL + `${'live'}/${channelData.slug || channelData.content_slug}`
    const qrcodeImage = generateQRCode(qrCodeImageURL)
    const stringsArrayToReplace = [days, channelData.rent.duration + ' ' + 'hours']
    const rentDescription = replacePlaceholdersInTranslations(Language.translate('plans.tvod_conditions_1.text'), stringsArrayToReplace)
    this.visible = true
    this.setSmooth('alpha', 1)
    Router.getActivePage().widgets.backgroundsidepanel.data = { command: 'clear' }
    Router.getActivePage().widgets.sidepanelnavigation.data = { component: 'clear' }
    Router.getActivePage().widgets.episodelisting.clearEpisodes()
    Router.getActivePage().widgets.moreinfosidepanel.alpha = 0

    this.patch({
      TVODOverlay: {
        visible: true, alpha: 1,
        ContentDescription: {
          ContentTitle: {
            text: { text: channelData.title },
          },
          SubscriberPrice: {
            visible: (configurations.userType && isRentObjectExists)
          },
          RentTitle: {
            visible: isRentObjectExists,
            DiscountedRent: { text: { text: Language.translate('plans.rent_for_only.text') + ' ' + rentAmountToShow } },
            CrossedRentText: {
              visible: channelData.rent.amount !== channelData.rent.sub_amount,
              text: { text: crossedRentAmount }
            }
          },
          RentDescription: {
            visible: isRentObjectExists,
            text: { text: rentDescription }
          },
          SubscriberPrice2: {
            visible: (configurations.userType && isBuyObjectExists)
          },
          BuyTitle: {
            visible: isBuyObjectExists,
            DiscountedBuy: { text: { text: Language.translate('plans.buy_for_only.text') + ' ' + buyAmountToShow } },
            CrossedBuyText: {
              visible: channelData.buy.amount !== channelData.buy.sub_amount,
              text: { text: crossedBuyAmount }
            }
          },
          BuyDescription: {
            visible: isBuyObjectExists,
            text: { text: Language.translate('plans.tvod_conditions_2.text') },
          },
          SubscribeNow: {
            visible: this.returnTruthiness(channelData),
            text: { text: Language.translate('plans.subscribe_for_discount_tv.text') + ' ' + rentSubAmount }
          }
        },
        QRcodeDescription: {
          ScanQRCode: { text: { text: Language.translate('plans.scan_qr.text') } },
          QRCodeImage: { src: qrcodeImage },
          ORText: { text: { text: Language.translate('common_keys.or.text') } },
          URLDescription: {
            Child1: {
              content: [
                {
                  text: Language.translate('plans.visit_url_on_browser_part1.text'),
                  style: 'textStyle'
                },
                {
                  text: configurations.angularSiteURL,
                  style: {
                    textColor: Colors('tvodLabelColor').get(), fontFace: 'Regular',
                    fontSize: 32, maxLines: 3, wordWrapWidth: 100, textAlign: 'left'
                  }
                }
              ]
            },
            Child2: { text: { text: Language.translate('plans.visit_url_on_browser_part2.text') } }
          },
        },
      }
    })
  }

  returnTruthiness(channelData) {
    return !configurations.userType && channelData.rent.amount !== channelData.rent.sub_amount
  }

  closeTVODOverlay() {
    this.visible = false
    this.setSmooth('alpha', 0)
    Router.getActivePage().focusComponent('TVODButton')
  }

  _getFocused() {
    Router.getActivePage().focusComponent('back')
  }
}
