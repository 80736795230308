import ManageProfiles from '../Views/ManageProfiles'
/**
 * Defines the configuration for the 'home' page.
 */
export default {
    path: 'manageprofiles/:profileId',
    component: ManageProfiles,
    /**
     * Defines the asynchronous setup for the 'home' page.
     * @param {Object} page - The page instance.
     */

    on: async (page, params) => {
      return page.data = params.profileId
    },
    widgets: ['Back'],
}
