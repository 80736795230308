import { Lightning, Storage, Router, Language, Colors } from '@lightningjs/sdk'
import configurations from '../Helper/Configurations'
import { getEpgScheduleList } from '../Services/AssetsService'
import { ChannelsList } from '../Components/index.js'
import { ProgramsList } from '../Components/index.js'
import Loader from '../Components/Loader.js'

export default class TVguide extends Lightning.Component {
    static _template() {
        return {
            Background: {
                type: Lightning.components.FastBlurComponent,
                amount: 3,
                w: 1920,
                h: 1080,
                content: {
                    Texture: {
                        rect: true,
                        color: Colors('backgroundColor').get(),
                        w: 1920,
                        h: 1080,
                    },
                },
                PageTitle: {
                    x: 202, y: 43, alpha: 1, zIndex: 5,
                    text: {
                        text: Language.translate('activate_tv.tv_guide.text'), fontSize: 56, textColor: Colors('primaryFontColor1').get(), fontFace: 'Bold', lineHeight: 84, FontStyle: 'normal'
                    },
                },
                Loading: {
                    visible: false,
                    type: Loader,
                    zIndex: 10,
                },
                ChannelsList: {
                    type: ChannelsList
                },
                ProgramsList: {
                    type: ProgramsList
                }
            },
        }
    }

    get loaderTag() {
        return this.tag('Background.Loading')
    }

    displayLoader() {
        this.loaderTag.visible = true
    }

    hideLoader() {
        this.loaderTag.visible = false
    }

    enableFocusToProgramsList(show) {
        this.isProgramListVisible = show
    }

    focusComponent(Component) {
        switch (Component) {
            case 'channels':
                this._setState('ChannelsList')
                break
            case 'programs':
                this._setState('ProgramsList')
                break
            // case 'metaInfo':
            //     this._setState('MetaInfo')
            //     break
            // case 'back':
            //     this._setState('Back')
            //     break
            // case 'TVODButton':
            //     this._setState('TVODButton')
            //     break
            default:
                break
        }
    }

    patchProgramListData(programsInfo, channelInfo) {
        this.tag('Background.ProgramsList').itemPreparation(programsInfo, channelInfo)
    }

    set data(catData) {
        const feedIds = [];
        let mainJson = [];
        configurations.livechannels = catData[0].list;

        Router.focusPage()
        this.contentId = catData[0].list[0].content_id

        const allChannelArr = catData[0].list
        if (allChannelArr.length === 0) return this.noContentAvailable();

        for (const element of allChannelArr) {
            feedIds.push(element.feed_id);
        }

        this.displayLoader() // show loader
        this.fetchingEpgData(feedIds)
            .then((data) => {
                mainJson = data;
                const epgData = { jsonData: mainJson, channelData: configurations.livechannels };
                this.patchingDataToTemplate(epgData);
                this.hideLoader() // show loader
            })
    }

    async fetchingEpgData(feedIds) {
        const date = new Date()
        const year = date.getFullYear()
        const mon = `${date.getMonth() + 1}`.padStart(2, 0)
        const day = `${date.getDate()}`.padStart(2, 0)
        const newDate = year + '-' + mon + '-' + day
        return await getEpgScheduleList(feedIds, newDate)
    }

    patchingDataToTemplate(v) {
        const channelIndex = v?.channelData.findIndex(channel => {
            return channel.content_id === this.contentId
        })
        if (v.jsonData[0] && !v.jsonData[0].flag) {
            this.tag('Background.ChannelsList').flexItem = { x: 203, y: 130, w: 1710 }
            this.tag('Background.ProgramsList').flexItem = { x: 203, y: 268, w: 1700, h: 810 }
            this.tag('Background.ChannelsList').data = { channelInfo: v.channelData, programInfo: v.jsonData, contentId: this.contentId }
            this.tag('Background.ProgramsList').data = { programInfo: v.jsonData, channelInfo: v.channelData[channelIndex] }
            this.enableFocusToProgramsList(true)
        } else {
            this.tag('Background.ChannelsList').flexItem = { x: 203, y: 130, w: 1710 }
            this.tag('Background.ProgramsList').flexItem = { x: 203, y: 268, w: 1700, h: 810 }
            this.tag('Background.ChannelsList').data = { channelInfo: v.channelData, programInfo: v.jsonData, contentId: this.contentId }
            this.tag('Background.ProgramsList').data = { programInfo: v.jsonData, channelInfo: v.channelData[channelIndex] }

            Storage.set('NofeedAvailable', true)
        }
        this._setState('ChannelsList')
    }

    noContentAvailable() {
        this.widgets.emptypage.data = {
            status: true,
            page: 'homelist',
            Message: Language.translate('error.content_not_found_region.text'),
            Image: 'globe.png',
        }
        Router.focusWidget('EmptyPage')
        this.widgets.emptypage.alpha = 1
        this.widgets.loader.alpha = 0
        this.widgets.epgshows.alpha = 0
        this.widgets.epgchannels.alpha = 0
    }

    _getFocused() {
        return this._setState('ChannelsList')
    }

    static _states() {
        return [
            class ChannelsList extends this {
                _getFocused() {
                    return this.tag('Background.ChannelsList')
                }
                _handleDown() {
                    if (this.isProgramListVisible) {
                        this._setState('ProgramsList')
                    }
                }
                _handleEnter() {
                }
            },
            class ProgramsList extends this {
                _getFocused() {
                    return this.tag('Background.ProgramsList.List')
                }
                _handleDown() {
                }
                _handleUp() {
                    this._setState('ChannelsList')
                }
                _handleEnter() {
                }
            }
        ]
    }

    _inactive() {
        Storage.remove('NofeedAvailable')
    }
}
