import {Lightning} from '@lightningjs/sdk';

export default class PlayerButton extends Lightning.Component{

	static _template(){
		return {
			rect: true, h: 100, w: 160, color: 0xFF202020,
			Image: { mount: 0.5, x: 80, y: 50 }
		};
	}

	set action(v){
		this._action = v;
	}

	get action(){
		return this._action;
	}

	set image(v){
		this.tag('Image').patch({ src: v });
	}

	_focus(){
		this.patch({
			smooth: { color: 0xFF2373BA }
		});
	}

	_unfocus(){
		this.patch({
			smooth: { color: 0xFF202020 }
		});
	}
}