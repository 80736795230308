import { Language, Lightning, Router, Storage, Utils, Colors } from '@lightningjs/sdk'
import IconDynamicButton from '../../Components/ButtonsList/IconDynamicButton'
import { List } from '@lightningjs/ui'
import { fontsizes } from '../../Helper/GlobalConstants'

export default class AudioSidePanel extends Lightning.Component {
  static _template() {
    return {
      zIndex: 6,
      x: 1400,
      y: 0,
      AudioHeader: {
        x: 100,
        y: 60,
        alpha: 0,
        text: {
          textAlign: 'right',
          fontSize: fontsizes.audioSidePanelHeader,
          textColor: Colors('primaryFontColor1').get(),
          lineHeight: 46,
          fontFace: 'Bold',
        },
      },
      AudioList: {
        y: 124,
        x: 100,
        h: 380,
        w: 250,
        clipping: true,
        type: List,
        direction: 'column',
        spacing: 20,
      }
    }
  }

  set audioItems(val) {
    this.audioItemsArray = val
    // audioLabelArray used for setting audio to storage
    this.audioLabelArray = this.audioItemsArray.map((item) => item.label)
    const audioLabelFromStorage = Storage.get('audio-lang-selected')
    // setting index if found in array or tick the firstAudioAvailable(index-0)
    const indexOfLabel = this.audioLabelArray.findIndex((item) => item === audioLabelFromStorage)
    const audioIndexFromStorage = indexOfLabel !== -1 ? indexOfLabel : 0

    this.tag('AudioList').clear()

    //Each buttonItem preparation
    const children = this.audioItemsArray.map((audioItem, index) => {
      const languageJSON = audioItem.langoption
      let isCaptionData = !!languageJSON
      let labelData
      if (isCaptionData) {
        labelData = audioItem.label != Language.translate('player.none.text') && languageJSON[audioItem.label]
          ? Language.translate(languageJSON[audioItem.label]['iso_language_name'].toLowerCase())
          : Language.translate(audioItem.label)
      } else {
        labelData = audioItem.label
      }
      return {
        type: IconDynamicButton,
        h: 46,
        textPadding: 30,
        label: labelData,
        iconImage: audioIndexFromStorage === index ? Utils.asset(`static_assets/tick.png`) : "", // set this to satisfy same way src delivery to iconDynamic-button
        iconWithText: true,
        flexItem: { marginTop: 24, alignSelf: 'stretch', maxHeight: 100 },
        isVerticalBtn: true,
        //isMoreAboutBtn: button.isMoreAboutBtn ? true : false,
        //episodesListing: button.episodesListing ? true : false
      }
    })
    this.tag('AudioList').add(children)
    this._refocus()
    this.tag('AudioList').scroll = {
      after: 6,
      forward: 0.9,
      backward: 0.1,
    }
    this.tag('AudioHeader').patch({
      alpha: 1,
      text: Language.translate('common_keys.audio.text'),
    })

    this.tag('AudioList').setIndex(0)
  }

  set notActive(val) {
    if (val === true) {
      this.patch({ alpha: 0 })
    }
  }

  set isPlayerPage(v) {
    this.isPlayerPageActive = v ? true : false
  }

  _init() {
    this.index = 0
  }

  _disable() {
    this.index = 0
    this.isPlayerPageActive = false
  }

  _getFocused() {
    return this.tag('AudioList')
  }

  _handleDown() {
    const subtitlesArray = Router.getActivePage().widgets.subtitlessidepanel.returnSubtitlesArray()
    if (subtitlesArray.length > 0)
      Router.focusWidget('SubtitlesSidePanel')
  }

  _handleLeft() {
    Router.focusWidget('SidePanelNavigation')
  }

  _handleRight() { return }

  _handleKey() { return }

  _handleBack() {
    Router.focusWidget('SidePanelNavigation')
  }

  _handleEnter() {
    this.index = this.tag("AudioList").index
    //this.isPlayerPageActive = Storage.get("currentRoute").includes("player")

    if (this.isPlayerPageActive) {
      // player page
      // get label equal to this.index
      const selectedAudioLang = this.audioLabelArray[this.index]
      Router.getActivePage().setAudioLanguage(selectedAudioLang || null)
      Router.getActivePage().changeAudioTrack(selectedAudioLang)
      Router.focusWidget('AudioSidePanel')
    } else {
      Router.getActivePage().setAudioLanguage(this.audioLabelArray[this.index])
    }
    // updates the audioSidePanel-ui
    this.updateAudioTag(this.index)
  }

  updateAudioTag(audioIndex) {
    this.tag('AudioList').clear()
    const children = this.audioItemsArray.map((audioItem, index) => {
      const languageJSON = audioItem.langoption
      let isCaptionData = !!languageJSON
      let labelData
      if (isCaptionData) {
        labelData = audioItem.label != Language.translate('player.none.text') && languageJSON[audioItem.label]
          ? Language.translate(languageJSON[audioItem.label]['iso_language_name'].toLowerCase())
          : Language.translate(audioItem.label)
      } else {
        labelData = audioItem.label
      }

      return {
        type: IconDynamicButton,
        h: 46,
        textPadding: 30,
        label: labelData,
        iconImage: audioIndex === index ? Utils.asset(`static_assets/tick.png`) : "", // set this to satisfy same way src delivery to iconDynamic-button
        iconWithText: true,
        flexItem: { marginTop: 24, alignSelf: 'stretch', maxHeight: 100 },
        isVerticalBtn: true,
        //isMoreAboutBtn: button.isMoreAboutBtn ? true : false,
        //episodesListing: button.episodesListing ? true : false
      }
    })
    this.tag('AudioList').add(children)
    this._refocus()

    this.tag('AudioList').setIndex(audioIndex)
  }
}
