import { Language, Lightning, Router, Storage, Colors } from '@lightningjs/sdk'
import { createWebSocketConnection, showConcurrentStreamingPopUp } from '../../Helper/Helpers'
import { verifyParentalPIN } from '../../Services/PersonaService'
import { DASH_PLAYER, FONT_BOLD, HLS_PLAYER, UPDATE_CONTINUE_WATCHING_COLLECTION, fontsizes } from '../../Helper/GlobalConstants'
import { ContentPersonaResponse } from '../../Models/ContentPersonaModel'
import App from '../../App'
import configurations from '../../Helper/Configurations'
import Button from '../Button'
import Loader from '../Loader'
import PasswordCircle from '../Forms/PasswordCircle'

export default class PinControl extends Lightning.Component {
  static _template() {
    return {
      zIndex: 11,
      Blur: {
        color: Colors('overlayColor').get(),
        rect: true,
        w: App.width,
        h: App.height,
        zIndex: -1,
      },
      Background: {
        x: 500,
        y: 180,
        texture: Lightning.Tools.getRoundRect(928, 722, 0, 2, Colors('brandPrimaryColor').get(), true, Colors('backgroundColor').get()),
      },
      Heading: {
        y: 215,
        text: {
          fontFace: FONT_BOLD,
          textColor: Colors('primaryFontColor1').get(),
          fontSize: fontsizes.popUpAlertText,
          textAlign: 'center',
          wordWrap: true,
          wordWrapWidth: 500,
          maxLines: 1,
          maxLinesSuffix: '...'
        },
      },
      SubHeading: {
        x: 550,
        y: 315,
        text: {
          fontFace: FONT_BOLD,
          textColor: Colors('primaryFontColor1').get(),
          fontSize: fontsizes.popupButtonsText,
          wordWrap: true,
          wordWrapWidth: 500,
          lineHeight: 40,
          maxLines: 2,
          maxLinesSuffix: '...'
        },
      },
      ErrorMessage: {
        y: 530,
        alpha: 0,
        text: {
          fontFace: FONT_BOLD,
          textColor: Colors('errorColor').get(),
          fontSize: 20,
          wordWrap: true,
          wordWrapWidth: 600,
          maxLines: 1,
          maxLinesSuffix: '...'
        }
      },
      PinBox: {
        x: 610,
        y: 430,
        zIndex: 1,
        Box1: {
          x: 0,
          texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          Label1: {
            x: 25,
            y: 12,
            text: {
              textColor: Colors('backgroundColor').get(),
              fontFace: FONT_BOLD,
              fontSize: 42,
              lineHeight: 32,
            },
          },
        },
        Box2: {
          x: 86,
          texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          Label2: {
            x: 25,
            y: 12,
            text: {
              textColor: Colors('backgroundColor').get(),
              fontFace: 'bold',
              fontSize: 42,
              lineHeight: 39,
            },
          },
        },
        Box3: {
          x: 172,
          texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          Label3: {
            x: 25,
            y: 12,
            text: {
              textColor: Colors('backgroundColor').get(),
              fontFace: FONT_BOLD,
              fontSize: 42,
              lineHeight: 39,
            },
          },
        },
        Box4: {
          x: 258,
          texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          Label4: {
            x: 25,
            y: 12,
            text: {
              textColor: Colors('backgroundColor').get(),
              fontFace: FONT_BOLD,
              fontSize: 42,
              lineHeight: 39,
            }
          }
        }
      },
      Footer: {
        x: 550,
        y: 580,
        text: {
          fontFace: FONT_BOLD,
          textColor: Colors('secondaryFontColor').get(),
          fontSize: 18,
          wordWrap: true,
          wordWrapWidth: 500,
          lineHeight: 35,
          maxLines: 2,
          maxLinesSuffix: '...'
        },
      },
      ButtonList: { },
      PasswordCircleList: { zIndex: 1 },
      Loading: { visible: false, type: Loader, zIndex: 10, }
    }
  }

  _init() {
    this.buttonIndex = 0
    this.inputValue = ''
    this.transition('alpha').on('finish', () => {
      if (this.alpha === 0) {
        this.visible = false
      }
    })
    this.tag('ErrorMessage').on('txLoaded', () => {
      const width = this.tag('ErrorMessage').renderWidth
      const dynamicXPos = (App.width - width) / 2
      this.tag('ErrorMessage').patch({ x: dynamicXPos - 180 })
    })
}

  _getFocused() {
    return this.tag('ButtonList').children[this.buttonIndex]
  }

  calculateTextWidth() {
    this.tag('Heading').on('txLoaded', () => {
      const width = this.tag('Heading').renderWidth
      const dynamicXPos = (App.width - width) / 2
      this.tag('Heading').patch({ x: dynamicXPos - 5 })
    })
  }

  get loaderTag() {
    return this.tag('Loading')
  }

  displayLoader() {
    this.loaderTag.visible = true
  }

  hideLoader() {
    this.loaderTag.visible = false
  }

  set data(data) {
      this.calculateTextWidth()
      Router.getActivePage().preparePinControlKeyboard()
      this.contentToValidate = data[0]
      this.personaData = data[1]
      this.enteredbuttonIndex = data[2]
      this.inputValue = ''
      this.buttonIndex = 0
      const headingText = this.contentToValidate.is_livechannel || this.contentToValidate.channel_type
        ? this.contentToValidate.access_age_limit
        : this.personaData.accessAgeLimit
      this.patch({
        Heading: {
          text: { text: headingText + ' ' + Language.translate('common_keys.content.text') }
        },
        SubHeading: {
          text: { text: Language.translate('parental_control.enter_pin.text') }
        },
        Footer: {
          text: { text: Language.translate('account.edit_account_info.text') }
        }
      })
      this.buttonsData = [
        {
          x: 550,
          y: 683,
          w: 810,
          h: 63,
          fontSize: 24,
          title: Language.translate('common_keys.confirmed.text'),
          layout: 'border',
          radius: 10,
        },
        {
          x: 550,
          y: 785,
          w: 810,
          h: 63,
          fontSize: 24,
          title: Language.translate('common_keys.back.text'),
          layout: 'border',
          radius: 10,
        },
      ]
      this.tag('ButtonList').children = this.buttonsData.map((button) => {
        return { type: Button, button }
      })
      for (let i = 0; i < 4; i++) {
        this.tag('Label' + (i + 1)).patch({
          text: { text: '', textColor: Colors('backgroundColor').get(), },
        })
        this.tag('Box' + (i + 1)).patch({
          texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
        })
      }
      const mainArray = []
      this.tag('PasswordCircleList').children = mainArray.map((maskedPassword) => {
        return { type: PasswordCircle, maskedPassword }
      })
      this.setSmooth('alpha', 1)
      this.tag('ErrorMessage').patch({ alpha: 0 })
      this.visible = true
  }

  async pinValidation() {
      const isLiveChannel = this.contentToValidate.is_livechannel
      const channelType = this.contentToValidate.channel_type
      const params = {
        access_pin: this.inputValue,
        content_id: isLiveChannel || channelType ? this.contentToValidate.channel_id : this.personaData.contentId,
        content_type: isLiveChannel || channelType ? 'livechannel' : 'video',
        supports_drm: 0,
        streaming_type: configurations.enableHlsStream ? 'hls' : 'dash'
      }
      if (configurations.enableProfiles) {
        params.profile_id = Storage.get('profileId') || ''
      }
      this.displayLoader()
      const response = await verifyParentalPIN(params)
      this.handleAPIResponse(response)
      this.hideLoader()
  }

  handleAPIResponse(response) {
    const responseHandlers = {
        handleSuccess: () => {
            if (this.contentToValidate.is_livechannel) {
                response.data.livechannel.contentType = 'livechannel'
                response.data.livechannel.action = 'live'
                response.data.livechannel.playbackUrl = response.data.livechannel.action.playback_url
                response.data.livechannel.videoId = this.contentToValidate.channel_id
                const routePrefix = configurations.enableHlsStream ? HLS_PLAYER : DASH_PLAYER
                Router.navigate(`${routePrefix}/live/${this.contentToValidate.channel_id}`, response.data.livechannel)
            } else if (this.contentToValidate.channel_type == 'live') {
                Router.getActivePage().parentalPinVerified(response.data.livechannel)
            } else {
                const videoData = new ContentPersonaResponse(response).contentData
                const contentId = Router.getActiveHash().includes('series') ? this.personaData.videoId : this.personaData.contentId
                const routePrefix = configurations.enableHlsStream ? HLS_PLAYER : DASH_PLAYER
                videoData.action = videoData.seek > 0 && this.enteredbuttonIndex === 0 ? 'continue' : 'start'
                videoData.videoId = contentId
                videoData.contentType = 'video'
                const routeParams = [videoData.action, contentId].join('/')
                if (videoData.availability === 6) return showConcurrentStreamingPopUp()
                if (configurations.concurrentStreamingEnabled) createWebSocketConnection(contentId)
                UPDATE_CONTINUE_WATCHING_COLLECTION(true)
                Router.navigate(routePrefix + '/' + routeParams, videoData)
            }
            this.closePinControlPopUp()
        },
        handleError: () => {
            this.tag('ErrorMessage').patch({
                alpha: 1,
                text: { text: response.error.message.trim() },
            })
            for (let i = 1; i < 5; i++) {
                this.tag('Label' + i).patch({
                    text: { textColor: Colors('errorColor').get() },
                })
                this.tag('Box' + i).patch({
                    texture: Lightning.Tools.getRoundRect(74, 74, 4, 2, Colors('errorColor').get(), true, Colors('primaryFontColor1').get()),
                })
            }
        }
    }

    response?.status_code === 200 ? responseHandlers.handleSuccess() : responseHandlers.handleError()
  }


  _handleEnter() {
    if (this.buttonIndex === 0) {
      this.inputValue?.length === 4
        ? this.pinValidation()
        : this.tag('ErrorMessage').patch({ alpha: 1, text: { text: Language.translate('live_channel.enter_pin.text') } })
    } else {
      this.closePinControlPopUp()
    }
  }

  closePinControlPopUp() {
    this.setSmooth('alpha', 0)
    Router.getActivePage().disablePinControlKeyboard()
    Router.getActivePage().focusPage()
  }

  _handleDown() {
    if (this.buttonIndex < this.buttonsData.length - 1 && this.tag('ButtonList').children.length > 1) this.buttonIndex++
  }

  _handleUp() {
    this.buttonIndex == 0 ? Router.getActivePage().focusPinControlKeyboard() : this.buttonIndex--
  }

  _handleLeft() { return }

  _handleRight() { return }

  _handleKey() { return }

  _handleBack() {
    this.closePinControlPopUp()
  }

  populateInput(value, keyAction) {
    const labelTags = ['Label1', 'Label2', 'Label3', 'Label4']
    const boxTags = ['Box1', 'Box2', 'Box3', 'Box4']
    this.tag('ErrorMessage').patch({ alpha: 0, text: { text: '' } })

    switch (keyAction) {
        case 'backspace':
            this.inputValue = this.inputValue.slice(0, -1)
            break
        case 'delete':
            this.inputValue = ''
            break
        default:
            if (keyAction !== 'backspace' && keyAction !== 'delete' && keyAction !== 'space' && this.inputValue?.length < 4) {
                this.inputValue += value
            }
            break
    }

    if (keyAction === 'backspace') {
        const endIndex = this.inputValue?.length + 1 || 0
        for (let i = endIndex; i <= 4; i++) {
          this.tag(labelTags[i - 1]).patch({ text: { text: '', textColor: Colors('backgroundColor').get() } })
          this.tag(boxTags[i - 1]).patch({
            texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          })
        }
        this.tag('ErrorMessage').patch({ alpha: 0, text: { text: '' } })
        this.pinConversion()
    } else if (keyAction === 'delete') {
      boxTags.forEach((tag, index) => {
            this.tag(tag).patch({
                texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
            })
            if (index < 5) {
                this.tag(labelTags[index]).patch({
                    text: { text: '', textColor: Colors('backgroundColor').get() },
                })
            }
        })
        this.tag('ErrorMessage').patch({ alpha: 0, text: { text: '' } })
        this.pinConversion()
    } else {
        labelTags.forEach((tag, index) => {
            if (!this.tag(tag).text?.text && index < this.inputValue.length) {
                this.pinConversion()
            }
        })
    }
  }

  pinConversion() {
    if (this.inputValue) {
        const inputArray = this.inputValue.split('')
        const mainArray = []
        this.xValue = 635
        this.yValue = 440
        for (let i = 0; i < inputArray.length; i++) {
            const tempObj = { x: this.xValue, y: this.yValue }
            mainArray.push(tempObj)
            this.xValue = this.xValue + 85
        }
        this.tag('PasswordCircleList').children = mainArray.map((maskedPassword) => {
            maskedPassword.fontSize = 60
            return { type: PasswordCircle, maskedPassword }
        })
    } else {
        const mainArray = []
        this.tag('PasswordCircleList').children = mainArray.map((maskedPassword) => {
            return { type: PasswordCircle, maskedPassword }
        })
    }
  }
}
