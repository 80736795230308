import { Router } from '@lightningjs/sdk'
import Page from '../Components/Page.js'
import { ViewAllGrid } from '../Components/index.js'
import { createViewAllItems } from '../Helper/Helpers.js'
import { Detail } from '../Components'

export default class ViewAllPage extends Page {
    static _template() {
        return {
            Content: {
                alpha: 1,
                type: ViewAllGrid,
            },
            Detail: { type: Detail, visible: false }
        }
    }

    _focus() {
        this.patch({ alpha: 1 })
    }

    _firstActive() {
        Router.focusPage()
        this.tag('Detail').visible = true
        ViewAllGrid.params = this.params
        const totalData = this.viewAllCollection?.data
        if (this.viewAllCollection.statusCode == 200) {
            const children = createViewAllItems(totalData)
            const gridItem = this.tag('Content')
            gridItem.total = totalData.totalCount
            gridItem.tag('Items').clear();
            gridItem.tag('Items').add(children)
            gridItem.tag('Title').text = totalData.title
            gridItem.tag('Items').setSmooth('alpha', 1, { delay: 0.2 });
        }
    }

    updateBackDropImg(image) {
        this.tag('Detail').updateBackDropImg(image)
    }

    updateDetailWidget(item) {
        this.tag('Detail').item = item
    }

    showDetailWidget() {
        this.tag('Detail').visible = true
    }

    _getFocused() {
        return this.tag('Content')
    }

    _handleBack() {
        Router.back()
    }
}
