
/**
 * Class representing the response for content persona.
 */
export class ContentPersonaResponse {
  /**
   * Creates an instance of ContentPersonaResponse.
   * @param {Object} val - The input object representing the response.
   */
  constructor(val) {
      this.statusCode = val.status_code;
      this.statusMessage = val.message;
      this.contentData = val.data ? new ContentPersonaDetails(val.data) : [];
  }
}

/**
* Class representing details for content persona.
*/
export class ContentPersonaDetails {
  /**
   * Creates an instance of ContentPersonaDetails.
   * @param {Object} videoData - The input object representing video data.
   */
  constructor(videoData) {
      this.geoAvailable = videoData.geo_available;
      this.playbackUrl = videoData.playback_url || "";
      this.hlsPlaybackUrl = videoData.hls_playback_url || "";
      this.dashPlaybackUrl = videoData.dash_playback_url || "";
      this.isDrm = videoData.is_drm || 0;
      this.adUrl = videoData.ad_url || "";
      this.durationMin = videoData.duration_min || 0;
      this.percentage = videoData.percentage || 0;
      this.wishlistStatus = videoData.wishlist_status || false;
      this.preferredLanguage = videoData.preferred_language || "";
      this.preferredSubtitle = videoData.preferred_subtitle || "";
      this.seek = videoData.seek || 0;
      this.continueId = videoData.continue_id || "";
      this.availability = videoData.availability || null;
      this.accessAgeLimit = videoData.access_age_limit || null;
      this.askAccessPin = videoData.ask_access_pin || 0;
      this.contentTitle = videoData.content_title || "";
      this.contentSlug = videoData.content_slug || ''
      this.slug = videoData.slug || ''
      this.contentId = videoData.content_id || "";
      this.contentIdAlt = videoData.contentId || "";
      this.contentTitleEn = videoData.content_title_en || "";
      this.contentDescription = videoData.content_description || "";
      this.homePageBannerImage = videoData.home_page_banner_image || "";
      this.homePageTitleImage = videoData.home_page_title_image || "";
      this.homePageBannerMedium = videoData.home_page_banner_medium || "";
      this.seriesVideo = videoData.series_video || 0;
      this.seasonId = videoData.season_id || "";
      this.seasonsSlug = videoData.seasons_slug || "";
      this.seasonPosition = videoData.season_position || 0;
      this.seriesSlug = videoData.series_slug || ''
      this.seriesId = videoData.series_id || ""
      this.rent = videoData.rent || {}
      this.buy = videoData.buy || {}
  }
}
