import { Language, Lightning, Router, Colors } from '@lightningjs/sdk'
import { InlineContent } from '@lightningjs/ui-components'
import { optValidate } from '../../Services/AuthService'
import { DEVICE_TYPE } from '../../Helper/GlobalConstants'
import Button from '../Button'
import configurations from '../../Helper/Configurations'
import Loader from '../Loader'
import { showDialog } from '../../Helper/Helpers'

export default class VerificationForm extends Lightning.Component {
  static _template() {
    return {
      zIndex: 2,
      ButtonList: {  },
      PopupMsg: {
        x: 1036,
        y: 300,
        alpha: 0,
        texture: Lightning.Tools.getRoundRect(740, 63, 2, 3, Colors('errorColor').get(), false),
        ResponseMsg: {
          x: 0,
          y: 15,
          w: 740,
          text: {
            fontFace: 'Regular',
            fontSize: 26,
            lineHeight: 18,
            textColor: Colors('primaryFontColor1').get(),
            textAlign: 'center',
          },
        },
      },
      Loading: {
        visible: false,
        type: Loader,
        zIndex: 10,
      },
      ResendText: {
        x: 1202,
        y: 920,
        type: InlineContent,
        content: [],
      },
      PasswordBox: {
        x: 1150,
        y: 690,
        Box1: {
          x: 0,
          texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          Label1: {
            x: 27,
            y: 10,
            text: {
              textColor: 0xff000000,
              fontFace: 'Bold',
              fontSize: 42,
              lineHeight: 32,
            },
          },
        },
        Box2: {
          x: 86,
          texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          Label2: {
            x: 27,
            y: 10,
            text: {
              textColor: 0xff000000,
              fontFace: 'bold',
              fontSize: 42,
              lineHeight: 39,
            },
          },
        },
        Box3: {
          x: 172,
          texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          Label3: {
            x: 27,
            y: 10,
            text: {
              textColor: 0xff000000,
              fontFace: 'Bold',
              fontSize: 42,
              lineHeight: 39,
            },
          },
        },
        Box4: {
          x: 258,
          texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          Label4: {
            x: 27,
            y: 10,
            text: {
              textColor: 0xff000000,
              fontFace: 'Bold',
              fontSize: 42,
              lineHeight: 39,
            },
          },
        },
        Box5: {
          x: 344,
          texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          Label5: {
            x: 27,
            y: 10,
            text: {
              textColor: 0xff000000,
              fontFace: 'Bold',
              fontSize: 42,
              lineHeight: 39,
            },
          },
        },
        Box6: {
          x: 430,
          texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          Label6: {
            x: 27,
            y: 10,
            text: {
              textColor: 0xff000000,
              fontFace: 'Bold',
              fontSize: 42,
              lineHeight: 39,
            },
          },
        },
      },
    }
  }

  _active() {
    this.inputValue = ''
    Router.getActivePage().handleInputType('otp')
  }

  _init() {
    this.buttonIndex = 0
  }

  set data(v) {
    this.buttonData = v
    this.showTimerToResendMail()
  }

  get loaderTag() {
    return this.tag('Loading')
  }

  displayLoader() {
    this.loaderTag.visible = true
  }

  hideLoader() {
    this.loaderTag.visible = false
  }

  showTimerToResendMail() {
    let timer = 30
    this.tag('ResendText').patch({
      x: 1202,
      alpha: 1,
      content: [
        {
          text: Language.translate('registration.email_verif.text'),
          style: {
            textColor: Colors('primaryFontColor1').get(),
            fontFace: 'Regular',
            fontSize: 25,
            lineHeight: 30,
          },
        },
        {
          text: `30 sec`,
          style: {
            textColor: Colors('primaryFontColor1').get(),
            fontFace: 'Bold',
            fontSize: 25,
            lineHeight: 30,
          },
        },
      ],
    })
    const IntervalId = setInterval(() => {
      if (timer != 1) {
        timer = timer - 1
        this.tag('ResendText').patch({
          x: 1202,
          alpha: 1,
          content: [
            {
              text: Language.translate('registration.email_verif.text'),
              style: {
                textColor: Colors('primaryFontColor1').get(),
                fontFace: 'Regular',
                fontSize: 25,
              },
            },
            {
              text: `${timer} sec`,
              style: {
                textColor: Colors('primaryFontColor1').get(),
                fontFace: 'Bold',
                fontSize: 25,
              },
            },
          ],
        })
      } else {
        this.tag('ResendText').alpha = 0
        this.tag('ButtonList').children.push(resendBtn)
        this.tag('ButtonList').children[1].alpha = 1
        clearInterval(IntervalId)
      }
    }, 1000)
    this.tag('ButtonList').children = this.buttonData.map((button) => {
      return { type: Button, button }
    })
    this.tag('ButtonList').children[1].alpha = 0
    const resendBtn = this.tag('ButtonList').children.pop()
    for (let i = 0; i < 6; i++) {
      this.tag('Label' + (i + 1)).patch({
        text: { text: '' },
      })
    }
  }

  validateOtp(val) {
    const otpRegex = /^\d{6}$/
    return otpRegex.test(val)
  }

  async verifyOTP() {
    const isValidOtp = this.validateOtp(this.inputValue)

    if (!isValidOtp)
      return this.showErrorMessage(Language.translate('registration.invalid_pin.error'))

    const params = {
      d_type: DEVICE_TYPE,
      region: configurations.region,
      country_code: configurations.countryCode,
      cu_id: configurations.userId,
      verify_code: this.inputValue,
    }
    this.displayLoader()
    const otpValRes = await optValidate(params)
    this.hideLoader()
    if (otpValRes?.status_code === 200) {
      if (configurations.enableProfiles)
        return Router.navigate('profile/add')

      return Router.navigate('home')
    }
    if (otpValRes.error) this.showErrorMessage(otpValRes.error.message.trim())
  }

  showErrorMessage(message) {
    this.tag('PopupMsg').patch({
      alpha: 1,
      texture: Lightning.Tools.getRoundRect(740, 63, 2, 3, Colors('errorColor').get(), false),
      ResponseMsg: { text: { text: message } },
    })
    for (let i = 1; i < 7; i++) {
      this.tag('Label' + i).patch({
        text: { textColor: Colors('errorColor').get() },
      })
      this.tag('Box' + i).patch({
        texture: Lightning.Tools.getRoundRect(74, 74, 4, 2, Colors('errorColor').get(), true, Colors('primaryFontColor1').get()),
      })
    }
  }

  async resendCode() {
    clearTimeout(this.timeoutId)
    const params = {
      d_type: DEVICE_TYPE,
      region: configurations.region,
      country_code: configurations.countryCode,
      cu_id: configurations.userId,
      resend_code: 1,
    }
    this.displayLoader()
    const otpValRes = await optValidate(params)
    this.hideLoader()

    if (otpValRes?.status_code === 200) {
      this.tag('PopupMsg').patch({
        alpha: 1,
        texture: Lightning.Tools.getRoundRect(740, 63, 2, 3, 0xff00ff00, false),
        ResponseMsg: {
          text: { text: otpValRes.message.trim() },
        },
      })
      this.timeoutId = setTimeout(() => {
        this.tag('PopupMsg').alpha = 0

        for (let i = 1; i < 7; i++) {
          this.tag('Label' + i).patch({
            text: { text: '', textColor: 0xff000000 },
          })
          this.tag('Box' + i).patch({
            texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
          })
        }
      }, 5000)
    } else if (otpValRes.error) {
      this.showErrorMessage(otpValRes.error.message.trim())
    }
  }

  _handleEnter() {
    if (this.buttonIndex === 0 && this.inputValue)
       return this.verifyOTP()

    if (this.buttonIndex === 1) {
      this.showTimerToResendMail()
      this.inputValue = ''
      return this.resendCode()
    }

    this.showErrorMessage(Language.translate('PIN should not be empty'))
  }

  _handleDown() {
    if (this.buttonIndex < this.buttonData.length - 1 && this.tag('ButtonList').children.length > 1) this.buttonIndex++
  }

  _handleUp() {
    this.buttonIndex != 0 ? this.buttonIndex-- : Router.getActivePage().focusBack()
  }

  _handleLeft() {
    return Router.getActivePage().focusKeyboard()
  }

  _handleRight() { return }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }

  _getFocused() {
    if (this.buttonIndex == -1) {
      return Router.getActivePage().focusBack()
    }
    return this.tag('ButtonList').children[this.buttonIndex]
  }

  $populateInput(value, _inputType, keyAction) {
    const labels = [this.tag('Label1'), this.tag('Label2'), this.tag('Label3'), this.tag('Label4'), this.tag('Label5'), this.tag('Label6')]
    const boxes = [this.tag('Box1'), this.tag('Box2'), this.tag('Box3'), this.tag('Box4'), this.tag('Box5'), this.tag('Box6')]

    if (keyAction !== 'backspace' && keyAction !== 'delete' && keyAction !== 'space' && this.inputValue?.length < 6) {
      this.inputValue += value
      this.tag('PopupMsg').patch({ alpha: 0 })

      for (let i = 0; i < labels.length; i++) {
        if (!labels[i].text?.text) {
          labels[i].patch({ text: { text: value } })
          break
        }
      }
    } else if (keyAction === 'backspace') {
      this.inputValue = this.inputValue.slice(0, -1)
      const endIndex = this.inputValue?.length + 1 || 0

      for (let i = endIndex; i <= 6; i++) {
        labels[i - 1].patch({ text: { text: '', textColor: 0xff000000 } })
        boxes[i - 1].patch({ texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()) })
      }

      this.tag('PopupMsg').patch({ alpha: 0, ResponseMsg: { text: { text: '' } } })
    } else if (keyAction === 'delete') {
      this.inputValue = ''

      for (let i = 0; i < 7; i++) {
        labels[i].patch({ text: { text: '', textColor: 0xff000000 } })
        boxes[i].patch({ texture: Lightning.Tools.getRoundRect(74, 74, 4, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()) })
      }

      this.tag('PopupMsg').patch({ alpha: 0, ResponseMsg: { text: { text: '' } } })
    }
  }
}
