import { Lightning, Router, Utils, Colors } from '@lightningjs/sdk'

export default class PinControlKeyboard extends Lightning.Component {
  static _template() {
    return {
      alpha: 0,
      zIndex: 11,
      x: 1060,
      y: 300,
      texture: Lightning.Tools.getRoundRect(260, 340, 0, 0, Colors('cardColor').get(), true, Colors('cardColor').get(),),
      ButtonList: {}
    }
  }

  _getFocused() {
    return this.tag('ButtonList').children[this._index]
  }

  reset() {
    this._index = 0
    this.value = ''
  }

  _active() {
    this._index = 0
    this.value = ''
  }

  prepareKeyboard() {
    this.value = ''
    this._index = 0
    this.patch({ alpha: 1 })
    this.buttonsData = [
      { x: 45,  y: 30,  key: '1', action: 'input' },
      { x: 120, y: 30,  key: '2', action: 'input' },
      { x: 195, y: 30,  key: '3', action: 'input' },
      { x: 45,  y: 105, key: '4', action: 'input' },
      { x: 120, y: 105, key: '5', action: 'input' },
      { x: 195, y: 105, key: '6', action: 'input' },
      { x: 45,  y: 185, key: '7', action: 'input' },
      { x: 120, y: 185, key: '8', action: 'input' },
      { x: 195, y: 185, key: '9', action: 'input' },
      { x: 40,  y: 270, icon: 'static_assets/pincontrol-backspace.png', action: 'backspace' },
      { x: 120, y: 260, key: '0', action: 'input' },
      { x: 190, y: 270, icon: 'static_assets/pincontrol-trash.png', action: 'delete' }
    ]
    this.tag('ButtonList').children = this.buttonsData.map((button) => {
      return { type: Button, button }
    })
  }

  disableKeyboard() {
    this.patch({ alpha: 0 })
  }

  _handleRight() {
    if (this._index < this.buttonsData.length - 1) this._index++
  }

  _handleLeft() {
    if (this._index > 0) this._index--
  }

  _handleUp() {
    if (this._index == 0 || this._index == 1 || this._index == 2) return
    this._index -= 3
  }

  _handleDown() {
    this._index == 9 || this._index == 10 || this._index == 11
      ? Router.getActivePage().focusPinControl()
      : this._index += 3
  }

  _handleEnter() {
    const key = this.tag('ButtonList').children[this._index].buttonData
    switch (key.action) {
      case 'input':
        if (this.value.length < 4) this.value = key.key
        break
      case 'backspace':
      case 'delete':
        if (this.value.length > 0) this.value = ''
        break
      default:
        break
    }
    Router.getActivePage().populateInput(this.value, key.action)
  }

  _disable() {
    this.reset()
  }

  _handleKey() { return }

  _handleBack() {
    Router.getActivePage().closePinControlPopUp()
  }
}


class Button extends Lightning.Component {
  static _template() {
    return {
      FocusTexture: {
        alpha: 0,
        texture: Lightning.Tools.getRoundRect(57, 60, 4, 0, Colors('brandPrimaryColor').get(), false, Colors('focusedBtnColor').get()),
      },
      Label: {
        alpha: 0,
        text: {
          fontFace: 'Regular',
          fontSize: 36,
          textAlign: 'center',
          textColor: Colors('primaryFontColor1').get(),
        },
      },
      Icon: {
        alpha: 0,
        w: 32,
        h: 32,
      }
    }
  }

  set button(v) {
    this.buttonData = v
    this.buttonData.key
      ? this.patch({
        FocusTexture: {
          alpha: 1,
          y: this.buttonData.y - 6,
          x: this.buttonData.x - 22,
        },
        Label: {
          alpha: 1,
          y: this.buttonData.y,
          x: this.buttonData.x,
          text: { text: this.buttonData.key },
        },
        Icon: { alpha: 0 }
      })
      : this.patch({
        FocusTexture: {
          alpha: 1,
          y: this.buttonData.y - 16,
          x: this.buttonData.x - 13,
        },
        Label: { alpha: 0 },
        Icon: {
          alpha: 1,
          y: this.buttonData.y,
          x: this.buttonData.x,
          src: Utils.asset(this.buttonData.icon)
        },
      })
  }

  _focus() {
    this.patch({
      FocusTexture: {
        alpha: 1,
        texture: Lightning.Tools.getRoundRect(57, 60, 4, 2, Colors('focusedStrokeColor').get(), true, Colors('focusedBtnColor').get())
      }
    })
  }

  _unfocus() {
    this.patch({
      FocusTexture: {
        alpha: 0,
        texture: Lightning.Tools.getRoundRect(57, 60, 4, 0, Colors('primaryFontColor1').get(), false)
      },
    })
  }
}

