import CaptionAsset from "./CaptionAsset"

export default class CaptionsHelper {
  static setCaptionText(captionText) {
    this._captions = this.parseVTT(captionText)
  }

  static getCaptionText(captionText) {
    return this.parseVTT(captionText)
  }

  static clearCurrentCaption() {
    this._currentCaption = null
    this._nextCaption = null
  }

  static getCaptionByTimeIndex(captionList, timeIndex) {
    let self = this
    if (
      captionList &&
      captionList.length &&
      this._currentCaption &&
      this._nextCaption &&
      Number(timeIndex.toFixed(2)) < Number(this._nextCaption.end.toFixed(2))
    ) {
      if (
        Number(timeIndex.toFixed(2)) >= Number(this._currentCaption.start.toFixed(2)) &&
        Number(timeIndex.toFixed(2)) < Number(this._currentCaption.end.toFixed(2))
      ) {
        return this._currentCaption.payload
      } else if (
        Number(timeIndex.toFixed(2)) >= Number(this._nextCaption.start.toFixed(2)) &&
        Number(timeIndex.toFixed(2)) < Number(this._nextCaption.end.toFixed(2))
      ) {
        return this._nextCaption.payload
      } else {
        return ''
      }
    } else {
      updateCaptions()
    }

    function updateCaptions() {
      if (captionList && captionList.length) {
        if (
          Number(timeIndex.toFixed(2)) <=
          Number(captionList[captionList.length - 1].start.toFixed(2))
        ) {
          if (Number(timeIndex.toFixed(2)) < Number(captionList[0].end.toFixed(2))) {
            if (captionList[1] && captionList[1].payload) {
              self._nextCaption = captionList[1]
            }
            self._currentCaption = captionList[0]
          } else {
            for (let index = 0; index < captionList.length; index++) {
              if (Number(captionList[index].end.toFixed(2)) >= Number(timeIndex.toFixed(2))) {
                captionList[index + 1] && captionList[index + 1].payload
                  ? (self._nextCaption = captionList[index + 1])
                  : { payload: '' }
                self._currentCaption = captionList[index]
                break
              }
            }
          }
        }
      }
    }
  }

  static parseVTT(vtt) {
    //     let match = s.match(/\<(.*?)\>/g);  remove tags...
    let lines = vtt
      .trim()
      .replace('\r\n', '\n')
      .split(/[\r\n]/)
      .map(line => {
        return line.trim()
      })
    let cues = []
    let start = null
    let end = null
    let payload = null

    for (let index = 0; index < lines.length; index++) {
      let lineValue = lines[index]
      if (lineValue.indexOf('-->') >= 0) {
        let splitted = lines[index].split(/[ \t]+-->[ \t]+/)

        start = CaptionsHelper.parseTimeStamp(splitted[0])
        end = CaptionsHelper.parseTimeStamp(splitted[1])
      } else if (payload) {
        if (start && end) {
          let cue = new CaptionAsset({ start, end, payload })
          cues.push(cue)
          start = null
          end = null
          payload = null
        }
      } else if (start && end && lineValue !== '') {
        if (payload == null) {
          let secondLine = ""
          if(lines[index + 1] && lines[index + 1] !== "" && lines[index + 1].indexOf('-->') < 0){
            secondLine = lines[index + 1] && lines[index + 1] !== "" && lines[index + 1].indexOf('-->') < 0 ? lines[index + 1] : ""
          }else{
            secondLine = lines[index + 2] && lines[index + 2] !== "" && lines[index + 2].indexOf('-->') < 0 ? lines[index + 2] : ""
          }
          payload = lineValue ? lineValue : null
          if(secondLine){
            payload += '\n' + secondLine
          }
        } else {
          payload += '\n' + (lineValue ? lineValue : null)
        }
      }
    }

    if (start && end) {
      let match = /<(.*?)>/g
      if (payload) {
        payload.replace(match, '')
      }
      let cue = new CaptionAsset({ start, end, payload })
      cues.push(cue)
    }
    return cues
  }



  static parseTimeStamp(s) {
    let match = s.match(/^(?:([0-9]+):)?([0-5][0-9]):([0-5][0-9](?:[.,][0-9]{0,3})?)/)

    let hours = parseInt(match[1] || '0', 10)
    let minutes = parseInt(match[2], 10)
    let seconds = parseFloat(match[3].replace(',', '.'))
    return seconds + 60 * minutes + 60 * 60 * hours
  }
}
