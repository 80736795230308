import { Lightning, Colors } from '@lightningjs/sdk';
import { transition } from '../lib/helpers';

export default class Backdrop extends Lightning.Component {
    static _template() {
        return {
            w: 1920,
            h: 1080,
            ImgSource: {
                x: -299.5, w: 300, h: 168
            },
            Backdrop: {
                zIndex: 2, x: 761.7, y: 0, w: 1158.3, h: 654, transitions: { alpha: { duration: 1 }},
            }
        }
    }

    _construct() {
        this._targetSrc = null;
    }

    _init() {
        const backdrop = this.tag('Backdrop');
        this._transitionAlpha = backdrop.transition('alpha');
        this.tag('Backdrop').on('txLoaded', (texture) => {
            if(this._backdrop === texture.src) {
                this._backdropLoaded = true;
                this._animateBackdrop();
            }
        });
        this.tag('ImgSource').on('txLoaded', (texture) => {
            if(this._imgSource === texture.src) {
                this._imgSrcLoaded = texture.source.nativeTexture;
                this._animateBackdrop();
            }
        });
        this._transitionAlpha.on('finish', () => {
                this._loadSrc();
        });
        this._baseColor = Colors('backgroundColor').get();
    }

    _animateBackdrop() {
        if(!this._backdropLoaded || !this._imgSrcLoaded) {
            return;
        }

        this._backdropLoaded = false;
        this._imgSrcLoaded = false;
        transition(this._transitionAlpha, 1);
    }

    _loadSrc() {
        if(this._debounce) {
            clearTimeout(this._debounce);
        }
        this._debounce = setTimeout(() => {
            this._loadTextures(this._targetSrc);
        }, 50);
    }

    _loadTextures(src) {
        this._imgSource = src //Img(src).contain(300, 168);
        this._backdrop = src //Img(src).original();

        // this.tag('ImgSource').src = src
        // this.tag('Backdrop').src = src

        this.tag('ImgSource').src = src //this._imgSource;
        this.tag('Backdrop').src = src //this._backdrop;
    }

    update(src) {
        if(src === this._targetSrc) {
            return;
        }
        this.setSmooth('alpha', !!(src !== null));
        if(this.tag('Backdrop').alpha === 0.001) {
            this._loadTextures(src);
        }
        else {
            transition(this._transitionAlpha, 0.001);
        }
        this._targetSrc = src;
    }
}
