import { Lightning, Colors } from '@lightningjs/sdk'
import { FONT_REGULAR } from '../../Helper/GlobalConstants'

export default class PasswordCircle extends Lightning.Component {
  static _template() {
    return {
      zIndex: 3,
      Label: {
        text: { fontFace: FONT_REGULAR },
      },
    }
  }

  set maskedPassword(data) {
    this.patch({
      x: data.x,
      y: data.y,
      Label: {
        text: { text: '*', textColor: data.color || Colors('cardColor').get(), fontSize: data.fontSize || 36 },
      },
    })
  }
}

