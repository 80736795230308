import UserProfiles from '../Views/UserProfiles'
/**
 * Defines the configuration for the 'UserProfiles' page.
 */
export default {
    path: 'profile/:type',
    component: UserProfiles,
    /**
     * Defines the asynchronous setup for the 'UserProfiles' page.
     * @param {Object} page - The page instance.
     */
    on: async (page, params) => {
        page.patchDataToTemplate(params.type, params)
    },
}
