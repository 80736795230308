export const vmapXml = async (vmapURL) => {
  try {
    const response = await fetch(vmapURL);
    const xmlString = await response.text();
    const respArray = [];
    let textTracks = [];
    let adBreaks = [];

    // Parsing XML
    const parser = new DOMParser();
    const srcDOM = parser.parseFromString(xmlString, "application/xml");
    const jsonData = xmlToJson(srcDOM);
    const vmapObj = {}

    // Extracting contentUri
    const contentUri = jsonData?.['vmap:VMAP']?.['vmap:Extensions']?.['bc:Brightcove']?.attributes;
    const parseTextTracks = jsonData?.['vmap:VMAP']?.['vmap:Extensions']?.['bc:timedTextURL'];
    vmapObj['contentUri'] = contentUri
    if(parseTextTracks != undefined) {
      if (Array.isArray(parseTextTracks)) {
        for (let element = 0; element < parseTextTracks.length; element++) {
          let tracksObj = {}
          tracksObj['webvtturl'] = parseTextTracks[element]['#text']
          tracksObj['languageCode'] = parseTextTracks[element]['attributes']['languageCode']
          textTracks.push(tracksObj)
        }
      } else {
        let tracksObj = {}
        tracksObj['webvtturl'] = parseTextTracks['#text']
        tracksObj['languageCode'] = parseTextTracks['attributes']['languageCode']
        textTracks.push(tracksObj)
      }
      vmapObj['textTracks'] = textTracks
    }
    respArray.push(vmapObj);
  


    const adBreakData = jsonData?.['vmap:VMAP']?.['vmap:AdBreak'];
    if (adBreakData) {
      if (Array.isArray(adBreakData)) { adBreaks.push(...adBreakData) }
      else { adBreaks.push(adBreakData) }
    }

    // Extracting and processing ads
    if (adBreaks?.length > 0 && adBreaks?.[0]) {
      const processedAds = adBreaks.map(ad => {
        const adSource = ad?.['vmap:AdSource'];
        const creatives = adSource['vmap:VASTData']['VAST']['Ad']['InLine']['Creatives']['Creative'];

        // Extracting duration
        const duration = Array.isArray(creatives) ? creatives[0]?.['Linear']['Duration'] : creatives?.['Linear']['Duration'];

        // Creating ad object
        const adObj = {
          timeOffset: ad?.attributes?.timeOffset,
          duration: duration,
          adUrl: adSource, // Change this according to your XML structure
        };

        return adObj;
      }) || [];
      respArray.push(processedAds);
    }
    
    return respArray;
  } catch (error) {
    console.error("Error fetching or processing VMAP XML:", error);
    return [];
  }
};

export const xmlToJson = (xml) => {
  let obj = {};

  if (xml.nodeType === 1) {
    if (xml.attributes.length > 0) {
      obj.attributes = {};
      for (let j = 0; j < xml.attributes.length; j++) {
        const attribute = xml.attributes.item(j);
        obj.attributes[attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType === 3) {
    obj = xml.nodeValue;
  }

  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i);
      const nodeName = item.nodeName;

      if (nodeName === '#cdata-section') {
        obj[nodeName] = item.data;
      } else if (!obj[nodeName]) {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (!Array.isArray(obj[nodeName])) {
          obj[nodeName] = [obj[nodeName]];
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }

  return obj;
}
