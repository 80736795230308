import { Language, Router, Utils, Storage, Colors } from "@lightningjs/sdk";
import { InlineContent } from "@lightningjs/ui-components";
import { ButtonsList, VerticalButtons, Page, PinControl, PinControlKeyboard, Back } from "../Components";
import { FONT_BOLD, FONT_REGULAR, THEME_TWO, UPDATE_CONTINUE_WATCHING_COLLECTION, UPDATE_WISHLIST_COLLECTION, fontsizes, gradientColor, imageSizes } from "../Helper/GlobalConstants";
import {
  getButtons, processGenreData, removeSpaces, returnBannerImage,
  updatedSeasonsArray, commaSeparatedwithSpaces, isEmptyObject, returnImageUrl,
  replacePlaceholdersInTranslations, formatHoursInDays, generateQRCode, getTitleType
} from "../Helper/Helpers";
import { addToWishlist, removeFromContinueWatching, removeFromWishlist, getPersonaDetails } from '../Services/PersonaService'
import { SeriesPersonaResponse } from "../Models/SeriesPersonaModel";
import { ContentPersonaResponse } from "../Models/ContentPersonaModel";
import App from "../App";
import configurations from "../Helper/Configurations";
import Loader from "../Components/Loader";
import Lightning from "@lightningjs/sdk/src/Lightning";
import TVODSidePanel from "../Components/TVODSidePanel";
export default class Detail extends Page {

  static _template() {
    return {
      Gradient: {
        zIndex: 2,
        w: App.width,
        h: App.height,
        rect: true,
        colorRight: gradientColor.bannerImgGradient,
        colorLeft: Colors('backgroundColor').get(),
      },
      Image: {
        zIndex: 1,
        x: 0,
        w: App.width,
        h: App.height,
        y: 0,
      },
      PortraitImage: {
        x: 196,
        y: 292.5,
        w: 316.5,
        h: 495,
        zIndex: 2,
        alpha: 0,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 12 }
      },
      Shadow: {
        zIndex: 1,
        x: 196,
        y: 670,
        alpha: 0,
        color: Colors('shadowColor').get(),
        texture: Lightning.Tools.getShadowRect(280, 100, 12, 16)
      },
      Logo: {
        zIndex: 5,
        x: 35,
        y: 51.6,
        mount: 0,
        src: Utils.asset('static_assets/contentDetailLogo.png')
      },
      Loading: {
        visible: false,
        type: Loader,
        zIndex: 10,
      },
      ContentContainer: {
        h: 800,
        zIndex: 5,
        flex: { direction: 'column', justifyContent: 'center' },
        OnAir: {
          flexItem: { marginBottom: 20 },
          zIndex: 3,
          type: InlineContent,
          justify: 'flex-start',
          style: { iconWidth: 32, iconHeight: 32 },
        },
        TitleLogo: {
          w: 200, h: 200,
          flexItem: { marginBottom: 30 }
        },
        Title: {
          text: {
            fontSize: fontsizes.seriesPageTitle,
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 1150,
            maxLines: 1,
            maxLinesSuffix: '...',
            fontFace: FONT_BOLD,
          },
          flexItem: { marginBottom: 20 }
        },
        GeoBlocking: {
          type: InlineContent,
          justify: 'flex-start',
          style: { iconWidth: 48, iconHeight: 48 },
          flexItem: { marginBottom: 20 }
        },
        TitleEN: {
          text: {
            fontSize: 40,
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 1150,
            maxLines: 1,
            maxLinesSuffix: '...',
            fontFace: FONT_BOLD,
          },
          flexItem: { marginBottom: 20 }
        },
        TagsData: {
          type: InlineContent,
          justify: 'flex-start',
          content: [],
          customStyleMappings: {
            textStyle: {
              fontSize: fontsizes.seriesPageMetadata,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 500,
              textAlign: 'left',
              fontFace: FONT_REGULAR,
            },
          },
          flexItem: { marginBottom: 30 }
        },
        Description: {
          text: {
            fontSize: fontsizes.seriesPageMetadata,
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 750,
            lineHeight: 30,
            fontFace: FONT_REGULAR,
            maxLines: 3,
            maxLinesSuffix: '...',
          },
          flexItem: { marginBottom: 20 }
        },
        Starring: {
          text: {
            fontSize: fontsizes.seriesPageMetadata,
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 750,
            fontFace: FONT_REGULAR,
            maxLines: 1,
            maxLinesSuffix: '...',
          },
        },
        Director: {
          text: {
            fontSize: fontsizes.seriesPageMetadata,
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 750,
            fontFace: FONT_REGULAR,
            maxLines: 1,
            maxLinesSuffix: '...',
          },
          flexItem: { marginBottom: 20 }
        },
        RentOrBuy: {
          flex: { direction: 'row', justifyContent: 'flex-start' },
          visible: false,
          Icon: { w: 32, h: 32, flexItem: { marginRight: 10 } },
          Label: {
            text: {
              fontSize: fontsizes.seriesPageMetadata,
              textColor: Colors('tvodLabelColor').get(),
              wordWrapWidth: 500,
              fontFace: 'Bold',
            }
          },
          flexItem: { marginBottom: 20 },
        },
        Expires: {
          visible: false,
          text: {
            fontSize: 20,
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 700,
            maxLines: 1,
            maxLinesSuffix: '...'
          },
          flexItem: { marginBottom: 20 }
        },
        HorizontalButtons: {
          type: ButtonsList,
          h: 56,
          flexItem: { marginTop: 30 }
        },
        VerticalButtons: {
          type: VerticalButtons,
          flexItem: { marginTop: 30, marginBottom: 30 }
        }
      },
      PinControl: { type: PinControl, visible: false },
      PinControlKeyboard: { type: PinControlKeyboard, visible: false },
      TVODSidePanel: { type: TVODSidePanel, visible: false },
      Back: { zIndex: 10, type: Back, visible: false },
      RentOrBuyOverlay: {
        visible: false, alpha: 0,
        w: App.width,
        h: App.height,
        Overlay: {
          zIndex: 7,
          w: (w) => w,
          h: (h) => h,
          rect: true,
          color: Colors('overlayColor').get()
        },
        ContentBackground: {
          zIndex: 6,
          w: (w) => w,
          h: (h) => h,
        },
        ContentDescription: {
          x: 120, zIndex: 8,
          h: App.height,
          flex: { direction: 'column', justifyContent: 'center' },
          ContentTitle: {
            text: {
              textColor: Colors('primaryFontColor1').get(),
              fontFace: 'Bold', fontSize: 55,
              wordWrapWidth: 700, maxLines: 1, maxLinesSuffix: '...'
            },
            flexItem: { marginBottom: 30 },
          },
          SubscriberPrice: {
            PriceText: {
              x: 10,
              y: 8,
              text: {
                text: Language.translate('plans.subscriber_special_price.tag'),
                fontFace: 'Bold', fontSize: 20,
                textColor: 0xff000000, wordWrapWidth: 400,
                maxLines: 1, maxLinesSuffix: '...'
              }
            },
            flexItem: { marginBottom: 10 },
          },
          RentTitle: {
            flex: { direction: 'row' },
            DiscountedRent: {
              flexItem: { marginRight: 10 },
              text: {
                textColor: Colors('primaryFontColor1').get(), fontFace: 'Bold',
                fontSize: 45, wordWrapWidth: 700,
                maxLines: 1, maxLinesSuffix: '...'
              }
            },
            CrossedRentText: {
              text: {
                textColor: 0x80ffffff, fontFace: 'Bold',
                fontSize: 45, wordWrapWidth: 700,
                maxLines: 1, maxLinesSuffix: '...'
              },
              CrossedRent: { x: -5, y: 30 }
            },
            flexItem: { marginBottom: 10 }
          },
          RentDescription: {
            text: {
              textColor: Colors('primaryFontColor1').get(), fontFace: 'Regular',
              fontSize: 24, wordWrapWidth: 700, lineHeight: 36,
              maxLines: 3, maxLinesSuffix: '...'
            },
            flexItem: { marginBottom: 30 }
          },
          SubscriberPrice2: {
            PriceText2: {
              x: 10,
              y: 8,
              text: {
                text: Language.translate('plans.subscriber_special_price.tag'), fontFace: 'Bold',
                fontSize: 20, textColor: 0xff000000, wordWrapWidth: 400, maxLines: 1, maxLinesSuffix: '...'
              }
            },
            flexItem: { marginBottom: 10 },
          },
          BuyTitle: {
            flex: { direction: 'row' },
            DiscountedBuy: {
              flexItem: { marginRight: 10 },
              text: {
                textColor: Colors('primaryFontColor1').get(), fontFace: 'Bold', fontSize: 45,
                wordWrapWidth: 700, maxLines: 1, maxLinesSuffix: '...'
              }
            },
            CrossedBuyText: {
              text: {
                textColor: 0x80ffffff, fontFace: 'Bold', fontSize: 45,
                wordWrapWidth: 700, maxLines: 1, maxLinesSuffix: '...'
              },
              CrossedBuy: { x: -5, y: 30 }
            },
            flexItem: { marginBottom: 10 }
          },
          BuyDescription: {
            text: {
              textColor: Colors('primaryFontColor1').get(), fontFace: 'Regular', fontSize: 24,
              wordWrapWidth: 700, lineHeight: 36, maxLines: 3, maxLinesSuffix: '...'
            },
            flexItem: { marginBottom: 20 }
          },
          SubscribeNow: {
            text: {
              textColor: Colors('tvodLabelColor').get(), fontFace: 'Bold',
              fontSize: 24, wordWrapWidth: 800, maxLines: 1, maxLinesSuffix: '...'
            },
          }
        },
        Divider: {
          zIndex: 8,
          x: (App.width - 2) / 2,
          y: (App.height - 700) / 2,
          texture: Lightning.Tools.getRoundRect(2, 700, 1, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
        },
        QRcodeDescription: {
          x: 1150,
          zIndex: 8,
          h: App.height,
          flex: { direction: 'column', alignItems: 'center', justifyContent: 'center' },
          ScanQRCode: {
            flexItem: { marginBottom: 30 },
            text: {
              textColor: Colors('primaryFontColor1').get(), fontSize: fontsizes.authenticatePageQRText,
              fontFace: 'Regular', lineHeight: 48, maxLines: 2, wordWrapWidth: 650, textAlign: 'left'
            },
          },
          QRCodeImage: {
            h: 357,
            w: 357,
            flexItem: { marginBottom: 40 }
          },
          ORText: {
            flexItem: { marginBottom: 20 },
            text: {
              color: Colors('primaryFontColor1').get(), fontSize: fontsizes.authenticatePageQRText,
              fontFace: 'Regular', lineHeight: 48, maxLines: 2, wordWrapWidth: 650,
            },
          },
          URLDescription: {
            x: -45,
            flex: { direction: 'column' },
            Child1: {
              type: InlineContent,
              justify: 'flex-start',
              content: [],
              customStyleMappings: {
                textStyle: {
                  textColor: Colors('primaryFontColor1').get(), fontFace: 'Regular',
                  fontSize: 32, maxLines: 3, maxLinesSuffix: '...', textAlign: 'left'
                }
              },
              flexItem: { marginBottom: 10 }
            },
            Child2: {
              text: {
                textColor: Colors('primaryFontColor1').get(), fontFace: 'Regular',
                fontSize: 32, maxLines: 2, wordWrapWidth: 700, maxLinesSuffix: '...', textAlign: 'left'
              }
            }
          }
        },
      }
    }
  }

  _init() {
    this.fadeAnimation = this.tag('ContentContainer').animation({
      delay: 0.2, duration: 0.3, actions: [
        { p: 'alpha', v: { 0: 0.001, 1: 1 } },
        { p: 'x', v: { 0: 216, 1: 176 } }
      ]
    })

    this.portraitFadeAnimation = this.tag('ContentContainer').animation({
      delay: 0.2, duration: 0.3, actions: [
        { p: 'alpha', v: { 0: 0.001, 1: 1 } },
        { p: 'x', v: { 0: 631, 1: 591 } }
      ]
    })
    this.drawTextures()
    this.clearSidePanel()
  }

  drawTextures() {
    this.tag('PriceText').on('txLoaded', () => {
      const SubscriberPriceText = this.tag('PriceText')
      const rectTotalWidth = SubscriberPriceText.renderWidth + 15
      const rectTotalHeight = SubscriberPriceText.renderHeight + 12

      this.tag('SubscriberPrice').patch({
        texture: Lightning.Tools.getRoundRect(rectTotalWidth, rectTotalHeight, 20, 2, Colors('tvodLabelColor').get(), true, 0xffB29401)
      })
    })

    this.tag('PriceText2').on('txLoaded', () => {
      const SubscriberPriceText = this.tag('PriceText2')
      const rectTotalWidth = SubscriberPriceText.renderWidth + 15
      const rectTotalHeight = SubscriberPriceText.renderHeight + 12

      this.tag('SubscriberPrice2').patch({
        texture: Lightning.Tools.getRoundRect(rectTotalWidth, rectTotalHeight, 20, 2, Colors('tvodLabelColor').get(), true, 0xffB29401)
      })
    })

    this.tag('CrossedRentText').on('txLoaded', () => {
      const crossedRentText = this.tag('CrossedRentText')
      const rectTotalWidth = crossedRentText.renderWidth + 15

      this.tag('CrossedRent').patch({
        texture: Lightning.Tools.getRoundRect(rectTotalWidth, 2, 1, 0, 0x80ffffff, true, 0x80ffffff)
      })
    })

    this.tag('CrossedBuyText').on('txLoaded', () => {
      const crossedBuyText = this.tag('CrossedBuyText')
      const rectTotalWidth = crossedBuyText.renderWidth + 15

      this.tag('CrossedBuy').patch({
        texture: Lightning.Tools.getRoundRect(rectTotalWidth, 2, 1, 0, 0x80ffffff, true, 0x80ffffff)
      })
    })
  }

  get loaderTag() {
    return this.tag('Loading')
  }

  displayLoader() {
    this.loaderTag.visible = true
  }

  hideLoader() {
    this.loaderTag.visible = false
  }

  _handleBack() {
    if (this.loaderTag.visible) return
    if (this.tag('RentOrBuyOverlay').visible) return this.closeTVODOverlay()
    Router.back()
  }

  setData(value) {
    if (configurations.enableFirebolt) {
      // fbInstance.getAdvertisingDetails().then((response) => {
      //   configurations.comcastAdvertisingObject = response
      // })
    }
    const contentInfo = value.contentMetaData
    const personaInfo = value.personaData
    this.mediaType = value.mediaType
    this.mediaId = value.mediaId
    if (this.mediaType === 'series') {
      this.overlayStatus = value.overlayStatus || (Storage.get('enteredEpisodeInfo') ? 'showOverlay' : 'hideOverlay')
    } else {
      this.overlayStatus = value.overlayStatus
    }

    this.mediaType === 'series' ? this.handleSeries({ personaInfo, metaInfo: contentInfo }) : this.handleVOD({ personaInfo, contentInfo })
  }

  // handling live channel content detail
  async handleLiveChannel(channelData, channelId) {
    this.channelId = channelId
    this.liveData = channelData.liveChannel
    this.liveData.is_livechannel = true
    this.geoBlocking = (this.liveData.geo_available !== 1)
    if (this.geoBlocking) {
      this.patch({
        ContentContainer: {
          GeoBlocking: {
            visible: true,
            content: [
              { icon: Utils.asset('static_assets/alert_icon_small.png'), w: 48, h: 48 },
              {
                text: Language.translate('player.content_not_avaliable.text'),
                style: { fontSize: fontsizes.geoBlockingAlertText, textColor: Colors('primaryFontColor1').get(), fontFace: FONT_REGULAR }
              }
            ]
          }
        }
      })
      this.focusVerticalBtns()
    } else {
      this.tag('GeoBlocking').patch({ visible: false })
      this.focusButtons()
    }
    this.personaInfo = this.liveData
    this.showContentMetadata(this.liveData)
    if (!this.geoBlocking) {
      this.buttonsData = getButtons(this.liveData)
      this.tag('HorizontalButtons').data = [this.buttonsData, this.personaInfo, this.personaInfo]
    }
    this.prepareAdditionalButtons()
    configurations.bannerType == THEME_TWO ? this.portraitFadeAnimation.start() : this.fadeAnimation.start()
  }

  // handling VOD content
  handleVOD({ personaInfo, contentInfo }) {
    this.metaInfo = contentInfo
    this.trailers = this.metaInfo.trailers
    this.geoBlocking = (personaInfo.geoAvailable !== 1)
    this.personaInfo = personaInfo
    this.continueId = personaInfo.continueId
    this.wishlistStatus = this.personaInfo.wishlistStatus
    this.audioTracks = this.metaInfo.audios || []
    this.subtitleTracks = this.metaInfo.subtitles || []
    this.showContentMetadata(this.metaInfo)
    this.handleTVODUI(personaInfo)
    this.prepareAdditionalButtons()
    this.handleGeoBlocking()
    configurations.bannerType == THEME_TWO ? this.portraitFadeAnimation.start() : this.fadeAnimation.start()
  }

  // handling series content
  handleSeries({ metaInfo, personaInfo }) {
    if (Storage.get('fromEpisodePanel') === 'Yes')
      this.overlayStatus = 'showOverlay'
    else if (Storage.get('fromEpisodePanel') === 'No')
      this.overlayStatus = 'hideOverlay'

    this.geoBlocking = (personaInfo.geoAvailable !== 1)
    if (metaInfo && personaInfo) {
      this.personaInfo = personaInfo
      this.metaInfo = metaInfo
      this.trailers = this.metaInfo.data.trailerVideos
      this.audioTracks = this.metaInfo.data.series.audios || []
      this.subtitleTracks = this.metaInfo.data.series.subtitles || []
      this.seasonsArray = this.metaInfo.data.seasons?.length > 0 ? updatedSeasonsArray(this.metaInfo.data.seasons) : []
      if (Storage.get('enteredEpisodeInfo')) {
        const enteredEpisode = JSON.parse(Storage.get('enteredEpisodeInfo'))
        this.episodeID = enteredEpisode.videoId
        if (enteredEpisode.seasonsSlug) {
          const clickedEpisodeSeason = this.seasonsArray.find((obj) => obj.seasonsSlug === enteredEpisode.seasonsSlug)
          this.selectedSeasonID = clickedEpisodeSeason.seasonId
        }
      } else {
        this.episodeID = personaInfo.videoId
        this.selectedSeasonID = personaInfo.seasonId
      }
      this.wishlistStatus = this.personaInfo.wishlistStatus
      this.continueId = personaInfo.continueId
      this.showContentMetadata(this.metaInfo)
      this.handleTVODUI(personaInfo)
      this.handleGeoBlocking()
      this.prepareAdditionalButtons()
    }
    configurations.bannerType == THEME_TWO ? this.portraitFadeAnimation.start() : this.fadeAnimation.start()
  }

  showContentMetadata(metaInfo) {
    const uiData = this.mediaType === 'series' ? metaInfo.data.series : metaInfo
    const slicedGenre = uiData.genre?.split(',').slice(0, 2).join(',')
    const genreTitle = uiData.genre ? processGenreData(slicedGenre) : ''
    const cast = removeSpaces(uiData.cast)
    const director = removeSpaces(uiData.director)
    const contentLogo = uiData.contentLogo
    const titleEn = uiData.titleEn || uiData.title_en
    const publishTime = uiData.publishTime || uiData.publish_time || ''
    const titleType = getTitleType(uiData)
    const localLogo = uiData.is_local_logo || uiData.isLocalLogo

    let seasonText, episodesText, seriesSeasonCount = ''
    let durationTag, tagsData = ''

    if (this.mediaType === 'series') {
      seasonText = uiData.totalSeasons > 1 ? Language.translate('common_keys.seasons.text') : Language.translate('common_keys.season.text')
      episodesText = uiData.seriesEpisodesCount > 1 ? Language.translate('common_keys.episodes.text') : Language.translate('content_detail.episodes.text')
      seriesSeasonCount = (uiData.totalSeasons && uiData.totalSeasons > 0)
        && ` | ${uiData.totalSeasons} ${seasonText}`
        || (uiData.seriesEpisodesCount && uiData.seriesEpisodesCount > 0)
        && ` | ${uiData.seriesEpisodesCount} ${episodesText}`
        || ''
    } else {
      durationTag = uiData.tags?.length > 0 && !uiData.is_livechannel ? `|  ${uiData.duration || ''}` : `${uiData.duration || ''}`
    }
    if (uiData.tags?.length > 0) {
      tagsData = typeof (uiData.tags) === 'string' ? commaSeparatedwithSpaces(uiData.tags) : this.getTagStringFromArray(uiData)
    }
    const genreORTags = uiData.genre && `${genreTitle}` || uiData.tags?.length > 0 && `${tagsData}` || ''
    let age = ''
    if (uiData.age && configurations.enableAgeRating) {
      age = publishTime ? ` | ${uiData.age + '+'} | ` : `${uiData.age + '+'} | `
    } else {
      age = publishTime ? ' | ' : ''
    }

    this.bannerBgImage = returnBannerImage(uiData)

    this.patch({
      PortraitImage: {
        alpha: configurations.bannerType === THEME_TWO ? 1 : 0,
        src: uiData.posterPortrait
          ? returnImageUrl(uiData.posterPortrait, imageSizes.card)
          : Utils.asset('static_assets/fallback_poster.png'),
      },
      Shadow: { alpha: configurations.bannerType === THEME_TWO && uiData.posterPortrait ? 1 : 0 },
      Image: { src: returnImageUrl(this.bannerBgImage, imageSizes.background) },
      ContentContainer: {
        x: configurations.bannerType === THEME_TWO ? 590: 176,
        OnAir: {
          visible: (uiData.is_livechannel),
          content: [
            {
              icon: Utils.asset('static_assets/onair.png'),
              w: 32,
              h: 32
            },
            {
              text: Language.translate('live.on_air.text'),
              fontSize: fontsizes.bannerItemLiveChannelTitle,
              textColor: Colors('errorColor').get(),
              wordWrapWidth: 1060,
              lineHeight: 30,
              maxLines: 1,
              fontFace: FONT_BOLD,
            }
          ],
        },
        TitleLogo: {
          visible: (contentLogo && configurations.bannerType != THEME_TWO && (titleType === 'onlyLogo' || titleType === 'logoAndTitle')),
          src: returnImageUrl(contentLogo, imageSizes.logo),
        },
        Title: {
          text: {
            visible: !(titleType === 'onlyLogo' || titleType === 'onlyLocalTitle' || titleType === 'logoAndTitle'),
            text: uiData.title
          },
        },
        TitleEN: {
          visible: !(titleType === 'onlyLogo' || uiData.title === titleEn || this.geoBlocking),
          text: {
            text: titleType === 'logoAndTitle' && !localLogo ? uiData.title : titleEn
          },
        },
        TagsData: {
          visible: true,
          content: [
            { text: publishTime, style: 'textStyle' },
            { text: age, style: 'textStyle' },
            { text: genreORTags, style: 'textStyle' },
            { text: this.mediaType === 'series' ? seriesSeasonCount : durationTag, style: 'textStyle' },
          ],
        },
        Description: {
          text: { text: uiData.description },
        }
      },
      RentOrBuyOverlay: { visible: false, alpha: 0 },
      Back: { visible: false }
    })

    cast && configurations.enableCast
      ? this.tag('Starring').patch({
        visible: !this.geoBlocking && !uiData.is_livechannel,
        text: { text: `${Language.translate('content_detail.cast.text')} :  ${cast}` }
      })
      : this.tag('Starring').patch({ visible: false, alpha: 0 })

    director
      ? this.tag('Director').patch({
        visible: !this.geoBlocking && !uiData.is_livechannel,
        text: { text: `${Language.translate('content_detail.director.text')} :  ${director}` }
      })
      : this.tag('Director').patch({ visible: false, alpha: 0 })
  }

  handleTVODUI(personaData) {
    if (personaData.availability === 2) {
      return this.patch({
        ContentContainer: {
          RentOrBuy: {
            visible: !this.geoBlocking,
            Icon: { src: Utils.asset('static_assets/rent_or_buy.png') },
            Label: { flexItem: { marginTop: 5 }, text: { text: Language.translate('plans.available_to_purchase.tag') } }
          }
        }
      })
    }
    if (personaData.availability === 3 && !isEmptyObject(personaData.rent)) {
      const stringsToReplace = [personaData.rent.duration]
      const expiresText = replacePlaceholdersInTranslations(Language.translate('plans.rental_expiry_condition.text'), stringsToReplace)
      this.patch({
        ContentContainer: {
          RentOrBuy: {
            visible: !this.geoBlocking,
            flexItem: { marginBottom: 10 },
            Icon: { src: Utils.asset('static_assets/expiry_icon.png') },
            Label: { flexItem: { marginTop: 0 }, text: { text: personaData.rent.expires_in, textColor: Colors('primaryFontColor1').get() } }
          },
          Expires: {
            visible: !(personaData.rent.started_streaming || this.geoBlocking),
            text: { text: expiresText }
          }
        }
      })
    }
  }

  openTVODOverlay(slug) {
    const isRentObjectExists = !isEmptyObject(this.personaInfo.rent)
    const isBuyObjectExists = !isEmptyObject(this.personaInfo.buy)
    const currentRegion = configurations.regions.find(region => region.region_code === configurations.region)
    const rentAmount = configurations.userType ? this.personaInfo.rent.sub_amount : this.personaInfo.rent.amount
    const buyAmount = configurations.userType ? this.personaInfo.buy.sub_amount : this.personaInfo.buy.amount
    const rentAmountWithCurrency = currentRegion.prefix_currency ? currentRegion.prefix_currency + rentAmount : rentAmount + currentRegion.suffix_currency
    const buyAmountWithCurrency = currentRegion.prefix_currency ? currentRegion.prefix_currency + buyAmount : buyAmount + currentRegion.suffix_currency
    const crossedRentAmount = `${currentRegion.prefix_currency || ''}${this.personaInfo.rent.amount}${currentRegion.suffix_currency || ''}`
    const crossedBuyAmount = `${currentRegion.prefix_currency || ''}${this.personaInfo.buy.amount}${currentRegion.suffix_currency || ''}`
    const buySubAmount = `${currentRegion.prefix_currency || ''}${this.personaInfo.buy.sub_amount}${currentRegion.suffix_currency || ''}`
    const rentSubAmount = `${currentRegion.prefix_currency || ''}${this.personaInfo.rent.sub_amount}${currentRegion.suffix_currency || ''}`
    const rentAmountToShow = this.personaInfo.rent.sub_amount < this.personaInfo.rent.amount ? rentSubAmount : rentAmountWithCurrency
    const buyAmountToShow = this.personaInfo.buy.sub_amount < this.personaInfo.buy.amount ? buySubAmount : buyAmountWithCurrency
    const days = formatHoursInDays(this.personaInfo.rent.expiry)
    const contentType = this.liveData ? 'live' : this.mediaType === 'series' ? 'series' : 'video'
    const qrCodeImageURL = configurations.angularSiteURL + `${contentType}/${slug}`
    const qrcodeImage = generateQRCode(qrCodeImageURL)
    const stringsArrayToReplace = [days, this.personaInfo.rent.duration + ' ' + 'hours']
    const rentDescription = replacePlaceholdersInTranslations(Language.translate('plans.tvod_conditions_1.text'), stringsArrayToReplace)
    this.tag('Back').updatePosition([105, 70])
    this.widgets.backgroundsidepanel.data = { command: 'clear' }
    this.widgets.sidepanelnavigation.data = { component: 'clear' }
    this.widgets.episodelisting.clearEpisodes()

    this.patch({
      RentOrBuyOverlay: {
        visible: true, alpha: 1,
        ContentBackground: {
          src: returnImageUrl(this.bannerBgImage, imageSizes.background)
        },
        ContentDescription: {
          ContentTitle: {
            text: { text: this.personaInfo.contentTitle || this.personaInfo.title || this.metaInfo.data.series.title },
          },
          SubscriberPrice: {
            visible: (configurations.userType && isRentObjectExists && this.personaInfo.rent.sub_amount < this.personaInfo.rent.amount)
          },
          RentTitle: {
            visible: isRentObjectExists,
            DiscountedRent: { text: { text: Language.translate('plans.rent_for_only.text') + ' ' + rentAmountToShow } },
            CrossedRentText: {
              visible: this.personaInfo.rent.sub_amount < this.personaInfo.rent.amount,
              text: { text: crossedRentAmount }
            }
          },
          RentDescription: {
            visible: isRentObjectExists,
            text: { text: rentDescription }
          },
          SubscriberPrice2: {
            visible: (configurations.userType && isBuyObjectExists && this.personaInfo.buy.sub_amount < this.personaInfo.buy.amount)
          },
          BuyTitle: {
            visible: isBuyObjectExists,
            DiscountedBuy: { text: { text: Language.translate('plans.buy_for_only.text') + ' ' + buyAmountToShow } },
            CrossedBuyText: {
              visible: this.personaInfo.buy.sub_amount < this.personaInfo.buy.amount,
              text: { text: crossedBuyAmount }
            }
          },
          BuyDescription: {
            visible: isBuyObjectExists,
            text: { text: Language.translate('plans.tvod_conditions_2.text') },
          },
          SubscribeNow: {
            visible: this.returnTruthiness(),
            text: { text: Language.translate('plans.subscribe_for_discount_tv.text') + ' ' + rentSubAmount }
          }
        },
        QRcodeDescription: {
          ScanQRCode: { text: { text: Language.translate('plans.scan_qr.text') } },
          QRCodeImage: { src: qrcodeImage }, //Rendering base64 string images
          ORText: { text: { text: Language.translate('common_keys.or.text') } },
          URLDescription: {
            Child1: {
              content: [
                {
                  text: Language.translate('plans.visit_url_on_browser_part1.text'),
                  style: 'textStyle'
                },
                {
                  text: configurations.angularSiteURL,
                  style: {
                    textColor: Colors('tvodLabelColor').get(), fontFace: 'Regular',
                    fontSize: 32, maxLines: 3, wordWrapWidth: 100, textAlign: 'left'
                  }
                }
              ]
            },
            Child2: { text: { text: Language.translate('plans.visit_url_on_browser_part2.text') } }
          },
        },
      },
      Back: { visible: true }
    })
    Router.focusPage()
    this._setState('Back')
  }

  returnTruthiness() {
    return !configurations.userType && this.personaInfo.rent.amount !== this.personaInfo.rent.sub_amount
  }

  closeTVODOverlay() {
    this.patch({
      RentOrBuyOverlay: { visible: false },
      Back: { visible: false }
    })
    this.focusButtons()
  }

  // button preparation for horizontal button list
  preparePlayButtons(episodeOverlayStatus) {
    this.buttonsData = getButtons(this.personaInfo)
    if (this.trailers.length > 0 && configurations.enableTrailers) {
      const trailerObj = {}
      trailerObj['text'] = Language.translate('content_detail.watch_trailer.btn')
      trailerObj['x'] = 400
      trailerObj['y'] = 430
      trailerObj['h'] = 62
      trailerObj['w'] = 250
      trailerObj['color'] = Colors('primaryFontColor1').get()
      trailerObj['rent'] = 1
      trailerObj['layout'] = 'border'
      trailerObj['Icon'] = Utils.asset('static_assets/play.png')
      if (this.personaInfo.seek > 0) trailerObj['x'] = 990
      this.buttonsData.push(trailerObj)
    }

    if (this.personaInfo.seek > 0 && this.mediaType === 'series') {
      const episodes = this.seasonsArray.length > 0
        ? this.seasonsArray.find((season) => season.seasonId == this.personaInfo.seasonId).contents
        : this.metaInfo.data.series.seriesContents
      const currentPlayingEpisodePos = episodes.findIndex((episode) => episode.contentId === this.personaInfo.videoId) + 1
      this.buttonsData[0].text = this.seasonsArray.length > 0
        ? `${Language.translate('common_keys.continue_watching.text')} - ${Language.translate('common_keys.seasons_notation.text')}${this.personaInfo.seasonPosition}  ${Language.translate('common_keys.episodes_notation.text')}${currentPlayingEpisodePos}`
        : `${Language.translate('common_keys.continue_watching.text')} - ${Language.translate('common_keys.episodes_notation.text')}${currentPlayingEpisodePos}`
    }

    this.tag('HorizontalButtons').data = [this.buttonsData, this.personaInfo, this.metaInfo]
    episodeOverlayStatus == 'showOverlay' ? this.getEpisodeSidepanel() : this.focusButtons()
  }

  getTagStringFromArray(data) {
    if (!data.is_livechannel) data.tags.shift()
    const tags = data.tags.slice(0, 3)
    return tags.map(tag => tag.trim()).join(', ')
  }

  // Geo-blocking handler
  handleGeoBlocking() {
    if (this.geoBlocking) {
      this.patch({
        ContentContainer: {
          GeoBlocking: {
            alpha: 1,
            visible: true,
            content: [
              {
                icon: Utils.asset('static_assets/alert_icon_small.png'),
                w: 48,
                h: 48
              },
              {
                text: Language.translate('player.content_not_avaliable.text'),
                style: {
                  fontSize: fontsizes.geoBlockingAlertText,
                  textColor: Colors('primaryFontColor1').get(),
                  fontFace: FONT_REGULAR,
                }
              }
            ]
          }
        }
      })
      this.focusVerticalBtns()
    } else {
      this.tag('GeoBlocking').patch({ alpha: 0, visible: false })
      this.preparePlayButtons(this.overlayStatus)
    }
  }

  prepareAdditionalButtons() {
    !this.geoBlocking
      ? this.tag('VerticalButtons').data = {
        buttonData: this.renderVerticalButtons(),
        personaInfo: this.personaInfo,
        isGeoBlocking: false
      }
      : this.tag('VerticalButtons').data = {
        isGeoBlocking: true,
        buttonData: [{ label: Language.translate('common_keys.back.text'), iconImage: 'contentBack.png' }],
      }
  }

  // button preparation for vertical-button list
  renderVerticalButtons() {
    const wishListLabel = this.wishlistStatus
      ? { label: Language.translate('my_list.remove.text'), iconImage: 'remove_from_watchlist.png' }
      : { label: Language.translate('my_list.pop_menu_list.text'), iconImage: 'add_to_watchlist.png' }

    const removeContinueObj = { label: Language.translate('content_detail.remove_from_cont_watch.btn'), iconImage: 'remove_continuewatching.png' }

    const audioSubtitles = { label: Language.translate('player.audio_subtitles.text'), iconImage: 'audioSubtitleIcon.png' }

    const episodes = { label: Language.translate('common_keys.episodes.text'), iconImage: 'episodes.png', episodesListing: true }

    let buttonsArray = [
      {
        label: Language.translate('content_detail.see_all_details.text'),
        iconImage: 'moreAboutIcon.png',
        isMoreAboutBtn: true,
      },
      { label: Language.translate('common_keys.back.text'), iconImage: 'contentBack.png' },
    ]

    if (configurations.loggedIn && !this.liveData?.is_livechannel) {
      buttonsArray.splice(1, 0, wishListLabel)
      if (this.personaInfo.seek > 0)
        buttonsArray.splice(2, 0, removeContinueObj)
    }

    const showSubtitles = configurations.enableMetroSubtitles && this.subtitleTracks?.length > 0
    const showAudios = configurations.enableMetroMultiAudios && this.audioTracks?.length > 0

    if (showSubtitles || showAudios) {
      buttonsArray.splice(buttonsArray.length - 1, 0, audioSubtitles)
    }

    if (this.mediaType == 'series') {
      buttonsArray.splice(buttonsArray.length - 1, 0, episodes)
    }

    const episodeButtonIndex = buttonsArray.findIndex((obj) => obj.label === Language.translate('common_keys.episodes.text'))
    Storage.set('episodesButton', episodeButtonIndex)

    return buttonsArray
  }

  isMultiAudiosPresent() {
    return configurations.enableMetroMultiAudios && this.audioTracks?.length > 0
  }

  getAudioAndSubtitlesButtons() {
    let showAudio = [], showSubtitle = []

    if (configurations.enableMetroMultiAudios && this.audioTracks?.length > 0) {
      showAudio = this.audioTracks
    }

    if (configurations.enableMetroSubtitles && this.subtitleTracks?.length > 0) {
      showSubtitle = this.subtitleTracks
    }

    this.widgets.detailpageaudiosubtitles.data = { showAudio, showSubtitle }

    this.widgets.backgroundsidepanel.data = { command: 'audioSubtitles' }
    this.widgets.sidepanelnavigation.data = { component: 'audioAndSubtitlesInDetailPage', xValue: 1335 }

    Router.focusWidget('SidePanelNavigation')
  }

  setAudioLanguage(audioLabel) {
    Storage.set('audio-lang-selected', audioLabel)
  }

  setSubtitleLanguage(subtitleLabel) {
    Storage.set('subtitle-lang-selected', subtitleLabel || null)
  }

  showPinControlPopUp(data) {
    this.tag('PinControl').data = data
    this.tag('PinControl').patch({ visible: true, alpha: 1 })
    this.tag('PinControlKeyboard').patch({ visible: true, alpha: 1 })
    this._setState('PinControlKeyboard')
  }

  preparePinControlKeyboard() {
    this.tag('PinControlKeyboard').prepareKeyboard()
  }

  disablePinControlKeyboard() {
    this.tag('PinControlKeyboard').disableKeyboard()
  }

  focusPinControl() {
    this._setState('PinControl')
  }

  focusPinControlKeyboard() {
    this._setState('PinControlKeyboard')
  }

  closePinControlPopUp() {
    this.tag('PinControl').closePinControlPopUp()
  }

  populateInput(value, action) {
    this.tag('PinControl').populateInput(value, action)
  }

  focusPage() {
    if (Storage.get('fromEpisodePanel') == 'Yes') {
      Router.focusWidget('EpisodeListing')
    } else {
      Router.focusPage()
      this.focusHorizontalBtns()
    }
  }

  removeEnteredEpisodeRemembrance() {
    this.episodeID = this.personaInfo.videoId
    this.defaultseason = undefined
    this.selectedSeasonID = this.personaInfo.seasonId
  }

  getEpisodeSidepanel() {
    this.prepareSeasonEpisodes()
    this.widgets.backgroundsidepanel.data = { command: 'episodes' }
    this.widgets.sidepanelnavigation.data = { component: 'episodes' }
    this.widgets.sidepanelnavigation.updatePosition()
  }

  prepareSeasonEpisodes() {
    const seasonsLength = this.seasonsArray.length

    const selectedSeasonIDExists = Boolean(this.selectedSeasonID)

    const seasonIdToFetch = selectedSeasonIDExists ? this.selectedSeasonID : this.seasonsArray[0]?.seasonId

    if (seasonsLength > 0) {
      const seasonID = this.seasonsArray.find(obj => obj.seasonId == seasonIdToFetch).seasonId
      this.getSeasonEpisodes(seasonID)
    } else {
      this.getSeriesEpisodes()
    }

    this.widgets.episodelisting.showEpisodes()
    this.widgets.episodelisting.retrieveSeasons({ data: this.seasonsArray, id: this.selectedSeasonID })
    Router.focusWidget('EpisodeListing')
  }

  getSeasonEpisodes(id) {
    if (!id) return
    const seasonObj = this.seasonsArray.find((obj) => id === obj.seasonId)
    const episodeIndex = seasonObj.contents.findIndex((obj) => obj.contentId === this.episodeID)
    const formattedIndex = episodeIndex === -1 ? 0 : episodeIndex
    // need to change here - slider
    this.widgets.episodelisting.retrieveEpisodes({
      orientation: 2,
      videos: seasonObj.contents,
      x: 390,
      y: 35,
      w: 1540,
      h: 1000,
      season: seasonObj,
      episodeIndex: formattedIndex,
      continueWatching: this.personaInfo.continueWatchingContent,
      seriesMetaData: this.metaInfo,
      seriesPersonaInfo: this.personaInfo
    })
  }

  async getSeriesEpisodes() {
    const seriesEpisodes = await this.metaInfo.data.series.seriesContents
    this.firstVideo = seriesEpisodes[0]
    const episodeIndex = seriesEpisodes.findIndex((obj) => obj.contentId === this.episodeID)
    const formattedIndex = episodeIndex === -1 ? 0 : episodeIndex
    this.widgets.episodelisting.retrieveEpisodes({
      orientation: 2,
      videos: seriesEpisodes,
      x: 390,
      y: 35,
      w: 1540,
      h: 1000,
      season: this.metaInfo.data.series,
      episodeIndex: formattedIndex,
      continueWatching: this.personaInfo.continueWatchingContent,
      onlyEpisodes: true,
      seriesMetaData: this.metaInfo,
      seriesPersonaInfo: this.personaInfo
    })
  }

  onSeasonClicked(season) {
    if (season.seasonId == 'seriesEpisodes') return
    this.selectedSeasonID = season.seasonId
    this.prepareSeasonEpisodes()
  }

  async handleWishlist() {
    UPDATE_WISHLIST_COLLECTION(true)
    this.displayLoader()
    if (this.wishlistStatus) return this.removeFromWishlist()
    const contentToAddToWishlist = this.mediaType == 'series' ? this.metaInfo.data.series.uniqueId : this.personaInfo.contentId
    const contentType = this.mediaType == 'series' ? 'series' : 'video'
    await addToWishlist(contentType, contentToAddToWishlist)
    this.hideLoader()
    this.wishlistStatus = true
    this.prepareAdditionalButtons()
    this.setVerticalBtnIndex(1)
  }

  async removeFromWishlist() {
    const contentToRemoveFromWishlist = this.mediaType == 'series' ? this.metaInfo.data.series.uniqueId : this.personaInfo.contentId
    const contentType = this.mediaType == 'series' ? 'series' : 'video'
    await removeFromWishlist(contentType, contentToRemoveFromWishlist)
    this.hideLoader()
    this.wishlistStatus = false
    this.prepareAdditionalButtons()
    this.setVerticalBtnIndex(1)
  }

  async removeContinueWatching() {
    this.displayLoader()
    UPDATE_CONTINUE_WATCHING_COLLECTION(true)
    await removeFromContinueWatching(this.continueId)
    const mediaType = this.mediaType == 'series' ? 'series' : 'video'
    const personaResponse = await getPersonaDetails(this.mediaId, mediaType)
    const personaInfo = this.mediaType == 'series'
      ? new SeriesPersonaResponse(personaResponse)
      : new ContentPersonaResponse(personaResponse)
    this.personaInfo = personaInfo.contentData || personaInfo.data
    this.continueId = this.personaInfo.continueId
    if (this.mediaType == 'series')
      this.removeEnteredEpisodeRemembrance()
    this.preparePlayButtons('hideOverlay')
    this.prepareAdditionalButtons()
    this.hideLoader()
  }

  showMoreInfo() {
    this.widgets.sidepanelnavigation.data = { component: 'showMoreInfo' }
    this.widgets.moreinfosidepanel.data = this.metaInfo || this.liveData
    Router.focusWidget('SidePanelNavigation')
  }

  clearSidePanel() {
    this.widgets.backgroundsidepanel.data = { command: 'clear' }
    this.widgets.sidepanelnavigation.data = { component: 'clear' }
    this.widgets.episodelisting.clearEpisodes()
    this.widgets.detailpageaudiosubtitles.alpha = 0
    this.widgets.moreinfosidepanel.alpha = 0
    Router.focusPage()
  }

  focusButtons() {
    if (!this.geoBlocking)
      this._setState('HorizontalBtns')
  }

  focusHorizontalBtns() {
    if (!this.geoBlocking)
      this._setState('HorizontalBtns')
  }

  focusVerticalBtns() {
    this._setState('VerticalBtns')
  }

  setVerticalBtnIndex(indexValue) {
    this.tag('VerticalButtons').setSpecificIndex(indexValue || 0)
  }

  openTVODSidePanel(data) {
    this.tag('TVODSidePanel').data = data
    this._setState('TVODSidePanel')
  }

  static _states() {
    return [
      class HorizontalBtns extends this {
        _getFocused() {
          return this.tag('HorizontalButtons')
        }
      },
      class VerticalBtns extends this {
        _getFocused() {
          return this.tag('VerticalButtons')
        }
      },

      class PinControl extends this {
        _getFocused() {
          return this.tag('PinControl')
        }
      },

      class PinControlKeyboard extends this {
        _getFocused() {
          return this.tag('PinControlKeyboard')
        }
      },

      class Back extends this {
        _getFocused() {
          return this.tag('Back')
        }
      },

      class TVODSidePanel extends this {
        _getFocused() {
          return this.tag('TVODSidePanel')
        }
      }
    ]
  }

  catchError(errResponse) {
    console.log(errResponse, 'catchError')
  }

  pageTransition(pageIn, pageOut) {
    return super.pageTransition(pageIn, pageOut)
  }
}
