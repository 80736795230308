import { Lightning, Router, Language, Colors } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { getStaticPageDetails } from '../../Services/AssetsService'
import { StaticPageResponse } from '../../Models/StaticPageRespModel'
import configurations from '../../Helper/Configurations'
import { getUserInfo, logout } from '../../Services/PersonaService'
import { flagBasedNavigation, showDialog } from '../../Helper/Helpers'
import Loader from '../Loader'

export default class SettingsAction extends Lightning.Component {
    static _template() {
        return {
            // Used List UI component for scrollable buttons
            SettingsAction: {
                zIndex: 1,
                w: 555,
                h: 870,
                x: 176,
                y: 120,
                rect: true,
                shader: { type: Lightning.shaders.RoundedRectangle, radius: 12 },
                color: Colors('cardColor').get(),
                SettingsButtons: {
                    x: 40,
                    y: 30,
                    h: 600,
                    w: 475,
                    clipping: true,
                    type: List,
                    direction: 'column',
                    spacing: 24,
                },
                SignOutButton: {
                    x: 40, y: 730
                },
            },
            Loading: {
              visible: false,
              type: Loader,
              zIndex: 10,
            },
        }
    }

    set data({ buttonData }) {
        this.buttonsData = buttonData[0]
        this.loginButtonData = buttonData[1] || []
        //Each buttonItem preparation
        const children = this.buttonsData.map((button) => {
          return { w: 475, h: 85, type: Button, button: button }
        })
        this.tag('SettingsButtons').clear()
        this.tag('SettingsButtons').add(children)
        this.tag('SignOutButton').children = this.loginButtonData.map((button) => { return { w: 475, h: 85, type: Button, button: button } })
        this.tag('SettingsButtons').scroll = { after: 5, forward: 0.9, backward: 0.1 }
    }

    _getFocused() {
        this._setState('SettingsButtons')
    }

    _handleRight() {
        const index = this.tag('SettingsButtons').index
        const selectedButton = this.tag('SettingsButtons').items[index].buttonData.type
        if (
          selectedButton !== 'ManageDevices' ||
          selectedButton !== 'SignIn' ||
          selectedButton !== 'SignOut'
        ) {
          Router.getActivePage().focusSettingsView()
        }
    }

    _handleUp() { return }

    _handleKey() { return }

    _handleDown() {
      if (this.loginButtonData.length == 0) return
      this._setState('SignOutButton')
    }

    _handleLeft() { Router.focusWidget('Menu') }

    _handleBack() { Router.getActivePage()._handleBack() }

    static _states() {
        return [
            class SettingsButtons extends this {
                _getFocused() {
                    return this.tag('SettingsButtons')
                }
                _handleDown() {
                  if (this.loginButtonData.length == 0) return
                  this._setState('SignOutButton')
                }
                _handleEnter() {
                    this.buttonsFunctionality()
                }
            },
            class SignOutButton extends this {
                _getFocused() {
                    return this.tag('SignOutButton').children[0]
                }
                _handleUp() {
                    this._setState('SettingsButtons')
                }
                _handleEnter() {
                    this.buttonsFunctionality(this.tag('SignOutButton').children[0].buttonData.type)
                }
            }
        ]
    }

    get loaderTag() {
        return this.tag('Loading')
    }

    displayLoader() {
        this.loaderTag.visible = true
    }

    hideLoader() {
        this.loaderTag.visible = false
    }


    async buttonsFunctionality(buttonType) {
        const btnIndex = this.tag('SettingsButtons').index
        const selectedBtnType = buttonType || this.tag('SettingsButtons').items[btnIndex].buttonData.type
        const page = Router.getActivePage()

        switch (selectedBtnType) {
            case 'PersonalInfo':
                const profileResponse = await Promise.all([getUserInfo()])
                const data = profileResponse[0]
                configurations.email = data.data.user_email
                page.userDetailsView(data)
                break
            case 'AppLanguage':
                page.languageOptionsView()
                break
            case 'ManageDevices':
                Router.navigate('managedevices/manage-devices')
                break
            case 'SignIn':
                flagBasedNavigation()
                break
            case 'SignOut':
                const dialog = this.fireAncestors('$returnDialog')
                const actionArray = [
                  {
                    label: Language.translate('common_keys.yes.text'),
                    action: async () => {
                        dialog.close()
                        this.displayLoader()
                        await logout()
                        !configurations.loginMode ? Router.navigate('home') : flagBasedNavigation()
                        this.hideLoader()
                    }
                  },
                  {
                    label: Language.translate('common_keys.no.text'),
                    action: () => { dialog.close() }
                  }
                ]
                if (!this.loaderTag.visible) {
                  showDialog(
                    Language.translate('common_keys.alert.text'),
                    Language.translate('account.logout_confirm.text'),
                    actionArray
                  )
                }
              break
            default:
                this.displayLoader()
                const staticRes = new StaticPageResponse(await getStaticPageDetails(selectedBtnType))
                page.staticPageScrollView(staticRes)
                this.hideLoader()
                break
        }
    }
}

class Button extends Lightning.Component {
    static _template() {
        return {
            Background: {
                Label: {}
            }
        }
    }

    set button(v) {
        this.buttonData = v
        this.tag('Background').patch({
            texture: Lightning.Tools.getRoundRect(470, 85, 4, 0, Colors('primaryFontColor1').get(), false),
            Label: {
                text: {
                    text: v?.label ? v.label : v.heading,
                    fontSize: 28, textColor: Colors('primaryFontColor1').get(), maxLinesSuffix: '...', fontFace: 'Bold', textAlign: 'center', wordWrapWidth: 400, maxLines: 1
                },
            },
        })
        this.tag('Background' && 'Label').on('txLoaded', () => {
            this.tag('Label').x = 20
            this.tag('Label').y = (this.tag('Background').finalH - this.tag('Label').finalH) / 2
        })
    }

    _focus() {
        this.tag('Background').patch({
            texture: Lightning.Tools.getRoundRect(470, 85, 4, 2, Colors('focusedStrokeColor').get(), true, Colors('focusedBtnColor').get())
        })
    }

    _unfocus() {
        this.tag('Background').patch({
            texture: Lightning.Tools.getRoundRect(470, 85, 4, 0, Colors('primaryFontColor1').get(), false)
        })
    }
}

