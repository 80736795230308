import {Img, Language, Lightning, Log, Registry, Router, Utils} from '@lightningjs/sdk';
import PlayerButton from './PlayerButton';
import PlayerProgress from './PlayerProgress';
//import Common from "../../lib/Common";
//import {getApi} from "../../lib/RadiolineAPI"
//import UniversalPlayback from '../../universalPlayer/UniversalPlayback';

export default class PlayerPage extends Lightning.Component{
	static _template(){
		return {
			clipbox: true, h: 1080, w: 1920, rect: true, color: 0xFF000000,
			Details: {
				Name: { h: 60,x: 100, w: 1720, y: 169 },
				BaseLine: { h: 40, x: 100, w: 1720, y: 246 },
				CurrentSong: { h: 43, w: 1920, y: 284 },
				Image: { y: 390, x: 760, w: 400, h: 400, src: Utils.asset('img/missing.png') }
			},
			ProgressBar: {
				x: 250, y: 820, type: PlayerProgress
			},
			Controls: {
				y: 880,
				FavoButton: { type: PlayerButton, x: 625, image: Utils.asset('img/player/fav.png') },
				StopButton: { visible: false, type: PlayerButton, x: 795, image: Utils.asset('img/player/stop.png') },
				PlayButton: { type: PlayerButton, x: 795, image: Utils.asset('img/player/play.png') },
				PrevButton: { type: PlayerButton, x: 965, image: Utils.asset('img/player/prev.png') },
				NextButton: { type: PlayerButton, x: 1135, image: Utils.asset('img/player/next.png') }
			},
		};
	}
	_init() {
		this._currentTime = 0
		this._noOfRetries = 0
		//UniversalPlayback.consumer(this)
	}

	get api(){
		//return getApi()
	}
	set params(args) {
		this.playlist = args
	}

	set playlist({ playlist, type, index }){
		if(type === 'playlist' && Array.isArray(playlist) && playlist.length){
			this._playlist = playlist;
			this.index = index;
			this.playlistItem = index;
		}else{
			this.playerDetails = playlist;
		}
	}

	set playlistIndex(index){
		this._playlistIndex = index;
	}

	get playlistIndex(){
		return parseInt(this._playlistIndex, 10);
	}

	set playlistItem(index){
		this.playerDetails = this.playlist[index];
		this.playlistIndex = index;
	}

	get playlist(){
		return this._playlist;
	}

	getNextVideo(){
		if(this.playlistIndex + 1 <= this.playlist.length - 1)
			this.playlistIndex = this.playlistIndex + 1;
		else if(this.playlistIndex + 1 >= this.playlist.length) {
			this.closePlayer()
			return
		} else
			this.playlistIndex = 0;

		this.playlistItem = this.playlistIndex;
	}

	getPreviousVideo(){
		if(this.playlistIndex - 1 >= 0) {
			this.playlistIndex = this.playlistIndex - 1;
		} else {
			this.playlistIndex = this.playlist.length - 1;
		}
		this.playlistItem = this.playlistIndex;
	}

	set playerDetails(v){
		this._GCInterval  = Registry.setInterval(() => {
			this.stage.gc()
		}, 3000)
		this.tag('ProgressBar').reset();
		this._data = v
		let permalink = '';
		Log.info('streamType:', v.type)
		this.tag('FavoButton').image = Utils.asset((this.api.existInFavourites(v)) ? 'img/player/faved.png' : 'img/player/fav.png');

		this.patch({
			Controls: {
				FavoButton: { x: (v.type === 'chapter') ? 625 : 795 },
				PlayButton: { x: (v.type === 'chapter') ? 795 : 965 },
				PrevButton: { visible: (v.type === 'chapter') },
				NextButton: { visible: (v.type === 'chapter') }
			},
			Details: {
				Name: { text: {maxLines: 1, textAlign: 'center', fontSize: 48, fontFace: 'RobotoCondensed', text: v.name || '' } },
				//BaseLine: { text: { maxLines: 1, textAlign: 'center', fontSize: 32, fontFace: 'RobotoCondensed', text: Common.getPrintableText(v.baseline, v.description) } },
				CurrentSong: { text: { textAlign: 'center', fontSize: 32, fontFace: 'RobotoCondensed', text: v.country || '' } },
				Image: (v.logo?{ texture: Img(v.logo.replace('200.jpg', '400.jpg')).original() } : {src:Utils.asset('img/missing.png')})
			}
		});

		if(!v.permalink || !v.type){
			return;
		}
		if(this.item && v.permalink === this.item.permalink && this._isPlaying){
			this.changePlayPauseIcon('pause');
			this.toggleVisibilityStop();
			this.updateProgress = true;
			return;
		}
		//UniversalPlayback.clear()

		if(v.type === 'radio' || v.type === 'chapter'){
			permalink = v.permalink + '/play';
		}

		this.permaLink = permalink

		this._setState('Loading');
		this.api.getInfo(permalink, false).then((data)=>{

			if(data.body && data.body.content && data.body.content.streams && data.body.content.streams[0]){
				const streams = data.body.content.streams;
				this.streamUrl = this.filterStreams(streams);
				this.item = v;
				this.startStream();
				this.getLiveData(v.permalink);
			} else {
                Log.error('No Stream URL')
                this._setState('Error');
            }
		}).
		catch((e)=>{
			Log.error(e)
			this._setState('Error');
		});
	}

	set permaLink(v) {
		this._permalink = v
	}
	get permaLink() {
		return this._permalink
	}

	filterStreams(streamData) {
        const items = streamData;
        const mostReliableItem = items.reduce((prev, cur) => (cur['codec'] !== 'aac' && cur['bitrate'] > prev['bitrate']) ? cur : prev );
        const mostReliableItemFormat = mostReliableItem['codec'];
        let nextBestItem = {};

        if(items.length > 1 && mostReliableItemFormat === 'aac'){
            nextBestItem = items.reduce((prev, cur) => (cur['bitrate'] > prev['bitrate']) ? cur : prev);
            return nextBestItem.url;
        }else{
            return mostReliableItem.url;
        }
	}

	set streamUrl(url){
		this._url = url;
	}

	get streamUrl(){
		return this._url;
	}

	set item(item){
		this._item = item;
	}

	get item(){
		return this._item;
	}

	getLiveData(permalink){
		this.api.getRadioPlayingInfo(permalink, true).then((data)=>{
			const imgPath = data && data.body && data.body.content && data.body.content.show && data.body.content.show.image
				|| data && data.body && data.body.content && data.body.content.show && data.body.content.show.animators && data.body.content.show.animators[0] && data.body.content.show.animators[0].image
				|| false;

			if(!imgPath)
				return;

			this.patch({
				Details: {
					Image: (imgPath?{ texture: Img(imgPath).original() } : {src:Utils.asset('img/missing.png')}),
					CurrentSong: { text: { text: data.name || '' } }
				}
			});
		});
	}

	startStream(){
		UniversalPlayback.clear()
		Log.info('streamURL:', this.streamUrl)
		if(!this.streamUrl){
			this._setState('Error');
		}else{
			const extension = (new URL(this.streamUrl)).pathname.split('.').pop().toLowerCase()
			const _supportedFormat = ['m3u8', 'mpd', 'mp3', 'm4v', 'm4a', 'mov', 'aac']
			if(_supportedFormat.indexOf(extension) > -1){
				UniversalPlayback.open(this.streamUrl);
			}else {
				UniversalPlayback.open(this.streamUrl, {forceFormat: 'native'}) // forcing format as native, for URL which has no extension(.acc)
			}
			this._isPlaying = true
			let _activePage = Router.getActivePage()
			if(_activePage.widgets && _activePage.widgets.menu) {
				_activePage.widgets.menu.togglePlayingButton(true)
				_activePage.widgets.menu.activeButton = 'NowPlaying'
			}
		}
	}
	showPlayer({show, params}){
		if(params) {
			this.params = params
		}
		this._playerScreenOn = !!show
		let _activePage = Router.getActivePage()
		this.setSmooth('alpha', !!show)
		if(show){
			if(_activePage.widgets && _activePage.widgets.menu) {
				this._previousMenuLabel = _activePage.widgets.menu.menuLabel
				_activePage.widgets.menu.menuLabel = Language.translate('NowPlaying')
			}
		} else {
			if(this._previousMenuLabel && _activePage.widgets && _activePage.widgets.menu){
				_activePage.widgets.menu.menuLabel = this._previousMenuLabel
				this._previousMenuLabel = null
			}
			this.hidePlayerControls()
		}
	}
	get playerScreenOn(){
		return this._playerScreenOn
	}
	get isPlaying(){
		return this._isPlaying
	}

	toggleVisibilityStop(){
		if(!this.tag('PrevButton').visible && !this.tag('NextButton').visible){
			this.patch({
				Controls: {
					FavoButton: { x: 795 },
					StopButton: { smooth: { x: 965 }, visible: true  },
					PlayButton: { visible: false }
				}
			});
			return;
		}

		this.patch({
			Controls: {
				FavoButton: { smooth: { x: 545 } },
				StopButton: { smooth: { x: 715 }, visible: true},
				PlayButton: { smooth: { x: 885 } },
				PrevButton: { smooth: { x: 1055 } },
				NextButton: { smooth: { x: 1225 } }
			}
		});
	}

	_focus(){
		this.setSmooth('alpha', 1, { duration: 0.7 });
		this.application.updateFocusSettings();
	}

	hidePlayerControls() {
		this.setSmooth('alpha', 0, { duration: 0.7 });
		this.changePlayPauseIcon('play');
	}

	_active() {
		if (this._isPlaying) {
			this.changePlayPauseIcon('pause');
			this.toggleVisibilityStop();
			this.updateProgress = true;
			return;
		}
	}

	_inactive(){
		this.hideControls();
		this.updateProgress = false;
		this.tag('ProgressBar').reset();
		this._noOfRetries = 0
		this.stage.gc()
	}

	hideControls(){
		this.patch({
			Controls: {
				FavoButton: { x: 625 },
				StopButton: { visible: false, x: 795 },
				PlayButton: { x: 795, visible: true },
				PrevButton: { x: 965 },
				NextButton: { x: 1135 }
			}
		});
	}

	_handleBack(e){
		this.showPlayer({show: false})
		Router.focusPage()
		e.preventDefault()
	}
	_handleLeft() {
		Router.focusWidget('menu')
	}
	_handleKey() {
		//Block all other keys
	}
	_handleRight(){
		// Block
	}
	_handleDown(){
		// Block
	}

    _handlePlay() {
		if (this.item && this.item.type === 'radio')
			return;

		UniversalPlayback.play();
		this._setState('PlayButton');
	}

    _handlePause() {
		if (this.item && this.item.type === 'radio')
			return;

		UniversalPlayback.pause();
		this._setState('PlayButton');
	}

    _handlePlayPause() {
		if (this.item && this.item.type === 'radio')
			return;

		UniversalPlayback.playPause();
		this._setState('PlayButton');
	}
	_handleStop() {
		this.closePlayer()
	}

	closePlayer() {
		Registry.clearInterval(this._GCInterval)
        this.hideControls();
		this._isPlaying = true
		let _activePage = Router.getActivePage()
		if(_activePage.widgets && _activePage.widgets.menu) {
			_activePage.widgets.menu.togglePlayingButton(false)
		}
        this.changePlayPauseIcon('play');
        this.stop();
        this.showPlayer({show: false})
		Router.focusPage()
	}

    _handleRewind() {
        //No functionality for this app
    }

    _handleForward() {
        //No functionality for this app
    }

    $videoPlayerError(err){
		Log.info('$videoPlayerError:', err)
		this._isPlaying = false
		if (this._noOfRetries <= 3) {
			this._noOfRetries++
			this.startStream();
			this.getLiveData(this._data.permalink);
			return
		}
		this.stop();
		this._setState('Error');
		return false;
	}

	$videoPlayerEnded(){
		if(!this._playlist || this._playlist.length === 1) {
			this.closePlayer()
			return
		}

		this.hideControls();
		this.getNextVideo();
	}

	$videoPlayerPause(){
		this._isPlaying = false
		this.changePlayPauseIcon('play');
	}
	$videoPlayerPlaying() {
		this._noOfRetries = 0
	}

	$videoPlayerPlay(){
		this._isPlaying = true
		this.changePlayPauseIcon('play');
		this.changePlayPauseIcon('pause');
		this.toggleVisibilityStop();
	}

	$videoPlayerLoadedData(){
		this.updateProgress = true;
	}
	$videoPlayerCanPlay(){
		this._setState('StopButton');
	}
	$videoPlayerTimeUpdate(){
		if(this._currentTime !== Math.floor(UniversalPlayback.currentTime)) {
			this._currentTime = Math.floor(UniversalPlayback.currentTime)
			this.tag('ProgressBar').setProgress(this._currentTime, this._data.type === 'radio'? Infinity : Math.floor(UniversalPlayback.duration));
		}
	}

	changePlayPauseIcon(i){
		this.tag('PlayButton').patch({ image: Utils.asset('img/player/' + i + '.png') });
	}

	stop(){
		this._isPlaying = false
		this.updateProgress = false;
		UniversalPlayback.close();
	}

	static _states(){
		return [
			class Loading extends this{
				$enter(){
					return this.fireAncestors('$toggleLoader', true);
				}

				$exit(){
					return this.fireAncestors('$toggleLoader');
				}

				_handleEnter(){
					// block
				}
				_handleBack() {
					//Loader is closed and previous page is displayed
					this._setState('InActive')
					this.closePlayer()
				}
			},
			class PlayButton extends this{
				_getFocused(){
					return this.tag('PlayButton');
				}

				_handleLeft(){
					if(this.tag('StopButton').visible)
						return this._setState('StopButton');

					this._setState('FavoButton');
				}

				_handleRight(){
					if(!this.tag('PrevButton').visible)
						return;

					this._setState('PrevButton');
				}

				_handleEnter(){
					UniversalPlayback.playPause();
				}
			},
			class StopButton extends this{
				_getFocused(){
					return this.tag('StopButton');
				}

				_handleLeft(){
					this._setState('FavoButton');
				}

				_handleRight(){
					if (this.tag('PlayButton').visible)
						this._setState('PlayButton');
				}

				_handleEnter(){
					this.closePlayer()
				}
			},
			class FavoButton extends this{
				_getFocused(){
					return this.tag('FavoButton');
				}

				_handleRight(){
					if(this.tag('StopButton').visible)
						return this._setState('StopButton');

					this._setState('PlayButton');
				}
				_handleLeft() {
					Router.focusWidget('menu')
				}

				_handleEnter(){
					this.api.setFavourites(this.item);
					this.fireAncestors('$refreshHome');
					this.tag('FavoButton').image = Utils.asset((this.api.existInFavourites(this.item)) ? 'img/player/faved.png' : 'img/player/fav.png');
				}
			},
			class PrevButton extends this{
				_getFocused(){
					return this.tag('PrevButton');
				}

				_handleLeft(){
					this._setState('PlayButton');
				}

				_handleRight(){
					this._setState('NextButton');
				}

				_handleEnter(){
					this.hideControls();
					this.getPreviousVideo();
				}
			},
			class NextButton extends this{
				_getFocused(){
					return this.tag('NextButton');
				}

				_handleLeft(){
					this._setState('PrevButton');
				}

				_handleEnter(){
					this.hideControls();
					this.getNextVideo();
				}
				_handleRight(){
					// Block
				}
			},
			class Error extends this{
				$enter(){
					this._isPlaying = false
					Registry.clearInterval(this._GCInterval)
					let _activePage = Router.getActivePage()
					if(_activePage.widgets && _activePage.widgets.menu) {
						_activePage.widgets.menu.togglePlayingButton(false)
					}
					Router.focusWidget('Error')
					Router.getActiveWidget().fromPage = 'player'
				}
				$exit(){
					this._noOfRetries = 0
				}
			},
			class InActive extends this{
			}
		];
	}
}
