import { ContentPersonaResponse } from "../Models/ContentPersonaModel";
import { MovieResponse } from "../Models/DetailsModel";
import { SeriesResponse } from "../Models/SeriesContentModel";
import { SeriesPersonaResponse } from "../Models/SeriesPersonaModel";
import { getSeriesDetails, getVideoDetails } from "../Services/AssetsService";
import { getLiveChannel, getPersonaDetails } from "../Services/PersonaService";
import { Detail } from "../Views";
import { LiveChannelResponse } from '../Models/LiveChannelModel'

export default {
  // Route configuration for the 'detail' page with a dynamic parameter 'mediaId'
  path: 'detail/:mediaType/:mediaId',
  // Component to be rendered for the 'detail' page
  component: Detail,
  // Before entering the page, perform asynchronous tasks
  on: async (page, params) => {
      const { mediaType, mediaId, overlayStatus } = params
      // Check if the mediaId contains 'live' (assuming 'live' is part of the mediaId)
      if (mediaId.includes('live')) {
        const id = mediaId.split(' ')[0]
          return getLiveChannel(id).then((response) => {
                  const channelData = new LiveChannelResponse(response)
                  page.handleLiveChannel(channelData, id)
              }).catch((err) => {
                console.log(err, 'Error fetching live channel data')
              })
      }

      if (mediaType.includes('series')) {
        await Promise.all([getSeriesDetails(mediaId), getPersonaDetails(mediaId, 'series')]).then((responseArray) => {

          // Destructure the array to get individual responses
          const metaInfo = responseArray[0]
          const personaInfo = responseArray[1]

          // Error handling for both responses
          if (metaInfo.error) {
            return page.catchError(metaInfo)
          }
          if (personaInfo.error) {
            return page.catchError(personaInfo)
          }

          // // Create response objects for movie and persona
          const seriesResponse = new SeriesResponse(metaInfo)
          const personaResponse = new SeriesPersonaResponse(personaInfo)

          // // Extract data from the responses
          const seriesData = seriesResponse;
          const personaData = personaResponse.data;

          // // Update the page with movie and persona data
          return page.setData({ contentMetaData: seriesData, personaData, mediaType, mediaId, overlayStatus });
      })
      .catch((err) => {
        console.log(err, "Error fetching video Details and personadetails data");
      });
        return
      }

      // For VOD (Video on Demand) content
      // Use Promise.all to fetch video details and persona details concurrently
      await Promise.all([getVideoDetails(mediaId, 'video'), getPersonaDetails(mediaId, 'video')]).then((responseArray) => {
              // Destructure the array to get individual responses
              const metaInfo = responseArray[0]
              const personaInfo = responseArray[1]

              // Error handling for both responses
              if (metaInfo.error) {
                  return page.catchError(metaInfo)
              }
              if (personaInfo.error) {
                  return page.catchError(personaInfo)
              }

              // Create response objects for movie and persona
              const movieResponse = new MovieResponse(metaInfo)
              const personaResponse = new ContentPersonaResponse(personaInfo)

              // Extract data from the responses
              const movieData = movieResponse.contentData
              const personaData = personaResponse.contentData

              // Update the page with movie and persona data
              return page.setData({ contentMetaData: movieData, personaData, mediaType, mediaId })
          })
          .catch((err) => {
            console.log(err, 'Error fetching video Details and personadetails data')
          })
  },
  // Widgets to be included on the 'detail' page
  widgets: [
     'SidePanelNavigation',
     'MoreInfoSidePanel',
     'BackgroundSidepanel',
     'EpisodeListing',
     "DetailPageAudioSubtitles"
    ],
}
