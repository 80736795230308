const template = {
    keyWidth: 120,
    keyHeight: 72,
    horizontalSpacing: 24,
    verticalSpacing: 12,
    layouts: {
        search: {
            rows: [
                {
                    keys: [{ c: 'a' }, { c: 'b' }, { c: 'c' }, { c: 'd' }, { c: 'e' }, { c: 'f' }],
                },
                {
                    keys: [{ c: 'g' }, { c: 'h' }, { c: 'i' }, { c: 'j' }, { c: 'k' }, { c: 'l' }],
                },
                {
                    keys: [{ c: 'm' }, { c: 'n' }, { c: 'o' }, { c: 'p' }, { c: 'q' }, { c: 'r' }],
                },
                {
                    keys: [{ c: 's' }, { c: 't' }, { c: 'u' }, { c: 'v' }, { c: 'w' }, { c: 'x' }],
                },
                {
                    keys: [{ c: 'y' }, { c: 'z' }, { c: '1' }, { c: '2' }, { c: '3' }, { c: '4' }],
                },
                {
                    keys: [{ c: '5' }, { c: '6' }, { c: '7' }, { c: '8' }, { c: '9' }, { c: '0' }],
                },
                {
                    keys: [{ c: ':' }, { c: "'" }, { c: '&' }, { c: '.' }, { c: '$' }, { c: '!' }],
                },
                {
                    keys: [
                        { action: 'backspace', w: 140, source: 'static_assets/backspace.png' },
                        { action: 'space', source: 'static_assets/space.png', w: 140 },
                        { action: 'delete', w: 120, source: 'static_assets/trash.png' },
                    ],
                },
            ],
        },
        landscape: {
            rows: [
                {
                    keys: [{ c: '1' }, { c: '2' }, { c: '3' }, { c: '4' }, { c: '5' }, { c: '6' }, { c: '7' }, { c: '8' }, { c: '9' }, { c: '0' }],
                },
                {
                    keys: [{ c: 'a' }, { c: 'b' }, { c: 'c' }, { c: 'd' }, { c: 'e' }, { c: 'f' }, { c: 'g' }, { c: 'h' }, { c: 'i' }, { c: 'j' }],
                },
                {
                    keys: [{ c: 'k' }, { c: 'l' }, { c: 'm' }, { c: 'n' }, { c: 'o' }, { c: 'p' }, { c: 'q' }, { c: 'r' }, { c: 's' }, { c: 't' }],
                },
                {
                    keys: [{ c: 'u' }, { c: 'v' }, { c: 'w' }, { c: 'x' }, { c: 'y' }, { c: 'z' }, { c: '?' }, { c: '.' }, { c: '#' }, { c: '@' }],
                },
                {
                    keys: [
                        { action: 'space', source: 'static_assets/landscapespacebar.png', w: 1308 },
                    ],
                },
            ],
        },
        abc: {
            rows: [
                {
                    keys: [{ c: '1' }, { c: '2' }, { c: '3' }, { c: '4' }, { c: '5' }, { c: '6' }, { c: '7' }, { c: '8' }, { c: '9' }, { c: '0' }],
                },
                {
                    keys: [{ c: 'a' }, { c: 'b' }, { c: 'c' }, { c: 'd' }, { c: 'e' }, { c: 'f' }, { c: 'g' },{ c: 'h' }, { c: 'i' }, { c: 'j' }],
                },
                {
                    keys: [{ c: 'k' }, { c: 'l' }, { c: 'm' }, { c: 'n' },{ c: 'o' }, { c: 'p' }, { c: 'q' },{ c: 'r' }, { c: 's' }, { c: 't' }],
                },
                {
                    keys: [ { c: 'u' },{ c: 'v' }, { c: 'w' }, { c: 'x' }, { c: 'y' }, { c: 'z' },{ c: '?' }, { c: '.' }, { c: '#' }, { c: '@' }],
                },
                {
                    keys: [
                        { action: 'space', source: 'static_assets/landscapespacebar.png', w: 1308 },
                    ],
                },
            ],
        },
        ABC: {
            rows: [
                {
                    keys: [{ c: '1' }, { c: '2' }, { c: '3' }, { c: '4' }, { c: '5' }, { c: '6' }, { c: '7' }, { c: '8' }, { c: '9' }, { c: '0' }],
                },
                {
                    keys: [{ c: 'A' }, { c: 'B' }, { c: 'C' }, { c: 'D' }, { c: 'E' }, { c: 'F' }, { c: 'G' }, { c: 'H' }, { c: 'I' }, { c: 'J' }],
                },
                {
                    keys: [{ c: 'K' }, { c: 'L' }, { c: 'M' }, { c: 'N' }, { c: 'O' }, { c: 'P' }, { c: 'Q' }, { c: 'R' }, { c: 'S' }, { c: 'T' }],
                },
                {
                    keys: [{ c: 'U' }, { c: 'V' }, { c: 'W' }, { c: 'X' }, { c: 'Y' }, { c: 'Z' }, { c: '?' }, { c: '.' }, { c: '#' }, { c: '@' }],
                },
                {
                    keys: [
                        { action: 'space', source: 'static_assets/landscapespacebar.png', w: 1308 },
                    ],
                },
            ]


        },
        SPCHAR: {
            rows: [
                {
                    keys: [{ c: '1' }, { c: '2' }, { c: '3' }, { c: '4' }, { c: '5' }, { c: '6' }, { c: '7' }, { c: '8' }, { c: '9' }, { c: '0' }],
                },
                {
                    keys: [{ c: '!' }, { c: '$' }, { c: '%' }, { c: '^' }, { c: '&' }, { c: '*' }, { c: '(' }, { c: ')' }, { c: '_' }, { c: '-' }],
                },
                {
                    keys: [{ c: '+' }, { c: '=' }, { c: '{' }, { c: '}' }, { c: '[' }, { c: ']' }, { c: '|' }, { c: '\\' }, { c: "'" }, { c: ':' }],
                },
                {
                    keys: [{ c: ';' }, { c: '?' }, { c: '.' }, { c: '#' }, { c: '@' }],
                },
                {
                    keys: [
                        { action: 'space', source: 'static_assets/landscapespacebar.png', w: 1308 },
                    ],
                },
            ],
        },
    },
}

export default template
