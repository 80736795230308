/**
 * Represents the response for all wishlist contents.
 */
export class WishlistResponse {
  constructor(response) {
    this.statusCode = response.status_code;
    this.statusMessage = response.message;
    this.data = new WishlistContentData(response.data);
  }
}

export class WishlistContentData {
  constructor(data) {
    this.contents = data.contents.map((item) => new WishlistContentItem(item));
    this.total = data.total;
  }
}

export class WishlistContentItem {
  constructor(value) {
    this.count = value.count;
    this.key = value.key;
    this.orientation = value.orientation;
    this.title = value.title;
    this.list = value.list.map((item) => new WishlistListItem(item, value.key));
  }
}

export class WishlistListItem {
  constructor(data, key) {
    this.contentId = data.content_id;
    this.titleEn = data.title_en;
    this.createdAt = data.created_at;
    this.age = data.age;
    this.isTvod = data.is_tvod;
    this.collectionSlug = data.collection_slug;
    this.seriesSlug = data.series_slug;
    this.contentSlug = data.content_slug;
    this.contentIdAlt = data.contentId;
    this.seasonsSlug = data.seasons_slug;
    this.isSeries = data.is_series;
    this.isLiveChannel = data.is_livechannel;
    this.releaseDate = data.release_date;
    this.publishTime = data.publish_time;
    this.isLocalLogo = data.is_local_logo
    this.key = key
    this.rent = data.rent || {}
    this.buy = data.buy || {}
    this.durationMin = data.duration_min || 0
    this.durationSec = data.duration_sec || 0
    this.trailerId = data.trailer_id;
    this.totalSeasons = data.total_seasons;
    this.seriesEpisodesCount = data.series_episodes_count;
    this.title = data.title;
    this.description = data.description;
    this.tags = data.tags;
    this.posterLandscape = data.poster_landscape;
    this.posterPortrait = data.poster_portrait;
    this.backgroundLandscape = data.background_landscape;
    this.backgroundPortrait = data.background_portrait;
    this.backgroundLandscapeCenter = data.background_landscape_center;
    this.screenshotLandscape = data.screenshot_landscape;
    this.posterSquare = data.poster_square;
    this.contentLogo = data.content_logo;
    this.genre = data.genre;
    this.curation = data.curation;
  }
}
