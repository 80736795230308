import { Lightning, Router, Colors } from '@lightningjs/sdk';
import { Grid } from '@lightningjs/ui';
import Item from '../VideoSlider/Item';
import { getViewAllVideos } from '../../Services/AssetsService'
import { ViewAllContentResponse } from '../../Models/ViewAllModel'
import { createViewAllItems } from '../../Helper/Helpers';

export default class ViewAllGrid extends Lightning.Component {
    static _template() {
        return {
            Title: { zIndex: 9, x: 180, y: 655, color: Colors('primaryFontColor1').get(), h: 70 },
            GridView: {
                clipping: true, w: 1730, h: 422, x: 180, y: 730,
                Items: {
                    visible: true, x: 10, y: 30, zIndex: 8, alpha: 1, w: 1730, h: 390, direction: 'column', columns: 5, crossSpacing: 35, mainSpacing: 35, itemType: Item, type: Grid, scrollTransition: { duration: 0.4 }, signals: { onIndexChanged: true }
                }
            },
        };
    }

    set total(val) {
      this.totalCount = val
    }

    _active() {
        this.tag('Items').setSmooth('alpha', 1)
        this._refocus()
        this.tag('Items').scroll = { after: 1 } // this solves initial focus and scroll
    }

    _init() {
        this.tag('Items').clear()
        this._refocus()
    }

    onIndexChanged(indexData) {
        const { index: currentIndex } = indexData
        if (this.tag('Items')._lines?.[this.tag('Items')._lines.length - 1].includes(currentIndex)) {
          this.requestNextItems()
        }
        this.tag('Items').scroll = (itemWrapper) => {
            const value = itemWrapper.finalY
            return -value
        }
    }

    async requestNextItems() {
        const skip = this.tag('Items').items.length;
        const take = 16
        if (skip + 1 > this.totalCount) { //+1 because here index starts from 0
            return
        }
        this.queryParams = ViewAllGrid.params
        const key = this.queryParams[1]
        await getViewAllVideos(this.queryParams[0], key, this.queryParams[2], skip, take)
            .then((response) => {
                let viewAllModel
                if (key === 'continue' || key === 'wishlist' || key === 'purchases' || key.includes('because_you_watched') || key === 'top_picks_for_you') {
                  const selectedPersonaViewMore = response.data.find(personaItem => personaItem.key === key)
                  selectedPersonaViewMore.status_code = response.status_code
                  viewAllModel = new ViewAllContentResponse(selectedPersonaViewMore)
                } else {
                  viewAllModel = new ViewAllContentResponse(response)
                }
                const totalData = viewAllModel?.data
                if (viewAllModel.statusCode == 200) {
                    const children = createViewAllItems(totalData)
                    this.totalCount = totalData.totalCount
                    this.tag('Items').addAt(children, this.tag('Items').items.length)
                    this.tag('Title').text = totalData.title
                    this.tag('Items').setSmooth('alpha', 1, { delay: 0.2 });
                }
            })
    }

    _getFocused() {
        return this.tag('Items')
    }

    _handleDown() {
        if (this.tag('Items')._lines?.[this.tag('Items')._lines.length - 1].includes(this.tag('Items')._index))
            return
        this.tag('Items').last()
    }

    _handleLeft() {
        Router.focusWidget('Menu');
    }

    _handleBack() {
        Router.back()
    }

    _handleKey() { return }

    _handleUp() { return }

    _handleRight() {
        this.tag('Items').next();
    }
}
