import { VASTClient } from './VastClient'
// let VASTClient = new VASTClient()
export const cliemtVmapXml = async (vmapURL) => {
  let str = await fetch(vmapURL).then(response => response.text())
  // .then(str => {
  const parser = new DOMParser();  // initialize dom parser
  const srcDOM = parser.parseFromString(str, "application/xml");  // convert dom string to dom tree.
  let jsonText = xmlToJson(srcDOM); // xmlDoc = xml dom document
  let Ads = jsonText['vmap:VMAP']['vmap:AdBreak'];
  let adsArray = []

  if (typeof Ads === 'object') {
    Array.isArray(Ads) ? adsArray = Ads : adsArray.push(Ads)
  }
  let processedAds = []
  for (let i = 0; i < adsArray.length; i++) {
    let adObj = {}
    if (adsArray[i].attributes.timeOffset == 'start') {
      adObj['time'] = 'start'
    } else if (adsArray[i].attributes.timeOffset == 'end') {
      adObj['time'] = 'end'
    } else {
      const timeString = adsArray[i].attributes.timeOffset; // input string

      const arr = timeString.split(":"); // splitting the string by colon

      const seconds = arr[0] * 3600 + arr[1] * 60 + (+arr[2]); // converting

      adObj['time'] = seconds
    }
    adObj['adUrl'] = adsArray[i]['vmap:AdSource']['vmap:AdTagURI']['#cdata-section']
    processedAds.push(adObj)
  }
  return processedAds
  // })

}
export const xmlToJson = (xml) => {
  let obj = {};
  if (xml.nodeType == 1) {
    if (xml.attributes.length > 0) {
      obj["attributes"] = {};
      for (let j = 0; j < xml.attributes.length; j++) {
        let attribute = xml.attributes.item(j);
        obj["attributes"][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType == 3) {
    obj = xml.nodeValue;
  }
  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      let item = xml.childNodes.item(i);
      let nodeName = item.nodeName;
      if (nodeName == '#cdata-section') {
        obj[nodeName] = xml.childNodes.item(i).data
      } else if (typeof (obj[nodeName]) == "undefined") {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (typeof (obj[nodeName].push) == "undefined") {
          let old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }
  return obj;
}
export const prepareAd = async (adUrl) => {
  let VAST = new VASTClient()
  let res = await VAST.get(adUrl) // here we prepare Ad based on Url.
  return res
}
