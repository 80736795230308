/**
 * Class representing the response for home data.
 */
export class HomeResponse {
    /**
     * Creates an instance of HomeResponse.
     * @param {Object} data - The input object representing the home response.
     */
    constructor(data) {
        this.statusCode = data.status_code;
        this.statusMessage = data.message;
        this.homeData = data.data ? data.data.map(item => new HomeDataItem(item)) : [];
    }
}

/**
 * Class representing details for home data item.
 */
class HomeDataItem {
    /**
     * Creates an instance of HomeDataItem.
     * @param {Object} data - The input object representing a home data item.
     */
    constructor(data) {
        this.items = data?.list?.map(item => new HomeListItem(item)) || [];
        this.title = data.title || "";
        this.key = data.key || "";
        this.contentTitle = data.content_title || ''
        this.orientation = data.orientation || 0;
        this.takeValue = data.take_value || 0;
        this.count = data.count || 0;
        this.totalCount = data.total_count || 0;
        this.slug = data.slug || "";
        this.categorySlug = data.category_slug || "";
        this.component = data.component || "";
        this.purchases = data.purchases || {}
    }
}

/**
 * Class representing details for a home list item.
 */
class HomeListItem {
    /**
     * Creates an instance of HomeListItem.
     * @param {Object} data - The input object representing a home list item.
     */
    constructor(data) {
        this.publishTime = data.publish_time || null;
        this.contentId = data.content_id || null;
        this.title = data.title || "";
        this.description = data.description || "";
        this.tags = data.tags || "";
        this.director = data.director || "";
        this.writer = data.writer || "";
        this.producer = data.producer || "";
        this.cast = data.cast || "";
        this.contentLogo = data.content_logo || "";
        this.screenshotLandscape = data.screenshot_landscape || "";
        this.backgroundPortrait = data.background_portrait || "";
        this.backgroundLandscapeCenter = data.background_landscape_center || "";
        this.backgroundLandscape = data.background_landscape || "";
        this.posterSquare = data.poster_square || "";
        this.posterLandscape = data.poster_landscape || "";
        this.posterPortrait = data.poster_portrait || "";
        this.collectionLogo = data.collection_logo || ""
        this.titleEn = data.title_en || "";
        this.age = data.age || 0;
        this.isTvod = data.is_tvod || 0;
        this.rent = data.rent || {}
        this.buy = data.buy || {}
        this.releaseDate = data.release_date || "";
        this.position = data.position || 0;
        this.durationMin = data.duration_min || 0;
        this.key = data.key || ''
        this.trailerId = data.trailer_id || "";
        this.seriesSlug = data.series_slug || "";
        this.contentSlug = data.content_slug || "";
        this.slug = data.slug || ""
        this.seasonsSlug = data.seasons_slug || "";
        this.seasonPosition = data.season_position || 0;
        this.contentIdAlt = data.contentId || "";
        this.collectionSlug = data.collection_slug || "";
        this.collectionType = data.collection_type || "";
        this.isSeries = data.is_series || 0;
        this.isLiveChannel = data.is_livechannel || 0;
        this.contentLogoResolutions = data.content_logo_resolutions || {};
        this.backgroundPortraitResolutions = data.background_portrait_resolutions || {};
        this.backgroundLandscapeCenterResolutions = data.background_landscape_center_resolutions || {};
        this.backgroundLandscapeResolutions = data.background_landscape_resolutions || {};
        this.posterSquareResolutions = data.poster_square_resolutions || {};
        this.posterLandscapeResolutions = data.poster_landscape_resolutions || {};
        this.isLocalLogo = data.is_local_logo || 0
        this.posterPortraitResolutions = data.poster_portrait_resolutions || {};
        this.totalSeasons = data.total_seasons || 0;
        this.seriesEpisodesCount = data.series_episodes_count || 0;
        this.genre = data.genre || "";
        this.curation = data.curation || "";
        this.availability = data.availability || 0;
        this.trailers = data.trailers || [];
        this.seek = data.seek || 0;
        this.percentage = data.percentage || 0;
        this.userType = data.user_type || 0;
        this.wishlistStatus = data.wishlist_status || false;
        this.durationSec = data.duration_sec || 0;
    }
}

