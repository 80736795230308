/**
 * Class representing the response for category data.
 */
export class DevicesAPIResponse {
  /**
   * Creates an instance of DevicesAPIResponse.
   * @param {Object} data - The input object representing the Devices API response.
   */
  constructor(data) {
    this.statusCode = data.status_code
    this.statusMessage = data.message
    this.devicesData = data.data ? data.data.devices.map(item => new DeviceItemObject(item)) : []
  }
}

/**
* Class representing details for a device item.
*/
class DeviceItemObject {
  /**
   * Creates an instance of DeviceItemObject.
   * @param {Object} data - The input object representing a device data item.
   */
  constructor(data) {
    this.uniqueId = data.unique_id || ''
    this.userId = data.user_id || 0
    this.deviceType = data.device_type || ''
    this.deviceTitle = data.device_title || ''
    this.ipAddress = data.ip_address || ''
    this.userAgent = data.user_agent || ''
    this.loginBy = data.login_by || ''
    this.deviceId = data.device_id || ''
    this.uniqueDeviceId = data.unique_device_id || ''
    this.userDeviceInfo = data.user_device_info || {}
    this.cityOrCountry = data.city_or_country || ''
    this.updatedAt = data.updated_at || ''
    this.streamStatus = data.stream_status || 0
    this.currentDevice = data.current_device || 0
  }
}
