import { Language, Lightning, Router, Utils, Storage, Colors } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { transition, animation } from '../lib/helpers'
import { Shadow } from '@lightningjs/ui-components'
import App from '../App'
import configurations from '../Helper/Configurations'
import { returnImageUrl, showExitPopUp } from '../Helper/Helpers'
import { imageSizes } from '../Helper/GlobalConstants'

export default class Menu extends Lightning.Component {
    static _template() {
        return {
            w: 140,
            h: 1080,
            CollapseIcon: {
                zIndex: 11,
                alpha: 1,
                w: 140,
                h: 225,
                flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
                Logo: {
                    src: Utils.asset('static_assets/menuShrinkedLogo.png'),
                }
            },
            ExpansionIcon: {
                zIndex: 11,
                alpha: 0,
                w: 340,
                h: 225,
                flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
                Logo: {
                    src: Utils.asset('static_assets/menuExpandedLogo.png'),
                }
            },
            Shadow: {
                zIndex: 8,
                x: 15,
                type: Shadow,
                h: App.height,
                w: 140,
                style: {
                    alpha: 1,
                    color: Colors('shadowColor').get(),
                }
            },
            Background: {
                w: 140, h: 1080, zIndex: 9,
                rect: true,
                color: Colors('menuColor').get()
            },
            List: {
                alpha: 0,
                zIndex: 10,
                spacing: 100,
                forceLoad: true,
                direction: 'column',
                y: 230,
                x: 0,
                w: 340,
                h: 710,
                clipping: true,
                type: List,
                signals: { onIndexChanged: true }
            },
            CollapsedList: {
                zIndex: 10,
                spacing: 100,
                forceLoad: true,
                direction: 'column',
                y: 230,
                x: 0,
                w: 140,
                h: 710,
                clipping: true,
                type: List,
                signals: { onIndexChanged: true }
            }
        }
    }

    set data(categoryData) {
        //navigateKey is the route path of page to navigate
        const homeObject = { 'title': Language.translate('common_keys.home.text'), 'icon_med': 'static_assets/home.png', 'navigateKey': 'home' }
        const searchObject = { 'title': Language.translate('common_keys.search.btn'), 'icon_med': 'static_assets/search.png', 'navigateKey': 'search' }
        const settingObject = { 'title': Language.translate('account.settings.text'), 'icon_med': 'static_assets/settings.png', 'navigateKey': 'settings' }
        const wishlistObject = { 'title': Language.translate('common_keys.my_list.text'), 'icon_med': 'static_assets/Icon_mylist.png', 'navigateKey': 'wishlist' }

        // Modify each object in the categoryData array by adding a dynamic navigateKey based on the categoryId.
        const routePrefix = configurations.enableHlsStream ? 'hlsliveplayer' : 'dashliveplayer'
        categoryData = categoryData.map((val) => {
            return val.slug === 'live-channel'
                ? configurations.epgEnabled
                    ? configurations.enableScheduleEPG
                        ? { ...val, 'navigateKey': `tvguide/${val.categoryId}` }
                        : { ...val, 'navigateKey': `${routePrefix}/${val.categoryId}/1` }
                    : { ...val, 'navigateKey': `category/${val.categoryId}` }
                : { ...val, 'navigateKey': `category/${val.categoryId}` }

        })

        this._items = categoryData
        this._items.unshift(homeObject, searchObject)
        if (configurations.loggedIn)
            this._items.push(wishlistObject)
        this._items.push(settingObject)
        const selectedIndex = Storage.get('activeCategory')
        const items = this._items.map((item, ind) => {
            return { type: MenuItem, item, selected: ind === selectedIndex ? true : false } //selected: falsetrue
        })
        this.tag('List').clear()
        this.tag('List').add(items)

        const collapsedItems = this._items.map((item, ind) => {
            return { type: CollapsedMenuItem, item, selected: ind === selectedIndex ? true : false } //selected: falsetrue
        })
        this.tag('CollapsedList').clear()
        this.tag('CollapsedList').add(collapsedItems)

        this.tag('List').scroll = { after: this._items.length > 9 ? 6 : 9 }
        this.tag('CollapsedList').scroll = { after: this._items.length > 9 ? 6 : 9 }
    }

    _pivotAnimation(value) {
        if (value === this._pivotValue) {
            return
        }
        this._anim = animation(this._anim, 'Focus', this, {
            duration: 0.3,
            actions: [
                { t: 'Lighting', p: 'shader.pivotY', v: { 0: this.tag('Lighting').shader.pivotY, 1: value } }
            ]
        })
        this._pivotValue = value
    }

    _handleUp() {
        this._pivotValue = 0.5
    }

    _handleDown() {
        this._pivotValue = 0.5
    }

    _handleKey() {
        return true
    }

    _handleBack() {
        if (Router.getActiveHash().includes('home')) return showExitPopUp()
        this.menuCollapse()
        Router.navigate('home')
    }

    _handleRight() {
        this.menuCollapse()
        Router.focusPage()
    }

    menuExpansion() {
        this.patch({
            ExpansionIcon: { alpha: 1 },
            CollapseIcon: { alpha: 0 },
            Shadow: { smooth: { x: 225 } },
            Background: { smooth: { w: 340 } },
            CollapsedList: { alpha: 0 },
            List: { alpha: 1 },
        })
    }

    menuCollapse() {
        this.patch({
            ExpansionIcon: { alpha: 0 },
            CollapseIcon: { alpha: 1 },
            Shadow: { smooth: { x: 15 } },
            Background: { smooth: { w: 140 } },
            CollapsedList: { alpha: 1 },
            List: { alpha: 0 },
        })
    }

    _focus() {
        this.menuExpansion()
    }

    _unfocus() {
        if (!Router.isNavigating()) {
            this.menuCollapse()
            this.tag('List').setIndex(this._selectedIndex)
            this.tag('CollapsedList').setIndex(this._selectedIndex)
        }
    }

    _getFocused() {
        return this.tag('List')
    }

    _onActivated(_page) {
        const list = this.tag('List')
        const currentRouteIndex = this._selectedIndex = Storage.get('activeCategory')
        list.items.forEach((item, index) => {
            item.selected = index === currentRouteIndex
        })
        list.setIndex(currentRouteIndex)

        const collapsedList = this.tag('CollapsedList')
        collapsedList.items.forEach((item, index) => {
            item.selected = index === currentRouteIndex
        })
        collapsedList.setIndex(currentRouteIndex)

        if (!this.active) {
            transition(this._focusTransitionY, Menu.focusDefaultPosition + 130 * currentRouteIndex, 1)
            this._focusTransitionY.finish()
        }
    }

    _handleEnter() {
        Storage.set('stateInfo', JSON.stringify({ banner: 1 }))
        const selectedMenuCategory = this.tag('List').items[this.tag('List').index]
        if (Router.getActiveHash().includes(selectedMenuCategory.item.categoryId) ||
            Router.getActiveHash().includes(selectedMenuCategory.item.navigateKey)) {
            this.menuCollapse()
            return Router.focusPage()
        }
        this.patch({
            ExpansionIcon: { alpha: 0 },
            CollapseIcon: { alpha: 1 },
            Shadow: { x: 15 },
            Background: { w: 140 },
            CollapsedList: { alpha: 1 },
            List: { alpha: 0 },
        })
        if (selectedMenuCategory.item.navigateKey.includes('tvguide') ||
            selectedMenuCategory.item.navigateKey.includes('hlsliveplayer') ||
            selectedMenuCategory.item.navigateKey.includes('dashliveplayer')) {
            Storage.set('EpgChannelSelected', JSON.stringify({ index: 0, id: '', from: 'menu' })) //contentId won't be available at menu phase
        }
        Router.navigate(selectedMenuCategory.item.navigateKey)
    }

    static get focusDefaultPosition() {
        return 230
    }
}

class MenuItem extends Lightning.Component {
    static _template() {
        return {
            alpha: 0.8,
            Focus: {
                texture: Lightning.Tools.getRoundRect(295, 60, 0, 0, Colors('backgroundColor').get(), false),
                y: 0,
                x: 20,
            },
            Container: {
                x: 35, w: 337,
                flex: { direction: 'row' },
                Icon: { w: 40, h: 40, scale: 0.9, y: 10, flexItem: { marginRight: 25 } },
                Label: {
                    alpha: 1, y: 30, mountY: 0.43,
                    text: {
                        fontFace: 'Regular',
                        fontSize: 25,
                        textColor: Colors('primaryFontColor1').get(),
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        wordWrapWidth: 190,
                        maxLines: 1,
                        maxLinesSuffix: '...',
                    },
                },
            }


        }
    }

    _init() {
        this._updateFocusAnimation()
    }

    _firstActive() {
        if (this.item.categoryId) {
            this.tag('Icon').patch({
                src: Utils.proxyUrl(returnImageUrl(this.item.iconUnfocused, imageSizes.icon)),
            })
        } else {
            this.tag('Icon').patch({
                src: Utils.asset(this.item.icon_med),
                color: Colors('primaryFontColor1').get()
            })
        }
        this.patch({ Container: { Label: { text: this.item.title } } })
    }


    _focus() {
        this.tag('Focus').patch({
            texture: Lightning.Tools.getRoundRect(295, 60, 1, 3, Colors('brandPrimaryColor').get(), true, Colors('focusedBtnColor').get()),
        })
    }

    _unfocus() {
        !this._selected
            ? this.tag('Focus').patch({
                texture: Lightning.Tools.getRoundRect(295, 60, 0, 0, Colors('backgroundColor').get(), false),
            })
            //selected one
            : this.tag('Focus').patch({
                texture: Lightning.Tools.getRoundRect(295, 60, 1, 3, Colors('selectedBtnColor').get(), true, Colors('selectedBtnColor').get()),
            })

        this.tag('Icon').patch({
            color: Colors('primaryFontColor1').get()
        })
        this.patch({ Container: { Label: { text: this.item.title } } })
    }

    _updateFocusAnimation(bool = this._selected) {
        if (this._focusAnimation && this._focusAnimation.isActive()) {
            this._focusAnimation.stopNow()
        }

        this._focusAnimation = this.animation({
            duration: 0.2, actions: [
                { p: 'alpha', v: { 0: bool ? 1 : 0.8, 1: 1 } },
                { t: 'Label', p: 'x', v: { 0: 190, 1: 140 } }
            ]
        })
    }

    set selected(bool) {
        this._updateFocusAnimation(bool)
        this.alpha = bool ? 1 : 0.8
        this._selected = bool
        this.tag('Icon').patch({
            color: Colors('primaryFontColor1').get()
        })
        bool
            ? this.tag('Focus').patch({
                texture: Lightning.Tools.getRoundRect(295, 68, 1, 3, Colors('selectedBtnColor').get(), true, Colors('selectedBtnColor').get()),
            })
            : this.tag('Focus').patch({
                texture: Lightning.Tools.getRoundRect(295, 60, 0, 0, Colors('backgroundColor').get(), false),
            })

    }

    get selected() {
        return this._selected
    }

    static get marginBottom() {
        return 30
    }

    static get width() {
        return 50
    }

    static get height() {
        return 50
    }
}

class CollapsedMenuItem extends Lightning.Component {
    static _template() {
        return {
            alpha: 0.8,
            Focus: {
                texture: Lightning.Tools.getRoundRect(295, 60, 0, 0, Colors('backgroundColor').get(), false),
                y: 0,
                x: 23,
            },
            Container: {
                x: 35, w: 337,
                flex: { direction: "row" },
                Icon: { w: 40, h: 40, scale: 0.9, y: 11, flexItem: { marginRight: 25 } },
            }
        }
    }

    _init() {
        this._updateFocusAnimation()
    }

    _firstActive() {
        if (this.item.categoryId) {
            this.tag('Icon').patch({
                src: Utils.proxyUrl(returnImageUrl(this.item.iconUnfocused, imageSizes.icon)),
            })
        } else {
            this.tag('Icon').patch({
                src: Utils.asset(this.item.icon_med),
                color: Colors('primaryFontColor1').get()
            })
        }
    }


    _updateFocusAnimation(bool = this._selected) {
        if (this._focusAnimation && this._focusAnimation.isActive()) {
            this._focusAnimation.stopNow()
        }

        this._focusAnimation = this.animation({
            duration: 0.2, actions: [
                { p: 'alpha', v: { 0: bool ? 1 : 0.8, 1: 1 } },
            ]
        })
    }

    set selected(bool) {
        this._updateFocusAnimation(bool)
        this.alpha = bool ? 1 : 0.8
        this._selected = bool
        this.tag('Icon').patch({
            color: Colors('primaryFontColor1').get()
        })
        bool
            ? this.tag('Focus').patch({
                texture: Lightning.Tools.getRoundRect(60, 60, 1, 3, Colors('selectedBtnColor').get(), true, Colors('selectedBtnColor').get()),
            })
            : this.tag('Focus').patch({
                texture: Lightning.Tools.getRoundRect(295, 60, 0, 0, Colors('backgroundColor').get(), false),
            })

    }

    get selected() {
        return this._selected
    }

    static get marginBottom() {
        return 30
    }

    static get width() {
        return 50
    }

    static get height() {
        return 50
    }
}
