import { Lightning, Router } from '@lightningjs/sdk'

export default class SkipButton extends Lightning.Component {
  static _template() {
    return {/* Google will take care about skip Ads Ui. We donot need to write anything. */}
  }

  _handleEnter() {
    Router.getActivePage().skipAd()
  }

  _handleLeft() {
    //
  }

  _handleRight() {
    //
  }

  _handleUp() {
    //

  }

  _handleDown() {
    //
  }

  _handleKey() {
    //
  }
}
