import { Category } from '../Views'
import { getCategory } from '../Services/AssetsService'
import { CategoryPageData } from '../Models/CategoryPageData'
import { BANNER_WIDGET, CONTINUE_WATCHING_WIDGET, MYLIST_WIDGET } from '../Helper/GlobalConstants'
import { getContinueWatchingVideos } from '../Services/PersonaService'
import { appendPersonaVideos, createPageComponents } from '../Helper/Helpers'
import configurations from '../Helper/Configurations'
import { Storage } from '@lightningjs/sdk'
/**
 * Defines the configuration for the 'category' page.
 */
export default {
  path: 'category/:categoryId',
  options: {
    reuseInstance: false
  },
  component: Category,
  /**
   * Defines the asynchronous setup for the 'category' page.
   * @param {Object} page - The page instance.
   */
  on: async (page, { categoryId }) => {
    await getCategory(categoryId)
      .then(async response => {
        let categoryData

        const parsedCategoryData = new CategoryPageData(response)

        const filteredData = parsedCategoryData.categoryPageData.filter(item => item.items?.length > 0)

        const dataWithoutBannerVideos = filteredData.filter((obj) => obj.component !== BANNER_WIDGET)

        const continuewatchingExists = parsedCategoryData.categoryPageData.some(Obj => Obj.component === CONTINUE_WATCHING_WIDGET)

        const wishlistExists = parsedCategoryData.categoryPageData.some(Obj => Obj.component === MYLIST_WIDGET)

        if (configurations.loggedIn && (continuewatchingExists || wishlistExists)) {
          const categorySlug = configurations.categories.categoryData.find(category => category.categoryId === categoryId).slug
          const personaAPIResponse = await getContinueWatchingVideos(categorySlug)
          const parsedPersonaResponse = new CategoryPageData(personaAPIResponse)
          categoryData = appendPersonaVideos(parsedCategoryData.categoryPageData, parsedPersonaResponse.categoryPageData)
        } else {
          categoryData = filteredData
        }

        const initialCarouselsArray = categoryData.slice(0, 4)

        page.setListData(dataWithoutBannerVideos, parsedCategoryData.categoryPageData, initialCarouselsArray)

        page.addLists(createPageComponents(initialCarouselsArray))


        // page.widgets.menu.menuCollapse()
        let categoryIndex
        configurations.categories.categoryData.forEach((Obj, index) => {
          if (Obj.categoryId === categoryId) {
            categoryIndex = index
          }
        })
        Storage.set('activeCategory', categoryIndex + 2)
        return true
      })
  },
  /**
   * List of widgets to be included on the 'category' page.
   */
  widgets: ['menu']
}
