import { SVODScreen } from '../Views'
/**
 * Defines the configuration for the 'SVODScreen' page.
 */
export default {
    path: 'svodpopup/:action',
    component: SVODScreen,
    on: async (page, { action }) => {
      return page.data = action
    },
}
