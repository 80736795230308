import { Lightning, Router } from '@lightningjs/sdk'
import { Grid } from '@lightningjs/ui'
import { userSettingsAPI } from '../../Services/AuthService'
import { getUserDevicesInfo, logout } from '../../Services/PersonaService'
import DeviceItem from '../UserDevicesList/DeviceItem'
import App from '../../App'
import Loader from '../Loader'
import { DevicesAPIResponse } from '../../Models/DeviceManagementModel'
import configurations from '../../Helper/Configurations'

export default class UserDevicesList extends Lightning.Component {
  static _template() {
    return {
      visible: true,
      GridView: {
        alpha: 1,
        w: 1440,
        h: 800,
        clipping: true,
        direction: 'column',
        columns: 2,
        crossSpacing: 35,
        mainSpacing: 25,
        itemType: DeviceItem,
        type: Grid,
      },
      Loading: { type: Loader, visible: false, zIndex: 10 }
    }
  }

  _active() {
    this.tag('GridView').setSmooth('alpha', 1)
  }

  _init() {
    this.tag('GridView').clear()
  }

  positionUpdate(pos) {
    this.tag('GridView').patch({ y: pos })
  }

  set data(data) {
    const dynamicXPos = (App.width - 1440) / 2
    this.tag('GridView').patch({ x: dynamicXPos - 5 })
    this.devicesData = data[0].devicesData
    this.checkFlow = data[1]
    this.prepareGridItems(this.devicesData)
  }

  prepareGridItems(deviceData) {
    const children = deviceData.map((item) => {
      return { type: DeviceItem, item }
    })

    this.tag('GridView').clear()
    this.tag('GridView').add(children)
    this.tag('GridView').setIndex(0)
    this.tag('GridView').scroll = { after: this.devicesData.length > 4 ? 2 : 3 }
  }

  _getFocused() {
    return this.tag('GridView')
  }

  _handleDown() {
    this._handleRight()
  }

  _handleUp() {
    if (this.tag('GridView').index == 0 || this.tag('GridView').index == 1) Router.getActivePage().focusSignOutButton()
  }

  _handleLeft() {
    this.tag('GridView').previous()
  }

  _handleEnter() {
    Router.getActivePage().openSignOutPopUp([this.devicesData[this.tag('GridView').index], this.checkFlow])
  }

  get loaderTag() {
    return this.tag('Loading')
  }

  displayLoader() {
    this.loaderTag.visible = true
  }

  hideLoader() {
    this.loaderTag.visible = false
  }

  async logoutIndividualDevice() {
    this.displayLoader()
    const selectedDevice = this.devicesData[this.tag('GridView').index].deviceId
    await logout(selectedDevice)
    const apiResponse = await getUserDevicesInfo()
    this.devicesData = new DevicesAPIResponse(apiResponse).devicesData
    this.prepareGridItems(this.devicesData)
    this.hideLoader()
    Router.getActivePage().focusDevicesList()
    if (this.checkFlow == 'during-sign-in') {
      const userSettingsAPIRes = await userSettingsAPI()
      const checkDeviceLimit = userSettingsAPIRes.data.device_limit_exceed
      if (!checkDeviceLimit) {
        if (configurations.enableProfiles) return Router.navigate('profile/add')
        Router.navigate('home')
      }
    }
  }

  _handleBack() {
    if (this.checkFlow == 'during-sign-in') return this.application.closeApp()
    const routeHistory = Router.getHistory()
    const lastEntry = routeHistory[routeHistory.length - 1]
    lastEntry.hash == 'editname' || lastEntry.hash.includes('player') ? Router.go(-2) : Router.back()
  }

  _handleRight() {
    this.tag('GridView').next()
  }
}
