import { Lightning, Router, Language, Storage, Utils, Colors } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { generateQRCode, processTranslationFile, showDialog } from '../../Helper/Helpers'
import { platformSettingsAPI } from '../../Services/AuthService'
import { PlatformSettingResponse } from '../../Models/PlatformSettingModel'
import { getCategories } from '../../Services/AssetsService'
import configurations from '../../Helper/Configurations'
import IconDynamicButton from '../ButtonsList/IconDynamicButton'

export default class SettingsView extends Lightning.Component {
  static _template() {
    return {
      // Used List UI component for scrollable buttons
      SettingsView: {
        zIndex: 1, x: 814, y: 120, texture: Lightning.Tools.getRoundRect(1011, 840, 12, 1, Colors('primaryFontColor1').get(), true, Colors('accountsBgColor').get()),
        LanguageOptions: {
          alpha: 0, x: 60, y: 40,
          ListItems: { h: 750, w: 951, clipping: true, type: List, direction: 'column', spacing: 24, }
        },
        StaticPageView: {
          alpha: 0,
          Title: { x: 60, y: 60, text: { text: 'About Us', fontSize: 40, textColor: Colors('primaryFontColor1').get(), maxLinesSuffix: '...', fontFace: 'Bold', textAlign: 'Center' } },
          Description: { x: 60, y: 143, text: { text: 'Description', fontSize: 24, textColor: Colors('primaryFontColor1').get(), maxLinesSuffix: '...', fontFace: 'Bold', textAlign: 'Center' } },
          Link: { text: { text: 'Link', fontSize: 32, textColor: Colors('primaryFontColor1').get(), maxLinesSuffix: '...', fontFace: 'Bold', textAlign: 'Center' } }
        },
        StaticPageScrollView: {
          alpha: 0,
          Title: { y: 60, x: 40, text: { fontSize: 24, textColor: Colors('primaryFontColor1').get(), maxLinesSuffix: '...', fontFace: 'Bold', maxLines: 2, lineHeight: 34, wordWrapWidth: 850 } },
          URLTag: { y: 180, text: { fontSize: 32, textColor: Colors('primaryFontColor1').get(), maxLinesSuffix: '...', fontFace: 'Bold', wordWrapWidth: 500, maxLines: 1 } },
          Divider: {
            x: 40, y: 300, flex: { direction: 'row' },
            Divider1: { flexItem: { marginRight: 10 }, texture: Lightning.Tools.getRoundRect(410, 1, 1, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()) },
            OR: { flexItem: { marginRight: 10 }, y: -15, text: { fontSize: 26, textColor: Colors('primaryFontColor1').get(), fontFace: 'Bold', wordWrapWidth: 100, maxLines: 1, maxLinesSuffix: '...' } },
            Divider2: { texture: Lightning.Tools.getRoundRect(410, 1, 1, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()) },
          },
          QRCodeImage: { x: 330, y: 400, w: 360, h: 360 }
        },
        HouseholdView: {
          alpha: 0,
          Title: {
            y: 60, x: 60, text: { text: Language.translate('my_account.profile.text'), fontSize: 40, textColor: Colors('primaryFontColor1').get(), maxLinesSuffix: '...', fontFace: 'Bold', textAlign: 'Center' },
          },
          Household: {
            y: 158, x: 60,
            text: { text: Language.translate('common_keys.household.text'), fontSize: 24, textColor: 0xff999999, maxLinesSuffix: '...', fontFace: 'Bold', textAlign: 'Center' },
          },
          HouseholdText: { y: 158, x: 280, text: { text: '', fontSize: 24, textColor: Colors('primaryFontColor1').get(), maxLinesSuffix: '...', fontFace: 'Bold', textAlign: 'Center' } },
        },
        UserDetailsView: {
          x: 50, w: 1011, h: 790, y: 50,
          visible: false,
          flex: { direction: 'column', justifyContent: 'flex-start' },
          ProfileInfo: {
            visible: false,
            flex: { direction: 'column' },
            ProfileDetails: {
              flex: { direction: 'row' },
              ProfileTexture: {
                visible: false,
                flexItem: { marginRight: 30 },
                texture: Lightning.Tools.getRoundRect(130, 130, 65, 1, Colors('brandPrimaryColor').get(), true, Colors('brandPrimaryColor').get(),),
                ProfileLetter: {
                  text: { textColor: Colors('primaryFontColor1').get(), fontSize: 54, fontFace: 'Bold' }
                },
              },
              ProfileImage: {
                visible: false,
                flexItem: { marginRight: 20 },
                w: 130,
                h: 130,
                shader: { type: Lightning.shaders.RoundedRectangle, radius: 65 },
              },
              ProfileName: {
                y: 45,
                flexItem: { marginRight: 40 },
                text: { textColor: Colors('primaryFontColor1').get(), fontSize: 32, fontFace: 'Regular' }
              },
              flexItem: { marginBottom: 40 }
            },
            ProfileButtons: { flex: { direction: 'row' }, },
            flexItem: { marginBottom: 60 },
          },
          PersonalInfo: {
            visible: false,
            flex: { direction: 'column' },
            UserIdHeading: {
              flexItem: { marginBottom: 20 },
              text: { text: Language.translate('account.user_id.text'), textColor: Colors('secondaryFontColor').get(), fontSize: 24, fontFace: 'Bold', },
              UserIdInfo: { x: 250, text: { textColor: Colors('primaryFontColor1').get(), fontSize: 24, fontFace: 'Bold', }, },
            },
            FullNameHeader: {
              flexItem: { marginBottom: 20 },
              text: { text: Language.translate('my_acc_desc_full_name_def'), textColor: Colors('secondaryFontColor').get(), fontSize: 24, fontFace: 'Bold' },
              FullNameUser: { x: 250, text: { textColor: Colors('primaryFontColor1').get(), fontSize: 24, fontFace: 'Bold', }, }
            },
            EmailHeader: {
              flexItem: { marginBottom: 40 },
              text: { text: Language.translate('my_account.email.text'), textColor: Colors('secondaryFontColor').get(), fontSize: 24, fontFace: 'Bold', },
              EmailUser: { x: 250, text: { textColor: Colors('primaryFontColor1').get(), fontSize: 24, fontFace: 'Bold', }, },
            },
            Info: {
              text: { text: Language.translate('account.edit_account_info.text'), textColor: Colors('primaryFontColor1').get(), fontSize: 22, fontFace: 'Regular', lineHeight: 45, maxLines: 2, wordWrapWidth: 890, maxLinesSuffix: '...' },
            },
          }
        },
      }
    }
  }

  _init() {
    this.tag('URLTag').on('txLoaded', () => {
      const width = this.tag('URLTag').renderWidth
      const dynamicPos = (1011 - width) / 2
      this.tag('URLTag').patch({ x: dynamicPos })
    })
    this.tag('ProfileLetter').on('txLoaded', () => {
      const width = this.tag('ProfileLetter').renderWidth
      const height = this.tag('ProfileLetter').renderHeight
      const xPos = (130 - width) / 2 // 130 is the circle width
      const yPos = (130 - height) / 2
      this.tag('ProfileLetter').patch({ x: xPos, y: yPos + 6 })
    })
  }

  set data(data) {
    this.index = 0
    if (configurations.loggedIn && !configurations.authenticateWithEntitlement) {
      this.userData = data
      this.userDetailsView(this.userData)
    } else if (configurations.authenticateWithEntitlement) {
      this.houseHoldIDViewData = data
      this.houseHoldIDView(this.houseHoldIDViewData)
    } else {
      this.languageOptionsView(data.data)
    }
  }

  staticPageScrollView(data) {
    this.tag('UserDetailsView').visible = false
    this.tag('LanguageOptions').alpha = 0
    this.tag('LanguageOptions').visible = false
    this.tag('HouseholdView').alpha = 0
    this.tag('HouseholdView').visible = false
    const qrCodeImageURL = configurations.angularSiteURL + 'app/page/' + data.data.slug
    const qrcodeImage = generateQRCode(qrCodeImageURL)
    this.patch({
      SettingsView: {
        StaticPageScrollView: {
          Title: {
            text: { text: 'To read our' + ' ' + data.data.heading + ', ' + 'visit the following link or scan the QR code:' }
          },
          URLTag: {
            text: { text: qrCodeImageURL }
          },
          Divider: {
            OR: { text: { text: Language.translate('common_keys.or.text') } }
          },
          QRCodeImage: { src: qrcodeImage }
        }
      }
    })
    this.tag('StaticPageScrollView').alpha = 1
    this.tag('StaticPageScrollView').visible = true
  }

  showingStaticPagesAsText() {
    const allContent = []
    this.tag('ScrollWrapper').content = []
    data.data.page_array.forEach(cEle => {
      allContent.push(
        {
          text: cEle, style: {
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 1650,
            fontSize: 24,
            lineHeight: 30,
            fontFace: 'Regular'
          }
        })
    })

    this.tag('ScrollWrapper').patch({
      content: allContent
    })
    this.tag('ScrollWrapper').showScrollBar = allContent.length > 12 ? true : false
  }

  userDetailsView(data) {
    this.tag('StaticPageScrollView').alpha = 0
    this.tag('StaticPageScrollView').visible = false
    this.tag('LanguageOptions').alpha = 0
    this.tag('LanguageOptions').visible = false
    this.tag('HouseholdView').alpha = 0
    this.tag('HouseholdView').visible = false
    if (configurations.enableProfiles) {
      const selectedProfileID = Storage.get('profileId') || configurations.selectedProfileId
      const selectedProfile = data.data.profiles.find(profile => profile.profile_id === selectedProfileID)
      if (selectedProfile) {
        const buttons = [
          { text: Language.translate('Switch Profiles'), icon: 'static_assets/switch_profiles.png' },
          { text: Language.translate('Manage Profiles'), icon: 'static_assets/manage_profiles.png' }
        ]
        this.patch({
          SettingsView: {
            UserDetailsView: {
              visible: true,
              ProfileInfo: {
                visible: true,
                ProfileDetails: {
                  ProfileTexture: {
                    visible: !(selectedProfile.icon),
                    ProfileLetter: {
                      text: { text: selectedProfile.name.charAt(0).toUpperCase() }
                    }
                  },
                  ProfileImage: {
                    visible: (selectedProfile.icon),
                    src: configurations.imageBasePath + selectedProfile.icon
                  },
                  ProfileName: {
                    text: { text: selectedProfile.name }
                  }
                }
              }
            }
          }
        })
        this.tag('ProfileButtons').children = buttons.map((button) => {
          return {
            type: IconDynamicButton,
            h: 60,
            textPadding: 30,
            label: button.text,
            iconWithText: true,
            iconImage: Utils.asset(button.icon),
            flexItem: { marginRight: 20, maxHeight: 100 },
            isHorizontalBtn: true,
          }
        })
      }
    } else {
      this.tag('UserDetailsView').patch({
        visible: true,
        ProfileInfo: { visible: false }
      })
    }
    this.tag('UserDetailsView').patch({
      visible: true,
      PersonalInfo: {
        visible: true,
        UserIdHeading: { UserIdInfo: { text: { text: data.data.user_id } } },
        FullNameHeader: { FullNameUser: { text: { text: data.data.user_name } } },
        EmailHeader: { EmailUser: { text: { text: data.data.user_email } } }
      }
    })
  }

  houseHoldIDView(data) {
    this.tag('UserDetailsView').visible = false
    this.tag('StaticPageScrollView').alpha = 0
    this.tag('StaticPageScrollView').visible = false
    this.tag('LanguageOptions').alpha = 0
    this.tag('LanguageOptions').visible = false
    this.tag('HouseholdView').patch({
      alpha: 1,
      visible: true,
      Household: {
        text: { text: Language.translate('common_keys.household.text') },
      },
      HouseholdText: { text: { text: data } },
    })
  }

  languageOptionsView() {
    const data = configurations.locales
    this.tag('UserDetailsView').visible = false
    this.tag('StaticPageScrollView').alpha = 0
    this.tag('StaticPageScrollView').visible = false
    this.tag('HouseholdView').alpha = 0
    this.tag('HouseholdView').visible = false
    let locale = Storage.get('locale')
    const children = data.map((item) => {
      item.lang_code === locale ? item.selectedLang = true : item.selectedLang = false
      return { type: Button, w: 420, h: 60, button: item }
    })
    this.tag('ListItems').clear()
    this.tag('ListItems').add(children)
    this.tag('ListItems').scroll = { after: 7, forward: 0.9, backward: 0.1, }
    this.tag('LanguageOptions').alpha = 1
    this.tag('LanguageOptions').visible = true
  }

  _getFocused() {
    if (this.tag('LanguageOptions').alpha === 1) return this.tag('ListItems')

    if (this.tag('UserDetailsView').visible && configurations.enableProfiles) {
      const index = !this.index ? this.index : 1
      return this.tag('ProfileButtons').children[index]
    }
    return Router.getActivePage().focusSettingsAction()
  }

  _handleRight() {
    if (this.tag('UserDetailsView').visible && configurations.enableProfiles) {
      if (this.index < 1) this.index++
    }
  }

  _handleKey() { return }

  _handleUp() { return }

  _handleLeft() {
    const userDetailsViewVisible = this.tag('UserDetailsView').visible
    const profilesEnabled = configurations.enableProfiles

    if (userDetailsViewVisible && profilesEnabled && this.index > 0) {
      return this.index--
    }

    Router.getActivePage().focusSettingsAction()
  }

  _handleEnter() {
    const userDetailsViewVisible = this.tag('UserDetailsView').visible
    const profilesEnabled = configurations.enableProfiles

    if (userDetailsViewVisible && profilesEnabled) {
      const actionType = (this.index === 0) ? 'add' : 'edit'

      const profileObject = {
        'type': actionType,
        'profile_id': configurations.selectedProfileId,
        'from': 'settings'
      }

      Router.navigate(`profile/${actionType}`, profileObject)
    } else {
      this.buttonsFunctionality()
    }
  }


  async buttonsFunctionality() {
    if (this.tag('ListItems').alpha === 1) {
      const btnIndex = this.tag('ListItems').index
      await processTranslationFile(configurations.locales, 'change', configurations.locales[btnIndex].lang_code)
      const dialog = this.fireAncestors('$returnDialog')
      const actionArray = [
        {
          label: Language.translate('common_keys.ok.text'),
          action: () => { dialog.close() }
        }
      ]
      this.categorieslocaleUpdate()
      new PlatformSettingResponse(await platformSettingsAPI())
      showDialog(
        Language.translate('common_keys.alert.text'),
        Language.translate('account.profile_successfully_updated.text'),
        actionArray
      )
      this.languageOptionsView()
      Router.getActivePage().buttonsView()
      this.tag('ListItems').setIndex(btnIndex)
    }
  }

  async categorieslocaleUpdate() {
    const categories = await getCategories()
    if (categories.error) return
    configurations.categories = categories
    Router.getActivePage().widgets.menu.data = configurations.categories.categoryData
  }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }

}

class Button extends Lightning.Component {
  static _template() {
    return {
      Background: {
        Label: {},
        CheckMark: { w: 31, h: 31 }
      }
    }
  }

  set button(v) {
    this.tag('Background').patch({
      texture: Lightning.Tools.getRoundRect(475, 85, 4, 0, Colors('primaryFontColor1').get(), false),
      Label: {
        text: {
          text: v.lang_name, fontSize: 28, textColor: Colors('primaryFontColor1').get(), maxLinesSuffix: '...', fontFace: 'Bold', textAlign: 'Center'
        },
      },
      CheckMark: { src: v.selectedLang ? Utils.asset('static_assets/check.png') : null }
    })
    this.tag('Label').on('txLoaded', () => {
      this.tag('Label').x = 20
      this.tag('Label').y = (this.tag('Background').finalH - this.tag('Label').finalH) / 2
      this.tag('CheckMark').x = this.tag('Label').renderWidth + 25
      this.tag('CheckMark').y = (this.tag('Background').finalH - this.tag('CheckMark').finalH) / 2
    })
  }

  _focus() {
    this.tag('Background').patch({
      texture: Lightning.Tools.getRoundRect(475, 85, 4, 2, Colors('brandPrimaryColor').get(), true, Colors('focusedBtnColor').get())
    })
  }

  _unfocus() {
    this.tag('Background').patch({
      texture: Lightning.Tools.getRoundRect(475, 85, 4, 0, Colors('primaryFontColor1').get(), false)
    })
  }
}
