import { SignUp } from '../Views'
/**
 * Defines the configuration for the 'signup' page.
 */
export default {
    path: 'signup',
    component: SignUp,
    on: async (page) => {
      return page.preparePageData()
    },
    widgets: ['Back', 'LanguageSelection', 'LocalesList', 'SidePanelNavigation']
}
