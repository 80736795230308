import { Lightning, Utils, Router, Storage, Colors, Language } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import configurations from '../Helper/Configurations.js'
import App from '../App.js'
import IconDynamicButton from '../Components/ButtonsList/IconDynamicButton.js'
import Loader from '../Components/Loader.js'
import { Marquee } from '@lightningjs/ui-components'

export default class UserProfiles extends Lightning.Component {
  static _template() {
    return {
      Heading: {
        y: 300,
        zIndex: 2,
        text: {
          fontSize: 40,
          lineHeight: 53,
          textColor: Colors('primaryFontColor1').get(),
        },
      },
      ProfileItems: {
        h: 501,
        y: 434,
        w: App.width,
        flex: { direction: 'row', justifyContent: 'center' },
        type: List,
        spacing: 60,
        direction: 'row',
        alpha: 1,
      },
      ButtonList: {
        y: 670,
        w: App.width,
        flex: { direction: 'row', justifyContent: 'center' },
      },
      Loading: {
        visible: false,
        type: Loader,
        zIndex: 10,
      },
    }
  }

  _init() {
    this.index = 0
    this.tag('Heading').on('txLoaded', () => {
      const width = this.tag('Heading').renderWidth
      const xPos = (App.width - width) / 2
      this.tag('Heading').patch({ x: xPos })
    })
  }

  _getFocused() {
    if (this.index === 0) return this.slider
    if (this.index === 1) return this.tag('ButtonList').children[0]
  }

  get slider() {
    return this.tag('ProfileItems')
  }

  get loaderTag() {
    return this.tag('Loading')
  }

  displayLoader() {
    this.loaderTag.visible = true
  }

  hideLoader() {
    this.loaderTag.visible = false
  }

  _handleDown() {
    if (this.index < 1) this.index++
  }

  _handleBack() { return }

  _handleEnter() {
    const buttonListLabel = this.tag('ButtonList').children[0]._label
    const profileData = configurations.profilesList[this.slider._index]
    const profileId = profileData.profile_id

    if (this.index === 1) {
        const actionType = buttonListLabel === Language.translate('profile.edit_profile_title.text') ? 'edit' : 'initial'
        const buttonLabel = buttonListLabel === Language.translate('profile.edit_profile_title.text')
          ? Language.translate('profile.done.button')
          : Language.translate('profile.edit_profile_title.text')

        this.processUserProfileInfo(actionType)
        this.buttonCreation(buttonLabel)
        return
    }

    if (this.index === 0) {
        Storage.set('enteredProfileIndex', JSON.stringify({ index: this.slider.index }))
        if (buttonListLabel === Language.translate('profile.edit_profile_title.text') && profileId !== 'add') {
            configurations.selectedProfileId = profileId
            Storage.set('profileId', profileId)
            return Router.navigate('home')
        }

        if (profileId === 'add') {
            profileData.view = 'initial'
        }
        Router.navigate(`manageprofiles/${profileId}`, profileData)
    }
  }

  _handleUp() {
    if (this.index > 0) this.index--
  }

  buttonCreation(type) {
    const editButtonObject = [{ text: type }]
    this.tag('ButtonList').children = editButtonObject.map((button) => {
      return {
        type: IconDynamicButton,
        h: 60,
        textPadding: 30,
        label: button.text,
        iconWithText: true,
        iconImage: Utils.asset(type === Language.translate('profile.edit_profile_title.text') ? `static_assets/profile_edit.png` : `static_assets/done.png`),
        flexItem: { marginRight: 30, maxHeight: 100 },
        isHorizontalBtn: true,
      }
    })
  }

  processUserProfileInfo(type, newProfileObj = '') {
    if (configurations?.profilesList.length >= 1) {
      this.profilesList = configurations.profilesList
      if (newProfileObj != '')
        this.profilesList.push(newProfileObj)
      const profileCards = this.profilesList.map((item) => {
        item.view = type
        return { w: 153, h: 176, x: 10, type: ProfileItem, alpha: 1, item }
      })
      this.tag('ProfileItems').clear()
      this.tag('ProfileItems').add(profileCards)
      this.tag('Heading').patch({ text: { text: Language.translate('profile.general_heading.text') } })
      const stateInfo = JSON.parse(Storage.get('enteredProfileIndex'))
      const profileIndex = stateInfo?.index || 0
      this.slider.setIndex(profileIndex)
      this.buttonCreation(Language.translate('profile.edit_profile_title.text'))
      this.hideLoader()
    }
  }

  async patchDataToTemplate(type, profileData) {
    this.displayLoader()
    let addObject = ''
    const isAddObjectExists = configurations.profilesList.some(obj => obj.profile_id === 'add')
    if(!isAddObjectExists)
      addObject = { 'icon': '', 'name': Language.translate('profile.add_profile.text'), 'profile_id': 'add' }

    if (profileData.from == 'settings' && type == 'add')
      return this.processUserProfileInfo('initial', addObject)

    if (profileData.from == 'settings' && type == 'edit') {
      this.processUserProfileInfo('edit', addObject)
      return this.buttonCreation(Language.translate('profile.done.button'))
    }

    this.processUserProfileInfo('initial', addObject)
  }
}

class ProfileItem extends Lightning.Component {
  static _template() {
    return {
      Border: {
        zIndex: 11,
        alpha: 0,
        x: -8.5,
        y: -8.5,
        texture: Lightning.Tools.getRoundRect(140, 140, 69, 5, Colors('brandPrimaryColor').get(), false),
      },
      Poster: {
        w: 130,
        h: 130,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 64 },
        AddImg: {
          alpha: 0,
          x: 35,
          y: 35,
          w: 60,
          h: 60,
          src: Utils.asset('static_assets/add.png'),
        }
      },
      OverLay: {
        alpha: 0,
        zIndex: 10,
        x: -1,
        y: -1,
        texture: Lightning.Tools.getRoundRect(130, 130, 64, 0, Colors('overlayColor').get(), true, Colors('overlayColor').get()),
        AddImg: {
          alpha: 1,
          x: 45,
          y: 45,
          w: 40,
          h: 40,
          src: Utils.asset('static_assets/profile_edit.png'),
        }
      },
      ProfileLetter: {
        alpha: 0,
        text: {
          fontSize: 50,
          textColor: Colors('primaryFontColor1').get(),
          fontFace: 'Bold',
        },
      },
      Marquee: {
        type: Marquee,
        w: 160,
        h: 50,
        x: -15,
        y: 150,
        title: { text: '' },
        style: {
          textStyle: {
            fontSize: 32,
            textColor: Colors('primaryFontColor1').get(),
            fontFace: 'Regular',
            textAlign: 'center',
            maxLines: 1,
            maxLinesSuffix: '...'
          }
        },
        autoStart: true
      }
    }
  }

  _init() {
    this.tag('ProfileLetter').on('txLoaded', () => {
      const width = this.tag('ProfileLetter').renderWidth
      const height = this.tag('ProfileLetter').renderHeight
      const xPos = (130 - width) / 2
      const yPos = (130 - height) / 2
      this.tag('ProfileLetter').patch({ x: xPos, y: yPos + 3 })
    })
  }


  set item(profileData) {
    this.width = 130
    this.height = 130
    const { imageBasePath } = configurations
    if (profileData.icon != '') {
      this.tag('Poster').src = imageBasePath + profileData.icon
      this.tag('ProfileLetter').alpha = 0
      this.tag('AddImg').alpha = 0
    } else if (profileData.profile_id == 'add') {
      this.tag('AddImg').alpha = 1
      this.tag('Poster').patch({
        texture: Lightning.Tools.getRoundRect(130, 130, 64, 5, Colors('cardColor').get(), true, Colors('cardColor').get(),),
      })
    } else {
      const profileFirstLetter = profileData.name.charAt(0).toUpperCase()
      this.tag('Poster').patch({
        texture: Lightning.Tools.getRoundRect(130, 130, 64, 5, Colors('brandPrimaryColor').get(), true, Colors('brandPrimaryColor').get(),),
      })
      this.tag('ProfileLetter').patch({
        alpha: 1,
        text: { text: profileFirstLetter }
      })
      this.tag('AddImg').alpha = 0
    }

    if (profileData?.view == 'edit' && profileData?.profile_id != 'add')
      this.tag('OverLay').alpha = 1

    this.tag('Marquee').patch({ title: { text: profileData.name } })
  }

  _focus() {
    this.tag('Border').patch({ alpha: 1 })
  }

  _unfocus() {
    this.tag('Border').patch({ alpha: 0 })
  }


  get item() {
    return this._item
  }

  static get width() {
    return 130
  }

  static get height() {
    return 130
  }
}
