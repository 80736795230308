import { Lightning, Router, Language, Colors } from '@lightningjs/sdk'
import { FONT_BOLD, FONT_REGULAR } from '../../Helper/GlobalConstants'
import Button from '../Button'
import App from '../../App'
import { showDialog } from '../../Helper/Helpers'

export default class SignOutPopup extends Lightning.Component {
      static _template() {
          return {
              zIndex: 11, color: Colors('shadowColor').get(), rect: true, w: App.width, h: App.height,
              Background: { texture: Lightning.Tools.getRoundRect(979, 533, 0, 2, Colors('backgroundColor').get(), true, Colors('backgroundColor').get()) },
              DeviceName: {
                text: { textColor: Colors('primaryFontColor1').get(), fontSize: 32, fontFace: FONT_BOLD, wordWrap: true, textAlign: 'center', wordWrapWidth: 800, maxLines: 1, maxLinesSuffix: '...' },
              },
              Description: {
                text: { textColor: Colors('primaryFontColor1').get(), fontSize: 26, fontFace: FONT_REGULAR, wordWrap: true, wordWrapWidth: 820, lineHeight: 40, maxLines: 2, maxLinesSuffix: '...' },
              },
              ButtonList: { },
          }
      }

      _init() {
          this.transition('alpha').on('finish', () => {
            if (this.alpha === 0) {
              this.visible = false
            }
          })
      }

      calculateDeviceNameWidth() {
          this.tag('DeviceName').on('txLoaded', () => {
            const deviceNameWidth = this.tag('DeviceName').renderWidth
            const dynamicXPos = (1950 - deviceNameWidth) / 2
            this.tag('DeviceName').patch({ x: dynamicXPos - 5 })
          })
      }

      set data(deviceData) {
          this.buttonIndex = 0
          this.singleDeviceObj = deviceData[0]
          this.scenarioType = deviceData[1]
          const currentDevice = Boolean(this.singleDeviceObj.currentDevice)
          this.buttonsArray = [
            {
              x: 570,
              y: currentDevice ? 600 : 550,
              w: 830,
              h: 74,
              fontSize: 32,
              title: Language.translate('dev_mgmt_edit_name_btn'),
              layout: 'border',
              buttonWithIcon: true,
              iconW: 36,
              iconH: 36,
              iconX: 760,
              iconY: 18,
              highlightOnlyBorder: true,
              radius: 10,
            },
            {
              x: 570,
              y: 670,
              w: 830,
              h: 74,
              fontSize: 32,
              title: Language.translate('common_keys.sign_out.btn'),
              layout: 'border',
              radius: 10,
              highlightOnlyBorder: true,
            },
          ]
          if (currentDevice) this.buttonsArray.pop()

          const popUpHeight =  currentDevice ? 420 : 533

          this.patch({
            Background: {
              x: (App.width - 983) / 2 + 20,
              y: (App.height - popUpHeight) / 2,
              texture: Lightning.Tools.getRoundRect(979, popUpHeight, 0, 2, Colors('backgroundColor').get(), true, Colors('backgroundColor').get()),
            },
            DeviceName: {
              y: currentDevice ? 380 : 310,
              text: { text: deviceData[0].deviceTitle }
            },
            Description: {
              x: 570, y: currentDevice ? 470 : 400,
              text: { text: Language.translate('device_management.edit_device_name_or_sign_out.btn') }
            }
          })
          this.calculateDeviceNameWidth()
          this.visible = true
          this.setSmooth('alpha', 1)

          this.tag('ButtonList').children = this.buttonsArray.map((button) => {
            return { type: Button, button }
          })
      }

      _getFocused() {
          return this.tag('ButtonList').children[this.buttonIndex]
      }

      _handleEnter() {
        if (this.buttonIndex == 0) {
          const deviceInfo = {
            deviceId: this.singleDeviceObj.deviceId,
            deviceTitle: this.singleDeviceObj.deviceTitle,
            deviceType: this.singleDeviceObj.deviceType,
            checkScenario: this.scenarioType,
          }
          return Router.navigate('editname', deviceInfo, false)
        }

        this.setSmooth('alpha', 0)
        const dialog = this.fireAncestors('$returnDialog')
        const message = Language.translate('device_management.remove_dev.part1.text') +
          ' ' +
          `"${this.singleDeviceObj.deviceTitle}"` +
          ' ' +
          Language.translate('device_management.remove_dev.part2.text')
        const actionArray = [
          {
            label: Language.translate('common_keys.sign_out.btn'),
            action: () => { dialog.close(), Router.getActivePage().logoutIndividualDevice() }
          },
          {
            label: Language.translate('common_keys.cancel.text'),
            action: () => { dialog.close(), Router.getActivePage().focusDevicesList() }
          }
        ]
        showDialog(Language.translate('common_keys.are_you_sure.text'), message, actionArray)
      }

      _handleKey() { return }

      _handleDown() {
        if (this.buttonIndex < this.buttonsArray.length - 1 && this.tag('ButtonList').children.length > 1)
          this.buttonIndex++
      }

      _handleUp() {
        if (this.buttonIndex > 0)
          this.buttonIndex--
      }

      _handleBack() {
        this.setSmooth('alpha', 0)
        Router.getActivePage().focusDevicesList()
      }

      _handleLeft() { return }

      _handleRight() { return }
}
