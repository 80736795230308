export class StaticPageResponse {
    constructor(res) {
        this.status_code = res.status_code;
        this.message = res.message;
        this.data = new PageData(res.data);
    }
}

class PageData {
    constructor(data) {
        this.region = data.page.region;
        this.description = data.page.description;
        this.heading = data.page.heading;
        this.slug = data.page.slug;
        this.unique_id = data.page.unique_id;
        this.updated_at = data.page.updated_at;
        this.page_array = data.page_array;
    }
}
