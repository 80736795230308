import { Lightning, Router, Storage } from '@lightningjs/sdk'
import { navigateToPlayer, navigateToLivePlayer } from '../../Helper/Helpers'
import { DASH_PLAYER, HLS_PLAYER } from '../../Helper/GlobalConstants'
import IconDynamicButton from './IconDynamicButton'
import configurations from '../../Helper/Configurations'

export default class ButtonsList extends Lightning.Component {
  static _template() {
    return {
      Wrapper: {
        ButtonList: {
          h: 56,
          flex: { direction: 'row' },
        },
      },
    }
  }

  set data(v) {
    this.buttonIndex = 0
    this.buttonsData = v[0]
    this.personaInfo = v[1]
    this.contentMetaData = v[2]
    this.trailers = this.contentMetaData.data?.trailerVideos || this.contentMetaData.trailers
    this.tag('ButtonList').children = this.buttonsData.map((button) => {
      return {
        type: IconDynamicButton,
        h: 60,
        textPadding: 30,
        label: button.text,
        iconWithText: true,
        iconImage: button.Icon,
        flexItem: { marginRight: 30, maxHeight: 100 },
        isHorizontalBtn: true,
      }
    })
  }

  _inactive() {
    this.tag('ButtonList').children = []
  }

  _handleLeft() {
    if (this.buttonIndex > 0) {
      this.buttonIndex--
    }
  }

  _handleRight() {
    if (this.buttonIndex < this.buttonsData.length - 1) {
      this.buttonIndex++
    }
  }

  playTrailer() {
    let trailerData = this.trailers[0]
    trailerData.action = 'start'
    trailerData.videoId = trailerData.trailerId
    trailerData.contentId = trailerData.trailerId
    trailerData.contentTitle = trailerData.title
    trailerData.contentType = 'trailer'
    trailerData.playbackUrl = configurations.enableHlsStream ? trailerData.hlsPlaybackUrl : trailerData.dashPlaybackUrl
    trailerData = { data: trailerData }
    const player = configurations.enableHlsStream ? HLS_PLAYER : DASH_PLAYER
    Router.navigate(`${player}/${trailerData.data.action}/${trailerData.data.contentId}`, trailerData)
  }

  _handleEnter() {
    if (Router.getActivePage().tag('Loading').visible) return

    Storage.set('fromEpisodePanel', 'No')
    const { enableRegistration, loggedIn } = configurations
    const { personaInfo, buttonIndex, contentMetaData } = this

    //before login whether enableRegistration is 1 or 0, if the content is svod content we need to show login else watch now option.
    if (
      ((enableRegistration && !loggedIn) ||
      (!enableRegistration && !loggedIn && personaInfo?.availability === 1)) && buttonIndex === 0
    ) {
      return Router.navigate('svodpopup/login')
    }

    if (contentMetaData.is_livechannel) {
      return navigateToLivePlayer(contentMetaData, false)
    }

    Router.getActivePage().displayLoader()
    return personaInfo?.seek > 0 ? this.handleSeek() : this.handleNormal()
  }

  handleSeek() {
    this.buttonIndex == 0 || this.buttonIndex == 1 ? this.playerNavigation() : this.playTrailer()
  }

  handleNormal() {
    this.buttonIndex == 0 ? this.playerNavigation() : this.playTrailer()
  }

  playerNavigation() {
    const contentId = Router.getActiveHash().includes('series') ? this.personaInfo?.videoId : this.contentMetaData.contentId
    navigateToPlayer(contentId, this.contentMetaData, this.personaInfo, this.buttonIndex, true)
  }

  _handleDown() {
    Router.getActivePage().setVerticalBtnIndex(0)
    Router.getActivePage().focusVerticalBtns()
  }

  _handleUp() { return }

  _handleKey() { return }

  _getFocused() {
    return this.tag('ButtonList').children[this.buttonIndex]
  }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }
}
