// MovieResponse class representing the response for movie data
export class MovieResponse {
  constructor(data) {
    this.statusCode = data.status_code || 0;
    this.statusMessage = data.message || "";
    this.contentData = data.data.content ? new MovieDetails(data.data.content) : null;
  }
}

// Content class representing details for content
export class MovieDetails {
  constructor(data) {
    this.publishTime = data.publish_time || null;
    this.contentId = data.content_id || null;
    this.title = data.title || "";
    this.description = data.description || "";
    this.tags = data.tags || [];
    this.genre = data.genre || ''
    this.director = data.director || "";
    this.writer = data.writer || "";
    this.producer = data.producer || "";
    this.cast = data.cast || "";
    this.contentLogo = data.content_logo || "";
    this.screenshotLandscape = data.screenshot_landscape || "";
    this.backgroundPortrait = data.background_portrait || "";
    this.backgroundLandscapeCenter = data.background_landscape_center || "";
    this.backgroundLandscape = data.background_landscape || "";
    this.posterSquare = data.poster_square || "";
    this.posterLandscape = data.poster_landscape || "";
    this.posterPortrait = data.poster_portrait || "";
    this.slug = data.slug || "";
    this.titleEn = data.title_en || "";
    this.adUrl = data.ad_url || "";
    this.audios = data.audios || [];
    this.subtitles = data.subtitles || [];
    this.duration = data.duration || "";
    this.durationMin = data.duration_min || 0;
    this.releaseDate = data.release_date || "";
    this.contentIdAlt = data.contentId || "";
    this.age = data.age || 0;
    this.isTvod = data.is_tvod || 0;
    this.audioUrl = data.audio_url || "";
    this.playbackType = data.playback_type || 0;
    this.position = data.position || 0;
    this.isLocalLogo = data.is_local_logo || 0
    this.seriesId = data.series_id || "";
    this.seasonId = data.season_id || "";
    this.seasonsSlug = data.seasons_slug || "";
    this.seasonPosition = data.season_position || 0;
    this.isDrm = data.is_drm || 0;
    this.durationSec = data.duration_sec || 0;
    this.contentLogoResolutions = data.content_logo_resolutions || "";
    this.backgroundPortraitResolutions = data.background_portrait_resolutions || "";
    this.backgroundLandscapeCenterResolutions = data.background_landscape_center_resolutions || "";
    this.backgroundLandscapeResolutions = data.background_landscape_resolutions || "";
    this.posterSquareResolutions = data.poster_square_resolutions || "";
    this.posterLandscapeResolutions = data.poster_landscape_resolutions || {};
    this.posterPortraitResolutions = data.poster_portrait_resolutions || {};
    this.seriesVideo = data.series_video || 0;
    this.trailers = data.trailers?.length > 0 ? data.trailers.map((item) => new TrailerMetaData(item)) : []
    this.wishlistStatus = data.wishlist_status || false;
    this.userType = data.user_type || 0;
    this.seek = data.seek || 0;
    this.availability = data.availability || 1;
    this.preferredLanguage = data.preferred_language || "";
    this.preferredSubtitle = data.preferred_subtitle || "";
    this.geoAvailable = data.geo_available || 1;
    this.smartSigns = data.smart_signs || [];
    this.playbackUrl = data.playback_url || "";
    this.hlsPlaybackUrl = data.hls_playback_url || "";
    this.dashPlaybackUrl = data.dash_playback_url || "";
    this.orientation = data.orientation || 1;
  }
}

class TrailerMetaData {
  constructor(data) {
    this.title = data.title || "";
    this.title_en = data.title_en || "";
    this.description = data.description || "";
    this.slug = data.slug || "";
    this.age = data.age || 0;
    this.duration = data.duration || "";
    this.duration_min = data.duration_min || 0;
    this.publish_time = data.publish_time || "";
    this.director = data.director || "";
    this.writer = data.writer || "";
    this.cast = data.cast || "";
    this.producer = data.producer || "";
    this.ad_url = data.ad_url || "";
    this.tags = data.tags || "";
    this.contentId = data.contentId || "";
    this.trailer_id = data.trailer_id || "";
    this.playback_type = data.playback_type || 0;
    this.playback_url = data.playback_url || "";
    this.hls_playback_url = data.hls_playback_url || "";
    this.dash_playback_url = data.dash_playback_url || "";
    this.poster_portrait = data.poster_portrait || "";
    this.poster_landscape = data.poster_landscape || "";
    this.duration_sec = data.duration_sec || 0;
    this.poster_landscape_resolutions = data.poster_landscape_resolutions || "";
    this.poster_portrait_resolutions = data.poster_portrait_resolutions || {};
  }
}


