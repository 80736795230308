const template = {
  keyWidth: 70,
  keyHeight: 70,
  horizontalSpacing: 12,
  verticalSpacing: 28,
  layouts: {
    search: {
      rows: [
        {
          keys: [{ c: 'a' }, { c: 'b' }, { c: 'c' }, { c: 'd' }, { c: 'e' }, { c: 'f' }],
        },
        {
          keys: [{ c: 'g' }, { c: 'h' }, { c: 'i' }, { c: 'j' }, { c: 'k' }, { c: 'l' }],
        },
        {
          keys: [{ c: 'm' }, { c: 'n' }, { c: 'o' }, { c: 'p' }, { c: 'q' }, { c: 'r' }],
        },
        {
          keys: [{ c: 's' }, { c: 't' }, { c: 'u' }, { c: 'v' }, { c: 'w' }, { c: 'x' }],
        },
        {
          keys: [{ c: 'y' }, { c: 'z' }, { c: '1' }, { c: '2' }, { c: '3' }, { c: '4' }],
        },
        {
          keys: [{ c: '5' }, { c: '6' }, { c: '7' }, { c: '8' }, { c: '9' }, { c: '0' }],
        },
        {
          keys: [{ c: ':' }, { c: "'" }, { c: '&' }, { c: '.' }, { c: '$' }, { c: '!' }],
        },
        {
          keys: [
            { action: 'backspace', w: 140, source: 'static_assets/backspace.png' },
            { action: 'space', source: 'static_assets/space.png', w: 140 },
            { action: 'delete', w: 120, source: 'static_assets/trash.png' },
          ],
        },
      ],
    },
    landscape: {
      rows: [
        {
          keys: [{ c: '1' }, { c: '2' }, { c: '3' }, { c: '4' }, { c: '5' }, { c: '6' }, { c: '7' }, { c: '8' }, { c: '9' }, { c: '0' }],
        },
        {
          keys: [{ c: 'a' }, { c: 'b' }, { c: 'c' }, { c: 'd' }, { c: 'e' }, { c: 'f' }, { c: 'g' }, { c: 'h' }, { c: 'i' }, { c: 'j' }],
        },
        {
          keys: [{ c: 'k' }, { c: 'l' }, { c: 'm' }, { c: 'n' }, { c: 'o' }, { c: 'p' }, { c: 'q' }, { c: 'r' }, { c: 's' }, { c: 't' }],
        },
        {
          keys: [
            { c: 'u' }, { c: 'v' }, { c: 'w' }, { c: 'x' }, { c: 'y' }, { c: 'z' },
            { action: 'space', source: 'static_assets/spaceLandscape.png', w: 163 },
            { action: 'backspace', source: 'static_assets/backspaceLandscape.png', w: 163 }
          ],
        },
      ],
    },
    password: {
      rows: [
        {
          keys: [
            { c: 'a' },
            { c: 'b' },
            { c: 'c' },
            { c: 'd' },
            { c: 'e' },
            { c: 'f' },
            { c: 'g' },
            { c: '1' },
            { c: '2' },
            { c: '3' },
          ],
        },
        {
          keys: [
            { c: 'h' },
            { c: 'i' },
            { c: 'j' },
            { c: 'k' },
            { c: 'l' },
            { c: 'm' },
            { c: 'n' },
            { c: '4' },
            { c: '5' },
            { c: '6' },
          ],
        },
        {
          keys: [
            { c: 'o' },
            { c: 'p' },
            { c: 'q' },
            { c: 'r' },
            { c: 's' },
            { c: 't' },
            { c: 'u' },
            { c: '7' },
            { c: '8' },
            { c: '9' },
          ],
        },
        {
          keys: [
            { c: 'v' },
            { c: 'w' },
            { c: 'x' },
            { c: 'y' },
            { c: 'z' },
            { c: '@' },
            { c: '.' },
            { c: '0' },
            { action: 'toggleToLayout', toLayout: 'P#123', c: '#$!', w: 150 },
          ],
        },
        {
          keys: [
            { action: 'toggleToLayout', toLayout: 'PABC', c: 'ABC', w: 150 },
            { action: 'space', source: 'static_assets/space.png', w: 150 },
            { action: 'backspace', w: 150, source: 'static_assets/backspace.png' },
            { action: 'delete', w: 150, source: 'static_assets/trash.png' },
            { action: '.com', c: '.com', w: 150 },
          ],
        },
        {
          keys: [
            { action: '@gmail.com', c: '@gmail.com', w: 257 },
            { action: '@yahoo.com', c: '@yahoo.com', w: 257 },
            { action: '@hotmail.com', w: 257, c: '@hotmail.com' },
          ],
        },
      ],
    },
    abc: {
      rows: [
        {
          keys: [{ c: 'a' }, { c: 'b' }, { c: 'c' }, { c: 'd' }, { c: 'e' }, { c: 'f' }, { c: 'g' }],
        },
        {
          keys: [{ c: 'h' }, { c: 'i' }, { c: 'j' }, { c: 'k' }, { c: 'l' }, { c: 'm' }, { c: 'n' }],
        },
        {
          keys: [{ c: 'o' }, { c: 'p' }, { c: 'q' }, { c: 'r' }, { c: 's' }, { c: 't' }, { c: 'u' }],
        },
        {
          keys: [{ c: 'v' }, { c: 'w' }, { c: 'x' }, { c: 'y' }, { c: 'z' }, { c: '1' }, { c: '2' }],
        },
        {
          keys: [{ c: '3' }, { c: '4' }, { c: '5' }, { c: '6' }, { c: '7' }, { c: '8' }, { c: '9' }],
        },
        {
          keys: [
            { c: '0' },
            { c: '.' },
            { c: '@' },
            { action: 'toggleToLayout', toLayout: '#123', c: '#$!', w: 150 },
            { action: 'toggleToLayout', toLayout: 'ABC', c: 'ABC', w: 150 },
          ],
        },
        {
          keys: [
            { action: '.com', c: '.com', w: 100 },
            { action: '@gmail.com', c: '@gmail.com', w: 217 },
            { action: '@yahoo.com', c: '@yahoo.com', w: 217 },
          ],
        },
        {
          keys: [
            { action: 'backspace', w: 160, source: 'static_assets/backspace.png' },
            { action: 'space', source: 'static_assets/space.png', w: 170 },
            { action: 'delete', w: 170, source: 'static_assets/trash.png' },
          ],
        },
      ],
    },
    ABC: {
      rows: [
        {
          keys: [{ c: 'A' }, { c: 'B' }, { c: 'C' }, { c: 'D' }, { c: 'E' }, { c: 'F' }, { c: 'G' }],
        },
        {
          keys: [{ c: 'H' }, { c: 'I' }, { c: 'J' }, { c: 'K' }, { c: 'L' }, { c: 'M' }, { c: 'N' }],
        },
        {
          keys: [{ c: 'O' }, { c: 'P' }, { c: 'Q' }, { c: 'R' }, { c: 'S' }, { c: 'T' }, { c: 'U' }],
        },
        {
          keys: [{ c: 'V' }, { c: 'W' }, { c: 'X' }, { c: 'Y' }, { c: 'Z' }, { c: '1' }, { c: '2' }],
        },
        {
          keys: [{ c: '3' }, { c: '4' }, { c: '5' }, { c: '6' }, { c: '7' }, { c: '8' }, { c: '9' }],
        },
        {
          keys: [
            { c: '0' },
            { c: '.' },
            { c: '@' },
            { action: 'toggleToLayout', toLayout: '#123', c: '#$!', w: 150 },
            { action: 'toggleToLayout', toLayout: 'abc', c: 'abc', w: 150 },
          ],
        },
        {
          keys: [
            { action: '.com', c: '.com', w: 100 },
            { action: '@gmail.com', c: '@gmail.com', w: 217 },
            { action: '@yahoo.com', c: '@yahoo.com', w: 217 },
          ],
        },
        {
          keys: [
            { action: 'backspace', w: 160, source: 'static_assets/backspace.png' },
            { action: 'space', source: 'static_assets/space.png', w: 170 },
            { action: 'delete', w: 170, source: 'static_assets/trash.png' },
          ],
        },
      ],
    },
    PABC: {
      rows: [
        {
          keys: [
            { c: 'A' },
            { c: 'B' },
            { c: 'C' },
            { c: 'D' },
            { c: 'E' },
            { c: 'F' },
            { c: 'G' },
            { c: '1' },
            { c: '2' },
            { c: '3' },
          ],
        },
        {
          keys: [
            { c: 'H' },
            { c: 'I' },
            { c: 'J' },
            { c: 'K' },
            { c: 'L' },
            { c: 'M' },
            { c: 'N' },
            { c: '4' },
            { c: '5' },
            { c: '6' },
          ],
        },
        {
          keys: [
            { c: 'O' },
            { c: 'P' },
            { c: 'Q' },
            { c: 'R' },
            { c: 'S' },
            { c: 'T' },
            { c: 'U' },
            { c: '7' },
            { c: '8' },
            { c: '9' },
          ],
        },
        {
          keys: [
            { c: 'V' },
            { c: 'W' },
            { c: 'X' },
            { c: 'Y' },
            { c: 'Z' },
            { c: '@' },
            { c: '.' },
            { c: '0' },
            { action: 'toggleToLayout', toLayout: 'P#123', c: '#$!', w: 150 },
          ],
        },
        {
          keys: [
            { action: 'toggleToLayout', toLayout: 'password', c: 'abc', w: 150 },
            { action: 'space', source: 'static_assets/space.png', w: 150 },
            { action: 'backspace', w: 150, source: 'static_assets/backspace.png' },
            { action: 'delete', w: 150, source: 'static_assets/trash.png' },
            { action: '.com', c: '.com', w: 150 },
          ],
        },
        {
          keys: [
            { action: '@gmail.com', c: '@gmail.com', w: 257 },
            { action: '@yahoo.com', c: '@yahoo.com', w: 257 },
            { action: '@hotmail.com', w: 257, c: '@hotmail.com' },
          ],
        },
      ],
    },
    'P#123': {
      rows: [
        {
          keys: [
            { c: '!' },
            { c: '@' },
            { c: '#' },
            { c: '$' },
            { c: '%' },
            { c: '^' },
            { c: '&' },
            { c: '*' },
            { c: '(' },
            { c: ')' },
          ],
        },
        {
          keys: [
            { c: '-' },
            { c: '_' },
            { c: '+' },
            { c: '=' },
            { c: '{' },
            { c: '[' },
            { c: ']' },
            { c: '}' },
            { c: '|' },
            { c: '\\' },
          ],
        },
        {
          keys: [
            { c: ':' },
            { c: ';' },
            { c: "'" },
            { c: '<' },
            { c: '>' },
            { c: ',' },
            { c: '.' },
            { c: '?' },
            { c: '/' },
            { c: '`' },
          ],
        },
        {
          keys: [
            { c: '1' },
            { c: '2' },
            { c: '3' },
            { c: '4' },
            { c: '5' },
            { c: '6' },
            { c: '7' },
            { c: '8' },
            { c: '9' },
            { c: '0' },
          ],
        },
        {
          keys: [
            { action: 'toggleToLayout', toLayout: 'PABC', c: 'ABC', w: 150 },
            { action: 'space', source: 'static_assets/space.png', w: 150 },
            { action: 'backspace', w: 150, source: 'static_assets/backspace.png' },
            { action: 'delete', w: 150, source: 'static_assets/trash.png' },
            { action: '.com', c: '.com', w: 150 },
          ],
        },
        {
          keys: [
            { action: '@gmail.com', c: '@gmail.com', w: 257 },
            { action: '@yahoo.com', c: '@yahoo.com', w: 257 },
            { action: '@hotmail.com', w: 257, c: '@hotmail.com' },
          ],
        },
      ],
    },
    '#123': {
      rows: [
        {
          keys: [{ c: '!' }, { c: '@' }, { c: '#' }, { c: '$' }, { c: '%' }, { c: '^' }, { c: '&' }],
        },
        {
          keys: [{ c: '*' }, { c: '(' }, { c: ')' }, { c: '-' }, { c: '_' }, { c: '+' }, { c: '=' }],
        },
        {
          keys: [{ c: '{' }, { c: '}' }, { c: '[' }, { c: ']' }, { c: '|' }, { c: '\\' }, { c: ':' }],
        },
        {
          keys: [{ c: ';' }, { c: "'" }, { c: '`' }, { c: '<' }, { c: '>' }, { c: ',' }, { c: '.' }],
        },
        {
          keys: [{ c: '?' }, { c: '/' }, { c: '1' }, { c: '2' }, { c: '3' }, { c: '4' }, { c: '5' }],
        },
        {
          keys: [
            { c: '6' },
            { c: '7' },
            { c: '8' },
            { c: '9' },
            { c: '0' },
            { action: 'toggleToLayout', toLayout: 'ABC', c: 'ABC', w: 150 },
          ],
        },
        {
          keys: [
            { action: '.com', c: '.com', w: 100 },
            { action: '@gmail.com', c: '@gmail.com', w: 217 },
            { action: '@yahoo.com', c: '@yahoo.com', w: 217 },
          ],
        },
        {
          keys: [
            { action: 'backspace', w: 160, source: 'static_assets/backspace.png' },
            { action: 'space', source: 'static_assets/space.png', w: 170 },
            { action: 'delete', w: 170, source: 'static_assets/trash.png' },
          ],
        },
      ],
    },
    authenticate: {
      rows: [
        {
          keys: [{ c: 'A' }, { c: 'B' }, { c: 'C' }, { c: 'D' }, { c: 'E' }, { c: 'F' }],
        },
        {
          keys: [{ c: 'G' }, { c: 'H' }, { c: 'I' }, { c: 'J' }, { c: 'K' }, { c: 'L' }],
        },
        {
          keys: [{ c: 'M' }, { c: 'N' }, { c: 'O' }, { c: 'P' }, { c: 'Q' }, { c: 'R' }],
        },
        {
          keys: [{ c: 'S' }, { c: 'T' }, { c: 'U' }, { c: 'V' }, { c: 'W' }, { c: 'X' }],
        },
        {
          keys: [{ c: 'Y' }, { c: 'Z' }, { c: '1' }, { c: '2' }, { c: '3' }, { c: '4' }],
        },
        {
          keys: [{ c: '5' }, { c: '6' }, { c: '7' }, { c: '8' }, { c: '9' }, { c: '0' }],
        },
        {
          keys: [
            { action: 'backspace', w: 230, source: 'static_assets/backspace.png' },
            { action: 'delete', w: 230, source: 'static_assets/trash.png' },
          ],
        },
      ],
    },
    promoCode: {
      rows: [
        {
          keys: [{ c: 'a' }, { c: 'b' }, { c: 'c' }, { c: 'd' }, { c: 'e' }, { c: 'f' }, { c: '1' }, { c: '2' }, { c: '3' }],
        },
        {
          keys: [{ c: 'g' }, { c: 'h' }, { c: 'i' }, { c: 'j' }, { c: 'k' }, { c: 'l' }, { c: '4' }, { c: '5' }, { c: '6' }],
        },
        {
          keys: [{ c: 'm' }, { c: 'n' }, { c: 'o' }, { c: 'p' }, { c: 'q' }, { c: 'r' }, { c: '7' }, { c: '8' }, { c: '9' }],
        },
        {
          keys: [{ c: 's' }, { c: 't' }, { c: 'u' }, { c: 'v' }, { c: 'w' }, { c: 'x' }, { c: 'y' }, { c: 'z' }, { c: '0' }],
        },
        {
          keys: [
            { action: 'backspace', w: 350, source: 'static_assets/backspace.png' },
            { action: 'delete', w: 350, source: 'static_assets/trash.png' },
          ],
        },
      ],
    },
  },
}

export default template
