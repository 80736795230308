import { Lightning, Utils, Language, Router, Registry, Profile, Storage, Colors } from '@lightningjs/sdk'
import { InlineContent } from '@lightningjs/ui-components'
import { getActivationCode } from '../Services/PersonaService.js'
import { DEVICE_TYPE, REGION, LOCALE, BEARER, gradientColor, fontsizes } from '../Helper/GlobalConstants.js'
import { Back, LanguageSelection, LocalesList } from '../Components/index.js'
import { showExitPopUp } from '../Helper/Helpers.js'
import configurations from '../Helper/Configurations.js'
import App from '../App.js'
import { userSettingsAPI } from '../Services/AuthService.js'

export default class Authenticate extends Lightning.Component {
  static _template() {
    return {
      Background: {
        x: 0,
        y: 0,
        w: App.width,
        h: App.height,
        rect: true,
        color: Colors('backgroundColor').get(),
      },
      Logo: {
        h: 170,
        w: 1920,
        zIndex: 4,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        Logo: {
          src: Utils.asset('static_assets/qrCodeActivationLogo.png'),
        }
      },
      LinearGradient: {
        zIndex: 2,
        x: 730,
        w: 220,
        rect: true,
        h: App.height,
        colorRight: gradientColor.bannerImgGradient,
        colorLeft: Colors('backgroundColor').get()
      },
      BrandingStripe: {
        zIndex: 1,
        w: App.width,
        h: App.height,
        src: Utils.asset('static_assets/branding_stripes.png')
      },
      Process: {
        y: 270,
        x: 120,
        zIndex: 3,
        flex: { direction: 'column', alignItems: 'flex-start' },
        Label1: {
          type: InlineContent,
          content: [],
        },
        Label1Child: {
          type: InlineContent,
          content: [],
        },
        Label2: {
          text: {
            textColor: Colors('primaryFontColor1').get(),
            fontSize: fontsizes.authenticatePageSteps,
            fontFace: 'Regular',
            wordWrapWidth: 750,
            lineHeight: 56,
            maxLines: 3,
            maxLinesSuffix: '...'
          },
        },
        Label3: {
          text: {
            textColor: Colors('primaryFontColor1').get(),
            fontSize: fontsizes.authenticatePageSteps,
            fontFace: 'Regular',
            wordWrapWidth: 750,
            maxLines: 3,
            maxLinesSuffix: '...'
          },
        },
        Box: {
          Text: {
            y: 22,
            text: {
              fontSize: fontsizes.authenticatePageCode,
              fontFace: 'Bold',
              textColor: Colors('primaryFontColor1').get(),
              textAlign: 'center',
            },
          },
        },
      },
      OR: {
        x: 930,
        y: 510,
        text: {
          textAlign: 'center',
          fontSize: 40,
          textColor: Colors('primaryFontColor1').get(),
          fontFace: 'Bold',
          wordWrapWidth: 100,
          maxLines: 1,
          maxLinesSuffix: '...'
        }
      },
      Divider1: {
        zIndex: 3,
        alpha: 0,
        x: 955,
        y: 265,
        texture: Lightning.Tools.getRoundRect(1, 200, 1, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
      },
      Divider2: {
        zIndex: 3,
        alpha: 0,
        x: 955,
        y: 610,
        texture: Lightning.Tools.getRoundRect(1, 200, 1, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
      },
      SubText: {
        x: 1150,
        y: 260,
        text: {
          color: Colors('primaryFontColor1').get(),
          fontSize: fontsizes.authenticatePageQRText,
          fontFace: 'Regular',
          lineHeight: 48,
          maxLines: 2,
          wordWrapWidth: 650,
        },
      },
      QRcode: {
        x: 1315,
        y: 430,
        QRimage: {
          h: 300,
          w: 300,
        },
      },
      ScanQRCode: {
        y: 770,
        text: {
          color: Colors('primaryFontColor1').get(),
          fontSize: 42,
          fontFace: 'Bold',
          lineHeight: 45,
          maxLines: 2,
          wordWrapWidth: 350,
        },
      },
      LanguageSelection: { type: LanguageSelection },
      LocalesList: { type: LocalesList },
      Back: { type: Back }
    }
  }

  changeAuthenticateCode() {
    this.callactivate()
    this.intervalId = Registry.setInterval(() => {
      if (configurations.checkAuthentication) {
        Registry.clearInterval(this.intervalId)
        Registry.clearIntervals()
      } else {
        this.callactivate()
      }
    }, 10000)
  }

  getHouseHoldID() {
    Profile.household().then((res) => {
      this.houseHoldID = res
    })
  }

  returnOperator() {
    Profile.operator().then(res => {
      this.Operator = res
    })
  }

  async callactivate() {
    const paramsObj = {}
    paramsObj['region'] = REGION
    paramsObj['locale'] = LOCALE
    paramsObj['d_type'] = DEVICE_TYPE
    paramsObj['d_id'] = configurations.deviceId
    paramsObj['unique_device_id'] = this.houseHoldID

    let activationResponse
    if (!configurations.checkAuthentication)
      activationResponse = await getActivationCode(paramsObj)
    if (activationResponse?.data.user_id && !configurations.checkAuthentication) {
      // need to reset the token to the access_token received from response when activation is done

      configurations.checkAuthentication = true
      Registry.clearInterval(this.intervalId)
      Registry.clearIntervals()
      Storage.remove('stateInfo')
      if (activationResponse.data.device_limit_exceed && configurations.enableDeviceMangement)
        return Router.navigate('managedevices/during-sign-in')

      if (configurations.enableProfiles) {
        await userSettingsAPI()
        return Router.navigate('profile/add')
      }
      return Router.navigate('home')
    }

    if (!configurations.checkAuthentication) {
      const array = []
      array.push(activationResponse.data.mcode)
      const str = array[0]
      const arr = Object.assign([], str)
      this.tag('Box').patch({
        Text: { text: arr.join(' ') },
      })
      this.tag('QRimage').patch({
        src: Utils.proxyUrl(activationResponse.data.qr_code_url)
      })
      this.tag('Text').on('txLoaded', () => {
        const width = this.tag('Text').renderWidth
        const dynamicXPos = (385 - width) / 2
        this.tag('Text').patch({ x: dynamicXPos })
      })
    }
  }

  _firstActive() {
    if (configurations.locales.length > 1) {
      this.tag('LanguageSelection').data = configurations.locales
    }
  }

  clearSidePanel() {
    this.widgets.sidepanelnavigation.alpha = 0
    this.tag('LocalesList').alpha = 0
  }

  _handleBack() {
    Registry.clearInterval(this.intervalId)
    Registry.clearIntervals()
    if (configurations.loginMode && configurations.supportedLogins == 'qr') return showExitPopUp()
    configurations.loginMode && configurations.supportedLogins == 'qr,credentials'
      ? Router.navigate('loginoption')
      : Router.back()
  }

  _disable() {
    Registry.clearInterval(this.intervalId)
    Registry.clearIntervals()
  }

  _active() {
    this.clearSidePanel()
  }

  calculateTextWidth() {
    this.tag('ScanQRCode').on('txLoaded', () => {
      const width = this.tag('ScanQRCode').renderWidth
      const dynamicXPos = (App.width - width) / 2
      this.tag('ScanQRCode').patch({ x: dynamicXPos + 500 })
    })
  }

  updateLanguageCode() {
    this.tag('LanguageSelection').updateLanguageCode()
  }

  focusLanguageSelection() {
    this._setState('LanguageSelection')
  }

  _handleLeft() {
    this._setState('Back')
  }

  _getFocused() {
    return configurations.locales.length > 1 ? this.focusLanguageSelection() : this._setState('Back')
  }

  openLocalesList() {
    this.tag('LocalesList').openLocalesList()
    this._setState('LocalesList')
  }

  focusLocalesList() {
    this._setState('LocalesList')
  }

  focusBack() {
    if (configurations.locales.length > 1)
      this._setState('Back')
  }

  updateTranslations() {
    this.patchTranslations()
  }

  patchTranslations() {
    const textStyle = {
      textColor: Colors('primaryFontColor1').get(),
      fontSize: fontsizes.authenticatePageSteps,
      fontFace: 'Regular',
      wordWrapWidth: 750,
      lineHeight: 40,
      maxLines: 3,
      maxLinesSuffix: '...'
    }
    this.tag('Process').patch({
      Label1: {
        content: [
          {
            text: Language.translate('activate_tv.please_visit.text'),
            style: {
              textColor: Colors('primaryFontColor1').get(),
              fontSize: fontsizes.authenticatePageSteps,
              fontFace: 'Regular',
              lineHeight: 40,
              maxLines: 1,
            },
          },
          {
            text: Language.translate('activate_tv.smart_tv.text'),
            style: {
              fontSize: fontsizes.authenticatePageSteps,
              fontFace: 'Regular',
              textColor: Colors('brandPrimaryColor').get(),
              lineHeight: 40,
              wordWrapWidth: 800,
              maxLines: 1,
            },
          },
        ],
        flexItem: { marginBottom: 20 },
      },
      Label1Child: {
        content: [
          {
            text: Language.translate('activate_tv.browser_or_qr_code.text'),
            style: textStyle
          },
        ],
        flexItem: { marginBottom: 50 },
      },
      Label2: {
        text: {
          text: Language.translate('activate_tv.sign_up_your_account.text'),
        },
        flexItem: { marginBottom: 20 },
      },
      Label3: {
        text: {
          text: Language.translate('activate_tv.enter_code.text'),
        },
        flexItem: { marginBottom: 60 },
      },
      Box: {
        texture: Lightning.Tools.getRoundRect(381, 127, 2, 2, Colors('brandPrimaryColor').get(), false),
      }
    })
    this.tag('Divider1').patch({ alpha: 1 })
    this.tag('Divider2').patch({ alpha: 1 })
    this.tag('OR').patch({
      text: {
        text: Language.translate('common_keys.or.text')
      }
    })
    this.tag('SubText').patch({
      text: {
        text: Language.translate('activate_tv.scan_qr_code_reader.text'),
      },
    })
    this.tag('ScanQRCode').patch({
      text: {
        text: Language.translate('activate_tv.scan_code.text'),
      },
    })
  }

  patchDataToTemplate() {
    this.getHouseHoldID()
    this.changeAuthenticateCode()
    this.calculateTextWidth()
    this.tag('Back').updatePosition([105, 60])
    this.tag('Text').on('txLoaded', () => {
      this.patchTranslations()
    })
    configurations.locales.length > 1 ? this.focusLanguageSelection() : this._setState('Back')
  }

  static _states() {
    return [
      class LanguageSelection extends this {
        _getFocused() {
          return this.tag('LanguageSelection')
        }
      },

      class Back extends this {
        _getFocused() {
          return this.tag('Back')
        }
      },

      class LocalesList extends this {
        _getFocused() {
          return this.tag('LocalesList')
        }
      }
    ]
  }
}
