export class SeriesPersonaResponse {
  constructor(val) {
    this.statusCode = val.status_code;
    this.statusMessage = val.message;
    this.data = val.data ? new SeriesPersonaDetails(val.data) : [];
  }
}

class SeriesPersonaDetails {
  constructor(seriesData) {
    this.geoAvailable = seriesData.geo_available || 0;
    this.askAccessPin = seriesData.ask_access_pin || 0
    this.accessAgeLimit = seriesData.access_age_limit || ''
    this.availability = seriesData.availability || 0;
    this.wishlistStatus = seriesData.wishlist_status || false;
    this.videoId = seriesData.video_id || "";
    this.seriesId = seriesData.series_id || "";
    this.seasonId = seriesData.season_id || "";
    this.seasonsSlug = seriesData.seasons_slug || "";
    this.seasonPosition = seriesData.season_position || 0;
    this.seek = seriesData.seek || 0;
    this.continueId = seriesData.continue_id || "";
    this.playbackUrl = seriesData.playback_url || "";
    this.playbackType = seriesData.playback_type || 0;
    this.durationSec = seriesData.duration_sec || 0;
    this.durationMin = seriesData.duration_min || 0;
    this.adUrl = seriesData.ad_url || "";
    this.percentage = seriesData.percentage || 0;
    this.continueWatchingContent = seriesData.continue_watching_content?.length > 0 ? seriesData.continue_watching_content.map(item => new ContinueWtachingContent(item)) : [];
    this.preferredLanguage = seriesData.preferred_language || "";
    this.preferredSubtitle = seriesData.preferred_subtitle || "";
    this.hlsPlaybackUrl = seriesData.hls_playback_url || "";
    this.dashPlaybackUrl = seriesData.dash_playback_url || "";
    this.buy = seriesData.buy || {}
    this.rent = seriesData.rent || {}
  }
}

class ContinueWtachingContent {
  constructor(data) {
    this.contentId = data.content_id
    this.slug = data.slug
    this.percentage = data.percentage
    this.position = data.position
    this.id = data.id
  }
}
