import { Language, Lightning, Router } from '@lightningjs/sdk'
import { isEmptyObject } from '../../Helper/Helpers.js'
import configurations from '../../Helper/Configurations.js'
import DynamicButton from '../DynamicButton'

export default class TVODButton extends Lightning.Component {
  static _template() {
    return {
      Wrapper: {
        ButtonList: {
          x: 830,
          y: 550,
        }
      }
    }
  }

  prepareButton(channelData) {
    this.channelData = channelData
    let rentAmount = null, buyAmount = null
    if (!isEmptyObject(channelData.rent)) {
      rentAmount = configurations.userType ? channelData.rent.sub_amount : channelData.rent.amount
    }
    if (!isEmptyObject(channelData.buy)) {
      buyAmount = configurations.userType ? channelData.buy.sub_amount : channelData.buy.amount
    }
    const finalAmount = rentAmount && buyAmount ? Math.min(rentAmount, buyAmount) : (rentAmount || buyAmount)
    const currentRegion = configurations.regions.find(region => region.region_code === configurations.region)
    const priceAmount = currentRegion.prefix_currency ? currentRegion.prefix_currency + finalAmount : finalAmount + currentRegion.suffix_currency
    const button = [{ text: Language.translate('plans.watch_from.button') + ' | ' + ' ' + priceAmount }]
    this.tag('ButtonList').children = button.map(button => {
      return {
        type: DynamicButton,
        h: 55,
        borderRadius: 2,
        textPadding: 30,
        label: button.text,
        withoutTexture: false,
        flexItem: { margin: 20, alignSelf: 'stretch', maxHeight: 100 },
      }
    })
  }

  _handleEnter() {
    Router.getActivePage().openTVODOverlay(this.channelData)
  }

  _getFocused() {
    return this.tag('ButtonList').children[0]
  }

  _handleKey() { return }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }

  _handleDown() {
    Router.getActivePage().focusComponent('channels')
  }

  _handleUp() {
    Router.getActivePage().focusComponent('back')
  }
}
