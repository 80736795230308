import { TVguide } from '../Views'
import { getCategory } from '../Services/AssetsService'
import configurations from '../Helper/Configurations'
import { Storage } from '@lightningjs/sdk'
/**
 * Defines the configuration for the 'category' page.
 */
export default {
    path: 'tvguide/:categoryId',
    options: {
        // reuseInstance: false
    },
    component: TVguide,
    /**
     * Defines the asynchronous setup for the 'live-category' page.
     * @param {Object} page - The page instance.
     */
    on: async (page, { categoryId }) => {
        let categoryIndex
        configurations.categories.categoryData.forEach((Obj, index) => {
            if (Obj.categoryId === categoryId) {
                categoryIndex = index
            }
        })
        Storage.set('activeCategory', categoryIndex + 2)
        await getCategory(categoryId)
            .then(async response => {
                const filteredData = response.data.filter((val) => {
                    if (val.component === "livechannel_widget") return val
                })
                page.data = filteredData
                return true
            })
    },
    /**
     * List of widgets to be included on the 'live-category' page.
     */
    widgets: ['menu']
}
