import { Settings } from '../Views/index'
import { Router, Storage } from '@lightningjs/sdk'
import configurations from '../Helper/Configurations'

export default {
    path: 'settings',
    component: Settings,
    /**
     * @param {Object} page - The page instance.
     */
    on: async (page) => {
        const categoryLength = configurations.categories.categoryData.length
        Storage.set('activeCategory', configurations.loggedIn ? categoryLength + 3 : categoryLength + 2)
        page.widgets.menu.menuCollapse()
        Router.focusPage()
        return true
    },
    widgets: ['menu', 'SettingsAction', 'SettingsView']
}