export class SeriesResponse {
  constructor(val) {
    this.statusCode = val.status_code;
    this.statusMessage = val.message;
    this.data = val.data ? new SeriesContentData(val.data) : [];
  }
}

class SeriesContentData {
  constructor(data) {
    this.series = data.series && new SeriesMetaData(data.series);
    this.trailerVideos = data.trailer_videos?.length > 0 ? data.trailer_videos.map((item) => new TrailerMetaData(item)) : [];
    this.seasons = data.seasons?.length > 0 ? data.seasons.map((item) => new SeasonMetaData(item)) : [];
  }
}

class SeriesMetaData {
  constructor(data) {
    this.publishTime = data.publish_time || "";
    this.uniqueId = data.unique_id || "";
    this.slug = data.slug || "";
    this.title = data.title || "";
    this.titleEn = data.title_en || "";
    this.description = data.description || "";
    this.releaseDate = data.release_date || "";
    this.categoryId = data.category_id || null;
    this.director = data.director || "";
    this.writer = data.writer || "";
    this.producer = data.producer || "";
    this.cast = data.cast || "";
    this.age = data.age || 0;
    this.smartSigns = data.smart_signs || [];
    this.trailerId = data.trailer_id || 0;
    this.tags = data.tags || [];
    this.genre = data.genre || ''
    this.customTag = data.custom_tag || "";
    this.adUrl = data.ad_url || null;
    this.categories = data.categories || "";
    this.audios = data.audios || [];
    this.subtitles = data.subtitles || [];
    this.status = data.status || 0;
    this.contentLogo = data.content_logo || "";
    this.screenshotLandscape = data.screenshot_landscape || "";
    this.backgroundPortrait = data.background_portrait || "";
    this.backgroundLandscapeCenter = data.background_landscape_center || "";
    this.backgroundLandscape = data.background_landscape || "";
    this.posterSquare = data.poster_square || "";
    this.posterLandscape = data.poster_landscape || "";
    this.posterPortrait = data.poster_portrait || "";
    this.totalSeasons = data.total_seasons || 0;
    this.isLocalLogo = data.is_local_logo || 0
    this.seriesEpisodesCount = data.series_episodes_count || 0;
    this.contentLogoResolutions = data.content_logo_resolutions || {};
    this.backgroundPortraitResolutions = data.background_portrait_resolutions || "";
    this.backgroundLandscapeCenterResolutions = data.background_landscape_center_resolutions || "";
    this.backgroundLandscapeResolutions = data.background_landscape_resolutions || "";
    this.posterSquareResolutions = data.poster_square_resolutions || "";
    this.posterLandscapeResolutions = data.poster_landscape_resolutions || {};
    this.posterPortraitResolutions = data.poster_portrait_resolutions || {};
    this.seriesContents = data.series_contents?.length > 0 ? data.series_contents.map(episode => new SeriesContentMetaData(episode)) : []
    this.availability = data.availability || 0;
    this.userType = data.user_type || 0;
    this.wishlistStatus = data.wishlist_status || false;
    this.videoId = data.video_id || "";
    this.contentSlug = data.content_slug || "";
    this.geoAvailable = data.geo_available || 0;
  }
}

class TrailerMetaData {
  constructor(data) {
    this.title = data.title || "";
    this.titleEn = data.title_en || "";
    this.description = data.description || "";
    this.slug = data.slug || "";
    this.age = data.age || 0;
    this.duration = data.duration || "";
    this.durationMin = data.duration_min || 0;
    this.publishTime = data.publish_time || "";
    this.director = data.director || "";
    this.writer = data.writer || "";
    this.cast = data.cast || "";
    this.producer = data.producer || "";
    this.adUrl = data.ad_url || "";
    this.tags = data.tags || "";
    this.contentId = data.contentId || "";
    this.posterLandscape = data.poster_landscape || "";
    this.posterPortrait = data.poster_portrait || "";
    this.trailerId = data.trailer_id || "";
    this.playbackType = data.playback_type || 0;
    this.playbackUrl = data.playback_url || "";
    this.hlsPlaybackUrl = data.hls_playback_url || "";
    this.dashPlaybackUrl = data.dash_playback_url || "";
    this.textTracks = data.text_tracks || [];
    this.durationSec = data.duration_sec || 0;
    this.posterLandscapeResolutions = data.poster_landscape_resolutions || {};
    this.posterPortraitResolutions = data.poster_portrait_resolutions || {};
  }
}

class SeasonMetaData {
  constructor(data) {
    this.seasonId = data.season_id || "";
    this.title = data.title || "";
    this.description = data.description || "";
    this.posterLandscape = data.poster_landscape || "";
    this.seasonPosition = data.season_position || 0;
    this.trailerId = data.trailer_id || "";
    this.contentCode = data.content_code || "";
    this.contentId = data.contentId || "";
    this.seasonsSlug = data.seasons_slug || "";
    this.posterLandscapeResolutions = data.poster_landscape_resolutions || {};
    this.contents = data.contents?.length > 0 ? data.contents.map((item) => new ContentMetaData(item)) : [];
  }
}

class ContentMetaData {
  constructor(data) {
    this.publishTime = data.publish_time || "";
    this.contentId = data.content_id || "";
    this.contentIdAlternate = data.contentId || ""; // Added to handle the duplicate 'contentId' key
    this.title = data.title || "";
    this.description = data.description || "";
    this.tags = data.tags || "";
    this.director = data.director || "";
    this.writer = data.writer || "";
    this.producer = data.producer || "";
    this.cast = data.cast || "";
    this.contentLogo = data.content_logo || "";
    this.screenshotLandscape = data.screenshot_landscape || "";
    this.backgroundPortrait = data.background_portrait || "";
    this.backgroundLandscapeCenter = data.background_landscape_center || "";
    this.backgroundLandscape = data.background_landscape || "";
    this.posterSquare = data.poster_square || "";
    this.posterLandscape = data.poster_landscape || "";
    this.posterPortrait = data.poster_portrait || "";
    this.titleEn = data.title_en || "";
    this.isTvod = data.is_tvod || 0;
    this.duration = data.duration || "";
    this.durationMin = data.duration_min || 0;
    this.age = data.age || 0;
    this.seriesId = data.series_id || "";
    this.seasonId = data.season_id || "";
    this.position = data.position || 0;
    this.contentSlug = data.content_slug || "";
    this.releaseDate = data.release_date || "";
    this.durationSec = data.duration_sec || 0;
    this.contentLogoResolutions = data.content_logo_resolutions || {};
    this.backgroundPortraitResolutions = data.background_portrait_resolutions || "";
    this.backgroundLandscapeCenterResolutions = data.background_landscape_center_resolutions || "";
    this.backgroundLandscapeResolutions = data.background_landscape_resolutions || "";
    this.posterSquareResolutions = data.poster_square_resolutions || "";
    this.posterLandscapeResolutions = data.poster_landscape_resolutions || {};
    this.posterPortraitResolutions = data.poster_portrait_resolutions || {};
  }
}

class SeriesContentMetaData {
  constructor(data) {
    this.backgroundLandscape = data.background_landscape || ""
    this.backgroundLandscapeCenter = data.background_landscape_center || ""
    this.backgroundLandscapeCenterResolutions = data.background_landscape_center_resolutions || ""
    this.backgroundLandscapeResolutions = data.background_landscape_resolutions || ""
    this.backgroundPortrait = data.background_portrait || ""
    this.backgroundPortraitResolutions = data.background_portrait_resolutions || ""
    this.Cast = data.cast || ""
    this.contentId = data.contentId || ""
    this.contentCode = data.content_code || ""
    this.contentId = data.content_id || ""
    this.contentLogo = data.content_logo || ""
    this.contentLogoResolutions = data.content_logo_resolutions || ""
    this.contentPartnerId = data.content_partner_id || ""
    this.contentSlug = data.content_slug || ""
    this.description = data.description || ""
    this.director = data.director || ""
    this.duration = data.duration || 0
    this.durationMin = data.duration_min || 0
    this.durationSec = data.duration_sec || 0
    this.h1Tag = data.h1_tag || ""
    this.isLocalLogo = data.is_local_logo || 0
    this.isAudio = data.is_audio || 0
    this.isFreeInSeries = data.is_free_in_series || 0
    this.isSvod = data.is_svod || 0
    this.isTvod = data.is_tvod || 0
    this.metaDescription = data.meta_description || ""
    this.metaKeywords = data.meta_keywords || ""
    this.backgroundLandscape = data.background_landscape || ""
    this.metaTitle = data.meta_title || ""
    this.offlineDownload = data.offline_download || 0
    this.position = data.position || 0
    this.posterLandscape = data.poster_landscape || ""
    this.posterLandscapeResolutions = data.poster_landscape_resolutions || ""
    this.posterPortrait = data.poster_portrait || ""
    this.posterPortraitResolutions = data.poster_portrait_resolutions || ""
    this.posterSquare = data.poster_square || ""
    this.posterSquareResolutions = data.poster_square_resolutions || ""
    this.producer = data.producer || ""
    this.publish = data.publish || ""
    this.publishTime = data.publish_time || ""
    this.releaseDate = data.release_date || ""
    this.screenshotLandscape = data.screenshot_landscape || ""
    this.seasonId = data.season_id || ""
    this.seriesId = data.series_id || ""
    this.tags = data.tags || ""
    this.title = data.title || ""
    this.titleEn = data.title_en || ""
    this.tvodPlanId = data.tvod_plan_id || ""
    this.useExistingImage = data.use_existing_image || ""
    this.writer = data.writer || ""
  }
}

