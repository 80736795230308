
import HlsVodPlayer from "../Views/HlsVodPlayer"
export default {
    path: 'hlsvodplayer/:action/:videoId',
    component: HlsVodPlayer,
    on: async (_page) => {
      return
    },
    widgets: [
      'SidePanelNavigation',
      'AudioSidePanel',
      'SubtitlesSidePanel',
      'BackgroundSidepanel',
      "EpisodeListing"
    ],
}
