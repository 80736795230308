import { getViewAllVideos } from '../Services/AssetsService'
import { ViewAllContentResponse } from '../Models/ViewAllModel'
import ViewAllPage from '../Views/ViewAll'
import { Item } from '../Components'
/**
 * Defines the configuration for the 'ViewAll' page.
 */
export default {
    path: 'viewallpage/:mainComponent/:key/:category',
    component: ViewAllPage,
    /**
     * Defines the asynchronous setup for the 'ViewAll' page.
     * @param {Object} page - The page instance.
     */
    on: async (page, { mainComponent, key, category }) => {
        const skip = 0
        const take = 16
        page.tag('Content').tag('GridView').ItemType = Item
        page.params = [mainComponent, key, category]
        page.viewAllCollection = await getViewAllVideos(mainComponent, key, category, skip, take)
            .then((response) => {
                if (key === 'continue' || key === 'wishlist' || key === 'purchases' || key.includes('because_you_watched') || key == 'top_picks_for_you') {
                  const selectedPersonaViewMore = response.data.find(personaItem => personaItem.key === key)
                  selectedPersonaViewMore.status_code = response.status_code
                  return new ViewAllContentResponse(selectedPersonaViewMore)
                }
                return new ViewAllContentResponse(response)
            })
    },
    /**
     * List of widgets to be included on the 'ViewAll' page.
     */
    widgets: ['menu']
}
