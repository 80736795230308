import { Router, Storage } from '@lightningjs/sdk'
import { Search } from '../Views'
/**
 * Defines the configuration for the 'search' page.
 */
export default {
  path: 'search',
  component: Search,
  on: async (page) => {
    Storage.set('activeCategory', 1)
    Router.focusPage()
    return true
  },
  /**
   * List of widgets to be included on the 'search' page.
   */
  widgets: ['menu']
}
