import { Lightning, Colors, Utils, Language } from '@lightningjs/sdk'
import { returnImageUrl } from '../../Helper/Helpers'
import { fontsizes, imageSizes } from '../../Helper/GlobalConstants'

export default class VideoItem extends Lightning.Component {
  static _template() {
    return {
      zIndex: 4,
      color: Colors('primaryFontColor1').get(),
      w: VideoItem.width,
      h: VideoItem.height,
      Background: {
        zIndex: 2,
        alpha: 0,
        texture: Lightning.Tools.getRoundRect(
          VideoItem.width - 5,
          VideoItem.height - 5,
          8,
          2,
          Colors('brandPrimaryColor').get(),
          true,
          Colors('focusedBtnColor').get()
        )
      },
      LazyBackground: {
        rect: true,
        color: Colors('focusedBtnColor').get(),
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 8 }
      },
      Image: {
        zIndex: 3,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 8 }
      },
      Title: {
        zIndex: 4,
        w: 330,
        h: 48,
        x: 25,
        alpha: 0,
        text: {
          fontFace: 'Regular',
          lineHeight: 48,
          fontSize: fontsizes.videoItemTitle,
          textColor: Colors('primaryFontColor1').get(),
          maxLines: 1,
          maxLinesSuffix: '...',
          wordWrapWidth: 330
        }
      },
      TVOD: {
        zIndex: 5, h: 32, rect: true, color: 0x90141414, alpha: 0, x: 13, y: 156,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: [0, 0, 8, 8] },
        flex: { direction: 'row', justifyContent: 'center' },
        TVODIcon: { zIndex: 8, flexItem: { marginRight: 8 } },
        TVODText: {
          zIndex: 8,
          text: { fontSize: 22, fontFace: 'Bold', textColor: Colors('tvodLabelColor').get(), maxLines: 1, maxLinesSuffix: '...' }
        }
      }
    }
  }

  _focus() {
    this.tag('Background').patch({
      w: this.width,
      h: this.height,
      x: 2.7,
      y: 2.5,
      alpha: 1,
      texture: Lightning.Tools.getRoundRect(
        this.width - 5,
        this.height - 5,
        8,
        3,
        Colors('brandPrimaryColor').get(),
        true,
        Colors('focusedBtnColor').get()
      )
    })
  }

  _unfocus() {
    this.tag('Background').patch({ alpha: 0 })
  }

  set item(v) {
    let src = null
    if (v.orientation == 2) {
      this.width = 225
      this.height = 334
      this.tag('Title').patch({
        text: { text: v.title },
        x: 10,
        y: v.h + 10,
        alpha: 0
      })
      src = returnImageUrl (v.posterPortrait, imageSizes.card)
    } else {
      this.width = 334
      this.height = 196
      this.tag('Title').patch({
        text: { text: v.title },
        x: 10,
        y: v.h + 10
      })
      src = returnImageUrl (v.posterLandscape, imageSizes.card)
    }
    this.patch({
      w: this.width,
      h: this.height,
      Background: {
        alpha: 0,
        texture: Lightning.Tools.getRoundRect(
          this.width,
          this.height,
          8,
          8,
          Colors('brandPrimaryColor').get(),
          true,
          Colors('focusedBtnColor').get()
        )
      },
      Image: {
        src: src,
        w: v.w - 6,
        h: v.h - 6,
        x: 11,
        y: 11
      },
      LazyBackground: {
        w: v.w - 18,
        h: v.h - 18,
        x: 11,
        y: 11
      }
    })
    if (v.orientation == 2) {
      this.tag('Image').patch({
        x: (this.width - v.w) * 0.5 + 3,
        y: (this.height - v.h) * 0.5 + 3
      })
      this.tag('LazyBackground').patch({
        w: v.w,
        h: v.h,
        x: (this.width - v.w) * 0.5 + 3,
        y: (this.height - v.h) * 0.5 + 3
      })
    } else {
      this.tag('Image').patch({
        mountX: 0.5,
        mountY: 0.5,
        x: this.width / 2 + 3,
        y: this.height / 2 + 3
      })
      this.tag('LazyBackground').patch({
        mountX: 0.5,
        mountY: 0.5,
        x: this.width / 2 + 3,
        y: this.height / 2 + 3
      })
    }
    this.patch({
      x: v.x,
      y: v.y
    })
    if (v.isTvod) {
      this.tag('TVOD').patch({
        w: 314,
        alpha: 1,
        TVODIcon: { w: 24, h: 24, src: Utils.asset('static_assets/rent_or_buy_small.png') },
        TVODText: {
          text: {
            text: Language.translate('plans.available_to_purchase.tag'),
            wordWrapWidth: this.width
          }
        }
      })
    }
  }

  static get width() {
    return 320
  }

  static get height() {
    return 180
  }
}
