import { Lightning, Router, Language, Colors } from '@lightningjs/sdk'
import ListComponent from './ListComponent'
import { fontsizes } from '../Helper/GlobalConstants'

export default class Seasons extends Lightning.Component {
  static _template() {
    return {
      zIndex: 6,
      Container: {
        x: 100,
        y: 40,
        SeasonsTitle: {
          alpha: 0,
          text: {
            fontSize: 28,
            textColor: Colors('primaryFontColor1').get(),
            wordWrapWidth: 250,
            fontFace: 'Bold',
            maxLines: 1,
            maxLinesSuffix: '...',
          },
        },
        Slider: {
          y: 60,
          type: ListComponent,
          w: 270,
          clipping: true,
          h: 980,
          itemSize: 75,
          scrollTransition: { duration: 0.4 },
          horizontal: false,
          viewportScrollOffset: 0.01,
          itemScrollOffset: 0.0,
          _invertDirection: true
        }
      }
    }
  }

  _focus() { return }

  set data({ data, id }) {
    this.defaultSeasonID = id
    const singleSeason = []
    if (data.length == 0) {
      const firstSeason = {
        title: Language.translate('common_keys.seasons.text') + ' ' + 1,
        seasonId: 'seriesEpisodes'
      }
      singleSeason.push(firstSeason)
      this.seasonsArray = singleSeason
    } else {
      this.seasonsArray = data
    }
    this.tag('SeasonsTitle').patch({
      alpha: 1,
      text: Language.translate('common_keys.seasons.text'),
    })
    const children = this.seasonsArray.map((button, index) => {
      button.x = 1
      button.w = 240
      button.h = 64
      button.y = 1
      button.layout = 'border'
      button.color = Colors('primaryFontColor1').get()
      button.index = index
      button.id = this.defaultSeasonID
      if (button.seasonId == this.defaultSeasonID || (!id && index == 0)) {
        button.layout = 'rect'
        button.buttonColor = Colors('selectedBtnColor').get(),
        this.index = index
        Router.getActivePage().widgets.episodelisting.getCurrentSeasonSlug(button.seasonsSlug)
      }

      return { type: Button, button }
    })
    this.tag('Slider').items = children
    this.slider.setIndex(0)
  }

  get slider() {
    return this.tag('Slider')
  }

  get children() {
    return this.slider.items
  }

  get active() {
    return this.children[this.index]
  }

  _handleRight() {
    Router.getActivePage().widgets.episodelisting.focusEpisodes()
  }

  _handleLeft() {
    Router.focusWidget('SidePanelNavigation')
  }

  _handleDown() {
    if (this.index < this.seasonsArray.length - 1) {
      this.index ++
      this.tag('Slider').setNext()
    }
  }

  _handleUp() {
    if (this.index > 0) {
      this.index --
      this.tag('Slider').setPrevious()
    }
  }

  _handleEnter() {
    const enterdIndexId = this.seasonsArray[this.index].seasonId
    const children = this.seasonsArray.map((button, index) => {
      button.x = 1
      button.w = 240
      button.h = 64
      button.y = 1
      button.layout = 'border'
      button.color = Colors('primaryFontColor1').get()
      button.index = index
      button.id = enterdIndexId
      if (button.seasonId == enterdIndexId) {
        button.layout = 'rect'
        button.buttonColor = Colors('selectedBtnColor').get(),
        Router.getActivePage().widgets.episodelisting.getCurrentSeasonSlug(button.seasonsSlug)
      }
      return { type: Button, button }
    })
    this.tag('Slider').items = children
    Router.getActivePage().onSeasonClicked(this.seasonsArray[this.index])
  }

  _getFocused() {
    Router.getActivePage().widgets.episodelisting.sliderDownTransition()
    return this.active
  }

  _unfocus() {
    if (!this.selectedflag) return
    this.tag('Slider').items[this.unfocusIndex].patch({
      texture: Lightning.Tools.getRoundRect(240, 60, 8, 1, Colors('selectedBtnColor').get(), true, Colors('selectedBtnColor').get())
    })
    this.selectedflag = false
  }

  _handleKey() { return }

  _handleBack() {
    Router.focusWidget('SidePanelNavigation')
  }
}

class Button extends Lightning.Component {
  static _template() {
    return {
      texture: Lightning.Tools.getRoundRect(
        400,
        60,
        30,
        1,
        0xfff3f3f3,
        true,
        0xfff3f3f3
      ),
      Label: {
        text: {
          fontFace: 'Bold',
          fontSize: fontsizes.popupButtonsText,
          textAlign: 'center',
          wordWrap: true,
          wordWrapWidth: 180,
          maxLines: 2,
          maxLinesSuffix: '...',
        }
      }
    };
  }

  set button(v) {
    this.buttonData = v
    this.highlight = this.buttonData.buttonColor || 0xfff3f3f3
    this.y = this.buttonData.y
    this.h = this.buttonData.h
    this.w = this.buttonData.w
    this.x = this.buttonData.x
    this.radius = this.buttonData.radius || 8
    this.patch({
      texture:
        this.buttonData.layout == 'border'
          ? Lightning.Tools.getRoundRect(
              this.w - 2,
              this.h - 2,
              2,
              2,
              Colors('strokeColor').get(),
              false
            )
          : Lightning.Tools.getRoundRect(
              this.w - 2,
              this.h - 2,
              2,
              1,
              Colors('selectedBtnColor').get(),
              true,
              this.highlight
            ),
      Label: {
        color: this.buttonData.color || Colors('primaryFontColor1').get(),
        y: this.buttonData.h * 0.5,
        x: this.buttonData.w * 0.5,
        mount: 0.5,
        text: {
          text: this.buttonData.title,
          textColor: this.buttonData.color || Colors('primaryFontColor1').get()
        }
      }
    })
  }

  _focus() {
    this.highlight = Colors('focusedStrokeColor').get(),
    this.texture = Lightning.Tools.getRoundRect(
      this.w,
      this.h,
      2,
      2,
      this.highlight,
      false,
    )
    this.tag('Label').patch({ text: { textColor: Colors('primaryFontColor2').get() } })
  }

  _unfocus() {
    this.patch({
      texture:
        this.buttonData.layout == 'border'
          ? Lightning.Tools.getRoundRect(
              this.w - 2,
              this.h - 2,
              2,
              2,
              Colors('strokeColor').get(),
              false
            )
          : Lightning.Tools.getRoundRect(
              this.w - 2,
              this.h - 2,
              2,
              1,
              Colors('selectedBtnColor').get(),
              true,
              Colors('selectedBtnColor').get()
            ),
      Label: {
        text: {
          textColor: this.buttonData.color || Colors('primaryFontColor1').get()
        }
      }
    })
  }
}

