import { createPageComponents } from '../Helper/Helpers'
import { Wishlist } from '../Views'
import { WishlistResponse } from '../Models/WishlistModel'
import { getWishlistPageContent } from '../Services/PersonaService'
import { Storage } from '@lightningjs/sdk'
import configurations from '../Helper/Configurations'

/**
 * Defines the configuration for the 'wishlist' page.
 */
export default {
  path: 'wishlist',
  component: Wishlist,
  /**
   * Defines the asynchronous setup for the 'wishlist' page.
   * @param {Object} page - The page instance.
   */
  on: async (page) => {
    await getWishlistPageContent()
      .then((response) => {

        const responseObj = new WishlistResponse(response)

        const initialCarouselsArray = responseObj?.data?.contents

        page.setListData(responseObj.data.contents, responseObj.data.contents, initialCarouselsArray)

        page.addLists(createPageComponents(initialCarouselsArray))

        const categoryLength = configurations.categories.categoryData.length
        Storage.set('activeCategory', categoryLength + 2)
        return true

      })
  },
  /**
   * List of widgets to be included on the 'wishlist' page.
   */
  widgets: ['menu']
}
