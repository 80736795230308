import { Language, Lightning, Router, Utils, Colors } from '@lightningjs/sdk'
import App from '../../App'
import configurations from '../../Helper/Configurations'
import TagsButton from '../../Components/TagsButton'
import { fontsizes } from '../../Helper/GlobalConstants'
import { InlineContent } from '@lightningjs/ui-components'


export default class MoreInfoSidePanel extends Lightning.Component {
  static _template() {
    return {
      h: App.height,
      alpha: 0,
      TopToBottomGradient: {
        x: 1297,
        w: App.width - 1297,
        h: App.height,
        rect: true,
        color: Colors('backgroundColor').get(),
        zIndex: 2,
      },
      ContentContainer: {
        w: 550,
        x: 1370,
        y: 30,
        clipping: true,
        flex: { direction: 'column' },
        Title: {
          w: 500,
          zIndex: 6,
          text: {
            textColor: Colors('primaryFontColor1').get(),
            fontSize: fontsizes.moreInfoSidePanelContentContainerTitle,
            lineHeight: 45,
            wordWrapWidth: 399,
            fontFace: 'Bold',
            textAlign: 'right',
            maxLines: 5,
          },
        },
        TitleEn: {
          alpha: 1,
          w: 500,
          zIndex: 6,
          text: {
            textColor: Colors('primaryFontColor1').get(),
            fontSize: 26,
            lineHeight: 45,
            wordWrapWidth: 399,
            fontFace: 'Bold',
            textAlign: 'right',
            maxLines: 3,
          },
        },
        DurationContainer: {
          w: 500,
          flex: { direction: 'row', justifyContent: 'flex-end' },
          Duration: {
            zIndex: 6,
            text: {
              fontSize: fontsizes.moreInfoSidePanelDuration,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 150,
              maxLines: 1,
              maxLinesSuffix: '...',
              lineHeight: 28,
              textAlign: 'right',
              fontFace: 'Regular',
            },
          },
          ClockImg: {
            y: 4,
            zIndex: 5,
            src: Utils.asset('/static_assets/timer.png'),
          }
        },
        FlexBoxContainer: {
          w: 500,
          zIndex: 6,
          flex: { direction: 'column' },
          SeasonsEpisodesHeader: {
            alpha: 1,
            visible: false,
            w: 500,
            text: {
              fontSize: fontsizes.moreInfoSidePanelDescription,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 396,
              lineHeight: 32,
              textAlign: 'right',
              fontFace: 'Regular',
            },
          },
          Description: {
            x: 0,
            w: 500,
            text: {
              fontSize: fontsizes.moreInfoSidePanelDescription,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 396,
              lineHeight: 32,
              fontFace: 'Regular',
              textAlign: 'right',
            },
          },
          SmartSignContainer: {
            flexItem: { marginLeft: 50, marginRight: 20, shrink: 1 },
            y: 0,
            x: 15,
            w: 500,
            flex: {
              direction: 'row',
              justifyContent: 'flex-end',
              alignContent: 'flex-end',
            },
            SmartSignsListContainer: {
              flex: {
                direction: 'row',
                justifyContent: 'flex-end',
                alignContent: 'flex-end',
                alignItems: 'flex-end',
              },
              flexItem: { marginRight: 20 },
              w: 500,
            },
          },
          MaturityContainer: {
            flexItem: { shrink: 1 },
            w: 500,
            flex: { direction: 'column', justifyContent: 'flex-end', alignContent: 'flex-end' },
            MaturityRatingText: {
              alpha: 0,
              justify: 'flex-end',
              type: InlineContent,
              flexItem: { marginBottom: 20 },
              style: { iconW: 28, iconH: 25 },
              content: [
                { icon: Utils.asset('static_assets/maturity_rating.png'), w: 28, h: 25 },
                {
                  text: Language.translate('content_detail.maturity_rating.text'),
                  fontSize: fontsizes.moreInfoSidePanelMaturityText,
                  fontFace: 'Bold',
                }
              ]
            },
            SignNames: {
              w: 500,
              alpha: 0,
              text: {
                fontSize: fontsizes.moreInfoSidePanelSignNames,
                fontFace: 'Regular',
                lineHeight: 35,
                textAlign: 'right',
              },
            },
          },
          StarringTitle: {
            w: 500,
            text: {
              fontSize: fontsizes.moreInfoSidePanelStarringTitle,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 30,
              fontFace: 'Bold',
            },
          },
          Starring: {
            flexItem: { marginBottom: 25 },
            w: 500,
            text: {
              fontSize: fontsizes.moreInfoSidePanelStarringText,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 30,
              fontFace: 'Regular',
            },
          },
          CreatorTitle: {
            w: 500,
            text: {
              fontSize: fontsizes.moreInfoSidePanelCreatorTitle,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 30,
              fontFace: 'Bold',
            },
          },
          Creator: {
            flexItem: { marginBottom: 25 },
            w: 500,
            text: {
              fontSize: fontsizes.moreInfoSidePanelCreatorText,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 30,
              fontFace: 'Regular',
            },
          },
          WriterTitle: {
            w: 500,
            text: {
              fontSize: fontsizes.moreInfoSidePanelCreatorTitle,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 30,
              fontFace: 'Bold',
            }
          },
          Writer: {
            flexItem: { marginBottom: 25 },
            w: 500,
            text: {
              fontSize: fontsizes.moreInfoSidePanelCreatorText,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 30,
              fontFace: 'Regular',
            },
          },
          ProducerTitle: {
            w: 500,
            text: {
              fontSize: fontsizes.moreInfoSidePanelCreatorTitle,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 30,
              fontFace: 'Bold',
            }
          },
          Producer: {
            flexItem: { marginBottom: 25 },
            w: 500,
            text: {
              fontSize: fontsizes.moreInfoSidePanelCreatorText,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 30,
              fontFace: 'Regular',
            },
          },
          Tags: {
            flexItem: { marginTop: 10 },
            flex: { direction: 'row', wrap: true, justifyContent: 'flex-end' },
            w: 500,
          },
        },
      },
    }
  }

  set data(v) {
    this._item = v.data?.series || v
    const titleEn = this._item.titleEn || this._item.title_en

    this.tag('Title').patch({
      text: { text: this._item.title },
    })
    this.tag('TitleEn').patch({
      visible: titleEn && titleEn !== this._item.title,
      text: {
        text: titleEn !== this._item.title ? titleEn : '',
      }
    })
    this.tag('Duration').patch({
      text: { text: !v.is_livechannel ? Math.round(this._item.durationSec / 60) + ' ' + Language.translate('common_keys.min.text') + '.' : '' },
    })
    this.tag('Description').patch({
      text: this._item.description
    })
    this.renderSmartSigns()

    const castAndCrew = [
      {
        field: 'cast',
        titleTag: 'StarringTitle',
        contentTag: 'Starring',
        translationKey: 'kws_kw_cast_def',
      },
      {
        field: 'director',
        titleTag: 'CreatorTitle',
        contentTag: 'Creator',
        translationKey: 'kws_kw_dir_def',
      },
      {
        field: 'writer',
        titleTag: 'WriterTitle',
        contentTag: 'Writer',
        translationKey: 'cnt_del_page_desc_writr_def',
      },
      {
        field: 'producer',
        titleTag: 'ProducerTitle',
        contentTag: 'Producer',
        translationKey: 'cnt_del_page_desc_prdcr_def',
      },
    ]

    castAndCrew.forEach((fieldInfo) => {
      if (this._item[fieldInfo.field]) {
        this._item[fieldInfo.field] = this._item[fieldInfo.field].split(',').join('\n')
        this.tag(fieldInfo.titleTag).patch({
          visible: true,
          text: { text: Language.translate(fieldInfo.translationKey) },
        })
        this.tag(fieldInfo.contentTag).patch({
          visible: true,
          text: { text: this._item[fieldInfo.field] },
        })
      } else {
        this.tag(fieldInfo.titleTag).visible = false
        this.tag(fieldInfo.contentTag).visible = false
      }
    })

    if (this._item.genre || this._item.tags?.length > 0) {
      const tagsData = this._item.genre?.split(',') || this._item.tags
      const tagsArray = tagsData.map(tag => {
        return {
          type: TagsButton,
          h: 35,
          borderRadius: 0,
          textPadding: 13,
          withoutTexture: false,
          label: Language.translate(tag),
          flexItem: { marginRight: 10, marginBottom: 10, alignSelf: 'stretch', maxHeight: 100 },
        }
      })

      this.tag('Tags').patch({
        visible: true,
        alpha: 1,
        children: tagsArray
      })
    } else {
      this.tag('Tags').alpha = 0
      this.tag('Tags').visible = false
    }

    //season/episodes header
    if (this._item?.totalSeasons || this._item?.seriesEpisodesCount) {
      const seasonText = this._item.totalSeasons > 1
        ? Language.translate('common_keys.seasons.text')
        : Language.translate('common_keys.season.text')
      const episodesText = this._item.seriesEpisodesCount > 1
        ? Language.translate('common_keys.episodes.text')
        : Language.translate('content_detail.episodes.text')
      const seriesSeasonCount = this._item.totalSeasons > 0
        ? `${this._item.totalSeasons} ${seasonText}`
        : `${this._item.seriesEpisodesCount} ${episodesText}`

      this.tag('SeasonsEpisodesHeader').patch({
        visible: true,
        alpha: 1,
        text: {
          text: seriesSeasonCount
        }
      })
      this.tag('Duration').alpha = 0
      this.tag('ClockImg').alpha = 0
      this.tag('Duration').visible = false
      this.tag('ClockImg').visible = false
    } else {
      this.tag('Duration').alpha = 1
      this.tag('ClockImg').alpha = 1
      this.tag('SeasonsEpisodesHeader').alpha = 0
      this.tag('SeasonsEpisodesHeader').visible = false
    }

    if (Router.getActiveHash().includes('series')) {
      this.tag('Duration').alpha = 0
      this.tag('ClockImg').alpha = 0
      this.tag('Duration').visible = false
      this.tag('ClockImg').visible = false
    } else {
      this.tag('Duration').alpha = 1
      this.tag('ClockImg').alpha = 1
      this.tag('Duration').visible = true
      this.tag('ClockImg').visible = true
    }


    if (this._item.is_livechannel) {
      this.tag('Duration').alpha = 0
      this.tag('ClockImg').alpha = 0
      this.tag('Duration').visible = false
      this.tag('ClockImg').visible = false
      this.tag('SeasonsEpisodesHeader').alpha = 0
      this.tag('SeasonsEpisodesHeader').visible = false
    }

    this.tag('ContentContainer').patch({ y: 30 })
    this.setSmooth('alpha', 1)
  }

  _handleDown() {
    if (this.tag('ContentContainer').finalH > 1000 &&
      (Math.abs(this.tag('ContentContainer').finalY) <=
        (this.tag('ContentContainer').finalH - 1000))) {
      this.tag('ContentContainer').setSmooth('y', this.tag('ContentContainer').finalY - 150)
    }
  }

  isScrollable() {
    return this.tag('ContentContainer').finalH > 1030 ? true : false
  }

  _handleUp() {
    this.tag('ContentContainer').finalH > 1000 && this.tag('ContentContainer').finalY + 180 <= 30
      ? this.tag('ContentContainer').setSmooth('y', this.tag('ContentContainer').finalY + 180)
      : this.tag('ContentContainer').setSmooth('y', 30)
  }

  _handleLeft() {
    Router.focusWidget('SidePanelNavigation')
  }

  _handleBack() {
    Router.focusWidget('SidePanelNavigation')
  }

  _getFocused() { return }

  _handleKey() { return }

  _handleRight() { return }

  renderSmartSigns() {
    const smartSigns = this._item.smartSigns || this._item.smart_signs
    if (smartSigns?.length > 0 && configurations.enableSmartsigns) {
      if (smartSigns[0]['icon_image'] != '') {
        this.tag('SmartSignContainer').alpha = 1
        this.tag('MaturityRatingText').alpha = 0
        this.tag('SignNames').alpha = 0
        this.tag('SmartSignsListContainer').alpha = 1
        this.tag('StarringTitle').patch({
          flexItem: { marginTop: 0 },
        })
        this.tag('Description').patch({
          flexItem: { marginBottom: 40 },
        })
        this.tag('SmartSignsListContainer').children = smartSigns.map((button) => {
          button.h = 50
          button.w = 50
          button.src = button.icon_image || button.iconImage
          return {
            type: SmartSignsListContainer,
            button,
            flexItem: { marginRight: 20 },
          }
        })
      } else {
        this.tag('SmartSignContainer').alpha = 1
        this.tag('SmartSignsListContainer').alpha = 0
        this.tag('MaturityRatingText').alpha = 1
        this.tag('SignNames').alpha = 1
        const stringArray = []
        for (const element of smartSigns) {
          element['translation_key'] == Language.translate(element['translation_key'])
            ? stringArray.push(element['title'])
            : stringArray.push(Language.translate(element['translation_key']))
        }
        this.tag('SignNames').patch({
          text: { text: stringArray.join('\n') },
        })
        this.tag('StarringTitle').patch({
          flexItem: { marginTop: 25 },
        })
        this.tag('Description').patch({
          flexItem: { marginBottom: 10 },
        })
      }
      this.tag('SmartSignContainer').visible = true
    } else {
      this.tag('SmartSignContainer').alpha = 0
      this.tag('SmartSignContainer').visible = false
    }
  }

}


class SmartSignsListContainer extends Lightning.Component {
  static _template() {
    return {
      Border: {},
      Image: {
        mount: 0.5,
      },
    }
  }
  set button(v) {
    this.buttonData = v
    this.patch({
      w: this.buttonData.w ? this.buttonData.w : 100,
      h: this.buttonData.h ? this.buttonData.h : 100,
      Image: {
        w: this.buttonData.w,
        h: this.buttonData.h,
        src: Utils.proxyUrl(configurations.imageBasePath + this.buttonData.src),
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 25 },
      },
    })
  }
}
