/**
 * Class representing the response for settings API data.
 */
import configurations from "../Helper/Configurations";
export class PlatformSettingResponse {
    /**
     * Creates an instance of settings API response.
     * @param {Object} data - The input object representing the settings API response.
     */
    constructor(data) {
        this.statusCode = data.status_code;
        this.statusMessage = data.message;
        this.platformsettingsData = data.data ? new GetPlatformSettingsConfigurations(data.data) : {};
    }
}

/**
 * Class representing details for a home list item.
 */
class GetPlatformSettingsConfigurations {
    /**
     * Creates an instance of getPlatformSettingsConfigurations.
     * @param {Object} data - The input object representing a home list item.
     */
    constructor(data) {
        configurations.bannerType = data.settings.app_theme
        configurations.enableAdIntegration = data.settings.avod_deployed_devices?.includes('metrotv')
        // deal With login mode:
        configurations.loginMode = parseInt(data.login_mode) || 0
        configurations.supportedLogins = data.supported_logins || 'qr'
        configurations.imageBasePath = data.settings.image_base_path

        configurations.playerLanguageOptions = data.settings.player_language_options
        configurations.locales = data.configuration.locales || ''
        configurations.s3EpgPath = data.settings.s3_epg_path || ''
        configurations.epgVideoPlaceholder = data.settings.epg_video_placeholder
        configurations.staticPages_data = data.pages
        configurations.angularSiteURL = data.settings.angular_site_url
        configurations.regions = data.regions || []
        configurations.epgChannelBgImage = data.settings.epg_channel_bg_image
        configurations.authenticateWithEntitlement = data.settings.metro_auto_login_entitlement ? parseInt(data.settings.metro_auto_login_entitlement) : 0

        // audio & subtitles

        configurations.enableMetroSubtitles = data.settings.metro_subtitles_selection ? parseInt(data.settings.metro_subtitles_selection) : 1
        configurations.enableRegistration = data.settings.app_user_registration ? parseInt(data.settings.app_user_registration) : 1
        configurations.epgEnabled = data.settings.linear_channel_epg ? parseInt(data.settings.linear_channel_epg) : 0
        configurations.smartChannelsEnabled = data.settings.linear_channel_smart ? parseInt(data.settings.linear_channel_smart) : 0
        configurations.paymentsEnabled = data.settings.payments_enabled ? parseInt(data.settings.payments_enabled) : 0
        configurations.enableDeviceMangement = data.settings.device_management ? parseInt(data.settings.device_management) : 0
        configurations.epgTimeFormat = data.settings.display_epg_time_format ? parseInt(data.settings.display_epg_time_format) : 12
        configurations.enableMetroMultiAudios = data.settings.metro_multi_audio ? parseInt(data.settings.metro_multi_audio) : 1

        // additional config settings
        configurations.enableTrailers = data.settings.trailer ? parseInt(data.settings.trailer) : 1
        configurations.enableCast = data.settings.enable_cast ? parseInt(data.settings.enable_cast) : 1
        configurations.enableLiveChannel = data.settings.linear_channel ? parseInt(data.settings.linear_channel) : 1
        configurations.enableTv2zAnalytics = data.settings.analytics_media ? parseInt(data.settings.analytics_media) : 0
        configurations.takeValue = data.settings.take_value ? parseInt(data.settings.take_value) : 12
        configurations.enableSmartsigns = data.settings.smart_sign ? parseInt(data.settings.smart_sign) : 1
        configurations.enableGenrecurations = data.settings.genre_curation ? parseInt(data.settings.genre_curation) : 1

        configurations.enabledYoubora = data.settings.analytics_youbora ? parseInt(data.settings.analytics_youbora) : 0
        configurations.gtmEnabled = data.settings.analytics_gtm ? parseInt(data.settings.analytics_gtm) : 0
        configurations.youboraCode = data.settings.youboraCode || ''
        configurations.youboraSiteName = data.settings.youbora_site_name || ''
        configurations.webSocketUrl = data.settings.web_socket_url || ''
        configurations.activeRouteInterval = parseInt(data.settings.active_route_interval) || ''
        configurations.concurrentStreamingEnabled = parseInt(data.settings.concurrent_streaming)
        configurations.enableProfiles = parseInt(data.settings.app_user_profiles) >= 1 ? parseInt(data.settings.app_user_profiles) : 0
        configurations.defaultProfileImages = data?.profile_images || 0
        configurations.otpIntervalTime = parseInt(data.settings.otp_interval_time) || 90
        configurations.otpRetryCount = parseInt(data.settings.otp_retry_count) || 2
        configurations.appUserProfiles = parseInt(data.settings.app_user_profiles) || 3

        //   if ('entitlement_channel_no' in data.settings && configurations.authenticate_with_entitlement)
        //     configurations.film1ChannelNumber = data.settings.entitlement_channel_no

        configurations.analyticsUrl = `${data.settings.analytics_site_url}/${configurations.inHouseAnalyticsEndpoint}` || ''
        configurations.idsite = data.settings.analytics_site_id
    }
}

