import { Lightning, Router, Colors, Language } from '@lightningjs/sdk'
import App from '../../App'

export default class Input extends Lightning.Component {
  static _template() {
    return {
      texture: Lightning.Tools.getRoundRect(740, 63, 4, 0, Colors('secondaryFontColor').get(), true, Colors('primaryFontColor1').get()),
      Label: {
        alpha: 0,
        x: 20,
        y: App.btnHeight / 2 + 1,
        text: {
          fontFace: 'Regular',
          fontSize: 24,
          lineHeight: 39,
          textAlign: 'center',
        },
      },
    }
  }

  set input(v) {
    this.inputFillColor = v.inputFillColor
    this.w = v.w
    const inputFillColor = this.inputFillColor || Colors('primaryFontColor1').get()
    const textColor = v.placeholder?.includes(Language.translate('profile.name_placeholder.text')) ? Colors('secondaryFontColor').get() : Colors('primaryFontColor1').get()
    const inputWidth = v.w || 740
    this.patch({
      x: v.x,
      y: v.y,
      texture: Lightning.Tools.getRoundRect(inputWidth, 63, 4, 0, Colors('secondaryFontColor').get(), true, inputFillColor),
      Label: {
        alpha: v.placeholder == '' ? 0 : v.alpha,
        x: v.textX,
        text: {
          text: v.placeholder || '',
          textColor: this.inputFillColor ? textColor : Colors('tertiaryFontColor').get()
        }
      },
    })
  }

  _focus() {
    const inputFillColor = this.inputFillColor || Colors('primaryFontColor1').get()
    const inputWidth = this.w || 740
    this.patch({
      texture: Lightning.Tools.getRoundRect(inputWidth, 63, 4, 3, Colors('brandPrimaryColor').get(), true, inputFillColor),
    })
  }

  _unfocus() {
    const checkHighlightStatus = Router.getActivePage().returnSelectedField()
    if (checkHighlightStatus) return
    const inputFillColor = this.inputFillColor || Colors('primaryFontColor1').get()
    const inputWidth = this.w || 740
    this.patch({
      texture: Lightning.Tools.getRoundRect(inputWidth, 63, 4, 0, Colors('secondaryFontColor').get(), true, inputFillColor),
    })
  }
}
