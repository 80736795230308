import { Lightning, Language, Router, Utils, Storage, Colors, Registry } from '@lightningjs/sdk';
import { isEmptyObject, navigateToLivePlayer, returnBannerImage } from '../../Helper/Helpers';
import { InlineContent } from '@lightningjs/ui-components';
import { GENRE_CURATION, LIVECHANNEL_WIDGET, TOP_TEN_WIDGET, VIEW_ALL, fontsizes } from '../../Helper/GlobalConstants';
import configurations from '../../Helper/Configurations';
import { imageSizes } from '../../Helper/GlobalConstants';
import { returnImageUrl } from '../../Helper/Helpers';

export default class Item extends Lightning.Component {
  static _template() {
    return {
      Poster: { zIndex: 1, rect: false, shader: { type: Lightning.shaders.RoundedRectangle, radius: 12 }, },
      CardBackground: { zIndex: 0, rect: true, color: Colors('cardColor').get(), shader: { type: Lightning.shaders.RoundedRectangle, radius: 12 } },
      Background: { y: -23.5, x: -8.5, alpha: 0, },
      Title: { text: { fontFace: 'Bold', lineHeight: 42, fontSize: fontsizes.ItemGenreCurationTitle, textColor: Colors('primaryFontColor1').get(), maxLines: 2, wordWrapWidth: 180, textAlign: 'center', }, },
      ViewAllPlusImg: {},
      Shadow: { x: -35, y: -35, zIndex: 0, alpha: 0, color: Colors('shadowColor').get() },
      ProgressBar: {
        flex: { direction: 'column', justifyContent: 'flex-end' }, zIndex: 6, alpha: 0,
        Bar: { h: 6, rect: true, color: Colors('brandPrimaryColor').get(), }
      },
      GenreCurationBg: { alpha: 0, zIndex: 2, h: 152, src: Utils.asset('static_assets/genreCurationBgGradient.png'), },
      GradientOnCard: { alpha: 0, zIndex: 4, rect: true, color: Colors('cardOverlayColor').get(), shader: { type: Lightning.shaders.RoundedRectangle, radius: 12 } },
      LiveChannelDial: { alpha: 0, zIndex: 5, x: 85.1, y: 27, src: Utils.asset('static_assets/dial-filmaction_1.png') },
      LiveChannelDial2: { alpha: 0, zIndex: 5, x: 85.1, y: 27, src: Utils.asset('static_assets/dial-filmaction_2.png') },
      LiveChannelIcon: { alpha: 0, zIndex: 6, w: 101, h: 73 },
      Top10Metadata: {
        zIndex: 6,
        Top10Image: { alpha: 0, y: 60, src: Utils.asset('static_assets/top_10.png'), },
        Rank: { alpha: 0, y: 125, text: { color: Colors('primaryFontColor1').get(), fontSize: 130, fontFace: 'Bold', }, },
        TagsData: {
          alpha: 0, x: 220, y: 220, type: InlineContent, justify: 'flex-start', content: [],
          customStyleMappings: {
            textStyle: { fontSize: fontsizes.ItemBannerInfo, textColor: Colors('primaryFontColor1').get(), wordWrapWidth: 150, fontFace: 'Regular', },
          },
        },
      },
      TVOD: {
        zIndex: 8, h: 32, rect: true, color: 0x90141414, alpha: 0,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: [0, 0, 12, 12] },
        flex: { direction: 'row', justifyContent: 'center' },
        TVODIcon: { zIndex: 8, flexItem: { marginRight: 8 } },
        TVODText: {
          zIndex: 8,
          text: { fontSize: 22, fontFace: 'Bold', textColor: Colors('tvodLabelColor').get(), maxLines: 1, maxLinesSuffix: '...' }
        }
      }
    }
  }

  _firstActive() {
    const {
      width, height, rent, buy, isTvod, title, contentId, availability, viewMoreNumber,
      percentage, component, posterLandscape, collectionLogo, orientation, posterPortrait
    } = this.item

    const sourceImage = (component === VIEW_ALL && (posterLandscape || collectionLogo)) ||
      (component === GENRE_CURATION && collectionLogo) ||
      (orientation === 2 ? posterLandscape : posterPortrait)

    const text = `${Language.translate('common_keys.view_more.part1.text')} ${viewMoreNumber} ${Language.translate('common_keys.view_more.part2.text')}`
    this.patch({
      Background: {
        texture: Lightning.Tools.getRoundRect(width + 12, height + 12, 16, 3, Colors('brandPrimaryColor').get(), true, Colors('focusedBtnColor').get()),
      },
      CardBackground: { w: width, h: height },
      Title: {
        zIndex: 3,
        text: {
          text: this.item?.contentId === VIEW_ALL ? text : component == GENRE_CURATION ? title : '',
          maxLines: orientation == 1 ? 2 : 1,
          wordWrapWidth: orientation == 1 ? 150 : 250,
          textAlign: 'center',
          fontSize: 24
        }
      },
      ProgressBar: {
        h: height - 15,
        alpha: percentage > 0 ? 1 : 0,
        Bar: { w: percentage > 0 ? (width - 14) * (percentage / 100) : 0 }
      },
      ViewAllPlusImg: { zIndex: 3, src: this.item?.contentId === VIEW_ALL ? Utils.asset('static_assets/viewAll_Plus.png') : '' },
      Shadow: {
        alpha: 1,
        texture: Lightning.Tools.getShadowRect(width + 10, height + 10, 12, 16)
      },
      Poster: {
        w: width, h: height,
        src: (contentId !== VIEW_ALL) ? returnImageUrl(sourceImage, imageSizes.card) : null
      },
    });
    if (this.item.contentId === VIEW_ALL) this.patch({ Poster: { rect: true, color: Colors('cardColor').get() } })
    if (this.item.component === GENRE_CURATION) this.patch({ GenreCurationBg: { alpha: 1 } })
    if (this.item.component === TOP_TEN_WIDGET) this.topTenWidget()
    if (this.item.component === LIVECHANNEL_WIDGET) this.liveChannelWidget()
    this.calculateTextWidth(width, height)

    if (!isEmptyObject(rent))
      return this.handleTVODUI('static_assets/expiry_icon_colored.png', rent.expires_in, 28)

    if (!isEmptyObject(buy)) return

    if (isTvod || availability === 2) {
      this.handleTVODUI('static_assets/rent_or_buy_small.png', Language.translate('plans.available_to_purchase.tag'), 24)
    }
  }

  handleTVODUI(imageURL, text, dimensions) {
    this.tag('TVOD').patch({
      w: this.item.width,
      y: this.item.height - 32.5,
      alpha: 1,
      TVODIcon: { w: dimensions, h: dimensions, src: Utils.asset(imageURL) },
      TVODText: {
        text: {
          text: text,
          wordWrapWidth: this.item.width
        }
      }
    })
  }


  topTenWidget() {
    if (this.item.contentId === VIEW_ALL) return
    let tags
    if (this.item.tags) {
      tags = this.item.tags.includes('&')
        ? this.item.tags.replace('&', ' ').split(' ')[0]
        : this.item.tags.replace(',', ' ').split(' ')[0]
    }
    this.patch({
      GradientOnCard: { w: this.item.width, h: this.item.height, alpha: 1 },
      Top10Metadata: {
        Rank: { alpha: 1, text: { text: this.item.rank } },
        Top10Image: { alpha: 1 },
        TagsData: {
          alpha: 1,
          content: [
            { text: this.item.publishTime, style: 'textStyle' },
            { text: this.item.age ? `|  ${this.item.age + '+'}` : '', style: 'textStyle' },
            { text: this.item.tags ? `|  ${tags.slice(0, 10)}` : '', style: 'textStyle' },
          ]
        }
      }
    })
  }

  liveChannelWidget() {
    if (this.item.contentId === VIEW_ALL) return
    if (this.item.imageBorderColor) {
      const xPos = (this.item.width - 191) / 2 // 191 & 175 are LiveChannelDial image w & h
      const yPos = (this.item.height - 175) / 2
      this.patch({
        LiveChannelDial: { alpha: 1, x: xPos, y: yPos - 5 },
        LiveChannelDial2: { alpha: 1, x: xPos, y: yPos - 5 },
      })
      const colorCode = this.item.imageBorderColor
      const finalColor = '0xff' + colorCode.replace('#', '')
      this.item.imageBorderColor.startsWith('#')
        ? this.patch({ LiveChannelDial: { color: finalColor } })
        : this.patch({ LiveChannelDial: { src: Utils.asset('static_assets/dial-filmaction.png') } })
    }
    if (this.item.icon) {
      this.tag('GradientOnCard').patch({ w: this.item.width, h: this.item.height, alpha: 1 })
      this.tag('LiveChannelIcon').patch({
        x: (this.item.width - 101) / 2,  // 101 & 73 are LiveChannelIcon tag w & h
        y: ((this.item.height - 73) / 2),
        alpha: 1,
        src: returnImageUrl(this.item.contentLogo, imageSizes.icon),
      })
    }
  }

  calculateTextWidth(cardWidth, cardHeight) {
    this.tag('Title').on('txLoaded', () => {
      const titlewidth = this.tag('Title').renderWidth
      const titleheight = this.tag('Title').renderHeight
      const titleYPos = (cardHeight - titleheight) / 2
      const titleXPos = (cardWidth - titlewidth) / 2
      const imageYPos = (cardHeight - 56) / 2  // 56 -> View more image w & h
      const imageXPos = (cardWidth - 56) / 2
      const addendPos = this.item.orientation === 1 ? 70 : 50
      this.tag('Title').patch({ x: titleXPos, y: this.item?.contentId === VIEW_ALL ? titleYPos + addendPos : titleYPos + 10 })
      this.tag('ViewAllPlusImg').patch({ x: imageXPos, y: imageYPos - 30 })
    })

    this.tag('Rank').on('txLoaded', () => {
      const textWidth = this.tag('Rank').renderWidth
      const dynamicXPos = (199 - textWidth) / 2 // 190 -> circle image width in Top 10
      this.tag('Rank').patch({ x: dynamicXPos - 22 })
    })
  }

  _init() {
    const poster = this.tag('Poster')
    const fallbackImage = this.item?.orientation === 1 ? 'static_assets/fallback_poster.png' : 'static_assets/fallback_landscape.png'
    poster.on('txError', () => { poster.src = Utils.asset(fallbackImage) })
  }

  _focus() {
    const banner = returnBannerImage(this.item)
    this.item?.contentId === VIEW_ALL ? '' : Router.getActivePage().updateBackDropImg({ src: returnImageUrl(banner, imageSizes.banner) })
    Router.getActivePage().updateDetailWidget(this.item)
    const tvodTagPos = this.item.height - 46.5
    this.patch({
      Background: { smooth: { alpha: 1 } },
      CardBackground: { smooth: { y: -15 } },
      Shadow: { smooth: { y: -50 } },
      Poster: { smooth: { y: -15 } },
      GenreCurationBg: { smooth: { y: -15 } },
      GradientOnCard: { smooth: { y: -15 } },
      TVOD: { smooth: { y: tvodTagPos } },
      Top10Metadata: {
        Top10Image: { smooth: { y: 45 } },
        Rank: { smooth: { y: 115 } }
      }
    });
  }

  _unfocus() {
    const tvodTagPos = this.item.height - 32.5
    this.patch({
      Background: { smooth: { alpha: 0 } },
      CardBackground: { smooth: { y: 0 } },
      Shadow: { smooth: { y: -35 } },
      Poster: { smooth: { y: 0 } },
      GenreCurationBg: { smooth: { y: 0 } },
      GradientOnCard: { smooth: { y: 0 } },
      TVOD: { smooth: { y: tvodTagPos } },
      Top10Metadata: {
        Top10Image: { smooth: { y: 60 } },
        Rank: { smooth: { y: 125 } }
      }
    });
  }

  _handleEnter() {
    if (Router.getActivePage()?.tag('Loading')?.visible) return
    const { contentId, isSeries, component, key, category, isLiveChannel, slug, collectionSlug, videoId, seasonsSlug, contentSlug } = this.item

    if (isSeries) {
      if (!videoId || ['Continue', 'Wishlist'].includes(collectionSlug)) {
        return Router.navigate(`detail/series/${contentId}`, { overlayStatus: 'hideOverlay', keepAlive: true })
      }
      Storage.set('enteredEpisodeInfo', JSON.stringify({ videoId: videoId, seasonsSlug: seasonsSlug }))
      return Router.navigate(`detail/series/${contentId}`, { overlayStatus: 'showOverlay', keepAlive: true })
    }

    if (contentId === VIEW_ALL) {
      return Router.navigate(`viewallpage/${component}/${key}/${category}`, { keepAlive: true })
    }

    if (component === GENRE_CURATION) {
      return Router.navigate(`viewallpage/${key}/${key}/${category}`, { keepAlive: true })
    }

    if (isLiveChannel) {
      const liveCategory = configurations.categories.categoryData.find(obj => obj.slug && obj.slug === 'live-channel')
      if (liveCategory) {
        const routePrefix = configurations.enableHlsStream ? 'hlsliveplayer' : 'dashliveplayer'
        Storage.set('EpgChannelSelected', JSON.stringify({ index: null, id: contentId, from: 'card' }))
        configurations.epgEnabled ? Router.navigate(`${routePrefix}/${liveCategory.categoryId}/${contentId}`) : navigateToLivePlayer(this.item, true)
      } else {
        Router.navigate(`detail/vod/${slug || contentSlug} live`, { keepAlive: true })
      }
      return
    }

    Router.navigate(`detail/vod/${contentId}`, { keepAlive: true })
  }
}
