import { Colors, Lightning, VideoPlayer, Storage, Router, Utils, Language, Img } from '@lightningjs/sdk'
import { InlineContent } from '@lightningjs/ui-components'
import { planUpgradePopup, showConcurrentStreamingPopUp } from '../Helper/Helpers.js'
import { getCategory, getEpgScheduleList, getLanguageOptions } from '../Services/AssetsService.js'
import { DASHYOUBORADAPTERCODE } from '../Helper/GlobalConstants.js'
import { ChannelsList, ProgramsList, Back, PinControlKeyboard, PinControl } from '../Components/index.js'
import { sendWatchCount } from '../Services/PersonaService.js'
import App from '../App.js'
import Dash from './Dash.js'
import npaw from 'npawlib'
import Loader from '../Components/Loader.js'
import MetaInfo from '../Components/EPG/MetaInfoControls.js'
import TVODOverlay from '../Components/EPG/TVODOverlay.js'
import TVODButton from '../Components/EPG/TVODButton.js'
import configurations from '../Helper/Configurations.js'

export default class DashLivePlayer extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            color: Colors('backgroundColor').get(),
            w: App.width,
            h: App.height,
            ChannelsList: { type: ChannelsList },
            ProgramsList: { type: ProgramsList },
            MetaInfo: { type: MetaInfo },
            Back: { type: Back },
            PinControl: { type: PinControl, visible: false },
            PinControlKeyboard: { type: PinControlKeyboard, visible: false },
            Loading: {
                visible: false,
                type: Loader,
                zIndex: 10,
            },
            ChannelError: {
                alpha: 0,
                ErrorImage: {
                    x: (App.width - 77) / 2,
                    y: (App.height - 77) / 2,
                    w: 77,
                    h: 77,
                    src: Utils.asset('static_assets/FeedError.png'),
                },
                ErrorText: {
                    y: (App.height + 120) / 2,
                    text: {
                        text: Language.translate('live_channel.temp_unavailable.text'),
                        fontSize: 36,
                        textColor: Colors('primaryFontColor1').get(),
                        lineHeight: 59,
                        wrap: true,
                        textAlign: 'center',
                        wordWrapWidth: 600,
                        fontFace: 'Regular',
                        maxLines: 4,
                        maxLinesSuffix: '...'
                    },
                },
            },
            TVODOverlay: { type: TVODOverlay, visible: false },
            TVODUI: {
                visible: false,
                EPGBackground: {
                    w: App.width,
                    h: App.height,
                },
                ContentContainer: {
                    y: 0,
                    w: App.width,
                    h: 550,
                    flex: { direction: 'column', justifyContent: 'flex-end', alignItems: 'center' },
                    ChannelTitle: {
                        flexItem: { marginBottom: 20 },
                        text: {
                            fontSize: 48,
                            fontFace: 'Bold',
                            textColor: Colors('primaryFontColor1').get(),
                            wordWrapWidth: 600,
                            maxLines: 1,
                            maxLinesSuffix: '...',
                        }
                    },
                    ChannelDescription: {
                        flexItem: { marginBottom: 20 },
                        text: {
                            fontSize: 20,
                            fontFace: 'Regular',
                            textColor: Colors('primaryFontColor1').get(),
                            wordWrapWidth: 1000,
                            lineHeight: 36,
                            maxLines: 2,
                            textAlign: 'center',
                            maxLinesSuffix: '...',
                        }
                    },
                    SubscribeDescription: {
                        flexItem: { marginBottom: 30 },
                        text: {
                            fontSize: 20,
                            fontFace: 'Bold',
                            textColor: Colors('primaryFontColor1').get(),
                            wordWrapWidth: 1000,
                            maxLines: 1,
                            maxLinesSuffix: '...',
                        }
                    },
                    Purchase: {
                        flexItem: { marginBottom: 20 },
                        type: InlineContent,
                        content: [],
                        style: { iconWidth: 32, iconHeight: 32 },
                        customStyleMappings: {
                            textStyle: {
                                fontSize: 24,
                                textColor: 0xffffda23,
                                wordWrapWidth: 500,
                                fontFace: 'Bold',
                            }
                        }
                    },
                }
            },
            TVODButton: { type: TVODButton, visible: false },
        }
    }

    _active() {
        this.fireAncestors('$hideBackground')
        this.tag('Back').updatePosition([15, 30])
    }

    get loaderTag() {
        return this.tag('Loading')
    }

    displayLoader() {
        this.loaderTag.visible = true
    }

    hideLoader() {
        this.loaderTag.visible = false
    }

    patchProgramListData(programsInfo, channelInfo) {
        this.tag('ProgramsList').itemPreparation(programsInfo, channelInfo)
    }

    async data(categoryId, contentId) {
        const feedIds = []
        let mainJson = []
        this.contentId = contentId
        this.totalChannelsData = await getCategory(categoryId, configurations.epgChannel)
        if (this.totalChannelsData?.data?.livechannels?.length == 0)
            return this.noContentAvailable()
        const allChannelArr = configurations.epgChannel
            ? this.totalChannelsData.data.livechannels
            : this.totalChannelsData.data[0].list
        for (const element of allChannelArr) {
            //   for v3 :- this.totalChannelsData.data[0].list. currently we are using v2
            feedIds.push(element.feed_id)
        }
        this.displayLoader() // show loader
        this.fetchingEpgData(feedIds)
            .then((data) => {
                mainJson = data
                const epgData = { jsonData: mainJson, channelData: this.totalChannelsData }
                this.patchingDataToTemplate(epgData)
                this.hideLoader() // show loader
            })
    }

    async fetchingEpgData(feedIds) {
        const date = new Date()
        const year = date.getFullYear()
        const mon = `${date.getMonth() + 1}`.padStart(2, 0)
        const day = `${date.getDate()}`.padStart(2, 0)
        const newDate = year + '-' + mon + '-' + day
        return await getEpgScheduleList(feedIds, newDate)
    }

    patchingDataToTemplate(v) {
        const channelIndex = v.channelData?.data?.livechannels?.findIndex(channel => {
            return channel.content_id === this.contentId
        })
        if (v.jsonData[0] && !v.jsonData[0].flag) {
            this.tag('ChannelsList').flexItem = { x: 0, y: 897, w: 1920 }
            this.tag('ProgramsList').flexItem = { x: 0, y: 1080, w: 1920, h: 870 }
            this.tag('ProgramsList').data = { programInfo: v.jsonData, channelInfo: v.channelData.data.livechannels[channelIndex] }
            this.tag('ChannelsList').data = { channelInfo: v.channelData.data.livechannels, programInfo: v.jsonData, contentId: this.contentId }
            this.enableFocusToProgramsList(true)
        } else {
            this.tag('ChannelsList').flexItem = { x: 0, y: 897, w: 1920 }
            this.tag('ProgramsList').flexItem = { x: 0, y: 1080, w: 1920, h: 870 }
            this.tag('ProgramsList').data = { programInfo: v.jsonData, channelInfo: v.channelData.data.livechannels[channelIndex] }
            this.tag('ChannelsList').data = { channelInfo: v.channelData.data.livechannels, programInfo: v.jsonData, contentId: this.contentId }
        }
        this._setState('ChannelsList')
    }

    noContentAvailable() {
        this.hideLoader()
        this.tag('ChannelsList').visible = false
        this.tag('ProgramsList').visible = false
    }

    showOverlayCheck() {
        if (!this.tag('ChannelsList').visible) {
            this.showOverlay()
        }
    }

    static _states() {
        return [
            class ChannelsList extends this {
                _getFocused() { return this.tag('ChannelsList') }

                _handleEnter() { /* Action performed at Child Level */ }
            },
            class ProgramsList extends this {
                _getFocused() {
                    return this.tag('ProgramsList.List')
                }
                _handleDown() {
                    //Need to implement
                }
                _handleUp() {
                    this._setState('ChannelsList')
                }
            },

            class Back extends this {
                _getFocused() {
                    return this.tag('Back')
                }
            },

            class PinControlKeyboard extends this {
              _getFocused() {
                  return this.tag('PinControlKeyboard')
              }
            },

            class PinControl extends this {
              _getFocused() {
                  return this.tag('PinControl')
              }
            },

            class MetaInfo extends this {
                _getFocused() {
                    return this.tag('MetaInfo')
                }
            },
            class TVODButton extends this {
                _getFocused() {
                    return this.tag('TVODButton')
                }
            },
        ]
    }

    _init() {
        if (configurations.enabledYoubora)
            this.youboraPlugin = new npaw.Plugin({ accountCode: configurations.youboraCode, username: configurations.userId })
        VideoPlayer.consumer(this)
        this.tag('ErrorText').on('txLoaded', () => {
            const textWidth = this.tag('ErrorText').renderWidth
            const dynamicXPos = (App.width - textWidth) / 2
            this.tag('ErrorText').patch({ x: dynamicXPos })
        })
    }

    async _disable() {
        if (configurations.enabledYoubora) await this.youboraPlugin.fireStop()
        if (this.dash) this.dash.destroy()
        VideoPlayer.close()
        this.patch({ rect: true })
        this.tag('ChannelsList').revertToOriginal()
    }

    _inactive() {
        if (configurations.enabledYoubora) this.youboraPlugin.fireStop()
        Storage.remove('audioTracksAvailable')
        Storage.remove('NofeedAvailable')
    }

    enableFocusToProgramsList(show) {
        this.tag('ProgramsList').visible = show
        this.isProgramListVisible = show
    }

    parentalPinVerified(response) {
        this._data.hls_playback_url = response.data.livechannel.hls_playback_url
        this._data.dash_playback_url = response.data.livechannel.dash_playback_url
        this._data.playback_url = response.data.livechannel.playback_url
        const item = {
            data: this._data,
            action: 'live',
            videoId: channelData.content_id,
        }
        this.liveStreamPlay(item)
    }

    liveStreamPlay(item) {
        this.getCaptionsData()
        this.contentData = item.data || item
        this.contentData.content_id = item.videoId
        this.playing = true
        this.tag('MetaInfo').setIndexToDefault()
        this.tag('Back').visible = true
        this.tag('ChannelsList').visible = true
        this.tag('MetaInfo').visible = true
        this.status = 'loaded'
        this.play()
        this.focusComponent('channels')
    }

    updateLiveStreamPlayback(data, type, livechannelInfo) {
        this.patch({ rect: true })
        this.livechannelInfo = livechannelInfo
        this.type = type
        this._data = data
        this.patch({
            TVODUI: { visible: false },
            TVODButton: { visible: false }
        })

        switch (livechannelInfo.availability) {
            case 1:
                const pathParameter = configurations.loggedIn ? 'subscribe' : 'login'
                Router.navigate(`svodpopup/${pathParameter}`)
                break
            case 2:
                this.showTVODUI(livechannelInfo)
                break
            case 3:
                if (livechannelInfo.ask_access_pin) {
                    this.tag('PinControl').data = [livechannelInfo, null, 0]
                    this.tag('PinControl').patch({ visible: true, alpha: 1 })
                    this.tag('PinControlKeyboard').patch({ visible: true, alpha: 1 })
                    return this._setState('PinControlKeyboard')
                }
                const item = {
                    data: data,
                    action: 'live',
                    videoId: data.content_id,
                }
                this.liveStreamPlay(item)
                break
            case 4:
                this.tag('PinControl').data = [livechannelInfo, null, 0]
                this.tag('PinControl').patch({ visible: true, alpha: 1 })
                this.tag('PinControlKeyboard').patch({ visible: true, alpha: 1 })
                this._setState('PinControlKeyboard')
                break
            case 5:
                planUpgradePopup()
                break
            case 6:
                showConcurrentStreamingPopUp()
                break
        }
        this.hideLoader()
    }

    preparePinControlKeyboard() {
        this.tag('PinControlKeyboard').prepareKeyboard()
    }

    stopPlaybackOnConcurrentStream() {
      VideoPlayer.close()
      this.dash?.destroy()
      this.fireAncestors('$showBackground')
    }

    disablePinControlKeyboard() {
        this.tag('PinControlKeyboard').disableKeyboard()
    }

    focusPinControl() {
        this._setState('PinControl')
    }

    focusPinControlKeyboard() {
        this._setState('PinControlKeyboard')
    }

    closePinControlPopUp() {
        this.tag('PinControl').closePinControlPopUp()
    }

    populateInput(value, action) {
      this.tag('PinControl').populateInput(value, action)
    }

    showTVODUI(channelData) {
        clearTimeout(this.showTimer)
        this.patch({
            TVODUI: {
                visible: true,
                EPGBackground: {
                    texture: Img(configurations.epgChannelBgImage).original()
                },
                ContentContainer: {
                    ChannelTitle: {
                        text: { text: channelData.title }
                    },
                    ChannelDescription: {
                        text: { text: Language.translate('live_channel.subs_to_watch.text') }
                    },
                    SubscribeDescription: {
                        visible: !(configurations.userType),
                        text: { text: Language.translate('plans.subscribe_desc.text') }
                    },
                    Purchase: {
                        content: [
                            { icon: Utils.asset('static_assets/rent_or_buy.png'), w: 32, h: 32 },
                            { text: Language.translate('plans.available_to_purchase.tag'), style: 'textStyle' }
                        ]
                    }
                }
            },
            TVODButton: { visible: true }
        })
        this.tag('TVODButton').prepareButton(channelData)
        this.tag('Back').visible = true
        this.tag('ChannelsList').visible = true
        this.tag('MetaInfo').visible = false
        this.buttonData = []
        this.tag('MetaInfo').data = { data: this.buttonData, index: 0 }
        return this._setState('TVODButton')
    }

    openTVODOverlay(data) {
        this.tag('TVODOverlay').patch({ visible: true })
        this.tag('TVODOverlay').openTVODOverlay(data)
        this.tag('Back').updatePosition([105, 70])
        return this._setState('Back')
    }

    playButtons() {
        return [
            {
                action: 'play',
                y: 50,
                mountX: 0.5,
                mountY: 0.5,
                src: this.playing ? 'static_assets/plain_pause.png' : 'static_assets/plain_play.png',
            },
            {
                action: 'play-center',
                alpha: this.loaderTag.visible || this.stopControls ? 0 : 1,
                src: this.playing ? 'static_assets/pause-center.png' : 'static_assets/play-center.png',
            },
        ]
    }

    buttonsdata() {
        const buttons = this.playButtons()
        const subtitleMultiAudio = {
            action: 'audioAndSubtitles',
            y: 50,
            mountX: 0.5,
            mountY: 0.5,
            src: 'static_assets/plain_subtitle.png',
        }
        if ((configurations.enableMetroSubtitles || configurations.enableMetroMultiAudios) && this.contentData.content_type != 'filler') {
            if (this.dash.getTracksFor('audio').length > 0 || this.dash.getTracksFor('text').length > 0) {
                buttons.push(subtitleMultiAudio)
            }
        }
        return this.stopControls ? [] : buttons
    }

    isPlayingStatus() {
        return this.tag('ChannelError').alpha
    }

    focusComponent(Component) {
        switch (Component) {
            case 'channels':
                this._setState('ChannelsList')
                break
            case 'programs':
                this._setState('ProgramsList')
                break
            case 'metaInfo':
                this._setState('MetaInfo')
                break
            case 'back':
                this._setState('Back')
                break
            case 'TVODButton':
                this._setState('TVODButton')
                break
            default:
                break
        }
    }

    async getCaptionsData() {
        this.languageJSON = await getLanguageOptions()
    }

    getLanguageJSON() {
        return this.languageJSON
    }

    setSubtitleLanguage(subtitleLabel) {
        Storage.set('subtitle-lang-selected', subtitleLabel || null)
    }

    setAudioLanguage(audioLabel) {
        Storage.set('audio-lang-selected', audioLabel)
    }

    play() {
        this.dash = undefined
        this._videoEl = undefined
        this.dash = Dash.MediaPlayer().create()
        this.videoEl = VideoPlayer._videoEl
        this.dash.initialize(this.videoEl, null, false)
        this.dash.updateSettings({
            streaming: {
                buffer: {
                    enableSeekDecorrelationFix: false,
                    fastSwitchEnabled: false,
                    flushBufferAtTrackSwitch: true,
                    reuseExistingSourceBuffers: true,
                    bufferPruningInterval: 20,
                    bufferToKeep: 20,
                    bufferTimeAtTopQuality: 30,
                    bufferTimeAtTopQualityLongForm: 60,
                    initialBufferLevel: NaN,
                    stableBufferTime: 12,
                    longFormContentDurationThreshold: 600,
                    stallThreshold: 0.3,
                    useAppendWindow: true,
                    setStallState: true,
                },
                text: {
                    defaultEnabled: false,
                },
            },
        })
        this.dash.attachSource(this.contentData.playback_url)
        if (configurations.enabledYoubora) {
            const adapterCode = DASHYOUBORADAPTERCODE()
            const youboraOptions = {
                'content.title': this.livechannelInfo.title_en,
                'content.id': this.livechannelInfo.content_id,
                'content.streamingProtocol': 'DASH',
                'content.isLive': true,
                'content.resource': this.contentData.playback_url,
                'device.id': configurations.deviceId,
                'device.type': 'metro',
                'content.customDimension.2': this.livechannelInfo.tilte,
                'app.name': configurations.youboraSiteName,
            }
            if (configurations.enabledYoubora)
                this.youboraPlugin = new npaw.Plugin({ accountCode: configurations.youboraCode, username: configurations.userId })
            this.youboraPlugin.registerAdapter(this.dash, adapterCode)
            this.youboraPlugin.setOptions(youboraOptions)
            this.youboraPlugin.fireInit()
        }
        this.videoEl.style.display = 'block'
        VideoPlayer.show()
        VideoPlayer.play()
        this.tag('ChannelError').patch({ alpha: 0 })

        this.videoEl.onloadedmetadata = () => {
            this.dash.on(Dash.MediaPlayer.events.ERROR, (_event) => {
                this.stopControls = true
                this.patch({ rect: true })
                this.tag('ChannelError').patch({ alpha: 1 })
                this.tag('MetaInfo').makeUIInvisible(false)
                this.showOverlay()
                this.buttonData = this.buttonsdata()
                this.tag('MetaInfo').data = { data: this.buttonData, index: 0 }
                this.hideLoader()
            })
            this.stopControls = false
            this.watchCountAPICall()
            if (this.contentData?.content_type !== 'filler') this.getAudioAndSubtitles()
            if (this.type === 'live' && !Storage.get('NofeedAvailable')) {
                const startMillis = this.tag('ProgramsList').sendProgressValueToLive()
                this.startMillis = startMillis
                this.seekToLive(startMillis)
            } else if (this.type === 'smart_channel') {
                this.startMillis = this.contentData.startMillis
                this.seekToLive(this.contentData.startMillis)
            }
        }

        this.videoEl.onplaying = () => {
            if (this.livechannelInfo.channel_type != 'live' && this.livechannelInfo.feed_id != '') {
                const bool = this.contentData?.content_type !== 'filler' ? true : false
                this.tag('MetaInfo').makeUIInvisible(bool)
            }
            this.showOverlay()
            this.patch({ rect: false })
            this.stopControls = false
            this.hideLoader()
            this.tag('ChannelError').patch({ alpha: 0 })
        }

        this.videoEl.ontimeupdate = () => {
            if (this.type === 'live' && this.tag('MetaInfo').visible && !Storage.get('NofeedAvailable')) {
                this.tag('ProgramsList').sendProgressValueToLive()
            } else if (this.type === 'smart_channel') {
                this.tag('MetaInfo').updateProgressBar(((VideoPlayer.currentTime / VideoPlayer.duration) * 100) / 100)
            }
        }

        this.videoEl.onended = () => {
            if (configurations.enabledYoubora) this.youboraPlugin.fireStop()
            this.dash.destroy()
            this.navigateBack()
        }
    }

    async watchCountAPICall() {
      await sendWatchCount(this.livechannelInfo.channel_id, 'livechannel')
    }

    _getFocused() {
        if (this.tag('TVODButton').visible) return this.focusComponent('TVODButton')

        if (Storage.get('NofeedAvailable')) return this.tag('ChannelsList')
    }

    getAudioAndSubtitles() {
        let selectedLangIndex
        if (this.dash.getTracksFor('text').length > 0) {
            if (Storage.get('subtitle-lang-selected') == null || Storage.get('subtitle-lang-selected') == '')
                selectedLangIndex = this.dash.getTracksFor('text').length
            else {
                selectedLangIndex = 0
            }
            this.dash.setTextTrack(selectedLangIndex)
            if (configurations.enabledYoubora) {
                const youboraParameter = {
                    'content.subtitles': Storage.get('subtitle-lang-selected') || 'None',
                }
                this.youboraPlugin.setOptions(youboraParameter)
            }
            const textTracksArray = [...this.dash.getTracksFor('text')]
            this.label = textTracksArray[selectedLangIndex] ? textTracksArray[selectedLangIndex].label : ''
        }
        this.buttonData = this.buttonsdata()
        this.tag('MetaInfo').data = {
            data: this.buttonData,
            index: 0,
        }
    }

    destroyLivePlayer() {
        if (configurations.enabledYoubora) this.youboraPlugin.fireStop()
        if (this.dash) {
            this.dash.setTextTrack(-1)
            this.dash.destroy()
            VideoPlayer.close()
            this.dash = undefined
        }
    }

    seekToLive(startMillis) {
        const currentTimeInMs = Date.now()
        const seekTime = currentTimeInMs - startMillis
        let seconds = seekTime / 1000
        VideoPlayer.seek(seconds)
        VideoPlayer.play()
    }

    pauseToLive() {
        this.displayLoader()
        const currentTimeInMs = Date.now()
        const pauseSeconds = VideoPlayer.currentTime
        const pauseMillis = pauseSeconds * 1000
        const totalPauseMillis = pauseMillis + this.startMillis
        const pauseTime = (currentTimeInMs - totalPauseMillis) / 1000
        const seekValue = pauseTime + pauseSeconds
        VideoPlayer.seek(seekValue)
        VideoPlayer.play()
        this.hideLoader()
    }

    _handleBack() {
        if (this.loaderTag.visible) return
        if (this.tag('TVODOverlay').visible) {
            this.tag('Back').updatePosition([15, 30])
            return this.tag('TVODOverlay').closeTVODOverlay()
        }
        if (configurations.enabledYoubora) this.youboraPlugin.fireStop()
        if (this.tag('TVODUI').visible) return this.navigateBack()
        this.displayLoader()
        this.dash?.setTextTrack(-1)
        this.dash?.destroy()
        this.navigateBack()
        this.hideLoader()
    }

    navigateBack() {
        VideoPlayer.close()
        this.patch({ rect: true })
        this.fireAncestors('$showBackground')
        Router.back()
    }

    _handlePlay() {
        this.hideLoader()
        !this.stopControls ? (VideoPlayer.play(), this.pauseToLive()) : null
        this.status = 'play'
        this.playing = true
        this.buttonData = this.buttonsdata()
        this.tag('MetaInfo').data = { data: this.buttonData, index: 0 }
    }

    _handlePause() {
        this.hideLoader()
        !this.stopControls ? VideoPlayer.pause() : null
        this.status = 'pause'
        this.playing = false
        this.buttonData = this.buttonsdata()
        this.tag('MetaInfo').data = { data: this.buttonData, index: 0 }
    }

    _handlePlayPause() {
        this.status = this.status == 'pause' ? 'play' : 'pause'
        this.hideLoader()
        if (this.status == 'pause') {
            !this.stopControls ? VideoPlayer.pause() : null
            this.playing = false
        } else {
            !this.stopControls ? (VideoPlayer.play(), this.pauseToLive()) : null
            this.playing = true
        }
        this.buttonData = this.buttonsdata()
        this.tag('MetaInfo').data = { data: this.buttonData, index: 0 }
    }

    _handleStop() {
        if (configurations.enabledYoubora) this.youboraPlugin.fireStop()
        this.dash.destroy()
        VideoPlayer.close()
        this.patch({ rect: true })
        this.fireAncestors('$showBackground')
        Router.back()
    }

    _handleRewind() {
        //  need to write
    }

    _handleForward() {
        //  need to write
    }

    $playPause(value) {
        if (value === 'play') return this._handlePlayPause()

        if (value === 'audioAndSubtitles') {
            this._handlePause()
            this.getAudioAndSubtitlesButtons()
        }
    }

    async getAudioAndSubtitlesButtons() {
        this.widgets.backgroundsidepanel.data = {
            command: 'audioSubtitles',
            title: '',
        }
        this.widgets.sidepanelnavigation.data = {
            component: 'audioAndSubtitlesInPlayer',
            xValue: 1335,
        }
        if (configurations.enableMetroMultiAudios && this.dash?.getTracksFor('audio').length > 0) {
            this.widgets.audiosidepanel.audioItems = await this.dash
                .getTracksFor('audio').map((audio) => ({ label: audio.lang, langoption: this.languageJSON }))
            this.widgets.audiosidepanel.alpha = 1
            Storage.set('audioTracksAvailable', true)
        } else {
            this.widgets.audiosidepanel.alpha = 0
            Storage.set('audioTracksAvailable', false)
        }
        if (configurations.enableMetroSubtitles && this.dash.getTracksFor('text').length > 0) {
            let subtitleTracks = this.dash.getTracksFor('text')
            let subtitleArray = [...subtitleTracks]
            this.widgets.subtitlessidepanel.subtitleItems = subtitleArray.map((subtitle) => ({ label: subtitle.lang, langoption: this.languageJSON }))
            this.widgets.subtitlessidepanel.placeInTop = (configurations.enableMetroMultiAudios) ? (this.dash.getTracksFor('audio').length > 0 ? false : true) : true
            this.widgets.subtitlessidepanel.alpha = 1
        } else {
            this.widgets.subtitlessidepanel.placeInTop = (configurations.enableMetroMultiAudios) ? (this.dash.getTracksFor('audio').length > 0 ? false : true) : true
            this.widgets.subtitlessidepanel.alpha = 0
        }
        //  isPlayerPage flag to call specific func in _handleEnter in those widgets
        this.widgets.audiosidepanel.isPlayerPage = true
        this.widgets.subtitlessidepanel.isPlayerPage = true
        this.widgets.audiosidepanel.alpha = 1
        this.widgets.subtitlessidepanel.alpha = 1
        Router.focusWidget('SidePanelNavigation')
        //  setting preferred audio and subtitle from persona-api to storage
        //  when we dont have subtitles and audio values from storage
        if (!Storage.get('subtitle-lang-selected')) {
            Storage.set('subtitle-lang-selected', this.contentData.preferred_subtitle)
        }
        if (!Storage.get('audio-lang-selected')) {
            Storage.set('audio-lang-selected', this.contentData.preferred_language)
        }
        Router.focusWidget('SidePanelNavigation')
    }

    changeTrack(label) {
        //  converting from label to index
        let labelIndex
        if (!label.includes('None')) {

            const textTracksArray = [...this.dash.getTracksFor('text')]
            labelIndex = textTracksArray.findIndex((subObj) => subObj.lang === label)
        } else {
            labelIndex = -1
        }
        //  set index as textTrackLength if not found in videoplayer's audioArray
        if (configurations.enabledYoubora) {
            const youboraParameter = { 'content.subtitles': label }
            this.youboraPlugin.setOptions(youboraParameter)
        }

        const index = labelIndex !== -1 ? labelIndex : this.dash.getTracksFor('text').length
        if (index === this.dash.getTracksFor('text').length) {
            //  setting as none
            Storage.set('subtitle-lang-selected', null)
            this.dash.setTextTrack(-1)
            this.label = ''
        } else {
            //  setting the subtitle to storage/
            Storage.set('subtitle-lang-selected', this.dash.getTracksFor('text')[index].lang)
            this.dash.setTextTrack(index)
        }

        VideoPlayer.play()
        this.status = 'play'
        this.playing = true
        this.buttonData = this.buttonsdata()
        this.tag('MetaInfo').data = {
            data: this.buttonData,
            action: 'play',
        }
    }

    changeAudioTrack(label) {
        clearTimeout(this.timer)
        this.displayLoader()
        const audioTracksArray = [...this.dash.getTracksFor('audio')]
        const labelIndex = audioTracksArray.findIndex((subObj) => subObj.lang === label)
        const index = labelIndex !== -1 ? labelIndex : 0

        if (labelIndex < 0) {
            Router.getActivePage().widgets.audiosidepanel.updateAudioTag(index)
        }
        this.dash.setCurrentTrack(this.dash.getTracksFor('audio')[index])
        this.dash.trackSwitchMode = { audio: 'alwaysReplace' }

        Storage.set('audio-lang-selected', label)
        Storage.set('audio-lang-selected-index', index)

        this.hideLoader()
        VideoPlayer.play()
        this.status = 'play'
        this.playing = true
        this.buttonData = this.buttonsdata()
        this.tag('MetaInfo').data = {
            data: this.buttonData,
            action: 'play',
        }
    }

    clearTimer() {
        this.showOverlay()
    }

    showOverlay() {
        clearTimeout(this.showTimer)
        this.tag('MetaInfo').visible = true
        this.tag('ProgramsList').visible = false
        this.tag('Back').visible = true
        this.tag('ChannelsList').visible = true
        this.showTimer = setTimeout(() => {
            this.hideOverlay()
        }, 5000)
    }

    hideOverlay() {
        if (this.tag('ProgramsList').visible) {
            this.tag('ProgramsList').visible = true
            this.tag('Back').visible = false
            this.tag('ChannelsList').visible = true
        } else {
            this.tag('MetaInfo').visible = false
            this.tag('ProgramsList').visible = false
            this.tag('Back').visible = false
            this.tag('ChannelsList').visible = false
        }
    }

    clearSidePanel() {
        if (!this.tag('TVODButton').visible)
            this._setState('MetaInfo')
        this.status = 'pause'
        this.widgets.backgroundsidepanel.data = { command: 'clear' }
        this.widgets.sidepanelnavigation.data = { component: 'clear' }
        this.widgets.audiosidepanel.alpha = 0
        this.widgets.subtitlessidepanel.alpha = 0
        this.showOverlay()
    }
}
