import { Lightning, Router, Storage, Utils, Colors } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import Channel from './ChannelItem.js'
import configurations from '../../Helper/Configurations.js'
import App from '../../App'
import { getLiveChannel } from '../../Services/PersonaService.js'
import { deleteData } from '../../Helper/Helpers.js'
import { LiveChannelResponse } from '../../Models/LiveChannelModel.js'

export default class ChannelsList extends Lightning.Component {
    static _template() {
        return {
            zIndex: 2,
            visible: true,
            alpha: 1,
            ...super._template(),
            Content: {
                List: {
                    h: 135,
                    clipping: true,
                    spacing: 8,
                    type: List,
                    direction: 'row',
                    signals: { onRequestItems: true, onIndexChanged: true }
                },
            },
            BottomGradient: {
                rect: true,
                y: 885,
                w: App.width,
                h: 200,
                color: Colors('backgroundColor').get(),
            },
            DownArrow: {
                alpha: 0,
                w: 48,
                h: 48,
                y: 1020,
                x: 936,
                src: Utils.asset('static_assets/Angle_Up.png'),
            },
            UpArrow: {
                zIndex: 3,
                alpha: 0,
                w: 48,
                h: 48,
                y: 30,
                x: 936,
                src: Utils.asset('static_assets/UpArrow.png'),
            },
        }
    }

    set data(v) {
        this.totalChannelData = v.channelInfo
        this.programInfo = v.programInfo
        this.patchListData(v)
    }

    set flexItem(v) {
        this.tag('List').patch({
            x: v.x,
            y: v.y,
            w: v.w
        })
    }

    patchListData(v) {
        const epgStateInfo = JSON.parse(Storage.get('EpgChannelSelected'))
        this.contentId = epgStateInfo?.id || v.contentId
        if (epgStateInfo?.from === 'menu') {
            this.selectedChannelIndex = 0
        } else {
            this.selectedChannelIndex = this.totalChannelData.findIndex((obj) => obj.content_id == this.contentId)
        }
        //for first channel storage without handleEnter got triggered
        Storage.set('EpgChannelSelected', JSON.stringify({ index: this.selectedChannelIndex, id: this.contentId, from: epgStateInfo.from }))
        const initalSliceCount = this.selectedChannelIndex + 11
        const initialChannels = this.totalChannelData.slice(0, initalSliceCount)
        const items = initialChannels.map((val, i) => {
            this.selectedChannel = i === this.selectedChannelIndex ? true : false
            return {
                w: 160,
                h: 120,
                type: Channel,
                item: [val, this.selectedChannel, this.selectedChannelIndex],
                index: i,
            }
        })
        this.tag('List').clear()
        this.tag('List').add(items)
        this.tag('List').setIndex(this.selectedChannelIndex)
        this.tag('List').reposition()
        this.livePersona()
    }


    _getFocused() {
        return this.tag('List')
    }

    _handleKey() {
        if (!Router.getActivePage().tag('TVODUI').visible)
            Router.getActivePage().showOverlay()
    }

    _handlePlayPause() {
        Router.getActivePage().$playPause('play')
    }

    _handlePlay() {
        Router.getActivePage()._handlePlay()
    }

    _handlePause() {
        Router.getActivePage()._handlePause()
    }

    _handleEnter() {
        if (!Router.getActiveHash().includes('tvguide') && Router.getActivePage().tag('ChannelsList').visible) {
            this.selectChannel()
            this.action = 'Up'
            this.zappingEffect()
            Router.getActivePage().destroyLivePlayer()
            // this.showSelectedChannelEpg(index)  //player is getting initialized multiple times with this it will fix
            Router.getActivePage().hideOverlay()
        } else {
            !Router.getActiveHash().includes('tvguide') ? Router.getActivePage().showOverlay() : this.selectChannel()
        }
    }

    selectChannel() {
        Router.getActivePage().tag('MetaInfo').makeUIInvisible(false)
        Router.getActivePage().displayLoader()
        this.selectedChannelIndex = this.tag('List')._index
        this.contentId = this.totalChannelData[this.selectedChannelIndex].content_id
        Storage.set('EpgChannelSelected', JSON.stringify({ index: this.selectedChannelIndex, id: this.contentId, from: '' }))
        deleteData()
        this.livePersona()
    }

    async livePersona() {
        const channelData = await getLiveChannel(this.contentId)
            .then(async response => {
                return new LiveChannelResponse(response)
            })
        if (!Router.getActiveHash().includes('tvguide') && channelData.liveChannel.channel_type !== 'smart_channel') {
            Router.getActivePage().updateLiveStreamPlayback(
                channelData.liveChannel,
                channelData.liveChannel.channel_type,
                channelData.liveChannel
            )
        }
        this.showSelectedChannelEpg(this.selectedChannelIndex, channelData.liveChannel)
        !Router.getActiveHash().includes('tvguide') && Router.getActivePage().tag('MetaInfo').receiveChannelData(channelData.liveChannel)
    }

    async showSelectedChannelEpg(index, channelData) {
        const mainJson = []
        const date = new Date()
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        const newDate = `${year}-${month}-${day}`
        try {
            await fetch(configurations.s3EpgPath + this.totalChannelData[index].feed_id + '_' + newDate + '.json')
                .then((res) => res.json())
                .then((data) => mainJson.push(data))
            Router.getActivePage().enableFocusToProgramsList(true)
        } catch (error) {
            const data = {
                flag: 'NoFeed',
                feed_id: this.totalChannelData[index].feed_id
            }
            mainJson.push(data)
            Router.getActivePage().enableFocusToProgramsList(false)
        }
        this.programInfo = mainJson
        Router.getActivePage().patchProgramListData(...mainJson, channelData) // Passing channel Info and channel programs list
        const items = this.totalChannelData.map((val, i) => {
            this.selectedChannel = i === index ? true : false
            return {
                w: 160,
                h: 120,
                type: Channel,
                item: [val, this.selectedChannel, index],
                index: index,
            }
        })
        this.tag('List').clear()
        this.tag('List').add(items)
        this.tag('List').setIndex(index)
        this.tag('List').reposition()
    }

    _handleUp() {
        if (!Router.getActiveHash().includes('tvguide')) {
            const channelsListAlpha = Router.getActivePage().tag('ChannelsList').visible
            const downArrowAlpha = this.tag('DownArrow').alpha
            const tvodUIVisible = Router.getActivePage().tag('TVODUI').visible

            if (channelsListAlpha && downArrowAlpha) {
                if (tvodUIVisible) return Router.getActivePage().focusComponent('TVODButton')
                Router.getActivePage().showOverlay()
                return Router.getActivePage().focusComponent('metaInfo')
            }

            this.action = 'Up'
            this.zappingEffect()
            if (!tvodUIVisible) Router.getActivePage().showOverlay()
        }
    }


    revertToOriginal() {
        this.action = 'Up'
        this.zappingEffect()
    }

    _handleDown() {
        const programInfoFlag = this.programInfo[0].flag
        if (!Router.getActiveHash().includes('tvguide')) {
            const channelsListAlpha = Router.getActivePage().tag('ChannelsList').visible
            const tvodUIVisible = Router.getActivePage().tag('TVODUI').visible

            if (channelsListAlpha) {
                this.action = 'Down'
                this.zappingEffect()
                if (!programInfoFlag) {
                    Router.getActivePage().focusComponent('programs')
                }
            } else if (!tvodUIVisible) {
                Router.getActivePage().showOverlay()
            }
        } else !programInfoFlag && Router.getActivePage().focusComponent('programs')
    }

    _handleRight() {
        if (!Router.getActiveHash().includes('tvguide')) {
            if (Router.getActivePage().tag('ChannelsList').visible || Router.getActivePage().tag('TVODUI').visible) return
            Router.getActivePage().showOverlay()
        }
    }

    _handleLeft() {
        !Router.getActiveHash().includes('tvguide') ? this._handleRight() : Router.focusWidget('Menu')
    }

    _handleBack() {
        !Router.getActiveHash().includes('tvguide') ? Router.getActivePage().tag('ProgramsList')._handleBack() : Router.getActivePage().tag('Background.ProgramsList')._handleBack()
    }

    zappingEffect() {
        if (this.action === 'Down') {
            Router.getActivePage().tag('ProgramsList').updateZappingPosition(this.action)
            Router.getActivePage().tag('ProgramsList').visible = true
            Router.getActivePage().tag('MetaInfo').visible = false
            this.patch({
                alpha: 1,
                Content: { List: { y: 86, alpha: 1 } },
                BottomGradient: { y: 1 },
                UpArrow: { alpha: 1 },
                DownArrow: { alpha: 0 },
            })
        } else {
            Router.getActivePage().tag('ProgramsList').updateZappingPosition(this.action)
            Router.getActivePage().tag('ProgramsList').visible = true
            if (!Router.getActivePage().tag('TVODUI').visible) Router.getActivePage().tag('MetaInfo').visible = true
            this.patch({
                alpha: 1,
                Content: { List: { y: 897, alpha: 1 } },
                BottomGradient: { y: 885 },
                DownArrow: { alpha: 1 },
                UpArrow: { alpha: 0 },
            })
        }
    }
}
