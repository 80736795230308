import configurations from "../Helper/Configurations"
import middleware from "./Middleware"
import { Storage } from '@lightningjs/sdk'
import { DEVICE_TYPE } from "../Helper/GlobalConstants"

/**
 * Fetches persona details for a specific content.
 * @param {string} id - The ID of the content.
 * @param {string} type - The type of the content.
 * @returns {Promise} A promise that resolves with the persona details response or rejects with an error.
 */
export const getPersonaDetails = async (id, type) => {
  const url = `${configurations.baseUrl}v2/persona/content/${type}/${id}`;
  const additionalParams = {};
  if (type === 'video') {
    additionalParams['supports_drm'] = 0;
    additionalParams['streaming_type'] = configurations.enableHlsStream ? 'hls' : 'dash';
  }
  additionalParams['version'] = 'v1'
  if (configurations.enableProfiles)
    additionalParams.profile_id = Storage.get('profileId') || configurations.selectedProfileId
  try {
    return await middleware('GET', url, additionalParams, true, configurations.headers);
  } catch (error) {
    console.error(error);
    return error;
  }
};

/**
 * @param {string} slug - Optional parameter to specify the category for fetching 'Continue Watching' videos.
 * @returns {Promise} - A Promise that resolves with the fetched data if successful, or an error object if an error occurs.
 */
 export const getContinueWatchingVideos = async (slug) => {
  const url = `${configurations.baseUrl}v2/persona/content/persona`
  const params = slug ? { category: slug } : {}
  if (configurations.enableProfiles) {
    params.profile_id = Storage.get('profileId') || configurations.selectedProfileId
  }
  params.version = 'v1'

  try {
    return await middleware('GET', url, params, true, configurations.headers)
  } catch (error) {
    console.error(error)
    return error
  }
};


/**
 * Fetches live channel details.
 * @param {string} id - The ID of the live channel.
 * @returns {Promise} A promise that resolves with the live channel response or rejects with an error.
 */
 export const getLiveChannel = async (id) => {
  const url = `${configurations.baseUrl}v2/persona/livechannels/${id}`;
  const additionalParams = {}
  additionalParams['supports_drm'] = 0
  additionalParams['streaming_type'] = configurations.enableHlsStream ? 'hls' : 'dash'
  additionalParams['version'] = 'v1'
  try {
    return await middleware('GET', url, additionalParams, true, configurations.headers);
  } catch (error) {
    return error;
  }
};

/**
 * Adds content to the wishlist.
 * @param {string} type - The type of content.
 * @param {string} id - The ID of the content.
 * @returns {Promise} A promise that resolves with the wishlist addition response or rejects with an error.
 */
export const addToWishlist = async (type, id) => {
  const url = `${configurations.baseUrl}v2/persona/wishlist`;
  const params = {
    content_id: id,
    content_type: type,
  };
  configurations.enableProfiles ? params.profile_id = configurations.selectedProfileId : params.u_id = configurations.userId

  try {
    return await middleware('POST', url, params, true, configurations.headers);
  } catch (error) {
    return error;
  }
};

/**
 * Removes content from the wishlist.
 * @param {string} type - The type of content.
 * @param {string} id - The ID of the content.
 * @returns {Promise} A promise that resolves with the wishlist removal response or rejects with an error.
 */
export const removeFromWishlist = async (type, id) => {
  const url = `${configurations.baseUrl}v2/persona/wishlist/${type}/${id}`
  const params = configurations.enableProfiles
    ? { profile_id: configurations.profileId || Storage.get('profileId') }
    : {}

  try {
    return await middleware('DELETE', url, params, true, configurations.headers);
  } catch (error) {
    return error;
  }
};

/**
 * Removes an item from the 'Continue Watching' section by sending a POST request to the specified URL.
 * @param {string} continueId - The ID of the item to be removed from continue watching.
 * @returns {Promise} - A Promise that resolves with the response data if successful, or an error object if an error occurs.
 */
 export const removeFromContinueWatching = async (continueId) => {
  const url = `${configurations.baseUrl}v2/persona/continue/watching/remove`

  const params = { continue_id: continueId }
  if (configurations.enableProfiles) {
    params.profile_id = Storage.get('profileId') || configurations.selectedProfileId
  }

  try {
    return await middleware('POST', url, params, true, configurations.headers)
  } catch (error) {
    return error
  }
};


export const getActivationCode = async (input) => {
  const url = `${configurations.baseUrl}v2/persona/device/atvcode`
  input.source = DEVICE_TYPE
  input.medium = 'tv-app-sales-funnel'
  input.campaign = '1.0.0'
  input.brand = DEVICE_TYPE
  try {
    const changeAuthenticateCodeRes = await middleware('POST', url, input, true, configurations.headers)
    if (changeAuthenticateCodeRes?.status_code === 200) {
      if (changeAuthenticateCodeRes.data.user_id) {
        configurations.userAccessToken = changeAuthenticateCodeRes.data.access_token
        configurations.userRefreshToken = changeAuthenticateCodeRes.data.refresh_token
        Storage.set('userAccessToken', configurations.userAccessToken)
        Storage.set('userRefreshToken', configurations.userRefreshToken)
        configurations.userId = changeAuthenticateCodeRes.data.user_id
        Storage.set('userId', configurations.userId)
        configurations.loggedIn = changeAuthenticateCodeRes.data.logged_in
        configurations.userType = changeAuthenticateCodeRes.data.user_type
        Storage.set('loggedIn', changeAuthenticateCodeRes.data.logged_in)
      }
    }
    return changeAuthenticateCodeRes
  } catch (error) {
    return error
  }
}

export const addToContinueWatch = async (id, time) => {
  const url = `${configurations.baseUrl}v2/persona/continue/watching`
  const params = {
    content_id: id,
    w_time: time,
  }
  if (configurations.enableProfiles) {
    params.profile_id = Storage.get('profileId') || configurations.selectedProfileId
  }
  try {
    return await middleware('POST', url, params, true, configurations.headers)
  } catch (error) {
    console.log(error)
    return error
  }
}

export const logout = async (deviceId = 0) => {

  const deviceToLogout = deviceId == 0 ? configurations.deviceId : deviceId
  const url = `${configurations.baseUrl}v2/persona/logout/devices/${deviceToLogout}`

  try {
    const logoutres = await middleware('GET', url, {}, true, configurations.headers)
    if (logoutres?.status_code === 200 && !deviceId) {
      configurations.userId = ''
      configurations.profileId = ''
      configurations.userAccessToken = ''
      configurations.userRefreshToken = ''
      Storage.remove('userAccessToken')
      Storage.remove('userRefreshToken')
      Storage.remove('userId')
      Storage.remove('profileId')
      configurations.loggedIn = 0
      Storage.set('loggedIn', 0)
      configurations.checkAuthentication = false
    }
    return logoutres
  } catch (error) {
    console.log(error)
    return error
  }
}

export const logoutAllDevices = async () => {
  const url = `${configurations.baseUrl}v2/persona/logout/devices`
  try {
    const logoutres = await middleware('GET', url, {}, true, configurations.headers)
    if (logoutres?.status_code === 200) {
      configurations.userId = ''
      configurations.profileId = ''
      configurations.userAccessToken = ''
      configurations.userRefreshToken = ''
      Storage.remove('userAccessToken')
      Storage.remove('userRefreshToken')
      Storage.remove('userId')
      Storage.remove('profileId')
      configurations.loggedIn = 0
      Storage.set('loggedIn', 0)
      configurations.checkAuthentication = false
    }
    return logoutres
  } catch (error) {
    console.log(error)
    return error
  }
}

export const getUserDevicesInfo = async () => {
  const url = `${configurations.baseUrl}v2/persona/devices`

  try {
    return await middleware('GET', url, null, true, configurations.headers)
  } catch (err) {
    return err
  }
}

export const editDeviceName = async (deviceObj) => {
  const url = `${configurations.baseUrl}v2/persona/edit/device`
  const params = {
    'device_name': deviceObj.updatedName,
    'device_id': deviceObj.deviceId,
    'd_type': deviceObj.deviceType,
  }
  try {
    return await middleware('POST', url, params, true, configurations.headers)
  } catch (error) {
    return error
  }
}

export const verifyParentalPIN = async (params) => {
  const url = `${configurations.baseUrl}v2/persona/content/verify_parental_pin`

  try {
    return await middleware('POST', url, params, true, configurations.headers)
  } catch (err) {
    return err
  }
}

/**
 * Get user details
 * @return {object} the response object of the user details
 */
export const getUserInfo = async () => {
  let url = `${configurations.baseUrl}v2/persona/users`

  try {
    return await middleware('GET', url, null, true, configurations.headers)
  } catch (error) {
    console.log(error)
    return error
  }
}

export const sendWatchCount = async (id, type) => {
  const url = `${configurations.baseUrl}v2/persona/watch/count`
  const params = {
    content_id: id,
    content_type: type,
  }
  try {
    return await middleware('POST', url, params, true, configurations.headers)
  } catch (error) {
    console.log(error)
    return error
  }
}

export const addToHistory = async (id) => {
  const url = `${configurations.baseUrl}v2/persona/history`
  const params = {
    content_id: id,
    u_id: configurations.userId,
  }
  try {
    return await middleware('POST', url, params, true, configurations.headers)
  } catch (error) {
    console.log(error)
    return error
  }
}

// wishlist category page api
export const getWishlistPageContent = async () => {
  const url = `${configurations.baseUrl}v2/persona/wishlist`
  const params = {
    skip: 0,
    take: configurations.takeValue,
    version: 'v1'
  }
  if (configurations.enableProfiles) {
    params.profile_id = Storage.get('profileId') || configurations.selectedProfileId
  }

  try {
    return await middleware('GET', url, params, true, configurations.headers)
  } catch (error) {
    console.log(error)
    return error
  }
}

export const createProfile = async (profileObj) => {
  const url = `${configurations.baseUrl}v2/auth/profile/create`

  const params = profileObj

  try {
    return await middleware('POST', url, params, true, configurations.headers)
  } catch (error) {
    return error
  }
}

export const updateProfile = async (profileObj) => {
  const url = `${configurations.baseUrl}v2/auth/profile/update`

  const params = profileObj

  try {
    return await middleware('POST', url, params, true, configurations.headers)
  } catch (error) {
    return error
  }
}

export const generateProfileDeleteOtp = async (profileObj) => {
  const url = `${configurations.baseUrl}v2/auth/profile/generate_otp`

  const params = profileObj

  try {
    return await middleware('POST', url, params, true, configurations.headers)
  } catch (error) {
    return error
  }
}

export const verifyGeneratedOtp = async (profileObj) => {
  const url = `${configurations.baseUrl}v2/auth/profile/verify_and_delete`

  const params = profileObj

  try {
    return await middleware('POST', url, params, true, configurations.headers)
  } catch (error) {
    return error
  }
}




