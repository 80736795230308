import { Lightning, Language, Utils, Colors } from '@lightningjs/sdk'
import { SignOutButton, SignOutPopUp, UserDevicesList } from '../Components'
import { FONT_BOLD, FONT_REGULAR } from '../Helper/GlobalConstants'
import App from '../App'

export default class ManageDevices extends Lightning.Component {
  static _template() {
    return {
      Background: {
        rect: true,
        color: Colors('backgroundColor').get(),
        w: App.width,
        h: App.height
      },
      Heading: {
        y: 60,
        text: {
          textColor: Colors('primaryFontColor1').get(),
          fontSize: 40,
          fontFace: FONT_BOLD,
          wordWrap: true,
          wordWrapWidth: 800,
          maxLines: 1,
          maxLinesSuffix: '...'
        }
      },
      AlertIcon: {
        alpha: 0,
        x: 900,
        y: 130,
        w: 60,
        h: 60,
        src: Utils.asset('static_assets/alert_icon.png')
      },
      SubHeading: {
        text: {
          textColor: Colors('secondaryFontColor').get(),
          fontSize: 26,
          fontFace: FONT_REGULAR,
          wordWrap: true,
          wordWrapWidth: 1000,
          maxLines: 1,
          maxLinesSuffix: '...'
        }
      },
      UserDevicesList: { type: UserDevicesList },
      SignOutButton: { type: SignOutButton },
      SignOutPopUp: { type: SignOutPopUp, alpha: 0 }
    }
  }

  initiatePage(data) {
    const checkScenario = data.checkFlow
    const userDevicesInfo = data.userDevicesInfo
    this.calculateTextWidth()
    checkScenario == 'manage-devices'
      ? this.patch({
        Heading: { text: { text: Language.translate('common_keys.manage_all_devices.text') } },
        AlertIcon: { alpha: 0 },
        SubHeading: {
          y: 160,
          text: { text: Language.translate('device_management.active_devices.text') }
        },
      })
      : this.patch({
        Heading: { text: { text: Language.translate('device_management.too_many_dev.error') } },
        AlertIcon: { alpha: 1 },
        SubHeading: {
          y: 210,
          text: { text: Language.translate('device_management.too_many_devices.error') + Language.translate('device_management.you_can.text') }
        },
      })
    this.tag('UserDevicesList').positionUpdate(checkScenario == 'manage-devices' ? 280 : 330)
    this.tag('UserDevicesList').data = [userDevicesInfo, checkScenario]
    this.tag('SignOutButton').prepareButtons(checkScenario)
    this.tag('SignOutPopUp').alpha = 0
    this._setState('UserDevicesList')
  }

  _getFocused() {
    return this._setState('UserDevicesList')
  }

  focusSignOutButton() {
    this._setState('SignOutButton')
  }

  focusDevicesList() {
    this._setState('UserDevicesList')
  }

  logoutIndividualDevice() {
    this.tag('UserDevicesList').logoutIndividualDevice()
  }

  openSignOutPopUp(deviceData) {
    this.tag('SignOutPopUp').data = deviceData
    this.tag('SignOutPopUp').alpha = 1
    this._setState('SignOutPopUp')
  }

  calculateTextWidth() {
    this.tag('Heading').on('txLoaded', () => {
      const width = this.tag('Heading').renderWidth
      const dynamicXPos = (App.width - width) / 2
      this.tag('Heading').patch({ x: dynamicXPos - 5 })
    })

    this.tag('SubHeading').on('txLoaded', () => {
      const width = this.tag('SubHeading').renderWidth
      const dynamicXPos = (App.width - width) / 2
      this.tag('SubHeading').patch({ x: dynamicXPos - 5 })
    })
  }

  static _states() {
    return [
      class UserDevicesList extends this {
        _getFocused() {
          return this.tag('UserDevicesList')
        }
      },

      class SignOutPopUp extends this {
        _getFocused() {
          return this.tag('SignOutPopUp')
        }
      },

      class SignOutButton extends this {
        _getFocused() {
          return this.tag('SignOutButton')
        }
      }
    ]
  }
}
