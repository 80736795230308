import { ForgotPassword } from '../Views'
/**
 * Defines the configuration for the 'forgotpassword' page.
 */
export default {
    path: 'forgotpassword',
    component: ForgotPassword,
    on: async (page) => {
      return page.preparePageData()
    },
    widgets: ['Back', 'LanguageSelection', 'LocalesList', 'SidePanelNavigation']
}
