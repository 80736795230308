import { Lightning, Router, Utils, Colors, Language } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { FONT_BOLD, appInstance } from '../Helper/GlobalConstants.js'
import { Back, Button, LandscapeKeyboard } from '../Components/index.js'
import { getColorCodeWithOpacity, removeLastElement, showDialog } from '../Helper/Helpers.js'
import App from '../App.js'
import Input from '../Components/Forms/Input.js'
import configurations from '../Helper/Configurations.js'
import { createProfile, updateProfile } from '../Services/PersonaService.js'

export default class ManageProfiles extends Lightning.Component {
  static _template() {
    return {
      Background: {
        w: App.width,
        h: App.height,
        rect: true,
        color: Colors('backgroundColor').get(),
      },
      CreateProfileHeading: {
        y: 200,
        text: {
          text: Language.translate('profile.create_profile.text'),
          fontFace: FONT_BOLD,
          fontSize: 40,
          lineHeight: 53,
          textColor: Colors('primaryFontColor1').get(),
        },
      },
      FocusCircle: {
        x: 877,
        y: 282,
        texture: Lightning.Tools.getRoundRect(125, 125, 62.5, 5, Colors('brandPrimaryColor').get(), false),
      },
      Profiles: {
        zIndex: 2,
        x: ((App.width - 920) / 2) - 20,
        y: 290,
        w: 920,
        h: 150,
        clipping: true,
        type: List,
        signals: { onIndexChanged: true },
      },
      ErrorMessage: {
        y: 435,
        alpha: 0,
        text: {
          fontFace: 'Regular',
          fontSize: 26,
          lineHeight: 18,
          textColor: Colors('errorColor').get(),
        },
      },
      ButtonsContainer: {
        x: 560,
        y: 450,
        ProfileTitle: {
          x: 2,
          text: {
            text: Language.translate('profile.profile_name.text'),
            fontSize: 24,
            textColor: Colors('primaryFontColor1').get(),
            fontFace: 'Regular',
          },
        },
        InputFields: { zIndex: 2 },
        ButtonFields: { zIndex: 2, visible: false }
      },
      LandscapeKeyboard: { type: LandscapeKeyboard, visible: false },
      Back: { type: Back, visible: true }
    }
  }

  _init() {
    this.fullNameValue = ''
    this.index = 0
    this.initialPosition = 6
    this.initialFocusedImage = 'default_image'
    this.tag('LandscapeKeyboard').visible = false
    this.tag('ButtonFields').visible = false
    this.textPositioning()
    this.tag('Back').updatePosition([70, 70])
  }

  focusProfiles() {
    this.tag('FocusCircle').alpha = 1
    this._setState('Profiles')
  }

  _getFocused() {
    this.focusProfiles()
  }

  textPositioning() {
    this.tag('CreateProfileHeading').on('txLoaded', () => {
      const headingwidth = this.tag('CreateProfileHeading').renderWidth
      const headingPos = (App.width - headingwidth) / 2
      this.tag('CreateProfileHeading').patch({ x: headingPos })
    })

    this.tag('ErrorMessage').on('txLoaded', () => {
      const width = this.tag('ErrorMessage').renderWidth
      const xPos = (App.width - width) / 2
      this.tag('ErrorMessage').patch({ x: xPos })
    })
  }

  set data(profileId) {
    this.profileInfo = configurations.profilesList.find(obj => obj.profile_id === profileId)
    if (this.profileInfo)
      this.profileInfo = this.profileInfo
    else
      this.profileInfo.view = 'initial'

    if (configurations.defaultProfileImages.includes('default_image_small')) {
      configurations.defaultProfileImages.splice(configurations.defaultProfileImages.indexOf('default_image_small'), 1)
    }
    this.patchDataToTemplate()
    let editButtonsArray = []
    if (this.profileInfo.view == 'edit' && this.profileInfo.profile_id != 'add') {
      this.tag('CreateProfileHeading').patch({ text: { text: 'Edit Profile' } })
      this.tag('ButtonFields').visible = true
      this.fullNameValue = this.profileInfo.name
      this.inputFields = [
        {
          x: 1, y: 50, textX: 10, alpha: 1,
          inputFillColor: Colors('cardColor').get(),
          placeholder: this.profileInfo.name,
        }
      ]
      this.prepareInputFields(this.inputFields)


      const updateBtnObject = {
        x: 1,
        y: 150,
        w: 355,
        h: 63,
        fontSize: 24,
        title: Language.translate('profile.update_profile.button'),
        layout: 'border',
        radius: 10,
      }
      const deleteBtnObject = {
        x: 455,
        y: 150,
        w: 355,
        h: 64,
        fontSize: 24,
        title: Language.translate('profile.delete_profile.text'),
        color: Colors('primaryFontColor1').get(),
        layout: 'border',
        radius: 10,
        buttonColor: Colors('primaryFontColor1').get(),
      }

      if (configurations.userId != this.profileInfo.profile_id) {
        editButtonsArray.push(updateBtnObject)
        editButtonsArray.push(deleteBtnObject)
      } else {
        updateBtnObject.x = 1
        updateBtnObject.w = 800
        editButtonsArray.push(updateBtnObject)
      }
      this.tag('ButtonFields').children = editButtonsArray.map((button) => {
        return { type: Button, button }
      })
    }
    this.buttonsLength = this.tag('ButtonFields').children.length
    this.inputLength = this.tag('InputFields').children.length

    this._setState('Profiles')
  }

  get slider() {
    return this.tag('Profiles')
  }

  actionControls() {
    this.tag('LandscapeKeyboard')._handleRight()
  }

  moveFocusCircle() {
    switch (this.slider.index) {
      case 0:
        this.patch({ FocusCircle: { smooth: { x: 472.5 } } })
        break
      case 1:
        this.patch({ FocusCircle: { smooth: { x: 607.5 } } })
        break
      case 2:
        this.patch({ FocusCircle: { smooth: { x: 742.5 } } })
        break
      case 3:
        this.patch({ FocusCircle: { smooth: { x: 877 } } })
        break
    }
  }


  _handleBack() {
    this.clearDefaultImage()
    Router.back()
  }

  retainState() {
    this.tag('LandscapeKeyboard').visible = false
    this._setState('InputFields')
  }

  clearDefaultImage() {
    configurations.defaultProfileImages = configurations.defaultProfileImages.filter(element => element.length > 1 && !element.includes('default_image_small'))
  }

  onKeyboardInputUpdate(value, useraction) {
    if (useraction == 'backspace') {
      if (value != '') {
        this.fullNameValue = removeLastElement(this.fullNameValue)
        this.inputFields[0]['placeholder'] = removeLastElement(this.inputFields[0]['placeholder'])
        this.prepareInputFields(this.inputFields)
      } else {
        this.reArrangeList({ 'name': 'default_image_small' })
        this.inputFields = [{
          x: 1, y: 50, textX: 10, alpha: 1,
          inputFillColor: Colors('cardColor').get(),
          placeholder: Language.translate('profile.name_placeholder.text'),
        }]
        this.prepareInputFields(this.inputFields)
      }
    } else if (useraction == 'delete') {
      this.reArrangeList({ 'name': 'default_image_small' })
      this.fullNameValue = ''
      this.inputFields = [{
        x: 1, y: 50, textX: 10, alpha: 1,
        inputFillColor: Colors('cardColor').get(),
        placeholder: Language.translate('profile.name_placeholder.text'),
      }]
      this.prepareInputFields(this.inputFields)
    } else if (useraction == 'done') {
      if (value == '') return
      this.tag('ButtonFields').visible = true
      this.tag('LandscapeKeyboard').visible = false
      this._setState('ButtonFields')
    } else {
      if (value != '') {
        const profileItem = this.tag('Profiles').items[this.slider.index]
        const nameLength = profileItem.imageData.name
        if ((nameLength.length == 1 || nameLength == 'default_image_small') && value.length == 1) {
          this.reArrangeList({ 'name': value.toUpperCase() })
          this.fullNameValue = value
          this.appendInputValue(value)
        } else {
          this.fullNameValue = value
          this.appendInputValue(value)
        }
      } else {
        this.inputFields = [{
          x: 1, y: 50, textX: 10, alpha: 1,
          inputFillColor: Colors('cardColor').get(),
          placeholder: Language.translate('profile.name_placeholder.text'),
        }]
        this.prepareInputFields(this.inputFields)
      }
    }
  }

  reArrangeList(updatedObject) {
    let updatedImageDetails = configurations.defaultProfileImages
    updatedImageDetails.splice(3, 1, updatedObject.name)
    updatedImageDetails = updatedImageDetails.map(imageName => { return { name: imageName } })
    const profileCards = updatedImageDetails.map((item, index) => {
      item.isDefaultCard = index === 3 ? true : false
      return {
        w: 135,
        h: 135,
        x: 10,
        type: ProfileItem,
        alpha: 1,
        item,
        spacing: 50,
      }
    })
    this.tag('Profiles').clear()
    this.tag('Profiles').add(profileCards)
    this.slider.setIndex(3)
    this.slider.scroll = { after: 4 }
    this._setState('LandscapeKeyboard')
  }


  appendInputValue(value) {
    if (this.inputFields[0]['placeholder'].includes(Language.translate('profile.name_placeholder.text'))) {
      this.inputFields[0]['placeholder'] = ''
    }
    this.inputFields[0].alpha = 1
    this.inputFields[0]['placeholder'] = value
    this.prepareInputFields(this.inputFields)
  }


  prepareInputFields(inputFields) {
    this.tag('InputFields').children = inputFields.map((input) => {
      input.w = 800
      return { type: Input, input }
    })
  }

  returnSelectedField() {
    return false
  }

  $returnScalingStatus() {
    return this.scalingStatus
  }

  onkeyboardExitUp() { return }

  onkeyboardExitLeft() { return }

  patchDataToTemplate() {
    let defaultImages = configurations.defaultProfileImages
    const insertDefaultImage = (image, index) => {
      if (!defaultImages.includes(image)) {
        defaultImages.splice(index, 0, image)
      }
    }
    const updateDefaultImages = () => {
      const profileCapLetter = this.profileInfo.name.charAt(0).toUpperCase()

      if (this.profileInfo.view === 'initial') {
        insertDefaultImage('default_image_small', 3)
      } else {
        const icon = this.profileInfo.icon
        const insertIndex = icon ? 2 : 3
        const replaceIndex = 3

        insertDefaultImage(profileCapLetter, insertIndex)

        if (icon) {
          defaultImages = defaultImages.filter(item => item !== icon)
          defaultImages.splice(replaceIndex, 0, icon)
        }
      }
    }
    updateDefaultImages()
    const imageDetails = defaultImages.map(imageName => { return { name: imageName } })
    const profileCards = imageDetails.map((item) => {
      return { w: 135, h: 135, x: 10, type: ProfileItem, alpha: 1, item }
    })

    this.tag('Profiles').clear()
    this.tag('Profiles').add(profileCards)
    this.slider.setIndex(3)
    this.slider.scroll = { after: 4 }
    this.inputFields = [
      {
        x: 1, y: 50, textX: 10, alpha: 1,
        inputFillColor: Colors('cardColor').get(),
        placeholder: Language.translate('profile.name_placeholder.text')
      }
    ]
    this.prepareInputFields(this.inputFields)
    const buttonsInfo = [
      {
        x: 1,
        y: 150,
        w: 800,
        h: 63,
        fontSize: 24,
        title: Language.translate('profile.create_profile.text'),
        layout: 'border',
        radius: 10,
      },
    ]
    this.tag('ButtonFields').children = buttonsInfo.map((button) => {
      return { type: Button, button }
    })

  }

  static _states() {
    return [
      class Profiles extends this {
        _getFocused() {
          this.scalingStatus = false
          if (this.slider.index <= 3) this.moveFocusCircle()
          return this.tag('Profiles')
        }

        _handleDown() {
          this._setState('InputFields')
        }

        _handleUp() {
          this.tag('FocusCircle').alpha = 0
          this._setState('Back')
         }

        _handleLeft() { return }

        _handleRight() { return }
      },

      class InputFields extends this {
        _getFocused() {
          this.scalingStatus = true
          return this.tag('ButtonsContainer.InputFields').children[0]
        }

        _handleDown() {
          if (this.tag('ButtonFields').visible)
            this._setState('ButtonFields')
        }

        _handleUp() {
          this._setState('Profiles')
        }

        _handleLeft() { return }

        _handleRight() { return }

        _handleEnter() {
          this.tag('ErrorMessage').patch({
            alpha: 1,
            text: { text: '' },
          })
          this.tag('ButtonsContainer').patch({y: 450})
          this.tag('LandscapeKeyboard').patch({ smooth: { visible: [true, { duration: 0.2, delay: 0.0 }] } })
          this.tag('LandscapeKeyboard').clearLetterByLetter(this.fullNameValue)
          return this._setState('LandscapeKeyboard')
        }
      },


      class ButtonFields extends this {
        $enter() {
          this.btnIndex = 0
        }

        _getFocused() {
          return this.tag('ButtonsContainer.ButtonFields').children[this.btnIndex]
        }

        _handleDown() { return }

        _handleUp() {
          this._setState('InputFields')
        }

        _handleLeft() {
          this.btnIndex = 0
        }

        _handleRight() {
          if (this.tag('ButtonsContainer.ButtonFields').children.length > 1) {
            this.btnIndex = 1
          }
        }

        async _handleEnter() {
          const handleErrorResponse = (response) => {
            this.clearDefaultImage()
            this.tag('ButtonsContainer').patch({ y: 550 })
            this.tag('ErrorMessage').patch({
              alpha: 1,
              text: { text: response.error.message.trim() },
            })
          }

          const handleCreateProfile = async () => {
            const isValidFullName = /^(?!.*\s{2})[a-zA-Z0-9\s]*$/.test(this.fullNameValue);
            if(!isValidFullName) {
              this.tag('ErrorMessage').patch({
                alpha: 1,
                text: { text: Language.translate('profile.name_acceptance_criteria_2.text')  },
              })
              this.tag('ButtonsContainer').patch({y: 500})
            } else {
              const profileObject = {
                'profile_name': this.fullNameValue,
                'profile_image': this.slider.items[this.slider.index].imageData.name.includes('profiles/')
                  ? this.slider.items[this.slider.index].imageData.name
                  : ''
              }
              const response = await createProfile(profileObject)

              if (response && response.status_code === 200) {
                this.clearDefaultImage()
                configurations.profilesList = response.data
                Router.navigate('profile/add')
              } else if (response.error) {
                handleErrorResponse(response)
              }
            }
          }

          const handleUpdateProfile = async () => {
            const profileObject = {
              'profile_name': this.fullNameValue,
              'profile_image': this.slider.items[this.slider.index].imageData.name.includes('profiles/')
                ? this.slider.items[this.slider.index].imageData.name
                : '',
              'profile_id': this.profileInfo.profile_id,
            }
            const response = await updateProfile(profileObject)

            if (response?.status_code === 200) {
              this.clearDefaultImage()
              configurations.profilesList = response.data
              const addObject = { 'icon': '', 'name': Language.translate('profile.add_profile.text'), 'profile_id': 'add' }
              configurations.profilesList.push(addObject)
              Router.navigate('profile/add')
            } else if (response.error) {
              handleErrorResponse(response)
            }
          }

          const handleDeleteProfileDialog = () => {
            const dialog = appInstance.$returnDialog()
            const actionsArray = [
              {
                label: 'Delete',
                action: () => { Router.navigate('deleteprofile', this.profileInfo) },
              },
              {
                label: 'Cancel',
                action: () => { dialog.close() },
              },
            ]
            showDialog(Language.translate('profile.delete_profile.text'), Language.translate('profile.profile_deletion_confirmation.text'), actionsArray)
          }

          if (this.btnIndex === 0) {
            if (this.profileInfo.view === 'initial') {
              return await handleCreateProfile()
            }

            if (this.profileInfo.view === 'edit' && this.tag('ButtonsContainer.ButtonFields').children.length === 2) {
              await handleUpdateProfile()
            }

            return await handleUpdateProfile()
          }

          if (this.btnIndex === 1 && this.profileInfo.view === 'edit') {
           return handleDeleteProfileDialog()
          }
        }

      },
      class LandscapeKeyboard extends this {
        _getFocused() {
          return this.tag('LandscapeKeyboard')
        }
      },

      class Back extends this {
        _getFocused() {
          return this.tag('Back')
        }
      },

    ]
  }
}


class ProfileItem extends Lightning.Component {
  static _template() {
    return {
      x: 10,
      y: 10,
      Border: {
        zIndex: 4,
        alpha: 0,
        w: 90, h: 90, alpha: 0,
        src: Utils.asset('static_assets/default_profile_ellipse.png'),
      },
      Overlay: { zIndex: 3, x: -1, y: -1 },
      Poster: {
        zIndex: 2,
        w: 90,
        h: 90,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 45 },
        AddImg: {
          alpha: 0,
          x: 22,
          y: 22,
          w: 45,
          h: 45,
          src: Utils.asset('static_assets/small_default_profile.png'),
        }
      },
      ProfileLetter: {
        alpha: 0,
        zIndex: 4,
        text: {
          fontSize: 50,
          textColor: Colors('primaryFontColor1').get(),
          fontFace: 'Bold',
        },
      },

    }
  }

  _init() {
    this.tag('ProfileLetter').on('txLoaded', () => {
      const width = this.tag('ProfileLetter').renderWidth
      const height = this.tag('ProfileLetter').renderHeight
      const xPos = (90 - width) / 2
      const yPos = (90 - height) / 2
      this.tag('ProfileLetter').patch({ x: xPos, y: yPos + 4 })
    })
  }

  set item(imagesData) {
    this.imageData = imagesData
    const gradientColor = getColorCodeWithOpacity(Colors('backgroundColor').get(), '50')

    if (imagesData.name == 'default_image_small') {
      this.patch({
        Border: { alpha: 1 },
        Poster: { AddImg: { alpha: 1 } },
        Overlay: { alpha: 0 }
      })
    } else {
      if (imagesData.name.length == 1) {
        this.tag('Poster').patch({
          texture: Lightning.Tools.getRoundRect(130, 130, 64, 5, Colors('brandPrimaryColor').get(), true, Colors('brandPrimaryColor').get(),),
        })
        this.tag('ProfileLetter').patch({
          alpha: 1,
          text: { text: imagesData.name }
        })
      } else {
        this.tag('Poster').src = configurations.imageBasePath + imagesData.name
        this.tag('Overlay').patch({
          alpha: 1,
          texture: Lightning.Tools.getRoundRect(90, 90, 45, 0, gradientColor, true, gradientColor)
        })
      }
    }

    if (imagesData.isDefaultCard) {
      this.patch({
        scale: 1.30, y: 20, x: 20,
        Overlay: { alpha: 0 },
        Border: { alpha: 0 }
      })
    }
  }

  _focus() {
    this.patch({
      scale: 1.30, y: 20, x: 20,
      Overlay: { alpha: 0 },
      Border: { alpha: 0 }
    })
  }

  _unfocus() {
    const scale = this.fireAncestors('$returnScalingStatus')
    if (scale) return
    this.patch({
      scale: 1, y: 10, x: 10,
      Border: { alpha: this.imageData.name === 'default_image_small' ? 1 : 0 },
      Overlay: { alpha: 1 },
    })
  }

}
