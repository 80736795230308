import { Language, Lightning, Utils, Colors } from '@lightningjs/sdk'
import { InlineContent } from '@lightningjs/ui-components'
import { commaSeparatedwithSpaces, getTitleType, isEmptyObject, processGenreData } from '../Helper/Helpers'
import { Backdrop } from '.'
import { GENRE_CURATION, THEME_TWO, VIEW_ALL, fontsizes, gradientColor } from '../Helper/GlobalConstants'
import configurations from '../Helper/Configurations'
import { returnImageUrl } from '../Helper/Helpers'
import { imageSizes } from '../Helper/GlobalConstants'

export default class Detail extends Lightning.Component {
  static _template() {
    return {
      PortraitPoster: {
        x: 195,
        y: 115,
        w: 280,
        h: 420,
        zIndex: 3,
        alpha: 0,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 12 }
      },
      Shadow: {
        zIndex: 2,
        x: 165,
        y: 414,
        alpha: 0,
        color: Colors('shadowColor').get(),
        texture: Lightning.Tools.getShadowRect(280, 100, 12, 16)
      },
      TopGradient: {
        alpha: 0,
        zIndex: 1,
        x: 160,
        y: 0,
        w: 1920,
        h: 654,
        rect: true,
        color: Colors('backgroundColor').get(),
      },
      TopLeftGradient: {
        alpha: 0,
        zIndex: 2,
        x: 761.7,
        y: 0,
        w: 800,
        h: 654,
        rect: true,
        colorRight: gradientColor.bannerImgGradient,
        colorLeft: Colors('backgroundColor').get(),
      },
      BannerImage: {
        zIndex: 1,
        type: Backdrop
      },
      ContentContainer: {
        h: 654,
        zIndex: 3,
        flex: { direction: 'column', justifyContent: 'center' },
        OnAir: {
          type: InlineContent,
          justify: 'flex-start',
          style: { iconWidth: 33, iconHeight: 33 },
          flexItem: { marginBottom: 20 }
        },
        TitleLogo: {
          w: 200, h: 200,
          flexItem: { marginBottom: 20 }
        },
        Title: {
          text: {
            fontSize: fontsizes.seriesPageTitle,
            textColor: Colors('primaryFontColor1').get(),
            maxLines: 1,
            maxLinesSuffix: '...',
            wordWrapWidth: 700,
            fontFace: 'Bold',
          },
          flexItem: { marginBottom: 10 }
        },
        TitleEN: {
          text: {
            fontSize: 40,
            textColor: Colors('primaryFontColor1').get(),
            maxLines: 1,
            maxLinesSuffix: '...',
            wordWrapWidth: 700,
            fontFace: 'Bold',
          },
          flexItem: { marginBottom: 10 }
        },
        TagsData: {
          type: InlineContent,
          justify: 'flex-start',
          content: [],
          customStyleMappings: {
            textStyle: {
              fontSize: fontsizes.seriesPageMetadata,
              textColor: Colors('primaryFontColor1').get(),
              textAlign: 'left',
              fontFace: 'Regular',
            },
          },
          flexItem: { marginBottom: 30 }
        },
        Description: {
          text: {
            wordWrapWidth: 750,
            textColor: Colors('primaryFontColor1').get(),
            fontSize: fontsizes.bannerItemMetaContainer,
            lineHeight: 30,
            maxLines: 3,
            maxLinesSuffix: '...',
          },
          flexItem: { marginBottom: 10 }
        },
        TVOD: {
          flex: { direction: 'row' },
          Icon: { w: 36, h: 36, flexItem: { marginRight: 10 } },
          Label: {
            text: {
              fontSize: fontsizes.seriesPageMetadata,
              textColor: Colors('tvodLabelColor').get(),
              wordWrapWidth: 500,
              fontFace: 'Bold',
              maxLines: 1,
              maxLinesSuffix: '...'
            }
          },
        },
      },
    }
  }

  _init() {
    this.fadeAnimation = this.tag('ContentContainer').animation({
      delay: 0.2, duration: 0.3, actions: [
        { p: 'alpha', v: { 0: 0.001, 1: 1 } },
        { p: 'x', v: { 0: 250, 1: 210 } }
      ]
    })
    this.portraitFadeAnimation = this.tag('ContentContainer').animation({
      delay: 0.2, duration: 0.3, actions: [
        { p: 'alpha', v: { 0: 0.001, 1: 1 } },
        { p: 'x', v: { 0: 561, 1: 521 } }
      ]
    })
    this.tag('PortraitPoster').on('txError', () => { this.tag('Shadow').patch({ alpha: 0 }) })
  }

  set item(data) {
    if (data?.contentId != VIEW_ALL) {
      const { bannerType } = configurations
      const titleLogo = returnImageUrl(data.contentLogo, imageSizes.icon)
      const titleType = getTitleType(data)
      this.patch({
        TopGradient: { alpha: 1 },
        TopLeftGradient: { alpha: 1 },
        PortraitPoster: {
          src: data.posterPortrait
            ? returnImageUrl(data.posterPortrait, imageSizes.card)
            : Utils.asset('static_assets/fallback_poster.png'),
          alpha: data.posterPortrait && bannerType == THEME_TWO && data.component != GENRE_CURATION ? 1 : 0
        },
        Shadow: { alpha: data.posterPortrait && bannerType === THEME_TWO ? 1 : 0 },
        ContentContainer: {
          OnAir: {
            visible: (data.isLiveChannel),
            content: [
              {
                icon: Utils.asset('static_assets/onair.png'),
                w: 33,
                h: 33
              },
              {
                text: Language.translate('live.on_air.text'),
                fontSize: fontsizes.bannerItemLiveChannelTitle,
                textColor: Colors('errorColor').get(),
                wordWrapWidth: 1060,
                lineHeight: 30,
                maxLines: 1,
                fontFace: 'Bold',
              }
            ],
          },
          TitleLogo: {
            visible: (data.contentLogo && bannerType != THEME_TWO && data.component != GENRE_CURATION && (titleType === 'onlyLogo' || titleType === 'logoAndTitle')),
            src: titleLogo && bannerType !== THEME_TWO ? titleLogo : null,
          },
          Title: {
            visible: !(titleType === 'onlyLogo' || titleType === 'onlyLocalTitle' || titleType === 'logoAndTitle'),
            text: {
              text: data.title || '',
              wordWrapWidth: 800
            },
          },
          TitleEN: {
            visible: (titleType !== 'onlyLogo'),
            text: {
              text: titleType === 'logoAndTitle' && !data.isLocalLogo ? data.title : data.titleEn,
              wordWrapWidth: 900
            },
          },
          Description: {
            visible: (data.description && data.isLiveChannel),
            text: { text: data.description },
          },
          TVOD: { visible: false }
        }
      })
      this.showTags(data)

      bannerType == THEME_TWO && data.posterPortrait ? this.portraitFadeAnimation.start() : this.fadeAnimation.start()

      if (!isEmptyObject(data.rent))
        return this.handleTVODUI('static_assets/expiry_icon_colored_large.png', data.rent.expires_in, 2)

      if (!isEmptyObject(data.buy)) return

      if (data.isTvod || data.availability === 2) {
        this.handleTVODUI('static_assets/rent_or_buy_large.png', Language.translate('plans.available_to_purchase.tag'), 5)
      }
    }
  }

  handleTVODUI(image, text, margin) {
    this.patch({
      ContentContainer: {
        TVOD: {
          visible: true,
          Icon: { src: Utils.asset(image) },
          Label: { flexItem: { marginTop: margin }, text: { text: text } }
        }
      }
    })
  }

  // Method to update the backdrop image
  updateBackDropImg(e) {
    this.tag('BannerImage').update(e.src)
  }

  showTags(data) {
    const slicedGenre = data.genre?.split(',').slice(0, 2).join(',')
    const genreTitle = data.genre ? processGenreData(slicedGenre) : ''
    const hr = Math.floor(data.durationMin / 60)
    const min = data.durationMin % 60
    const durationFormat = hr > 0 ? `${hr} Hour ${min} Minutes` : `${min} Minutes`
    const duration = data.durationMin ? ` |  ${durationFormat}` : ''
    const seasonText = data.totalSeasons > 1 ? Language.translate('common_keys.seasons.text') : Language.translate('common_keys.season.text')
    const episodesCount = data.seriesEpisodesCount > 1 ? Language.translate('common_keys.episodes.text') : Language.translate('content_detail.episodes.text')
    const slicedTags = data.tags?.split(',').slice(0, 3).join(',')
    const tagsData = data.tags ? commaSeparatedwithSpaces(slicedTags) : ''
    const genreORTags = data.genre && `${genreTitle}` || data.tags && `${tagsData}` || ''
    let age = ''
    if (data.age && configurations.enableAgeRating) {
      age = data.publishTime ? ` | ${data.age + '+'} | ` : `${data.age + '+'} | `
    } else {
      age = data.publishTime ? ' | ' : ''
    }

    let seriesSeasonCount = ''
    if (data.isSeries && !data.videoId) {
      seriesSeasonCount = (data.totalSeasons && data.totalSeasons > 0)
        && ` | ${data.totalSeasons} ${seasonText}`
        || (data.seriesEpisodesCount && data.seriesEpisodesCount > 0)
        && ` | ${data.seriesEpisodesCount} ${episodesCount}`
        || ''
    }
    this.patch({
      LiveChannelIndicator: { alpha: 0 },
      ContentContainer: {
        alpha: 1,
        TagsData: {
          visible: data.component !== GENRE_CURATION,
          content: [
            { text: data.publishTime || '', style: 'textStyle' },
            { text: age, style: 'textStyle' },
            { text: genreORTags, style: 'textStyle' },
            { text: data.isSeries ? seriesSeasonCount : duration, style: 'textStyle' }
          ],
        }
      },
    })
  }
}
