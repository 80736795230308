import { Lightning, Router, Language} from '@lightningjs/sdk'
import { logoutAllDevices } from '../../Services/PersonaService'
import { flagBasedNavigation, showDialog } from '../../Helper/Helpers'
import configurations from '../../Helper/Configurations'
import DynamicButton from '../DynamicButton'
import App from '../../App'

export default class SignOutButton extends Lightning.Component {
  static _template() {
    return {
      ButtonList: {
        w: App.width,
        flex: { direction: 'row', justifyContent: 'center' }
      },
    }
  }

  prepareButtons(checkFlow) {
    this.checkFlow = checkFlow
    this.tag('ButtonList').patch({ y: checkFlow === 'manage-devices' ? 200 : 245 })
    const signOut = [{ title: Language.translate('device_management.sign_out_all.btn'), action: 'sign-out' }]
    this.tag('ButtonList').children = signOut.map(button => {
      return {
        type: DynamicButton,
        h: 58,
        borderRadius: 2,
        textPadding: 40,
        withoutTexture: true,
        label: button.title,
        flexItem: { marginRight: 40, alignSelf: 'stretch', maxHeight: 100 },
        underline: true
      }
    })
  }

  _getFocused() {
    return this.tag('ButtonList').children[0]
  }

  _handleBack() {
    this.checkFlow === 'manage-devices' ? Router.back() : this.application.closeApp()
  }

  _handleDown() {
    Router.getActivePage().focusDevicesList()
  }

  _handleKey() { return }

  _handleRight() { return }

  _handleLeft() { return }

  _handleEnter() {
    const dialog = this.fireAncestors('$returnDialog')
    const actionArray = [
      {
        label: Language.translate('common_keys.sign_out.btn'),
        action: async () => {
          await logoutAllDevices()
          !configurations.loginMode ? Router.navigate('home') : flagBasedNavigation()
        }
      },
      {
        label: Language.translate('common_keys.cancel.text'),
        action: () => { dialog.close() }
      }
    ]
    showDialog(
      Language.translate('common_keys.are_you_sure.text'),
      Language.translate('device_management.remove_all_dev.text'),
      actionArray
    )
  }
}
