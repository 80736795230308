import { Lightning, Utils, Router, Language, Colors } from '@lightningjs/sdk'
import { Shadow } from '@lightningjs/ui-components'
import { FONT_BOLD, FONT_REGULAR } from '../Helper/GlobalConstants'
import { Back, Keyboard, LanguageSelection, LocalesList, VerificationForm } from '../Components'
import configurations from '../Helper/Configurations'
import App from '../App'

export default class VerificationMail extends Lightning.Component {
  static _template() {
    return {
      Background: {
        w: App.width,
        h: App.height,
        rect: true,
        color: Colors('backgroundColor').get(),
      },
      Logo: {
        h: 160,
        w: 1920,
        zIndex: 4,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        LogoImg: {
          src: Utils.asset('static_assets/loginLogo.png'),
        }
      },
      KeyboardShadow: {
        x: 140,
        y: 140,
        type: Shadow,
        w: 665,
        h: 870,
        style: {
          alpha: 1,
          color: Colors('shadowColor').get()
        },
      },
      VerificationMailTemplate: {
        x: 950,
        y: 170,
        zIndex: 1,
        texture: Lightning.Tools.getRoundRect(906, 839, 0, 0, Colors('cardColor').get(), true, Colors('cardColor').get(),),
        AlertLogo: {
          x: 408,
          y: 218,
          w: 85,
          h: 85,
          src: Utils.asset('static_assets/alert_icon.png'),
        },
        Heading: {
          y: 332,
          text: {
            fontFace: FONT_BOLD,
            fontSize: 48,
            lineHeight: 58,
            textColor: Colors('primaryFontColor1').get(),
            textAlign: 'center',
          },
        },
        SubHeading: {
          y: 430,
          text: {
            fontFace: FONT_REGULAR,
            fontSize: 26,
            wrap: true,
            wordWrapWidth: 850,
            textAlign: 'center',
            lineHeight: 70,
            textColor: Colors('primaryFontColor1').get(),
          },
        },
      },
      VerificationForm: { type: VerificationForm },
      Keyboard: { type: Keyboard },
      LanguageSelection: { type: LanguageSelection },
      LocalesList: { type: LocalesList },
      Back: { type: Back }
    }
  }

  dynamicPosForText() {
    this.tag('Heading').on('txLoaded', () => {
      this.width = this.tag('Heading').renderWidth
      const dynamicXPos = (908 - this.width) / 2
      this.tag('Heading').patch({ x: dynamicXPos - 5 })
    })

    this.tag('SubHeading').on('txLoaded', () => {
      this.width = this.tag('SubHeading').renderWidth
      const dynamicXPos = (908 - this.width) / 2
      this.tag('SubHeading').patch({ x: dynamicXPos - 5 })
    })
  }

  preparePageData() {
    this.patch({
      VerificationMailTemplate: {
        Heading: {
          text: { text: Language.translate('email_verification.verify_mail.text') }
        },
        SubHeading: {
          text: { text: Language.translate('Verify your email by entering the 6-digit code emailed to you.') }
        }
      }
    })
    this.dynamicPosForText()
    if (configurations.locales.length > 1)
      this.tag('LanguageSelection').data = configurations.locales
    const buttons = [
      {
        x: 1032,
        y: 814,
        w: 740,
        h: 63,
        title: Language.translate('downloads.continue.text'),
        layout: 'border',
        radius: 10,
      },
      {
        x: 1032,
        y: 907,
        w: 740,
        h: 63,
        title1: Language.translate('email_verification.verific_mail_not_received.text'),
        title2: 'Resend again',
        layout: 'border',
        radius: 10,
      },
    ]
    this.tag('VerificationForm').data = buttons
    this.tag('Keyboard').layout = 'abc'
    this.tag('Keyboard').patch({ x: 150, y: 170 })
    this.tag('Back').updatePosition([990, 213])
    this._setState('Keyboard')
  }

  filterActiveWidget() {
    const totalWidgets = this.widgets
    return Object.values(totalWidgets).filter((obj) => obj.__active === true)
  }

  onKeyboardInputUpdate(value, inputType, keyAction) {
    this.tag('VerificationForm').$populateInput(value, inputType, keyAction)
  }

  _handleBack() {
    Router.back()
  }

  handleInputType(inputType) {
    this.tag('Keyboard').type = inputType
  }

  focusForms() {
    this._setState('VerificationForm')
  }

  focusKeyboard() {
    this._setState('Keyboard')
  }

  _handleRight() {
    this.focusForms()
  }

  updateTranslations() {
    this.preparePageData()
  }

  _getFocused() {
    this._setState('Keyboard')
  }

  clearSidePanel() {
    this.widgets.sidepanelnavigation.alpha = 0
    this.tag('LocalesList').alpha = 0
  }

  updateLanguageCode() {
    this.tag('LanguageSelection').updateLanguageCode()
  }

  focusLanguageSelection() {
    this._setState('LanguageSelection')
  }

  openLocalesList() {
    this.tag('LocalesList').openLocalesList()
    this._setState('LocalesList')
  }

  focusBack() {
    if (configurations.locales.length > 1)
      this._setState('Back')
  }

  focusLocalesList() {
    this._setState('LocalesList')
  }

  static _states() {
    return [
      class VerificationForm extends this {
        _getFocused() {
          return this.tag('VerificationForm')
        }
      },

      class Keyboard extends this {
        _getFocused() {
          return this.tag('Keyboard')
        }
      },

      class LanguageSelection extends this {
        _getFocused() {
          return this.tag('LanguageSelection')
        }
      },

      class Back extends this {
        _getFocused() {
          return this.tag('Back')
        }
      },

      class LocalesList extends this {
        _getFocused() {
          return this.tag('LocalesList')
        }
      }
    ]
  }
}
