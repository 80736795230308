import { Lightning } from '@lightningjs/sdk'

export default class SidePanelButtons extends Lightning.Component {
  static _template() {
    return {
      Wrapper: {
        zIndex: 11,
        x: 600,
        y: 112,
        flex: { direction: 'column' },
      },
    }
  }

  set data(val) {
    this.tag('Wrapper').children = val.map((label) => {
      return {
        type: SidePanelButtons,
        flexItem: { marginBottom: 24, alignSelf: 'stretch', maxHeight: 100 },
        label,
      }
    })
  }

  _handlekey() {}
}
