
import DashVodPlayer from "../Views/DashVodPlayer"
export default {
    path: 'dashvodplayer/:action/:videoId',
    component: DashVodPlayer,
    on: async (_page) => {
      return
    },
    widgets: [
      'SidePanelNavigation',
      'AudioSidePanel',
      'SubtitlesSidePanel',
      'BackgroundSidepanel',
      "EpisodeListing"
    ],
}
