import { Lightning, Router, Colors } from '@lightningjs/sdk'
import { fontsizes } from '../../Helper/GlobalConstants'

export default class IconDynamicButton extends Lightning.Component {
  static _template() {
    return {
      h: (h) => h,
      w: (w) => w,

      Background: {
        w: (w) => w,
        h: 66,
        rect: true,
        texture: Lightning.Tools.getRoundRect(150, 40, 0, 1, Colors('strokeColor').get(), true, Colors('unfocusedBtnColor').get()),

        Label: {
          y: 40,
          x: 32,
          mountY: 0.7,
          text: {
            fontFace: 'Bold',
            fontSize: fontsizes.iconDynamicbuttonText,
            textAlign: 'center',
            wordWrap: true,
            wordWrapWidth: 1000,
            maxLines: 1,
            maxLinesSuffix: '...',
            textColor: Colors('primaryFontColor1').get()
          },
        },
        Image: {
          src: '',
          w: 28,
          h: 28,
          x: 45,
          y: 44,
          mountX: 0.5,
          mountY: 0.9,
        },
      },
      IconImage: {
        src: '',
        w: 35,
        h: 35,
        x: 40,
        y: 40,
        mountX: 0.5,
        mountY: 0.9,
      },
    }
  }

  _init() {
    if (this.w && !isNaN(this.w)) {
      return
    }
    this.tag('Label').on('txLoaded', () => {
      if (this._isBtnVertical) {
        // vertical btnList
        this.tag('Image').x = 30
        this.tag('Image').mountY = 0.83
        this.tag('Label').x = this.tag('Image').finalW + 40
        this.tag('Label').y = 40
        this.tag('Image').y = 40

        this.tag('Label').on('txLoaded', () => {
          //this.calculateWidth = this.tag('Label').finalW + 100

          this.tag('Background').patch({
            w: this.calculateWidth,
          })
        })
      } else {
        // only horizontal btnList

        if (this._btntype) {
          this.calculateWidth = this._iconImage
            ? this.tag('Label').finalW + this._textPadding * 2 + 50
            : this.tag('Label').finalW + this._textPadding * 2 + 10
        } else {
          this.calculateWidth = this.tag('Label').finalW + this._textPadding * 2
        }

        this.patch({
          w: this.calculateWidth,
        })

        if (this._iconImage != undefined && this._iconImage != '') {
          this.tag('Label').x = 72
          this.tag('Label').y = 42
        }
      }
      if (this._isMoreAboutBtn) {
        // for more about icon only
        this.tag('Image').patch({
          h: 9,
          x: 33,
          y: 35,
          w: 32,
        })
      }
      if (this.is_episodeListing) {
        // for episode listing icon modifying w and h
        this.tag('Label').patch({
          x: 65
        })
        this.tag('Image').patch({
          w: 40,
          h: 40,
          y: 42
        })
      }
      this.setButtonStyles()
    })
  }

  // using the below lifecycle event to solve the verticalBtn prob
  // that causes not updating of focus[i.e the rectangle border] when wishlist label is changed
  _active() {
    if (this._isBtnVertical) {
      // vertical btnList
      this.tag('Image').x = 30
      this.tag('Image').mountY = 0.83
      this.tag('Label').x = this.tag('Image').finalW + 40
      this.tag('Background').texture = undefined

      this.tag('Label').on('txLoaded', () => {
        this.calculateWidth = this.tag('Label').finalW + 100

        this.tag('Background').patch({
          w: this.calculateWidth,
          h: this.tag('Label').finalY + 22,
        })
      })
    }
  }

  set isVerticalBtn(val) {
    // either true or false
    this._isBtnVertical = val
  }

  set isMoreAboutBtn(val) {
    this._isMoreAboutBtn = val
  }

  set isHorizontalBtn(val) {
    this._isHorizontalBtn = val
  }

  set isFontChange(v) {
    this._isFontSizeChange = v
    this.tag('Label').patch({
      mountY: 0.6,
      text: {
        fontSize: this._isFontSizeChange,
        lineHeight: 30,
        fontFace: 'Bold',
      },
    })
  }

  set action(v) {
    this._action = v
  }

  set episodesListing(bool) {
    this.is_episodeListing = bool
  }

  get action() {
    return this._action
  }

  set textPadding(padding) {
    this._textPadding = padding
  }

  set iconWithText(btnType) {
    this._btntype = btnType
  }

  set iconImage(Img) {
    this._iconImage = Img
  }

  set pageBtns(type) {
    this._btnsPage = type
  }

  set iconCheck(type) {
    this._checkIconStatus = type
  }

  set label(label) {
    this._label = label
    if (!this.dynamicWidth && this.w && !isNaN(this.w)) {
      this._textPadding = 0
      this.patch({
        w: this.w,
        Label: {
          w: this.w,
          text: {
            w: this.w,
          },
        },
      })
      this.setButtonStyles()
    }
    this.tag('Label').text.text = label
  }

  get label() {
    return this._label
  }

  set borderRadius(radius) {
    this._borderRadius = radius
  }

  set color(color) {
    this._color = color
  }

  get color() {
    return this._color
  }

  setButtonStyles() {
    const width = Router.getActiveHash().includes('details') ||
      Router.getActiveHash().includes('series') ? 381 : 178    // sometimes this.calculateWidth is coming as undefined, then we're giving hardcoded value
    // It will display only Image
    if (!this._checkIconStatus) {
      // Display Only Label without Image
      !this._btntype
        ? this.patch({
            Background: {
              Image: {
                alpha: 0,
              },
              Label: {
                x: this._textPadding,
                y: this.h / 2 + 2,
              },
            },

          })
        : this.patch({
            Label: {
              x: this._textPadding + 40,
              y: this.h / 2 + 2,
            },
          })
      // Display Only Label without Image fn end

      // Display Image with Label start
      this.hasFocus()
        ? this.patch({
            Background: {
              texture: Lightning.Tools.getRoundRect(
                this.calculateWidth || width,
                40,
                2,
                2,
                Colors('focusedStrokeColor').get(),
                true,
                Colors('focusedBtnColor').get()
              ),
              Image: {
                color: Colors('primaryFontColor2').get(),
                src: this._iconImage,
              },
              Label: {
                text: {
                  textColor: Colors('primaryFontColor2').get(),
                },
              }
            }

          })
        : this.patch({
            Background: {
              texture: this._isHorizontalBtn
                ? Lightning.Tools.getRoundRect(this.calculateWidth, 40, 2, 1, Colors('strokeColor').get(), true, Colors('unfocusedBtnColor').get())
                : undefined,
              Image: {
                color: Colors('primaryFontColor1').get(),
                src: this._iconImage,
              },
              Label: {
                text: {
                  textColor: Colors('primaryFontColor1').get(),
                },
              }
            },

          })
      // Display Image with Label start
    } else {
      this.tag('Background').alpha = 0
      this.tag('Label').alpha = 0
      this.patch({
        IconImage: {
          src: this._iconImage,
        },
      })
    }
  }

  _focus() {
    this.toggleFocus(true) // here we set focus into true.
  }

  _unfocus() {
    this.toggleFocus(false) // here we set focus as false.
  }

  toggleFocus(focus) {
    if (focus) {
      this._checkIconStatus
        ? this.patch({
            IconImage: {
              color: Colors('brandPrimaryColor').get(),
            },
          })
        : this.patch({
            Background: {
              texture: Lightning.Tools.getRoundRect(
                this.calculateWidth,
                40,
                2,
                2,
                Colors('focusedStrokeColor').get(),
                true,
                Colors('focusedBtnColor').get()
              ),
              Image: {
                color: Colors('primaryFontColor2').get(),
              },
              Label: {
                text: {
                  textColor: Colors('primaryFontColor2').get(),
                },
              },
            }

          })
    } else {
      this._checkIconStatus
        ? this.patch({
            IconImage: {
              color: Colors('primaryFontColor1').get(),
            },
          })
        : this.patch({
            Background: {
              texture: Lightning.Tools.getRoundRect(this.calculateWidth, 40, 2, 1, Colors('strokeColor').get(), true, Colors('unfocusedBtnColor').get()),
              Label: {
                text: {
                  textColor: Colors('primaryFontColor1').get(),
                }
              },
              Image: {
                color: Colors('primaryFontColor1').get(),
              }
            }
          })

      if (this._isBtnVertical && !this.isHorizontalBtn) {
        this.tag('Background').texture = undefined
      }
    }
  }
}
