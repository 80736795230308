import { Lightning, Router, Storage, Language, Utils, Colors } from '@lightningjs/sdk'
import { InlineContent } from '@lightningjs/ui-components'
import { navigateToLivePlayer, commaSeparatedwithSpaces, processGenreData, returnBannerImage, isEmptyObject, checkContentIsPurchased, getTitleType, returnImageUrl } from '../Helper/Helpers'
import { GENRE_CURATION, THEME_TWO, fontsizes, gradientColor, imageSizes } from '../Helper/GlobalConstants'
import App from '../App'
import configurations from '../Helper/Configurations'
import DynamicButton from './DynamicButton'
export default class Banner extends Lightning.Component {
  static _template() {
    return {
      Items: {
        w: App.width,
        h: 685,
        x: 180
      },
      IndicatorBox: {
        w: 1920,
        flex: { direction: 'row', justifyContent: 'center' },
        y: 580,
        Indicators: {
          zIndex: 5,
          flex: { direction: 'row' }
        },
      },
    }
  }

  set data(data) {
    this.banneritems = data.items
    this.tag('Indicators').children = data.items.map((item) => { return { type: CircleIndicator, alpha: 1, item } })
    if (data.items.length <= 1) this.tag('Indicators').alpha = 0
    this.tag('Items').children = data.items.map((item) => { return { type: BannerItem, alpha: 0, item } })
    const stateInfo = Storage.get('stateInfo') ? JSON.parse(Storage.get('stateInfo')) : ''
    this.bannerIndex = stateInfo?.card || 0
    this.index = 0
    this.previousItem = this.tag('Items').children[this.bannerIndex]
    this.previousItem.alpha = 1
    this.updateIndicator(this.bannerIndex)
  }

  _getFocused() {
    return this.tag('Items').children[this.bannerIndex]
  }

  _handleRight() {
    if (this.bannerIndex === this.banneritems.length - 1) {
      this.bannerIndex = this.banneritems.length - 1
      this.tag('Items').children[this.bannerIndex].alpha = 1
      this.previousItem = this.tag('Items').children[this.bannerIndex]
    } else if (this.bannerIndex < this.banneritems.length - 1) {
      this.bannerIndex++
      this.storeStateInfo()
      this.previousItem.alpha = 0
      this.tag('Items').children[this.bannerIndex].alpha = 1
      this.previousItem = this.tag('Items').children[this.bannerIndex]
    }
    this.updateIndicator(this.bannerIndex, 'right')
  }

  _handleLeft() {
    if (this.bannerIndex === 0) {
      Router.focusWidget('Menu')
      this.bannerIndex = 0
      this.tag('Items').children[this.bannerIndex].alpha = 1
      this.previousItem = this.tag('Items').children[this.bannerIndex]
    } else if (this.bannerIndex <= this.banneritems.length) {
      this.bannerIndex--
      this.previousItem.patch({ alpha: 0 })
      this.tag('Items').children[this.bannerIndex].patch({ alpha: 1 })
      this.previousItem = this.tag('Items').children[this.bannerIndex]
    }
    this.updateIndicator(this.bannerIndex, 'left')
  }


  _handleKey() { return }

  _handleEnter() {
    this.storeStateInfo()
    const { videoId, contentId, seasonsSlug, isLiveChannel, slug, isSeries } = this.banneritems[this.bannerIndex].item
    const { epgEnabled, categories } = configurations

    let episodeOverlayStatus
    if (isSeries == 1) {
      !videoId
        ? episodeOverlayStatus = 'hideOverlay'
        : (
          Storage.set('enteredEpisodeInfo', JSON.stringify({ videoId: videoId, seasonsSlug: seasonsSlug })),
          episodeOverlayStatus = 'showOverlay'
        )
      return Router.navigate(`detail/series/${contentId}`, { overlayStatus: episodeOverlayStatus })
    }

    if (isLiveChannel) {
      const liveCategory = categories.categoryData.find((obj) => obj.slug && obj.slug == 'live-channel')
      if (liveCategory) {
        const routePrefix = configurations.enableHlsStream ? 'hlsliveplayer' : 'dashliveplayer'
        Storage.set('EpgChannelSelected', JSON.stringify({ index: null, id: contentId, from: "card" }))
        epgEnabled
          ? Router.navigate(`${routePrefix}/${liveCategory.categoryId}/${contentId}`)
          : navigateToLivePlayer(this.banneritems[this.bannerIndex].item, true)
      } else {
        Router.navigate(`detail/vod/${slug} live`, { keepAlive: true })
      }
      return
    }

    Router.navigate(`detail/vod/${contentId}`, { keepAlive: true })
  }

  storeStateInfo() {
    const stateObj = {
      card: this.bannerIndex,
      banner: 1,
      home: Router.getActiveHash().includes('home') ? 1 : 0,
      carouselYPosition: 0,
    }
    Storage.set('stateInfo', JSON.stringify(stateObj))
  }

  updateIndicator(index, direction) {
    const orgIndex = index - this.index

    const patchIndicator = (index, borderColor, fillColor) => {
      const itemWidth = fillColor === Colors('brandPrimaryColor').get() ? 40 : 25
      const rectWidth = fillColor === Colors('brandPrimaryColor').get() ? 40 : 12
      const outline = fillColor === Colors('brandPrimaryColor').get() ? 2 : 0
      this.tag('Indicators').children[index].patch({
        w: itemWidth,
        RoundRectangle: {
          texture: Lightning.Tools.getRoundRect(rectWidth, 12, 6, outline, borderColor, true, fillColor)
        }
      })
    }

    // Update the current indicator
    patchIndicator(orgIndex, Colors('brandPrimaryColor').get(), Colors('brandPrimaryColor').get())

    // Reset the previous indicator if necessary
    if (this.previousIndex === 0 && direction === 'right') {
      this.previousIndex = 0
      patchIndicator(this.previousIndex, Colors('secondaryFontColor').get(), Colors('secondaryFontColor').get())
    } else if (this.previousIndex === this.index && direction === 'left') {
      this.previousIndex = this.index
    } else if (this.previousIndex >= 0) {
      patchIndicator(this.previousIndex, Colors('secondaryFontColor').get(), Colors('secondaryFontColor').get())
    }

    // Set the new previous index and update its indicator
    this.previousIndex = orgIndex
    patchIndicator(this.previousIndex, Colors('brandPrimaryColor').get(), Colors('brandPrimaryColor').get())
  }

  _handleUp() { return }

  _handleDown() {
    Router.getActivePage().showDetailWidget()
    Router.getActivePage().focusCarousel()
    Storage.set('stateInfo', JSON.stringify({ banner: 0 }))
  }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }
}

class CircleIndicator extends Lightning.Component {
  static _template() {
    return {
      w: 25,
      flex: { direction: 'row', justifyContent: 'center' },
      RoundRectangle: {
        texture: Lightning.Tools.getRoundRect(12, 12, 6, 0, Colors('secondaryFontColor').get(), true, Colors('secondaryFontColor').get()),
      },
    }
  }
}

class BannerItem extends Lightning.Component {
  static _template() {
    return {
      BannerGradient: {
        x: 580,
        w: 500,
        h: 654,
        rect: true,
        colorLeft: Colors('backgroundColor').get(),
        colorRight: gradientColor.bannerImgGradient,
        zIndex: 3,
      },
      Shadow: {
        zIndex: 3,
        x: -17,
        y: 413,
        alpha: 0,
        color: Colors('shadowColor').get(),
        texture: Lightning.Tools.getShadowRect(280, 100, 12, 16)
      },
      PortraitImage: {
        x: 15,
        y: 115,
        w: 280,
        h: 420,
        zIndex: 3,
        alpha: 0,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 12 }
      },
      BannerBgImage: {
        x: 582,
        w: 1158.3,
        h: 654,
        zIndex: 2,
      },
      ContentContainer: {
        h: 610,
        zIndex: 3,
        transitions: { alpha: { duration: 1 } },
        flex: { direction: 'column', justifyContent: 'center' },
        OnAir: {
          type: InlineContent,
          justify: 'flex-start',
          style: { iconW: 33, iconH: 33 },
          flexItem: { marginBottom: 20 }
        },
        TitleLogo: {
          w: 200, h: 200,
          flexItem: { marginBottom: 20 }
        },
        Title: {
          text: {
            fontSize: fontsizes.seriesPageTitle,
            textColor: Colors('primaryFontColor1').get(),
            maxLines: 1,
            maxLinesSuffix: '...',
            wordWrapWidth: 700,
            fontFace: 'Bold',
          },
          flexItem: { marginBottom: 10 }
        },
        TitleEN: {
          text: {
            fontSize: 40,
            textColor: Colors('primaryFontColor1').get(),
            maxLines: 1,
            maxLinesSuffix: '...',
            wordWrapWidth: 700,
            fontFace: 'Bold',
          },
          flexItem: { marginBottom: 10 }
        },
        TagsData: {
          type: InlineContent,
          justify: 'flex-start',
          content: [],
          customStyleMappings: {
            textStyle: {
              fontSize: fontsizes.seriesPageMetadata,
              textColor: Colors('primaryFontColor1').get(),
              textAlign: 'left',
              fontFace: 'Regular',
            },
          },
          flexItem: { marginBottom: 30 }
        },
        Description: {
          text: {
            wordWrapWidth: 750,
            textColor: Colors('primaryFontColor1').get(),
            fontSize: fontsizes.bannerItemMetaContainer,
            lineHeight: 30,
            maxLines: 3,
            maxLinesSuffix: '...',
          },
          flexItem: { marginBottom: 10 }
        },
        TVOD: {
          flex: { direction: 'row' },
          Icon: { w: 36, h: 36, flexItem: { marginRight: 10 } },
          Label: {
            text: {
              fontSize: fontsizes.seriesPageMetadata,
              textColor: Colors('tvodLabelColor').get(),
              wordWrapWidth: 500,
              fontFace: 'Bold',
              maxLines: 1,
              maxLinesSuffix: '...'
            }
          },
          flexItem: { marginBottom: 30 }
        },
        MoreDetails: {}
      },
    }
  }

  _init() {
    this.fadeAnimation = this.tag('ContentContainer').animation({
      delay: 0.2, duration: 0.3, actions: [
        { p: 'alpha', v: { 0: 0.001, 1: 1 } },
        { p: 'x', v: { 0: 130, 1: 45 } }
      ]
    })
    this.portraitFadeAnimation = this.tag('ContentContainer').animation({
      delay: 0.2, duration: 0.3, actions: [
        { p: 'alpha', v: { 0: 0.001, 1: 1 } },
        { p: 'x', v: { 0: 405, 1: 345 } }
      ]
    })
    this.tag('PortraitImage').on('txLoaded', () => { this.tag('Shadow').patch({ alpha: 1 }) })
    this.tag('PortraitImage').on('txError', () => { this.tag('Shadow').patch({ alpha: 0 }) })
  }

  set item(bannerData) {
    let data = bannerData.item
    const moreDetails = [
      {
        height: 64,
        width: 186,
        radius: 10,
        text: Language.translate('home.more_details.btn'),
        color: Colors('primaryFontColor1').get(),
        layout: 'rect',
      },
    ]
    this.tag('MoreDetails').children = moreDetails.map((button) => {
      return {
        type: DynamicButton,
        h: 55,
        borderRadius: 2,
        textPadding: 30,
        label: button.text,
        withoutTexture: false,
        flexItem: { margin: 20, alignSelf: 'stretch', maxHeight: 100 },
      }
    })
    const bannerBgImage = returnBannerImage(data)
    const { bannerType } = configurations
    const titleLogo = returnImageUrl(data.contentLogo, imageSizes.icon)
    const titleType = getTitleType(data)
    if (!isEmptyObject(configurations.myPurchases)) data = checkContentIsPurchased(data)

    this.patch({
      BannerBgImage: {
        src: returnImageUrl(bannerBgImage, imageSizes.banner)
      },
      PortraitImage: {
        src: data.posterPortrait && bannerType == THEME_TWO
          ? returnImageUrl(data.posterPortrait, 'lg')
          : Utils.asset('static_assets/fallback_poster.png'),
        alpha: bannerType == THEME_TWO ? 1 : 0
      },
      Shadow: { alpha: bannerType === THEME_TWO && data.posterPortrait ? 1 : 0 },
      ContentContainer: {
        x: configurations.bannerType == THEME_TWO ? 345 : 45,
        OnAir: {
          visible: data.isLiveChannel,
          content: [
            {
              icon: Utils.asset('static_assets/onair.png'),
              w: 33,
              h: 33
            },
            {
              text: Language.translate('live.on_air.text'),
              fontSize: fontsizes.bannerItemLiveChannelTitle,
              textColor: Colors('errorColor').get(),
              wordWrapWidth: 1060,
              lineHeight: 30,
              maxLines: 1,
              fontFace: 'Bold',
            }
          ],
        },
        TitleLogo: {
          visible: (data.contentLogo && configurations.bannerType != THEME_TWO && data.component != GENRE_CURATION && (titleType === 'onlyLogo' || titleType === 'logoAndTitle')),
          src: titleLogo && bannerType !== THEME_TWO ? titleLogo : null,
        },
        Title: {
          visible: !(titleType === 'onlyLogo' || titleType === 'onlyLocalTitle' || titleType === 'logoAndTitle'),
          text: {
            text: data.title || '',
            wordWrapWidth: 800,
          },
        },
        TitleEN: {
          visible: (titleType !== 'onlyLogo'),
          text: {
            text: titleType === 'logoAndTitle' && !data.isLocalLogo ? data.title : data.titleEn,
            wordWrapWidth: 900,
          },
        },
        Description: {
          visible: data.description && data.isLiveChannel,
          text: { text: data.description },
        },
        MoreDetails: { visible: true }
      }
    })
    this.showTagsData(data)

    if (data.alreadyPurchased) {
      if (!isEmptyObject(data.rent)) this.handleTVODUI('static_assets/expiry_icon_colored_large.png', data.rent.expires_in, 2)
      return
    }
    if (data.isTvod || data.availability === 2) {
      this.handleTVODUI('static_assets/rent_or_buy_large.png', Language.translate('plans.available_to_purchase.tag'), 5)
    }
  }

  handleTVODUI(image, text, margin) {
    this.patch({
      ContentContainer: {
        TVOD: {
          visible: true,
          Icon: { src: Utils.asset(image) },
          Label: { flexItem: { marginTop: margin }, text: { text: text } }
        }
      }
    })
  }

  showTagsData(data) {
    const slicedGenre = data.genre?.split(',').slice(0, 2).join(',')
    const genreTitle = data.genre ? processGenreData(slicedGenre) : ''
    const hr = Math.floor(data.durationMin / 60)
    const min = data.durationMin % 60
    const durationFormat = hr > 0 ? `${hr} Hour ${min} Minutes` : `${min} Minutes`
    const duration = data.durationMin ? ` |  ${durationFormat}` : ''
    const seasonText = data.totalSeasons > 1 ? Language.translate('common_keys.seasons.text') : Language.translate('common_keys.season.text')
    const episodesCount = data.seriesEpisodesCount > 1 ? Language.translate('common_keys.episodes.text') : Language.translate('content_detail.episodes.text')
    const slicedTags = data.tags?.split(',').slice(0, 3).join(',')
    const tagsData = data.tags ? commaSeparatedwithSpaces(slicedTags) : ''
    const genreORTags = data.genre && `${genreTitle}` || data.tags && `${tagsData}` || ''
    let age = ''
    if (data.age && configurations.enableAgeRating) {
      age = data.publishTime ? ` | ${data.age + '+'} | ` : `${data.age + '+'} | `
    } else {
      age = data.publishTime ? ' | ' : ''
    }

    let seriesSeasonCount = ''
    if (data.isSeries && !data.videoId) {
      seriesSeasonCount = (data.totalSeasons && data.totalSeasons > 0)
        && ` | ${data.totalSeasons} ${seasonText}`
        || (data.seriesEpisodesCount && data.seriesEpisodesCount > 0)
        && ` | ${data.seriesEpisodesCount} ${episodesCount}`
        || ''
    }
    this.patch({
      LiveChannelIndicator: { alpha: 0 },
      ContentContainer: {
        alpha: 1,
        TagsData: {
          visible: data.component !== GENRE_CURATION,
          content: [
            { text: data.publishTime || '', style: 'textStyle' },
            { text: age, style: 'textStyle' },
            { text: genreORTags, style: 'textStyle' },
            { text: data.isSeries ? seriesSeasonCount : duration, style: 'textStyle' }
          ],
        }
      },
    })
  }

  _getFocused() {
    return this.tag('MoreDetails').children[0]
  }

  _focus() {
    configurations.bannerType == THEME_TWO
      ? this.portraitFadeAnimation.start()
      : this.fadeAnimation.start()
  }
}

