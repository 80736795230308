import { Language, Lightning, Router, Colors } from '@lightningjs/sdk'
import App from '../../App'
import { fontsizes } from '../../Helper/GlobalConstants'


export default class DetailPageAudioSubtitles extends Lightning.Component {
  static _template() {
    return {
      h: App.height,
      alpha: 0,
      TopToBottomGradient: {
        x: 1297,
        w: App.width - 1297,
        h: App.height,
        rect: true,
        color: Colors('overlayColor').get(),
        zIndex: 2,
      },
      ContentContainer: {
        w: 450,
        x: 1490,
        y: 30,
        clipping: true,
        flex: { direction: 'column' },
        FlexBoxContainer: {
          w: 400,
          zIndex: 6,
          flex: { direction: 'column' },
          AudioTitle: {
            w: 400,
            text: {
              fontSize: fontsizes.audioSidePanelHeader,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 46,
              fontFace: 'Bold',
            },
          },
          AudioList: {
            flexItem: { marginBottom: 25 },
            w: 400,
            text: {
              fontSize: fontsizes.iconDynamicbuttonText,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 46,
              fontFace: 'Regular',
            },
          },
          SubtitleTitle: {
            w: 400,
            text: {
              fontSize: fontsizes.subtitlesSidePanelSubtitleHeader,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 46,
              fontFace: 'Bold',
            },
          },
          SubtitleList: {
            flexItem: { marginBottom: 25 },
            w: 400,
            text: {
              fontSize: fontsizes.iconDynamicbuttonText,
              textColor: Colors('primaryFontColor1').get(),
              wordWrapWidth: 399,
              textAlign: 'right',
              lineHeight: 46,
              fontFace: 'Regular',
            },
          },
        },
      },
    }
  }

  set data(val) {
    const { showAudio, showSubtitle } = val

    const updateList = (listTag, titleTag, data, translationKey) => {
        const value = data?.length > 0 ? data.join('\n  ') : ''
        listTag.patch({ alpha: data?.length > 0 ? 1 : 0, text: { text: value } })
        titleTag.patch({ alpha: data?.length > 0 ? 1 : 0, text: { text: Language.translate(translationKey) } })
    }

    updateList(this.tag('AudioList'), this.tag('AudioTitle'), showAudio, 'common_keys.audio.text')
    updateList(this.tag('SubtitleList'), this.tag('SubtitleTitle'), showSubtitle, 'common_keys.subtitles.text')

    this.tag('ContentContainer').patch({ y: 30 })
    this.setSmooth('alpha', 1)
  }

  _handleLeft() {
    Router.focusWidget('SidePanelNavigation')
  }

  _handleBack() {
    Router.focusWidget('SidePanelNavigation')
  }

  _handleDown() {
    if (this.tag('ContentContainer').finalH > 1000 &&
      (Math.abs(this.tag('ContentContainer').finalY) <=
        (this.tag('ContentContainer').finalH - 1000))) {
      this.tag('ContentContainer').setSmooth('y', this.tag('ContentContainer').finalY - 150)
    }
  }

  isScrollable() {
    return this.tag('ContentContainer').finalH > 1030 ? true : false
  }

  _handleUp() {
    this.tag('ContentContainer').finalH > 1000 && this.tag('ContentContainer').finalY + 180 <= 30
      ? this.tag('ContentContainer').setSmooth('y', this.tag('ContentContainer').finalY + 180)
      : this.tag('ContentContainer').setSmooth('y', 30)
  }

  _getFocused() { return }

  _handleKey() { return }

  _handleRight() { return }

}
