
import { Router, Language } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { Page, Detail } from '../Components'
import { IS_WISHLIST_COLLECTION_UPDATED, UPDATE_CONTINUE_WATCHING_COLLECTION, UPDATE_WISHLIST_COLLECTION } from '../Helper/GlobalConstants'
import { createPageComponents } from '../Helper/Helpers'
import configurations from '../Helper/Configurations'
import Loader from '../Components/Loader'
import EmptyPage from '../Components/EmptyPage'

export default class Wishlist extends Page {
    static _template() {
        return {
          VideoSlider: {
            x: 110,
            type: List,
            w: (w) => w,
            y: 660,
            h: 500,
            direction: 'column',
            scroll: 0,
            scrollTransition: { duration: 0.4 },
            signals: { onIndexChanged: true },
          },
          Detail: { type: Detail, visible: false },
          Loading: { type: Loader, visible: false },
          EmptyPage: { type: EmptyPage, visible: false }
        }
    }

    setListData(wishList, data, wishListVideos) {
      // category completedata list is stored
      this.wishListData = wishList
      this.wishListAPIData = data
      this.initialCarousels = wishListVideos
    }

    pageTransition(pageIn, pageOut) {
        const menu = pageIn.widgets.menu
        if(menu.alpha !== 1) {
            menu.visible = false
            menu.alpha = 0.001
            menu.menuCollapse()
            menu.setSmooth('alpha', 1, {delay: 0.2, duration: 0.2})
        }
        return super.pageTransition(pageIn, pageOut)
    }

    _getFocused() {
      return this.focusCarousel()
    }

    addLists(wishListedVideos) {
      // this shifts focus from menu to page
      Router.focusPage()
      if (wishListedVideos.length === 0) return this.showEmptyPage()
      const content = this.tag('VideoSlider')
      content.items.length === 0 && content.add(wishListedVideos)
      this.updatePersonaCarousels()
      this.focusCarousel()
    }

    showEmptyPage() {
      this.slider.clear()
      this.tag('Detail').visible = false
      this.tag('EmptyPage').visible = true
      this.tag('EmptyPage').data = {
        Message: Language.translate('my_list.your_list_is_empty.text'),
        Image: 'empty_heart.png'
      }
      return this._setState('EmptyPage')
    }

    get slider() {
      return this.tag('VideoSlider')
    }

    _handleLeft() {
      Router.focusWidget('Menu')
    }

    _handleBack() {
      Router.focusWidget('Menu')
    }

    focusCarousel() {
      this.tag('Detail').visible = true
      this._setState('VideoSlider')
      return this.tag('VideoSlider')
    }

    updateBackDropImg(image) {
      this.tag('Detail').updateBackDropImg(image)
    }

    updateDetailWidget(item) {
      this.tag('Detail').item = item
    }

    showDetailWidget() {
      this.tag('Detail').visible = true
    }

    updatePersonaCarousels() {
      // Re-updating the continue-watching & my-list carousels to get latest contents
      if (configurations.loggedIn) {

        const reorganizeCarousel = (collectionKey) => {
          const collectionExists = this.wishListAPIData.some(collection => collection.key == collectionKey.toLowerCase())

          if (collectionExists) {
            const videoSlider = this.tag('VideoSlider')
            const oldCarousel = videoSlider.items.find(collection => collection.items[0].item.key === collectionKey)
            const updatingExistingCarousel = this.wishListData.some(obj => obj.title === oldCarousel?.title)
            const newCarousel = createPageComponents(this.wishListData.filter(collection => collection.key === collectionKey.toLowerCase()))
            if (updatingExistingCarousel) {
                const carouselIndexToUpdate = videoSlider.items.findIndex(collection => collection.items[0].item.key === collectionKey)
                videoSlider.removeAt(carouselIndexToUpdate)
                videoSlider.addAt(newCarousel, carouselIndexToUpdate)
                if (carouselIndexToUpdate <= 1 && videoSlider.index > 1) videoSlider.setIndex(videoSlider.index - 1)
            } else {
                const carouselIndexToAdd = this.wishListData.findIndex(collection => collection.key === collectionKey.toLowerCase())
                if (carouselIndexToAdd === -1) {
                  if (!oldCarousel) return
                  videoSlider.remove(oldCarousel)
                  if (videoSlider.index === 0) {
                    videoSlider.clear()
                    return this.addLists(createPageComponents(this.initialCarousels))
                  }
                  return videoSlider.setIndex(videoSlider.index - 1)
                }
                videoSlider.addAt(newCarousel, carouselIndexToAdd)
                videoSlider.setIndex(0)
            }
          }
        }

        if (IS_WISHLIST_COLLECTION_UPDATED) {
            reorganizeCarousel('series')
            reorganizeCarousel('video')
        }

        UPDATE_CONTINUE_WATCHING_COLLECTION(false)
        UPDATE_WISHLIST_COLLECTION(false)
      }
    }

    static _states() {
      return [
        class VideoSlider extends this {
          _getFocused() {
            return this.tag('VideoSlider')
          }
        },

        class EmptyPage extends this {
          _getFocused() {
            return this.tag('EmptyPage')
          }
        }
      ]
    }
}
