import { Lightning, Colors } from '@lightningjs/sdk'
import App from '../../App'


export default class SidePanelBg extends Lightning.Component {
  static _template() {
    return {
      SidePanelBg: {
        zIndex: 5,
        alpha: 0,
        h: 1080,
        rect: true,
        color: Colors('overlayColor').get(),
      },
    }
  }

  set data(v) {
    switch (v.command) {
      case 'clear': {
        this.tag('SidePanelBg').patch({ alpha: 0, x: 1134.9 })
        break
      }
      case 'episodes': {
        this.tag('SidePanelBg').patch({ alpha: 1, x: 0, w: App.width })
        break
      }
      case 'audioSubtitles': {
        this.tag('SidePanelBg').patch({ alpha: 1, x: 1360, w: 785 })
        break
      }
      default:
        break
    }
  }

}
