import { Lightning, Utils, Router, Colors } from '@lightningjs/sdk'
export default class PlayerControls extends Lightning.Component {
  static _template() {
    return {
      x: 25,
      Background: {
        alpha: 0,
        w: 300,
        h: 100,
      },
      PlayerButtons: {
        zIndex: 4,
        y: 980,
      },
      Play: {
        x: 650,
        y: 450,
        flex: { direction: 'row' },
      },
      ContentTitle: {
        zIndex: 4,
        x: 30,
        y: 40,
        text: {
          fontSize: 36,
          textColor: Colors('primaryFontColor1').get(),
          wordWrapWidth: 1100,
          fontFace: 'Bold',
          maxLines: 1,
          maxLinesSuffix: '...',
        },
      }
    }
  }

  set data(v) {
    this.updateControls(v)
    const centerPlayPauseButtons = v.data.filter((obj) => obj.action === 'centerButtons')
    const isLivePlaying = Router.getActiveHash().includes('player') && Router.getActivePage().getIsLive()
    this.tag('Play').children = centerPlayPauseButtons.map((button, ind) => {
      return {
        type: PlayButton,
        button,
        flexItem: { marginRight: ind == 0 ? 240 : 200 },
        alpha: isLivePlaying && ind % 2 == 0 ? 0 : 1, // if live, dont show fwd/rwd buttons,fwd&rwd button indexes are even
      }
    })
  }

  set patchTitle(title) {
    this.tag('ContentTitle').patch({
      text: { text: title }
    })
  }

  set updateBtns(v) {
    this.updateControls(v)
  }

  setIndexToDefault() {
    this._index = 0
  }

  updateControls(v) {
    this.tag('Background').alpha = 1
    this.tag('PlayerButtons').alpha = 1
    this.tag('Play').alpha = 1
    this._index = this._index > 0 ? this._index : 0
    this.isPlaying = true
    this.buttonsData = v.data.filter((obj) => obj.action !== 'centerButtons')
    let x = 80
    this.tag('PlayerButtons').children = this.buttonsData.map((button, index) => {
      if (index != 0) x = x + 100
      if (button.action == v.action) this._index = index
      button.x = x
      return { type: PlayerButton, button }
    })
    this.tag('Background').patch({
      w: this.buttonsData.length * 100,
      alpha: 1,
    })
    this.tag('PlayerButtons').patch({
      w: this.buttonsData.length * 100,
      alpha: 1,
    })
  }

  _handleStop() {
    this._handleBack()
  }

  _handleEnter() {
    this.$handleFunctionality('enter')
  }

  $handleFunctionality(checkFunction) {
    const controlsAplhaValue = Router.getActivePage().returnControlsVisibility()
    if (controlsAplhaValue) {
      switch (checkFunction) {
        case 'enter': {
          Router.getActivePage().$playPause(this.buttonsData[this._index].action)
          break
        }
        case 'left': {
          if (this._index > 0) this._index--
          Router.getActivePage().hidePlayerControls()
          break
        }
        case 'right': {
          if (this._index < this.buttonsData.length - 1) this._index++
          Router.getActivePage().hidePlayerControls()
          break
        }
        case 'show|hide': {
          Router.getActivePage().hidePlayerControls()
          break
        }
        default:
          break
      }
    } else {
      Router.getActivePage().showPlayerControls()
    }
  }

  _handleLeft() {
    this.$handleFunctionality('left')
  }

  _handleRight() {
    this.$handleFunctionality('right')
  }

  _play() {
    this.isPlaying = true
    Router.getActivePage().hidePlayerControls()
  }

  _pause() {
    this.isPlaying = false
    Router.getActivePage().hidePlayerControls()
  }

  _handlePlayPause() {
    Router.getActivePage().$playPause('play')
  }

  _handleForward() {
    this.seekTime = Router.getActivePage()._handleForward()
  }

  _handleRewind() {
    this.seekTime = Router.getActivePage()._handleRewind()
  }

  _captureKeyRelease(event) {
    if (this.seekTime === 0 || event.keyCode === 38) return
    let keyType
    if (event.keyCode === 54 || event.keyCode === 228) {
      keyType = 'right'
    } else if (event.keyCode === 53 || event.keyCode === 227) {
      keyType = 'left'
    }

    if (event.keyCode === 54 || event.keyCode == 53 || event.keyCode === 228 || event.keyCode === 227) {
      clearTimeout(this.seekTimer)
      this.seekTimer = setTimeout(() => {
        Router.getActivePage().videoFwdandRwd(keyType, this.seekTime)
      }, 500)
    }
  }

  _handlePlay() {
    Router.getActivePage()._handlePlay()
  }

  _handlePause() {
    Router.getActivePage()._handlePause()
  }

  _handleBack() {
    Router.getActivePage()._handleBack()
  }

  _getFocused() {
    if (this.buttonsData?.length > 0)
      return this.tag('PlayerButtons').children[this._index]
  }

  _handleKey() { return }

  _handleUp() {
    this.$handleFunctionality('show|hide')
    Router.getActivePage().focusProgressBar()
  }

  _handleDown() {
    this.$handleFunctionality('show|hide')
  }
}

class PlayerButton extends Lightning.Component {
  static _template() {
    return {
      Image: {
        mount: 0.5,
      },
    }
  }
  set button(v) {
    this.buttonData = v
    this.patch({
      Image: {
        x: this.buttonData.x,
        y: this.buttonData.y,
        mountX: this.buttonData.mountX,
        mountY: this.buttonData.mountY,
        src: Utils.asset(this.buttonData.src),
      },
    })
  }

  _focus() {
    this.patch({ Image: { color: Colors('brandPrimaryColor').get(), }} )
  }

  _unfocus() {
    this.patch({ Image: { color: Colors('primaryFontColor1').get() }} )
  }
}

class PlayButton extends Lightning.Component {
  static _template() {
    return {
      Icon: {
        // mount: 0.5,
      },
    }
  }
  set button(v) {
    this.patch({
      Icon: {
        alpha: v.alpha,
        src: Utils.asset(v.src),
      },
    })
  }
}
