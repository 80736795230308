import { Lightning, Utils, Router, Colors } from '@lightningjs/sdk'
import configurations from '../../Helper/Configurations'
import { showExitPopUp } from '../../Helper/Helpers'

export default class Back extends Lightning.Component {
  static _template() {
    return {
      BackIcon: {
        w: 45,
        h: 45,
        zIndex: 9,
        src: Utils.asset('static_assets/arrowLeft.png'),
        FocusTexture: {
          x: -16,
          y: -8,
          alpha: 0,
          texture: Lightning.Tools.getRoundRect(75, 60, 2, 2, Colors('brandPrimaryColor').get(), false),
        },
      },
    }
  }

  _focus() {
    this.tag('FocusTexture').alpha = 1
  }

  _unfocus() {
    this.tag('FocusTexture').alpha = 0
  }

  _handleEnter() {
    if (configurations.loginMode &&
      (configurations.supportedLogins == 'qr' ||
        configurations.supportedLogins == 'credentials') &&
      (Router.getActiveHash().includes('authenticate') ||
        Router.getActiveHash().includes('login'))) return showExitPopUp()
    Router.getActivePage()._handleBack()
  }

  _handleBack() {
    if (Router.getActiveHash().includes('liveplayer')) {
      return Router.getActivePage().tag('MetaInfo').visible ? this._handleEnter() : Router.getActivePage().showOverlay()
    }
    this._handleEnter()
  }

  updatePosition(v) {
    this.patch({ BackIcon: { x: v[0], y: v[1] } })
  }

  _handleDown() {
    if (Router.getActiveHash().includes('authenticate') || Router.getActiveHash().includes('detail')) return


    if (Router.getActiveHash().includes('liveplayer')) {

      if (Router.getActivePage().tag('TVODOverlay').visible) return

      if (Router.getActivePage().tag('TVODUI').visible) return Router.getActivePage().focusComponent('TVODButton')

      if (Router.getActivePage().tag('MetaInfo').visible) return Router.getActivePage().focusComponent('metaInfo')

      return Router.getActivePage().focusComponent('channels')
    }

    if (Router.getActiveHash().includes('manageprofiles')) return Router.getActivePage().focusProfiles()

    Router.getActivePage().focusForms()
  }

  _handleKey() { return }

  _handleUp() {
    if (Router.getActiveHash().includes('liveplayer')) {

      if (Router.getActivePage().tag('TVODOverlay').visible || Router.getActivePage().tag('TVODUI').visible) return

      if (!Router.getActivePage().tag('MetaInfo').visible) return Router.getActivePage().showOverlay()
    }

    if (Router.getActiveHash().includes('login') ||
      Router.getActiveHash().includes('signup') ||
      Router.getActiveHash().includes('forgotpassword') ||
      Router.getActiveHash().includes('verificationmail'))
      Router.getActivePage().focusLanguageSelection()
  }

  _handleLeft() {
    if (Router.getActiveHash().includes('liveplayer')) {

      if (Router.getActivePage().tag('TVODOverlay').visible || Router.getActivePage().tag('TVODUI').visible) return

      if (!Router.getActivePage().tag('MetaInfo').visible) return Router.getActivePage().showOverlay()
    }

    if (Router.getActiveHash().includes('login') ||
      Router.getActiveHash().includes('signup') ||
      Router.getActiveHash().includes('forgotpassword') ||
      Router.getActiveHash().includes('verificationmail')) {
      Router.focusPage()
      Router.getActivePage().focusKeyboard()
    }
  }

  _handleRight() {
    if (Router.getActiveHash().includes('liveplayer')) {

      if (Router.getActivePage().tag('TVODOverlay').visible || Router.getActivePage().tag('TVODUI').visible) return

      if (!Router.getActivePage().tag('MetaInfo').visible) return Router.getActivePage().showOverlay()
    }

    if (Router.getActiveHash().includes('login') ||
      Router.getActiveHash().includes('signup') ||
      Router.getActiveHash().includes('forgotpassword') ||
      Router.getActiveHash().includes('verificationmail') ||
      Router.getActiveHash().includes('authenticate') && configurations.locales.length > 1)
      Router.getActivePage().focusLanguageSelection()
  }
}
