import { Language, Lightning, Utils,Colors } from '@lightningjs/sdk'
import { FONT_REGULAR } from '../../Helper/GlobalConstants'

export default class DeviceItem extends Lightning.Component {
  static _template() {
    return {
      w: DeviceItem.width,
      h: DeviceItem.height,
      alpha: 1,
      Background: {
        texture: Lightning.Tools.getRoundRect(DeviceItem.width, DeviceItem.height, 0, 1, Colors('strokeColor').get(), true, Colors('cardColor').get(),)
      },
      DeviceMetaData: {
        x: 25,
        w: 646,
        h: DeviceItem.height,
        flex: { direction: 'row', justifyContent: 'space-between' },
        DeviceInfo: {
          h: DeviceItem.height - 10,
          flex: { direction: 'column', justifyContent: 'space-around', alignItems: 'flex-start' },
          DeviceTitle: {
            w: 646,
            y: 8,
            flex: { direction: 'row', justifyContent: 'space-between' },
            DeviceName: {
              flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
              Icon: { w: 36, h: 36, flexItem: { marginRight: 10 } },
              Label: {
                flexItem: { marginTop: 5 },
                text: {
                  textColor: Colors('primaryFontColor1').get(),
                  fontSize: 30,
                  fontFace: FONT_REGULAR,
                  wordWrapWidth: 400,
                  maxLines: 1,
                  maxLinesSuffix: '...'
                }
              }
            },
            CurrentDevice: {
              alpha: 0,
              y: 10,
              text: {
                textColor: Colors('primaryFontColor1').get(),
                fontSize: 24,
                fontFace: FONT_REGULAR,
                wordWrapWidth: 220,
                maxLines: 2,
                lineHeight: 35,
                maxLinesSuffix: '...',
              }
            },
          },
          Line: {
            texture: Lightning.Tools.getRoundRect(646, 1, 0, 0, Colors('strokeColor').get(), true, Colors('strokeColor').get())
          },
          IPAddress: {
            flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
            Icon: { w: 26, flexItem: { marginRight: 10 } },
            Label: {
              flexItem: { marginTop: 5 },
              text: {
                textColor: Colors('secondaryFontColor').get(),
                fontSize: 26,
                fontFace: FONT_REGULAR,
                wordWrap: true,
                wordWrapWidth: 350,
                maxLines: 1,
                maxLinesSuffix: '...'
              }
            },
          },
          CreatedAt: {
            h: 40,
            flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
            Icon: { w: 26, h: 26, flexItem: { marginRight: 10 } },
            Label: {
              flexItem: { marginTop: 5 },
              text: {
                textColor: Colors('secondaryFontColor').get(),
                fontSize: 26,
                fontFace: FONT_REGULAR,
                wordWrap: true,
                wordWrapWidth: 350,
                maxLines: 1,
                maxLinesSuffix: '...'
              }
            },
          },
          GeoLocation: {
            flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
            Icon: { w: 26, h: 26, flexItem: { marginRight: 10 } },
            Label: {
              flexItem: { marginTop: 5 },
              text: {
                textColor: Colors('secondaryFontColor').get(),
                fontSize: 26,
                fontFace: FONT_REGULAR,
                wordWrap: true,
                wordWrapWidth: 250,
                maxLines: 1,
                maxLinesSuffix: '...'
              }
            }
          }
        }
      },
      EditOrSignOut: {
        x: 300,
        y: 160,
        w: 380,
        h: 100,
        flex: { direction: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' },
        Label: {
          flexItem: { marginBottom: -10 },
          text: {
            textColor: Colors('secondaryFontColor').get(),
            fontSize: 26,
            fontFace: 'Regular',
            wordWrap: true,
            wordWrapWidth: 220,
            maxLines: 2,
            lineHeight: 35,
            maxLinesSuffix: '...'
          }
        },
        Icon: { w: 36, h: 36 }
      }
    }
  }

  set item(device) {
    this._item = device
    const date = new Date(device.updatedAt)
    const deviceIcon = this.returnDeviceIcon(device)

    const options = {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    }

    const convertedTime = date.toLocaleString('en-US', options)
    const formattedTime = convertedTime.replace('at', '')
    this.patch({
      DeviceMetaData: {
        DeviceInfo: {
          DeviceTitle: {
            DeviceName: {
              Icon: { src: Utils.asset(deviceIcon) },
              Label: { text: { text: device.deviceTitle } }
            },
          },
          IPAddress: {
            Icon: { src: Utils.asset('static_assets/ip_address.png') },
            Label: { text: { text: device.ipAddress } }
          },
          CreatedAt: {
            Icon: { src: Utils.asset('static_assets/created_at.png') },
            Label: { text: { text: formattedTime } }
          },
          GeoLocation: {
            Icon: { src: Utils.asset('static_assets/location.png') },
            Label: { text: { text: device.cityOrCountry } }
          }
        }
      }
    })
    if (device.currentDevice) {
      this.tag('CurrentDevice').patch({
        alpha: 1,
        text: { text: Language.translate('device_management.current_device.text') }
      })
    } else if (device.streamStatus) {
      this.tag('CurrentDevice').patch({
        alpha: 1,
        text: { text: Language.translate('device_management.currently_streaming.tag') }
      })
    } else {
      this.tag('CurrentDevice').alpha = 0
    }
    this.showEditOption(device)
  }

  returnDeviceIcon(device) {
    const deviceIcons = {
      'web': 'static_assets/device_web.png',
      'ios': 'static_assets/device_mobile.png',
      'android': 'static_assets/device_mobile.png',
      'default': 'static_assets/device_tv.png' // Default icon for other device types
    }

    // Determine the icon source based on the device type
    return deviceIcons[device.deviceType] || deviceIcons['default']
  }

  showEditOption(device) {
    this.tag('EditOrSignOut').patch({
      Label: {
        text: {
          text: device.currentDevice
            ? Language.translate('account.edit.text')
            : Language.translate('account.edit.text')
              + " " + Language.translate('device_management.or.text')
              + " " + Language.translate('common_keys.sign_out.btn'),
        }
      },
      Icon: { src: Utils.asset('static_assets/Angle_Right.png') }
    })
  }

  _focus() {
    this.tag('Background').patch({
      texture: Lightning.Tools.getRoundRect(DeviceItem.width, DeviceItem.height, 0, 2, Colors('brandPrimaryColor').get(), true, Colors('cardColor').get(),)
    })
  }

  _unfocus() {
    this.tag('Background').patch({
      texture: Lightning.Tools.getRoundRect(DeviceItem.width, DeviceItem.height, 0, 1, Colors('strokeColor').get(), true, Colors('cardColor').get(),)
    })
  }

  get item() {
    return this._item
  }

  static get width() {
    return 700
  }

  static get height() {
    return 280
  }
}
