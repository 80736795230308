import { Colors, Utils, Router, Language, Storage } from '@lightningjs/sdk'
import App from '../App'
import { FONT_BOLD, FONT_REGULAR, gradientColor } from '../Helper/GlobalConstants'
import { showExitPopUp } from '../Helper/Helpers'
import configurations from '../Helper/Configurations'
import { Page } from '../Components'
import Lightning from '@lightningjs/sdk/src/Lightning'

export default class LoginOption extends Page {
  static _template() {
    return {
      Gradient: {
        zIndex: 0,
        w: App.width,
        h: App.height,
        rect: true,
        color: Colors('backgroundColor').get(),
      },
      LinearGradient: {
        zIndex: 2,
        x: 700,
        w: 220,
        rect: true,
        h: App.height,
        colorRight: gradientColor.bannerImgGradient,
        colorLeft: Colors('backgroundColor').get()
      },
      LogoContainer: {
        zIndex: 1,
        x: 100,
        h: App.height,
        flex: {
          direction: 'column',
          justifyContent: 'center'
        },
        Logo: {
          src: Utils.asset('static_assets/loginOptionLogo.png')
        },
        WelcomeText: {
          flexItem: { marginTop: 70 },
          text: {
            text: Language.translate('Your Gateway to Unlimited Entertainment.'),
            textColor: Colors('primaryFontColor1').get(),
            fontsize: 52,
            fontFace: FONT_BOLD,
            lineHeight: 60,
            wordWrapWidth: 600,
            maxLines: 2,
          }
        }
      },
      Divider: {
        zIndex: 1,
        x: 1020,
        y: 200,
        texture: Lightning.Tools.getRoundRect(1, 666, 1, 0, Colors('primaryFontColor1').get(), true, Colors('primaryFontColor1').get()),
      },
      BrandingStripe: {
        zIndex: 1,
        src: Utils.asset('static_assets/branding_stripes.png')
      },
      Heading: {
        y: 320,
        zIndex: 2,
        text: {
          text: 'Sign In or Sign Up using',
          fontFace: FONT_BOLD,
          fontSize: 40,
          lineHeight: 53,
          textColor: Colors('primaryFontColor1').get(),
        },
      },
      LoginOptions: {
        x: 1180,
        y: 439,
        zIndex: 2,
        flex: { direction: 'row', justifyContent: 'flex-start' },
        Credential: {
          CrImage: {
            x: 35,
            y: 35,
            src: Utils.asset('static_assets/person.png'),
          },
          Text: {
            x: 62,
            y: 275,
            text: {
              text: 'Via Email',
              fontFace: FONT_REGULAR,
              fontSize: 28,
              textColor: Colors('primaryFontColor1').get(),
            },
          },
          texture: Lightning.Tools.getRoundRect(246, 246, 4, 0, Colors('brandPrimaryColor').get(), true, Colors('brandPrimaryColor').get(),),
        },
        QrCode: {
          QrImage: {
            x: 40,
            y: 40,
            src: Utils.asset('static_assets/qr_code.png'),
          },
          Text: {
            x: 45,
            y: 275,
            text: {
              text: 'Via QR Code',
              fontFace: FONT_REGULAR,
              fontSize: 28,
              textColor: Colors('primaryFontColor1').get(),
            },
          },
          flexItem: { marginLeft: 40 },
          texture: Lightning.Tools.getRoundRect(246, 246, 4, 0, Colors('backgroundColor').get(), true, 0x10ffffff),
        },
      },
    }
  }

  calculateTextWidth() {
    this.tag('Heading').on('txLoaded', () => {
      this.width = this.tag('Heading').renderWidth
      const dynamicXPos = (App.width - this.width) / 2
      this.tag('Heading').patch({ x: dynamicXPos + 500 })
    })
  }

  preparePage() {
    this.calculateTextWidth()
    const prevRoute = Storage.get('prevRoute')
    prevRoute.includes('authenticate') ? this._setState('QrCode') : this._setState('Credential')
  }

  static _states() {
    return [
      class Credential extends this {

        _getFocused() {
          this.tag('QrCode').texture = Lightning.Tools.getRoundRect(246, 246, 4, 0, Colors('backgroundColor').get(), true, 0x10ffffff)
          this.tag('Credential').texture = Lightning.Tools.getRoundRect(246, 246, 4, 0, Colors('brandPrimaryColor').get(), true, Colors('brandPrimaryColor').get(),)
        }

        _handleEnter() {
          Router.navigate('login')
        }

        _handleRight() {
          this._setState('QrCode')
        }

        _handleBack() {
          configurations.loginMode ? showExitPopUp() : Router.back()
        }
      },
      class QrCode extends this {

        _getFocused() {
          this.tag('QrCode').texture = Lightning.Tools.getRoundRect(246, 246, 4, 0, Colors('brandPrimaryColor').get(), true, Colors('brandPrimaryColor').get(),)
          this.tag('Credential').texture = Lightning.Tools.getRoundRect(246, 246, 4, 0, Colors('backgroundColor').get(), true, 0x10ffffff)
        }

        _handleLeft() {
          this._setState('Credential')
        }

        _handleEnter() {
          Router.navigate('authenticate')
        }

        _handleBack() {
          configurations.loginMode ? showExitPopUp() : Router.back()
        }
      }
    ]
  }


}
